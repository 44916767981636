<template>
  <v-navigation-drawer
    dark
    width="220"
    app
    fixed
    class="cg-nav"
    :value="$vuetify.breakpoint.lgAndUp || appDrawerLeft"
    @input="$store.commit('setAppDrawerLeft', $vuetify.breakpoint.lgAndUp ? false : $event)"
  >
    <div
      class="px-3 py-2"
    >
      <v-menu
        offset-y
        v-if="user"
        flat
        rounded="0"
        attach
      >
        <template v-slot:activator="{ on }">
          <div
            v-on="on"
            class="pa-2 pb-4 d-flex white--text"
            style="cursor: pointer;"
          >
            <account-avatar
              v-if="account"
              :account="account"
              color="accent"
              size="40"
            />
            <user-avatar
              v-else-if="user"
              :user="user"
              color="accent"
              size="40"
            />
            <div
              class="px-2 d-flex flex-column justify-center flex-grow-1"
              style="min-width: 0;"
            >
              <v-tooltip
                v-if="account"
                bottom
                max-width="300"
              >
                <template v-slot:activator="{ on }">
                  <div
                    class="account-name"
                    :style="account.status === 'DELETED' ? { 'text-decoration': 'line-through' } : {}"
                    v-on="on"
                  >{{ (account && account.name) || accountFriendlyName }}</div>
                </template>
                <div>{{ (account && account.name) || accountFriendlyName }}</div>
              </v-tooltip>
              <div
                v-if="user"
                class="user-name"
              >
                {{ userFriendlyName }}
              </div>
            </div>
            <div class="d-flex flex-column justify-center" style="margin-top: -3px">
              <v-icon style="font-size: 13px">fa-sort-down</v-icon>
            </div>
          </div>
        </template>
        <dropdown-content />
      </v-menu>

      <v-divider
        v-if="user && account"
      />

      <v-list>
        <template
          v-for="({ path, active, icon, name, indicator, tooltip, restrict, meta = {}, children }, $index) in primaryNavItems"
        >
          <v-tooltip
            :key="'primary' + $index"
            right
            :disabled="$vuetify.breakpoint.smAndDown || (!tooltip && !(user.level >= 5 && restrict))"
          >
            <template v-slot:activator="{ on }">
              <v-list-item
                link
                :to="path ? { name: name || meta.name } : undefined"
                v-on="on"
              >
                <v-list-item-icon>
                  <v-badge
                    right
                    overlap
                    color="primary"
                    offset-y="7"
                    offset-x="7"
                    :content="indicator > 0 ? (indicator > 100 ? '100+' : indicator) : undefined"
                    :value="!!(indicator && indicator > 0)"
                  >
                    <v-icon>{{ icon }}</v-icon>
                  </v-badge>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ meta.label || name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <div>
              <div v-if="tooltip">{{ tooltip }}</div>
              <div v-if="user.level >= 5 && restrict"><strong>Restricted To:</strong> {{ restrict }}</div>
            </div>
          </v-tooltip>
          <!-- $route.matched.map(({ name }) => name).includes(name) -->
          <div
            v-if="active && children && children.length"
            :key="'primary-child-items' + $index"
            class="nav-child-items pl-6"
          >
            <v-list-item
              v-for="(childRoute, childRouteIndex) in children"
              :key="`childRoute-${childRouteIndex}`"
              link
              :to="childRoute"
              class="nav-child-item"
            >
              <v-list-item-icon>
                <v-icon>{{ childRoute.meta && childRoute.meta.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  (childRoute.meta && childRoute.meta.label) ||
                  childRoute.name ||
                  childRoute.path
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </template>
      </v-list>

      <v-divider
        v-if="primaryNavItems.length && secondaryNavItems.length"
      />
      <div
        v-if="primaryNavItems.length && secondaryNavItems.length"
        style="font-size: 12px; color: #fff;"
        class="mt-3 pl-1"
        >Pro Features</div>
      <v-list>
        <template
          v-for="({ path, icon, name, indicator, tooltip, restrict, meta = {}, active, children }, $index) in secondaryNavItems"
        >
          <v-tooltip
            :key="'secondary' + $index"
            right
            :disabled="$vuetify.breakpoint.smAndDown || (!tooltip && !(user.level >= 5 && restrict))"
          >
            <template v-slot:activator="{ on }">
              <v-list-item
                link
                :to="path ? { name: name || meta.name } : undefined"
                v-on="on"
              >
                <v-list-item-icon>
                  <v-badge
                    right
                    overlap
                    color="primary"
                    offset-y="5"
                    offset-x="5"
                    :content="indicator > 0 ? (indicator > 100 ? '100+' : indicator) : undefined"
                    :value="!!(indicator && indicator > 0)"
                  >
                    <v-icon>{{ icon }}</v-icon>
                  </v-badge>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ meta.label || name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <div>
              <div v-if="tooltip">{{ tooltip }}</div>
              <div v-if="restrict"><strong>Restricted To:</strong> {{ restrict }}</div>
            </div>
          </v-tooltip>
          <div
            v-if="active && children && children.length"
            :key="'secondary-child-items' + $index"
            class="nav-child-items pl-6"
          >
            <v-list-item
              v-for="(childRoute, childRouteIndex) in children"
              :key="`childRoute-${childRouteIndex}`"
              link
              :to="childRoute"
              class="nav-child-item"
              :disabled="disabled"
            >
              <v-list-item-icon>
                <v-icon>{{ childRoute.meta && childRoute.meta.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  (childRoute.meta && childRoute.meta.label) ||
                  childRoute.name ||
                  childRoute.path
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </template>
      </v-list>
      <template
        v-if="$restrict({ 'user.level': { $gte: 5 } })"
      >
        <v-divider />
        <div
          style="font-size: 12px; color: #fff;"
          class="mt-3 pl-1"
        >Admin</div>
        <v-list
          class="pt-1"
        >
          <v-list-item
            link
            :to="{ name: 'AdminInvoices' }"
          >
            <v-list-item-icon>
              <v-icon>fa fa-dollar-sign</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Invoices</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'AdminUsers' }"
          >
            <v-list-item-icon>
              <v-icon>fa fa-user</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="openQueues"
          >
            <v-list-item-icon>
              <v-icon>fab fa-stack-overflow</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Queues</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="openIncomingMessage"
          >
            <v-list-item-icon>
              <v-icon>fas fa-envelope-open-text</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Incoming Msg</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="openMessageLog"
          >
            <v-list-item-icon>
              <v-icon>fa fa-paper-plane</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Message Log</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </div>
    <div slot="append">
      <div
        v-if="
          !appLoading &&
          account &&
          ($restrict({ 'addons.type': 'BUNDLE-PATCH-TRIAL' }) || !$store.getters.addons.length)"
        class="flex-grow-1 text-center pb-1"
        :style="(trialObject) ? 'background-color: ' + trialObject.divColor : ''"
      >
        <div
          v-if="trialObject.header"
          style="text-align: center; color: #fff; margin-top: 3px"
        >
          <h2
            class="py-2 px-3"
            style="font-size: 16pt;"
          >{{ trialObject.header }}</h2>
        </div>
        <v-btn
          color="white"

          outlined
          :to="{ name: 'Upgrade' }"
          style="border: 1px solid white;"
          class="mb-2"
        >Start Free Trial</v-btn>
        <div
          v-if="trialObject.subMessage"
          class="pb-2"
        >
          <small
            style="text-align: center; color: #fff;"
          ><em>{{ trialObject.subMessage }}</em></small>
        </div>
      </div>
      <div
        class="pa-3 d-flex justify-center align-center pb-2"
      >
        <div>
          <v-btn
            fab
            color="primary"
            dark
            :loading="textChatLoading"
            @click="openTextChat"
          >
            <v-icon>fa fa-comment</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        class="primary text-center pa-1"
        style="color: #fff; font-size: 13px;"
      >
        <div>NEED HELP?</div>
        <div>CALL OR TEXT</div>
        <div>
          <a
            href="tel:18886054429"
            style="color: #fff; text-decoration: none;"
          >
            <strong>1 (888) 605-4429</strong>
          </a>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.cg-nav.v-navigation-drawer {
  background-color: #2C2B36;
  .account-name {
    font-size: 14px;
    line-height: 16px;
    max-height: 32px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    margin-bottom: 4px;
  }
  .user-name {
    font-size: 10px;
    line-height: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-list-item, .v-list-item::before, .v-list-item > .v-ripple__container {
    border-radius: 5px;
  }
  .v-list-item__icon {
    margin-right: 15px;
    .v-icon {
      font-size: 1rem;
    }
  }

  .v-list-item__title {
    font-size: 0.9rem;
  }
  .v-list-item--active {
    &:before {
      opacity: 0; // Fixes the color being slightly off.
    }
    background-color: $accent;
    &.nav-child-item {
      color: $accent;
      background-color: initial;
    }
  }
}
</style>

<script>
import { AccountAvatar, UserAvatar } from 'ui/components/Avatar'
import { mapActions, mapGetters, mapState } from 'vuex'
import { routes } from '@/router'
import DropdownContent from './DropdownContent'
import { Indicator } from 'ui/models'

export default {
  components: {
    AccountAvatar,
    UserAvatar,
    DropdownContent
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mini: false
    }
  },
  computed: {
    ...mapState([
      'appDarkMode',
      'appDrawerLeft',
      'appLoading'
    ]),
    ...mapGetters([
      'user',
      'account',
      'userFriendlyName',
      'accountFriendlyName'
    ]),
    ...mapState({
      textChatLoading: 'widgets/textChatLoading'
    }),
    allowedRoutes () {
      if (!this.user || !this.account) {
        return []
      }
      return routes.filter(route => {
        return !(route.meta && route.meta.restrict) ||
          this.$restrict(route.meta.restrict) ||
          (
            Object.keys(route.meta.navRestrict || {}).length &&
            this.$restrict(route.meta.navRestrict || {})
          )
      }).map(route => ({
        ...route,
        tooltip: route.meta && route.meta.tooltip,
        restrict: route.meta && route.meta.restrict
      }))
    },
    primaryNavItems () {
      return this.allowedRoutes.filter(route => route.meta && route.meta.nav === 'primary')
        .map(route => {
          route = {
            ...route,
            icon: route.meta.icon,
            ...(route.meta.indicator && route.meta.indicator.id
              ? { indicator: route.meta.indicator.value(Indicator.find(route.meta.indicator.id)) }
              : {}
            ),
            active: (this.$route?.matched || [])
              .map(({ name, meta = {} }) => name || meta.name)
              .includes(route.name || (route.meta || {}).name),
            children: (route.children || []).filter(r => !!(r.meta && r.meta.nav))
          }
          if (route.meta?.children && typeof route.meta.children === 'function') {
            route.children = route.meta.children(route.children, this.$store)
          }
          return route
        })
    },
    secondaryNavItems () {
      return this.allowedRoutes.filter(route =>
        route.meta &&
        route.meta.nav === 'secondary'
      )
        .map(route => ({
          ...route,
          icon: route.meta.icon,
          ...(route.meta.indicator && route.meta.indicator.id
            ? { indicator: route.meta.indicator.value(Indicator.find(route.meta.indicator.id)) }
            : {}
          ),
          active: (this.$route?.matched || [])
            .map(({ name, meta = {} }) => name || meta.name)
            .includes(route.name || (route.meta || {}).name),
          children: (route.children || []).filter(r => !!(r.meta && r.meta.nav))
        }))
    },
    // trialDaysRemaining () {
    //   const trialAddon = Addon.query().where(a => a.type === 'BUNDLE-PATCH-TRIAL').first()
    //   const endAt = this.account?.billing?.start_billing_on || trialAddon?.end_at || null
    //   if (endAt) {
    //     const now = (new Date()).getTime()
    //     const exp = (new Date(endAt)).getTime()
    //     return Math.ceil(((exp - now) / 1000) / (24 * 3600))
    //   }
    //   return null
    // },
    trialObject () {
      // const trial = this.trialDaysRemaining || 0
      const trialObject = {}
      // trialObject.daysLeft = trial
      // if (trialObject.daysLeft < 1) {
      //   trialObject.divColor = 'red'
      //   trialObject.header = 'Your trial has expired'
      // } else {
      //   trialObject.daysLeft > 7 ? trialObject.divColor = '#b9359b' : trialObject.divColor = 'orange'
      //   trialObject.header = trialObject.daysLeft + ' day' + (trialObject.daysLeft > 1 ? 's' : '') + ' left in trial'
      //   trialObject.subMessage = '*Billing starts after trial ends'
      // }
      trialObject.divColor = '#b9359b'
      trialObject.header = 'Try Patch Pro'
      trialObject.subMessage = '14 Day Access'
      return trialObject
    }
  },
  methods: {
    ...mapActions({
      openTextChat: 'widgets/openTextChat'
    }),
    async openQueues () {
      const component = await import('@/modules/Admin/Queues/QueuesDialog')
      this.$openWindow({
        component: component.default
      })
    },
    async openIncomingMessage () {
      const component = await import('@/modules/Admin/IncomingMessage/IncomingMessageDialog')
      this.$openWindow({
        component: component.default
      })
    },
    async openMessageLog () {
      const component = await import('@/modules/Admin/MessageLog/MessageLogDialog')
      this.$openWindow({
        component: component.default
      })
    }
  }
}
</script>
