<template>
  <div
    class="failed-charge-view pa-6"
  >
    <v-card
      class="failed-charge-card"
    >
      <v-toolbar
        dark
        flat
        class="drag-handle"
        color="error"
      >
        <v-toolbar-title>Payment Information</v-toolbar-title>

        <v-spacer />

        <v-btn
          v-if="$restrict({ 'user.level': { $gte: 5 } })"
          icon
          @click="$store.commit('setFailedChargeOverride', true)"
        >
          <v-icon>fa fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container
        fluid
        class="pa-4"
      >
        <p>Before we finish logging you in we need at least one valid Payment Source on file. Feel free to call support at 1-888-605-4429 if you have any questions.</p>

        <div
          class="mt-4"
        >
          <payment-sources
            disable-add
            dense
          />
        </div>

        <div
          class="text-center mt-8 mb-4"
        >
          <v-btn
            color="primary"
            x-large
            @click="addPaymentSource"
          >Add New Payment Source</v-btn>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<style lang="scss">
.failed-charge-view {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .failed-charge-card {
    max-width: 800px;
    width: 100%;
  }
}
</style>

<script>
import PaymentSources from '@/modules/Settings/Billing/PaymentSources'
import PaymentSourceEditorDialog from '@/modules/Settings/Billing/PaymentSourceEditor/PaymentSourceEditorDialog'

export default {
  components: {
    PaymentSources
  },
  methods: {
    async addPaymentSource () {
      const result = await this.$openWindow({
        component: PaymentSourceEditorDialog
      })
      if (result?.record) {
        this.$store.dispatch('rootBootstrap')
      }
    }
  }
}
</script>
