<template>
  <div>
    <v-card v-if="punchcardFields.length">
      <v-card-title class="offer-card-titles pb-0 pt-2">Loyalty Program</v-card-title>
      <v-simple-table
        dense
      >
        <thead>
          <tr>
            <th
              class="text-uppercase"
              :style="`color: ${$vuetify.theme.currentTheme.primary}`"
            >Punchcard</th>
            <th
              class="text-center text-uppercase"
              :style="`color: ${$vuetify.theme.currentTheme.primary}`"
            >Points</th>
            <th
              class="text-center text-uppercase"
              :style="`color: ${$vuetify.theme.currentTheme.primary}`"
            >Adjust</th>
            <th
              class="text-center text-uppercase"
              :style="`color: ${$vuetify.theme.currentTheme.primary}`"
            >New Points</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="punchcardField in punchcardFields"
            :key="punchcardField.schema.path"
          >
            <td>{{ punchcardField.schema.name }}</td>
            <td class="text-center">{{ punchcardField.points || '0'}}</td>
            <td>
              <div style="width: 115px; margin: 0 auto;">
                <field-ui
                  class="mt-1 mb-1"
                  dense
                  :label="null"
                  outlined
                  hide-details
                  :value="(punchcardField.newPoints || 0) - (punchcardField.points || 0)"
                  :schema="punchcardField.schema"
                  @input="$emit('changeContext', { path: punchcardField.schema.path, value: ((punchcardField.points || 0) * 1 + $event * 1) || punchcardField.points })"
                />
              </div>
            </td>
            <td class="text-center">{{ punchcardField.newPoints || '0'}}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div
        v-if="allChanges.length"
        class="pa-2 text-end"
      >
        <v-btn
          @click="$emit('clearChanges')"
          color="warning"
          small
          outlined
        >Clear Changes</v-btn>
        <v-btn
          class="ml-2 ml-sm-4"
          @click="save"
          color="primary"
          :disabled="loading"
          small
        >Save</v-btn>
        <v-btn
          class="ml-2 ml-sm-4"
          @click="saveAndCheckIn"
          color="primary"
          :disabled="loading"
          small
        >Save &amp; Check In</v-btn>
      </div>
      <div
        v-else
        class="pa-2 text-end"
      >
        <v-btn
          @click="checkIn"
          color="primary"
          :disabled="loading"
          small
        >Check In</v-btn>
      </div>
    </v-card>
    <v-card class="mt-4">
      <v-card-title class="offer-card-titles pb-0 pt-2">Available Offers</v-card-title>
      <v-simple-table v-if="sortedOffersAvailable.length">
        <thead>
          <tr>
            <th
              class="text-uppercase"
              :style="`color: ${$vuetify.theme.currentTheme.primary}`"
            >Name</th>
            <th
              class="text-uppercase"
              :style="`color: ${$vuetify.theme.currentTheme.primary}`"
            >Type</th>
            <th
              class="text-uppercase"
              :style="`color: ${$vuetify.theme.currentTheme.primary}`"
            >Points Required</th>
            <th class="text-end">
              <v-btn
                @click="loadOffersAvailable(false)"
                :disabled="loadingOffersAvailable"
                icon
                small
              >
                <v-icon small>fa-redo</v-icon>
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="offerEnv in sortedOffersAvailable"
            :key="offerEnv.offer._id"
          >
            <td class="pt-1 pb-1">
              <offer-ref
                v-if="offerEnv.offer._id"
                :record-id="offerEnv.offer._id"
              />
              <div v-if="offerEnv.offer && offerEnv.offer.description">
                {{ offerEnv.offer.description }}
              </div>
            </td>
            <td class="pt-1 pb-1">
              <div v-if="offerEnv.offer && offerEnv.offer.type">
                {{ offerEnv.offer.type }}
              </div>
            </td>
            <td>
              <div v-if="offerEnv.offer.punchcard_key && offerEnv.offer.points > 0">
                {{ offerEnv.offer.points }}
              </div>
            </td>
            <td class="text-end pa-2">
              <div style="display: inline-block; white-space: nowrap;">
                <v-btn
                  v-if="!!offerEnv.activation"
                  @click="deactivateOffer(offerEnv)"
                  color="warning"
                  :disabled="loading || loadingOffersAvailable"
                  outlined
                  small
                >Deactivate</v-btn>
                <v-btn
                  v-if="!(offerEnv.faults || []).length"
                  class="ml-2 ml-sm-4"
                  @click="redeemOffer(offerEnv)"
                  color="primary"
                  :disabled="loading || loadingOffersAvailable"
                  small
                >Redeem</v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div
        v-if="sortedOffersAvailable.length"
        class="pa-2 text-end"
      >
        <v-btn
          @click="activateOffer"
          color="success"
          small
        >Activate Offer</v-btn>
      </div>
      <v-card-text v-else>No offers are currently available.</v-card-text>
    </v-card>
    <v-card class="mt-4">
      <v-card-title class="offer-card-titles pb-0 pt-2">Redeemed Offers</v-card-title>
      <v-simple-table v-if="offersRedeemed.length">
        <thead>
        <tr>
          <th
            class="text-uppercase"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >Name</th>
          <th
            class="text-uppercase"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >Type</th>
          <th
            class="text-uppercase"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >Time</th>
          <th
            class="text-uppercase d-flex align-center justify-space-between"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >
            Offer ID
            <v-btn
              @click="loadOffersRedeemed"
              :disabled="loadingOffersRedeemed"
              icon
              small
            >
              <v-icon small>fa-redo {{ loadingOffersRedeemed ? 'fa-spin' : '' }}</v-icon>
            </v-btn>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="{ event, offer } in mappedOffersRedeemed"
          :key="event._id"
        >
          <td class="pt-1 pb-1">
            <offer-ref
              v-if="event.data && event.data.offer_id"
              :record-id="event.data.offer_id"
            />
          </td>
          <td class="pt-1 pb-1">
            <div v-if="offer && offer.type">
              {{ offer.type }}
            </div>
          </td>
          <td class="pt-1 pb-1">
            <div v-if="event.at">
              {{ $timestamp(event.at) }}
            </div>
          </td>
          <td>
            <div v-if="event.data && event.data.offer_id">
              {{ event.data.offer_id }}
            </div>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-card-text v-else>No offers have been redeemed.</v-card-text>
    </v-card>
  </div>
</template>

<style>
.offer-card-titles {
  font-size: 14px;
  font-weight: bold;
}
</style>

<script>
import { mapGetters } from 'vuex'
import { Contact, Event, Offer } from 'ui/models'
import { get, omit } from 'lodash'
import FieldUi from 'ui/components/Field'
import { getContactFriendlyName } from 'shared/util/friendlyName'
import stringify from 'fast-json-stable-stringify'
import OfferRef from 'ui/references/Offer'
import ActivateOfferDialog from './ActivateOfferDialog'

export default {
  components: {
    FieldUi, OfferRef
  },
  props: {
    context: {
      type: Object,
      required: true
    },
    contextChanged: {
      type: Object,
      required: true
    },
    allChanges: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      loadingOffersAvailable: false,
      loadingOffersRedeemed: false,
      offersAvailable: [],
      offersRedeemed: []
    }
  },
  computed: {
    ...mapGetters(['schemas']),
    punchcardFields () {
      return Object.entries(this.schemas).filter(([path, schema]) => {
        return path.startsWith('contact.') && schema.type === 'punchcard'
      }).map(([path, schema]) => {
        return {
          schema,
          points: get(this.context, path),
          newPoints: get(this.contextChanged, path)
        }
      })
    },
    contact () {
      return (this.contextChanged && this.contextChanged.contact) || {}
    },
    stringifiedContact () { // Used to determine if a reload to available offers is needed
      return stringify(omit((this.context && this.context.contact) || {}, ['id']))
    },
    sortedOffersAvailable () {
      const offersAvailable = [...this.offersAvailable]
      offersAvailable.sort((a, b) => {
        const _a = (get(a, 'offer.name') || '').toLowerCase()
        const _b = (get(b, 'offer.name') || '').toLowerCase()
        return (_a > _b) ? 1 : ((_b > _a) ? -1 : 0)
      })
      return offersAvailable
    },
    mappedOffersRedeemed () {
      return this.offersRedeemed.map(event => {
        return {
          event,
          offer: Offer.find(event.data?.offer_id)
        }
      })
    }
  },
  watch: {
    loadingOffersAvailable () {
      this.$emit('updateCount', this.loadingOffersAvailable ? 'loading' : this.offersAvailable.length)
    },
    offersAvailable () {
      const count = this.offersAvailable.filter(offerEnv => !(offerEnv.faults || []).length).length
      this.$emit('updateCount', this.loadingOffersAvailable ? 'loading' : count)
    },
    stringifiedContact (next, prev) {
      // Since reference to contact can change without contact data actually changing
      if (next !== prev) {
        this.queueLoadOffersAvailable()
      }
    }
  },
  methods: {
    async save () {
      this.loading = true
      try {
        await Contact.save(this.contextChanged.contact)
        this.$emit('clearChanges')
        this.loading = false
      } catch (e) {
        this.loading = false
        throw e
      }
    },
    async saveAndCheckIn () {
      await this.save()
      await this.checkIn()
    },
    async checkIn () {
      if ((await this.$confirm('Are you sure you want to check this contact in?', { title: 'Are you sure?' })) !== 'Yes') {
        return
      }
      this.loading = true
      try {
        await Event.api().post(
          '/events',
          {
            primary_key: '_id',
            primary_key_value: this.contact._id,
            event: 'checkin.finished',
            data: {
              device_id: 'portal'
            }
          }
        )
        await this.$alert(`A check-in was created for ${getContactFriendlyName(this.context.contact)}`, { title: 'Success!' })
        this.loading = false
      } catch (e) {
        this.loading = false
        throw e
      }
    },
    async loadOffersAvailable (loadRedeemed = true) {
      const promise = this.$http({
        method: 'GET',
        url: `/v2/contacts/${this.contact._id}/offers`,
        params: {
          ignore_faults: 'POINTS'
        }
      })
      const response = await this.$manageLoading('loadingOffersAvailable', promise)
      this.offersAvailable = (get(response, 'data.data') || [])
      if (loadRedeemed) {
        await this.loadOffersRedeemed()
      }
    },
    async loadOffersRedeemed () {
      const promise = this.$http({
        method: 'GET',
        url: `/v2/events?contact_id=${this.contact._id}&event=offer.redeemed`
      })
      const response = await this.$manageLoading('loadingOffersRedeemed', promise)
      this.offersRedeemed = response?.data?.data || []
    },
    async queueLoadOffersAvailable () {
      // Heavily debouncing this method as there are so many things that can trigger this
      this.loadingOffersAvailable = true
      clearTimeout(this.loadAfterChangeTimeout)
      this.loadAfterChangeTimeout = setTimeout(async () => {
        clearTimeout(this.loadAfterChangeTimeout)
        await this.loadOffersAvailable()
        clearTimeout(this.loadAfterChangeTimeout)
        this.loadingOffersAvailable = false
      }, 500)
    },
    async redeemOffer (offerEnv) {
      if (this.allChanges.length > 0) {
        return this.$alert('You must save the changes you made to the punchcard before continuing.', { title: 'Please Save Changes' })
      }
      if ((await this.$confirm('Are you sure you want to redeem this offer?', { title: 'Please Confirm' })) !== 'Yes') {
        return
      }

      const promise = this.$http({
        url: `/v2/offers/${offerEnv.offer._id}/redemptions`,
        method: 'POST',
        data: {
          channel: 'PORTAL',
          contact_id: this.contact._id
        }
      })
      await this.$manageLoading('loading', promise)
      this.$emit('loadContact')
      await this.queueLoadOffersAvailable()
      await this.$alert('This offer has been redeemed.', { title: 'Success' })
    },
    async deactivateOffer (offerEnv) {
      if (this.allChanges.length > 0) {
        return this.$alert('You must save the changes you made to the punchcard before continuing.', { title: 'Please Confirm' })
      }
      if ((await this.$confirm('Are you sure you want to deactivate this offer?')) !== 'Yes') {
        return
      }
      const promise = this.$http({
        url: `/v2/offers/${offerEnv.offer._id}/activations`,
        params: {
          contactId: this.contact._id
        },
        method: 'DELETE'
      })
      await this.$manageLoading('loading', promise)
      this.queueLoadOffersAvailable()
    },
    async activateOffer () {
      await this.$openWindow({
        component: ActivateOfferDialog,
        props: {
          contactId: this.contact._id
        }
      })
      this.queueLoadOffersAvailable()
    }
  },
  async created () {
    await this.loadOffersAvailable()
  }
}
</script>
