import Editor from './Editor'

const editorConfig = {
  noPadding: true
}

const oWindow = (block) => {
  return {
    width: '900',
    height: 900
  }
}

export {
  editorConfig,
  Editor,
  oWindow
}

export default {
  Editor,
  oWindow
}
