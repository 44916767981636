<template>
  <v-card>
    <v-card-title># of Clicks</v-card-title>
    <v-card-text>
      <div>This link has been clicked {{ count }} time{{ count === 1 ? '' : 's' }}.</div>
      <div
        class="text-right"
      >
        <v-btn
          color="secondary"
          outlined
          small
          @click="reset"
        >Reset Clicks</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Smartlink } from 'ui/models'

export default {
  props: {
    record: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    count () {
      return this.record?.hit_count || 0
    }
  },
  methods: {
    async reset () {
      if ((await this.$confirm('Are you sure you want to reset the click count to 0?  This cannot be undone.')) !== 'Yes') {
        return
      }
      await this.$http({
        method: 'POST',
        url: '/v2/smartlinks/' + this.record._id + '/reset_clicks'
      })
      await Smartlink.fetchOne(this.record._id, { force: true })
    }
  }
}
</script>
