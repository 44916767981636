import Model from './Model'

class Offer extends Model {
  static entity = 'offers'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      display_name: this.attr(undefined),
      type: this.attr(undefined), // PROMOTIONAL or LOYALTY
      description: this.attr(undefined),
      active: this.attr(undefined),
      smartlink_id: this.attr(undefined),
      discount_amount: this.attr(undefined),
      discount_percent: this.attr(undefined), // 25 = 25%,
      webform_id: this.attr(undefined),
      webform_skip_if_finished: this.attr(undefined),
      start_at: this.attr(undefined),
      end_at: this.attr(undefined),
      total_use_limit: this.attr(undefined),
      customer_use_limit: this.attr(undefined),
      points: this.attr(undefined),
      punchcard_key: this.attr(undefined),
      // This means "requires activation"
      auto_activate: this.attr(undefined),
      redemption_count: this.attr(undefined),
      last_redemption_at: this.attr(undefined),
      last_redemption_by: this.attr(undefined),
      require_verification: this.attr(undefined),
      filter: this.attr(undefined),
      barcode: this.attr(undefined),
      barcode_type: this.attr(undefined),
      redemption_instructions: this.attr(undefined),
      redemption_timeout_minutes: this.attr(undefined),
      redemption_value: this.attr(undefined),
      image: this.attr(undefined),
      image_background: this.attr(undefined),
      color_button: this.attr(undefined),
      color_button_text: this.attr(undefined),
      color_font: this.attr(undefined),
      color_background: this.attr(undefined),
      coupon_code: this.attr(undefined), // This is the activation code'
      custom_id: this.attr(undefined),
      deduct_points: this.attr(undefined), // Whether to deduct points after redeeming.  true || undefined/null mean yes
      skip_points: this.attr(undefined),
      require_security_code: this.attr(undefined),
      channel: this.attr(undefined), // The channel that this offer can be redeemed on.
      // KIOSK or WEB or POS (Pos should only show up on the offer editor if they have an addon)
      activation_props: this.attr(undefined),
      stats: this.attr(undefined),
      permissions: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      offersV2: this.attr(undefined),
      tags: this.attr(undefined)
    }
  }
}

export default Offer
