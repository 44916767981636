import get from 'lodash/get'
import pick from 'lodash/pick'
import moment from 'moment-timezone'

export default (value, schema, options = {}) => {
  if (!value || (typeof value === 'object' && !Object.keys(value).length)) {
    return ''
  }
  const v = { // This method accepts a value in many different formats.  All of them convert into this
    y: null,
    m: null,
    d: null
  }
  // accept {y, m, d} and {m, d} formats
  if (typeof value === 'object' && value.m && value.d) {
    Object.assign(v, pick(value, ['y', 'm', 'd']))
  // accept MM-DD format
  } else if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(value)) {
    const parts = value.split('-').map(v => v * 1)
    if (parts[0] > 0) {
      v.y = parts[0]
    }
    if (parts[1] >= 1 && parts[1] <= 12 && parts[2] >= 1 && parts[2] <= 31) { // Good enough for now
      v.m = parts[1]
      v.d = parts[2]
    }
  } else if (/[0-9]{2}-[0-9]{2}/.test(value)) {
    const parts = value.split('-').map(v => v * 1)
    if (parts[0] >= 1 && parts[0] <= 12 && parts[1] >= 1 && parts[1] <= 31) { // Good enough for now
      v.m = parts[0]
      v.d = parts[1]
    }
  // accept YYYY-MM-DD format (even when YYYY is 0000)
  }

  if (!v.m || !v.d) {
    return 'Invalid'
  }
  const format = get(options, 'format') || (v.y ? 'MMM D, YYYY' : 'MMM D')
  const obj = moment()
    .month(v.m - 1) // JS Date spec 0-11 month index
    .date(v.d)
  if (v.y) {
    obj.year(v.y)
  }
  return obj.format(format)
}
