import Trigger from '../Trigger'

class WebformFinished extends Trigger {
  static get title () {
    return 'Form Finished'
  }

  static get class () {
    return 'WebformFinished'
  }

  static get restrict () {
    return { 'addons.type': 'WEBFORMS' }
  }

  // These are deprecated in favor of shared/schemas/events/*
  get schemas () {
    return [
      { key: 'webform_id', name: 'Form ID', type: '_id', resource: 'webforms' },
      { key: 'duration', name: 'Duration', type: 'number' }
    ]
  }
}

export default WebformFinished
