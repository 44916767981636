import BaseField from './_BaseField'
import padStart from 'lodash/padStart'
import moment from 'moment-timezone'

class Time extends BaseField {
  static sanitize ({ value }) {
    if (typeof value === 'string') {
      value = value.trim().toUpperCase()
      if (value.match('PM')) {
        value = value.replace('PM', '').trim()
        const parts = value.split(':')
        parts[0] = parts[0] * 1 + 12
        value = parts.join(':')
      }
      const parts2 = value.replace('AM', '').trim().split(':')
      for (var i = 0; i < parts2.length; i++) {
        parts2[i] = padStart(parts2[i], 2, '0')
      }
      value = parts2.join(':')
      const m = moment('2010-01-01 ' + value, moment.ISO_8601, true)
      value = {
        h: m.hour(),
        m: m.minute(),
        s: m.second()
      }
    }

    if (!(value && typeof value === 'object' && value.h && value.h * 1 >= 0 && value.h * 1 < 24 && value.m && value.m * 1 > 0 && value.m * 1 < 60)) {
      return null
    }

    value.h *= 1
    value.m *= 1
    if (value.s && value.s * 1 >= 0 && value.s * 1 < 60) {
      // Do nothing for now
      value.s *= 1
    } else {
      value.s = 0
    }
    value.v = padStart(value.h, 2, '0') + ':' + padStart(value.m, 2, '0') + ':' + padStart(value.s, 2, '0')

    return value
  }

  static unsanitize ({ value }) {
    if (typeof value === 'object' && value && Object.keys(value).length > 0) {
      if (value.v) {
        return value.v
      } else if (value.h || value.m || value.s) {
        return padStart(value.h || 0, 2, '0') + ':' + padStart(value.m || 0, 2, '0') + ':' + padStart(value.s || 0, 2, '0')
      }
      return null
    } else if (typeof value === 'string' && value) {
      return value
    }
    return null
  }
}

export default Time
