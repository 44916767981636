import Trigger from '../Trigger'

class CheckinFinished extends Trigger {
  static get title () {
    return 'Check-in Finished'
  }

  static get class () {
    return 'CheckinFinished'
  }

  static get restrict () {
    return { 'addons.type': { $in: ['KIOSKS'] } }
  }

  // These are deprecated in favor of shared/schemas/events/*
  get schemas () {
    return [
      { key: 'flow_id', name: 'Kiosk Flow ID', type: 'text', resource: 'flows' }, // Would be nice to change this to ObjectId someday
      { key: 'device_id', name: 'Kiosk Device ID', type: 'number' },
      { key: 'duration', name: 'Check-in Duration (in seconds)', type: 'number' },
      { key: 'since_last', name: 'Seconds since last Check-in', type: 'number' }
    ]
  }

  static getDefaultProps () {
    return {
      occur: {
        frequency: 'EVERY',
        n: 1,
        start: 1
      }
    }
  }
}

export default CheckinFinished
