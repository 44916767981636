<template>
  <div>
    <v-select
      label="Date Range"
      :value="value.date_range"
      @input="$emit('change', ['date_range', $event])"
      :items="dateRangeItems"
      outlined
      dense
      :hint="dateRangePreview"
      :persistent-hint="!!dateRangePreview"
      :class="{ 'mb-3': !!dateRangePreview }"
    />
    <event-type-picker
      label="Select Event Types"
      :value="value.events"
      multiple
      @input="$emit('change', ['events', $event])"
    />
    <schemas-picker
      label="Select Export Columns"
      :schemas="schemaOptions"
      :value="value.paths"
      @input="$emit('change', ['paths', $event])"
      entity="events"
    />
    <v-checkbox
      class="mt-0"
      label="Include Deleted Events"
      :input-value="value.with_deleted"
      @click="$emit('change', ['with_deleted', !value.with_deleted])"
    />
    <v-card
      class="pa-3"
    >
      <filter-editor
        :disabled="!(value && value.paths && value.paths.length)"
        :schemas="exportedSchemas"
        :value="value.filter"
        @input="$emit('change', ['filter', $event])"
        outlined
        dense
      />
    </v-card>
    <!-- <account-picker
      label="Limit To Specific Accounts"
      multiple
    /> -->
  </div>
</template>

<script>
import SchemasPicker from 'ui/pickers/SchemasPicker'
import EventTypePicker from 'ui/pickers/EventTypePicker'
// import AccountPicker from 'ui/pickers/AccountPicker'
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'
import { flatten, pick, intersection } from 'lodash'
import dateRangeItems from 'shared/data/dateRanges'
import FilterEditor from 'ui/components/Filter'

export default {
  components: {
    SchemasPicker,
    EventTypePicker,
    FilterEditor
    // AccountPicker
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['schemas', 'schemasByEvent', 'account', 'timezone']),
    dateRangeItems () {
      const now = moment().tz(this.timezone)
      return dateRangeItems({ now })
        .filter(i => i.value !== 'custom')
        .map(item => {
          return {
            ...item,
            text: item.name
          }
        })
    },
    dateRangePreview () {
      if (!(this.value && this.value.date_range)) {
        return
      }
      const item = this.dateRangeItems.find(i => i.value === this.value.date_range)
      if (!item) {
        return null
      }
      const now = moment().tz(this.timezone)
      const { from, to } = item.calc({ now })
      return `${from.format('lll')} - ${to.format('lll')} -- ${this.timezone} timezone`
    },
    generalOptions () {
      const generalOptions = []
      Object.keys(this.account?.settings?.general || {})
        .forEach((name) => {
          const path = 'account.settings.general.' + name
          generalOptions.push(
            {
              name,
              group: 'General Account Fields',
              path
            }
          )
        })
      return generalOptions
    },
    schemaOptions () {
      return [
        ...this.eventSchemas,
        ...this.contactSchemas,
        ...this.selectedEventSchemas,
        ...this.accountSchemas,
        ...this.generalOptions,
        ...(intersection(this.value && (this.value.events || []), ['offer.activated', 'offer.redeemed']).length > 0 ? this.offerSchemas : [])
      ].filter(schema =>
        !schema.unselectable &&
        !schema.hidden && // Remove any "hidden" fields
        !schema.path.startsWith('contact._files') && // Don't show these fields
        !schema.path.startsWith('contact.claims') && // Don't show these fields
        !schema.path.startsWith('contact.redemptions') // Don't show these fields
      )
    },
    eventSchemas () { // Return array
      return Object.values(this.schemas)
        .filter(schema => schema.path.startsWith('event.'))
        .map(schema => ({ ...schema, group: 'Event Fields' }))
    },
    contactSchemas () { // Return array
      return Object.values(this.$store.getters.contactSchemas)
        .map(schema => ({ ...schema, group: 'Contact Fields' }))
    },
    accountSchemas () {
      return Object.values(this.schemas)
        .filter(schema => schema.path.startsWith('account.'))
        .map(schema => ({ ...schema, group: 'Account Fields' }))
    },
    offerSchemas () {
      return Object.values(this.schemas)
        .filter(schema => schema.path.startsWith('offer.'))
        .map(schema => ({ ...schema, group: 'Offer Fields' }))
    },
    selectedEventSchemas () { // Return array
      let schemasBySelectedEvents = this.schemasByEvent
      if (this.value && (this.value.events || []).length) {
        schemasBySelectedEvents = pick(this.schemasByEvent, this.value.events)
      }
      const selectedEventSchemas = Object.values(schemasBySelectedEvents)
        .map(({ name, schemas }) => {
          return Object.entries(schemas).map(([path, schema]) => ({
            ...schema,
            full_name: schema.full_name || `Event ${schema.name}`,
            path: path.startsWith('event.data.') ? path : `event.data.${path}`,
            group: `Event Type: ${name}`
          }))
        })
      return flatten(selectedEventSchemas)
    },
    exportedSchemas () {
      return ((this.value && this.value.paths) || [])
        .map(path => this.schemaOptions.find(s => s.path === path))
        .filter(s => !!s)
    }
  }
}
</script>
