import { fromPairs } from 'lodash'

/**
 * Retrieves a subset of schemas that pertain to an object
 *
 * @param {Schemas} schemas - Object containing full list of schemas
 * {
 *   "contact.first_name": "...",
 *   "contact.redemptions": "...",
 *   "contact.redemptions.offer_id": "...",
 *   "contact.redemptions.at": "..."
 * },
 * "contact.redemptions"
 *  @param {String} path - path to retrieve subset of schemas for
 *
 *  @returns {Schemas} - Subset of schemas based on path
 * {
 *   "offer_id": "...",
 *   "at": "..."
 * }
 */
export default (schemas, path) => {
  return fromPairs(
    Object.entries(schemas || {})
      .filter(([fieldPath]) => fieldPath.startsWith(path + '.'))
      .map(([fieldPath, schema]) => [
        fieldPath.replace(path + '.', ''),
        schema
      ])
  )
}
