<template>
  <v-card
    class="cg-editor with-footer"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>{{ recordChanged._id ? 'Edit Send From Address' : 'Create Send From Address' }}</v-toolbar-title>

      <v-spacer />

      <v-btn
        icon
        :disabled="loading"
        @click="$emit('close')"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
      class="px-8 py-4"
    >
      <v-card class="mb-2 pa-4">
        <p><strong>Email Alias</strong></p>
        <p>You can create an email alias to use as your Send From email address within Patch Blasts and Automations using the options below.</p>
        <v-select
          label="Sending Domain"
          :value="recordChanged.gateway_id"
          @input="$handleChange('record', 'gateway_id', $event)"
          dense
          outlined
          :disabled="loading || (!!recordChanged._id && !!record.gateway_id) || !gatewayItems.length"
          :items="gatewayItems"
          :hint="!gatewayItems.length ? 'Your account does not have any sending domains! Go to email settings and add one before creating an email alias.': undefined"
          persistent-hint
        />
        <v-text-field
          label="Name"
          :value="recordChanged.name"
          @input="$handleChange('record', 'name', $event)"
          dense
          outlined
          hint="The name the customer will see the email is from."
          :disabled="loading"
        />
        <v-text-field
          label="Prefix"
          :value="recordChanged.alias"
          @input="$handleChange('record', 'alias', $event)"
          dense
          outlined
          hint="This helps build subscriber trust, so make the prefix similar to the name."
          :disabled="loading || !!recordChanged._id"
          :rules="[checkValidAlias]"
        />
      </v-card>
      <v-card class="mb-2 pa-4">
          <p><strong>Inbox Preview</strong></p>
          <p>From: {{ preview }}</p>
      </v-card>
      <v-card class="pa-4">
        <p><strong>Forwarding Address</strong></p>
        <p>To receive responses, include a forwarding address you currently manage.</p>
        <v-text-field
          label="Forward To"
          :value="recordChanged.destination"
          @input="$handleChange('record', 'destination', $event.replace(/ /g, ''))"
          dense
          outlined
          :disabled="loading"
          :rules="[validateForwardTo]"
        />
      </v-card>
      <!-- Soon to add: hint="Multiple addresses can be separated by commas" -->
    </v-container>
    <div class="footer d-flex pa-2">
      <permissions-btn
        v-if="$store.getters.isMultiAccount"
        @input="$handleChange('record', 'permissions', $event)"
        :record="recordChanged"
        :disabled="loading"
      />
      <v-spacer />
      <v-btn
        color="primary"
        @click="$emit('close')"
        :disabled="loading"
        outlined
      >Cancel</v-btn>
      <v-btn
        class="ml-2"
        color="primary"
        @click="save"
        :disabled="!allChanges.length || !allValid"
        :loading="loading"
      >Save &amp; Close</v-btn>
    </div>
  </v-card>
</template>

<script>
import { EmailAlias, Gateway } from 'ui/models'
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import validEmail from 'shared/util/validEmail'
import { PermissionsBtn } from 'ui/components/Permissions'

export default {
  oWindow: {
    width: 600,
    height: 850
  },
  mixins: [
    createChangeTrackerMixin({ path: 'record' })
  ],
  components: {
    PermissionsBtn
  },
  props: {
    recordId: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      getting: true,
      saving: false,
      dnsAccess: false
    }
  },
  computed: {
    loading () {
      return this.saving || this.getting
    },
    gateways () {
      return Gateway.query()
        .where(r => r.channel === 'EMAIL' && !!r.outgoing)
        .get()
    },
    gatewayItems () {
      return this.gateways.map(gateway => ({
        value: gateway._id,
        text: gateway.address + (gateway.name ? ' (' + gateway.name + ')' : '')
      }))
    },
    record () {
      return EmailAlias.find(this.recordId)
    },
    gateway () {
      if (this.recordChanged?.gateway_id) {
        return Gateway.find(this.recordChanged.gateway_id)
      }
      return null
    },
    preview () {
      if (!this.gateway?.address || !this.recordChanged.alias) {
        return 'Jane Doe <jane@send.domain.com>'
      }
      if (this.recordChanged.name) {
        return `${this.recordChanged.name} <${this.email}>`
      }
      return this.email
    },
    email () {
      return (this.recordChanged.alias || '') + '@' + this.gateway?.address
    },
    isValidEmail () {
      return !!validEmail(this.email)
    },
    allValid () {
      return this.gateway &&
        this.isValidEmail &&
        this.validateForwardTo() === true
    }
  },
  async created () {
    if (this.recordId) {
      await EmailAlias.fetchOne(this.recordId)
    }
    this.getting = false
  },
  methods: {
    async save () {
      this.saving = true
      try {
        await EmailAlias.save({
          ...this.recordChanged
        })
        this.saving = false
        this.clearChanges()
        this.$emit('close')
      } catch (e) {
        this.saving = false
        throw e
      }
    },
    checkValidAlias () {
      if (!validEmail(this.recordChanged.alias + '@whatever.com')) {
        return 'Invalid prefix'
      }
      return true
    },
    validateForwardTo () {
      const invalid = ((this.recordChanged.destination || '') + '')
        .split(',')
        .map(address => address.trim())
        .filter(v => !!v)
        .find(v => !validEmail(v))
      if (invalid) {
        return invalid + ' is not valid'
      }
      return true
    }
  }
}
</script>
