import BaseField from './_BaseField'

class Checkbox extends BaseField {
  static sanitize ({ value }) {
    if (value === null || value === undefined) {
      return null
    }
    if (typeof value === 'boolean') {
      return value
    }
    // At this point value must be either a number or string
    if (typeof value !== 'number' && typeof value !== 'string') {
      return null
    }
    // If it's a number or numeric string, > 0 is true, 0 is false
    if (typeof value === 'number' || (typeof value === 'string' && value && !isNaN(value * 1))) {
      return value * 1 !== 0
    }
    // If it's a string...
    if (typeof value === 'string') {
      switch ((value + '').trim().toLowerCase()) {
        case 'true':
        case 't':
        case 'yes':
        case 'on':
        case 'y':
        case '1':
          return true
        case 'false':
        case 'f':
        case 'no':
        case 'off':
        case 'n':
        case '0':
          return false
      }
    }

    return null
  }
}

export default Checkbox
