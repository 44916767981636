import Action from '../Action'
import colors from 'shared/colors'

class DeactivateOffer extends Action {
  static get title () {
    return 'Deactivate Offer'
  }

  static get class () {
    return 'DeactivateOffer'
  }

  static get restrict () {
    return { 'addons.type': 'OFFERS' }
  }

  static get color () {
    return colors.maroonRed
  }
}

export default DeactivateOffer
