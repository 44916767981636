import sanitizeValue from './sanitizeValue'

/**
 * Sanitize the value within the op
 *
 * Sanitization for an op depends on the op type and should always
 * be attempted unless the op is an $unset or $pullAll op,
 * neither of which require/utilize a value.
 */
export default ({
  op,
  schema,
  options = {}
}) => {
  if (['$unset', '$pullAll'].includes(op.op)) {
    op = {
      ...op,
      value: undefined
    }
    delete op.value
  } else {
    op = {
      ...op,
      value: sanitizeValue({
        value: op.value,
        schema,
        options
      })
    }
  }
  return op
}
