var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fixed-menu',{staticClass:"cg-filter-by-account-picker",attrs:{"props":{
    fixed: true,
    offsetY: true,
    minWidth: '300',
    closeOnContentClick: false,
    left: _vm.left,
    maxHeight: '60vh'
  }},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('tooltip-button',_vm._g(_vm._b({},'tooltip-button',{
        icon: 'user-lock',
        tooltip: 'Select Account',
        position: 'bottom',
        disabled: _vm.disabled,
        iconSize: '20',
        caret: true,
        badgeValue: !!_vm.value,
        badgeDot: true,
        buttonSize: 'large'
      },false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',{staticClass:"cg-filter-by-account-picker-menu"},[_c('v-sheet',{staticClass:"fill-height"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){_vm.$emit('input', null),_vm.menu = false}}},[_c('v-list-item-icon',{staticClass:"mr-2",style:({ opacity: !_vm.value ? 1 : 0 })},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa fa-check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All Accounts")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.$emit('input', true),_vm.menu = false}}},[_c('v-list-item-icon',{staticClass:"mr-2",style:({ opacity: _vm.value === true ? 1 : 0 })},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa fa-check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("This Account")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }