import { fromPairs } from 'lodash'

import CheckinFinished from './CheckinFinished'
import CheckinStarted from './CheckinStarted'
import ContactCreated from './ContactCreated'
import ContactRfm from './ContactRfm'
import ContactUpdated from './ContactUpdated'
import ConversationStarted from './ConversationStarted'
import ConversationFinished from './ConversationFinished'
import DiscountCreated from './DiscountCreated'
import DocumentFinished from './DocumentFinished'
import GoalHit from './GoalHit'
import MessageIn from './MessageIn'
import MessageOut from './MessageOut'
import OfferRedeemed from './OfferRedeemed'
import OrderAbandoned from './OrderAbandoned'
import OrderDelivered from './OrderDelivered'
import OrderFinished from './OrderFinished'
import OrderFulfillment from './OrderFulfillment'
import OrderOutForDelivery from './OrderOutForDelivery'
import OrderRefunded from './OrderRefunded'
import OrderShipped from './OrderShipped'
import SmartlinkHit from './SmartlinkHit'
import WebformFinished from './WebformFinished'

const mapping = {
  'checkin.finished': {
    name: 'Check-in Finished',
    schemas: CheckinFinished
  },
  'checkin.started': {
    name: 'Check-in Started',
    schemas: CheckinStarted
  },
  'contact.created': {
    name: 'Contact Created',
    schemas: ContactCreated
  },
  'contact.rfm': {
    name: 'Contact RFM',
    schemas: ContactRfm
  },
  'contact.updated': {
    name: 'Contact Updated',
    schemas: ContactUpdated
  },
  'conversation.started': {
    name: 'Conversation Started',
    schemas: ConversationStarted
  },
  'conversation.finished': {
    name: 'Conversation Finished',
    schemas: ConversationFinished
  },
  'discount.created': {
    name: 'Discount Created',
    schemas: DiscountCreated
  },
  'document.finished': {
    name: 'Waiver Finished',
    schemas: DocumentFinished,
    requireWhitelist: true
  },
  'goal.hit': {
    name: 'Conversion',
    schemas: GoalHit
  },
  'message.in': {
    name: 'Incoming Message',
    schemas: MessageIn
  },
  'message.out': {
    name: 'Outgoing Message',
    schemas: MessageOut
  },
  'offer.redeemed': {
    name: 'Offer Redeemed',
    schemas: OfferRedeemed
  },
  'order.abandoned': {
    name: 'Order Abandoned',
    schemas: OrderAbandoned,
    requireWhitelist: true
  },
  'order.delivered': {
    name: 'Order Delivered',
    schemas: OrderDelivered
  },
  'order.finished': {
    name: 'Order Finished',
    schemas: OrderFinished,
    requireWhitelist: true
  },
  'order.fulfillment': {
    name: 'Order Fulfillment',
    schemas: OrderFulfillment
  },
  'order.out_for_delivery': {
    name: 'Order Out For Delivery',
    schemas: OrderOutForDelivery
  },
  'order.refunded': {
    name: 'Order Refunded',
    schemas: OrderRefunded
  },
  'order.shipped': {
    name: 'Order Shipped',
    schemas: OrderShipped
  },
  'smartlink.hit': {
    name: 'Smartlink Clicked',
    schemas: SmartlinkHit
  },
  'webform.finished': {
    name: 'Webform Finished',
    schemas: WebformFinished
  }
}

export default mapping

// env should contain account, addons, schemas (which are account schemas)
const getSchemas = (env) => {
  return fromPairs(
    Object.entries(mapping).map(([eventType, data]) => {
      if (data.schemas && typeof data.schemas === 'function') {
        data = {
          ...data,
          schemas: data.schemas(env)
        }
      }
      return [eventType, data]
    })
  )
}

export {
  getSchemas,
  CheckinFinished,
  CheckinStarted,
  ContactCreated,
  ContactUpdated,
  ContactRfm,
  ConversationStarted,
  ConversationFinished,
  DocumentFinished,
  GoalHit,
  MessageIn,
  MessageOut,
  OfferRedeemed,
  OrderAbandoned,
  OrderDelivered,
  OrderFinished,
  OrderFulfillment,
  OrderOutForDelivery,
  OrderRefunded,
  OrderShipped,
  SmartlinkHit,
  WebformFinished
}
