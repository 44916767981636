import Op from './Op'
import toNumber from 'lodash/toNumber'
import get from 'lodash/get'

class Inc extends Op {
  static getValues (params) {
    let value = get(params, 'value')
    let opValue = get(params, 'op.value')
    if ([null, undefined].includes(value) || isNaN(toNumber(value)) || value === Infinity) {
      value = 0
    }
    value = toNumber(value)

    if ([null, undefined].includes(opValue) || isNaN(toNumber(opValue) || value === Infinity)) {
      opValue = 1
    }
    opValue = toNumber(opValue)
    return {
      value,
      opValue
    }
  }

  static run (params) {
    const { value, opValue } = this.getValues(params)
    return value + opValue
  }

  static shouldRun ({ value, op }) {
    if (value === undefined || value === null) {
      return true
    }
    if (Array.isArray(value) || isNaN(toNumber(value)) || value === Infinity) {
      return false
    }
    return true
  }
}

export default Inc
