import Trigger from '../Trigger'

class SmartlinkHit extends Trigger {
  static get title () {
    return 'Smartlink Clicked'
  }

  static get class () {
    return 'SmartlinkHit'
  }

  static get restrict () {
    return { 'addons.type': 'SMARTLINKS' }
  }

  // These are deprecated in favor of shared/schemas/events/*
  get schemas () {
    return [
      { key: 'smartlink_id', name: 'Smartlink ID', type: '_id', resource: 'smartlinks' },
      { key: 'ip', name: 'Customer IP Address', type: 'text' },
      { key: 'redirect_url', name: 'Redirect URL', type: 'text' }
    ]
  }

  static getDefaultProps () {
    return {
      occur: {
        frequency: 'EVERY',
        n: 1,
        start: 1
      }
    }
  }
}

export default SmartlinkHit
