/**
 * The motivation for this file is due to the fact that the
 * vue-infinite-loading plugin was very glitchy when trying to use it
 * in cases where it was necessary to scroll up to load more (the activity feed
 * in Messenger and Contact Editor).
 */

export default ({
  scroller,
  distance = 300,
  loading,
  hasMore,
  loadMore
}) => {
  let scrollTop = 0
  let scrollHeight = 0
  let throttled
  let error = null
  return {
    async mounted () {
      this.scrollHandler = () => {
        if (throttled || error || loading.call(this) || !scroller.call(this)) {
          return
        }
        if (!hasMore.call(this)) {
          scroller.call(this).removeEventListener('scroll', this.scrollHandler, { passive: true })
        }
        if (scroller.call(this).scrollTop < distance) {
          throttled = true
          loadMore.call(this)
        }
      }
      this.resetScrollHandler = () => {
        error = null
        scroller.call(this).removeEventListener('scroll', this.scrollHandler, { passive: true })
        scroller.call(this).addEventListener('scroll', this.scrollHandler, { passive: true })
      }
      this.resetScrollHandler()
    },
    beforeDestroy () {
      scroller.call(this).removeEventListener('scroll', this.scrollHandler, { passive: true })
    },
    methods: {
      captureScrollTop () {
        if (scroller.call(this)) {
          scrollTop = scroller.call(this).scrollTop
          scrollHeight = scroller.call(this).scrollHeight
        }
      },
      restoreScrollTop () {
        if (scroller.call(this)) {
          scroller.call(this).scrollTop = scrollTop + (scroller.call(this).scrollHeight - scrollHeight)
        }
      },
      scrollerReady () {
        setTimeout(() => {
          throttled = false
          this.scrollHandler()
        }, 500)
      },
      scrollerError (e) {
        error = e || 'Error'
      }
    }
  }
}
