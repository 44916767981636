const entity = 'Smartlink'
const path = 'smartlink'

const schemas = {
  _id: { type: '_id', name: 'ID' },
  name: { type: 'text', name: 'Name' },
  account_id: { type: 'number', name: 'Account ID' },
  domain: { type: 'text', name: 'Domain Name' },
  untraceable_key: { type: 'text', name: 'Untraceable URL' },
  untraceable: { type: 'checkbox', name: 'Force Untraceable' },
  last_hit_at: { type: 'timestamp', name: 'Last Hit Time' },
  hit_count: { type: 'number', name: 'Hit Count' }
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path
}
