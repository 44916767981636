/* eslint-disable quotes */
/* eslint-disable quote-props */
export default {
  html: {
    wrap: (content) => {
      return `<html><body style="background-color:#f3f4f9;" bgcolor="#f3f4f9"><table align="center" style="width:600px;padding-top:1em;padding-bottom:1em;"><tr><td style="border: 1px #ccc solid;padding: 1em;background-color:#fff;"><div>${content}</div></td></tr></table><br /></body></html>`
    },
    unsubscribe: "<br /><br /><hr /><br /><table width=\"100%\"><tbody><tr><td rowspan=\"2\"><a target=\"_blank\" href=\"https://www.patchretention.com\"><img src=\"https://cdn.patchretention.com/assets/patch-powered-by-gray.png\" width=\"140px\" /></a></td><td style=\"padding-bottom: 5px;\"><span style=\"font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-weight: 300; font-size: 11px; color: #aaa;\"><a target=\"_blank\" href=\"{{ _url_unsubscribe }}\" style=\"color: #24a9e0;\">Unsubscribe from all notifications</a><br />Problems viewing this email? <a href=\"{{ _url_archive }}\" target=\"_blank\" style=\"color: #24a9e0;\">View in browser</a>.</span></td></tr><tr><td><span style=\"font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-weight: 300; font-size: 10px; color: #aaa;\">Patch provides communication based on your consent. Your privacy is very important to us.<br />For more information, find us at <a href=\"https://www.patchretention.com\" target=\"_blank\" style=\"color: #24a9e0;\">patchretention.com</a>.</span></td></tr></tbody></table>"
  },
  text: {
    unsubscribe: '\n\nIf you no longer want to receive messages from us you can unsubscribe by visiting the URL below:\n{{ _url_unsubscribe }}'
  }
}
