/**
 * @todo DRY use @shared/utils/randomString
 */

const get = require('lodash/get')

const defaultLength = 5

const parseParams = ({ params = {}, options = {} } = {}) => {
  return {
    length: get(params, 'length') || get(options, 'length') || defaultLength,
    possible: get(params, 'possible') || get(options, 'possible') || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
    preview: get(params, 'preview') || get(options, 'preview') || false
  }
}

const random = (args) => {
  const { length, possible, preview } = parseParams(args)
  let response = possible.substr(0, length)
  if (!preview) {
    response = ''
    for (var i = 0; i < length; i++) {
      response += possible.charAt(Math.floor(Math.random() * possible.length))
    }
  }

  return response
}

const getLengths = (args) => {
  const { length } = parseParams(args)
  return {
    shortest: length,
    longest: length
  }
}

export default random

const humanizeCall = (args) => {
  const { length } = parseParams(args)
  return `Random ${length} Chars`
}

export {
  humanizeCall,
  getLengths
}
