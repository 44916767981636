export default (user) => {
  if (!user) {
    return ''
  }

  let name = ''
  if (user && user.first_name) {
    name = user.first_name
  }
  if (user && user.last_name) {
    name = (name ? name + ' ' : '') + user.last_name
  }
  if (name) {
    return name
  }
  return user.email || user.phone || 'User #' + (user._uid || user._id)
}
