import component from './Image'

export default {
  id: 'image',
  name: 'Image',
  icon: 'far fa-image',
  component,
  factory () {
    return {
      props: {
        padding: '10px',
        width: 100
      }
    }
  }
}
