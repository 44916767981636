import ColumnHeader from './ColumnHeader'
import DataTable from './DataTable'
import Paginator from './Paginator'

export default DataTable

export {
  ColumnHeader,
  Paginator
}
