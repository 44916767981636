/**
 * Returns the path of a node based on strict reference comparison
 * @param {*} json - The entire email builder json object
 * @param {*} node - Node to look for
 */
const getPathForNode = (json, node, path = []) => {
  if (!json) {
    return
  }

  if (json === node) {
    return path.join('-')
  }

  if (!json.children?.length) {
    return null
  }

  const foundNode = json.children.find(n => n === node)
  if (foundNode) {
    return [
      ...path,
      json.children.indexOf(foundNode)
    ].join('-')
  }

  for (var i = 0; i < json.children.length; i++) {
    const found = getPathForNode(json.children[i], node, [
      ...path,
      i
    ])
    if (found) {
      return found
    }
  }
  return null
}

export default getPathForNode
