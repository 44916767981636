import { isEqual, get, fromPairs, camelCase } from 'lodash'
import createSyncQueryStringMixin from './createSyncQueryStringMixin'

export default (
  // The model to paginate (required)
  // This automatically adds the mixin for this model to the component
  Model,
  {
    // The prefix for the pagination object (defaults to Model.entity)
    // If model were "Contact" then pagination path would be "contactsPagination"
    prefix = null,
    defaultLimit = 25,
    defaultSort = [],
    // Path to where any other loading flag exists if it's desirable to disable
    // pagination while other resources are loading
    // For example, if we're loading a number of other resources initially when a page loads
    // and we want to disable the pagination buttons we can specify the path of the loading
    // flag here and if it's true then ${path}Pagination will also be true which will in turn
    // disable any pagination while that flag is true.
    // It may make sense to rename this to "loadingOverride" or something
    loadingPath = null,
    requestOptionsPath = null,
    syncQueryString = null,
    // Path to reference of html element to scroll to the top of after pagination change
    scroller = null
  }
) => {
  const path = `${camelCase(prefix || Model.entity)}Pagination`

  // Call this function bound to the context of the VM to add limit/offset
  // if not already defined
  const prepareRequestOptions = function (requestOptions = {}) {
    if (requestOptionsPath) {
      requestOptions = this[requestOptionsPath] || {}
    }

    const params = {
      ...(requestOptions?.params || {})
    }
    if (params.limit === undefined) {
      params.limit = this[`${path}Data`].limit
    }
    if (params.offset === undefined) {
      params.offset = this[`${path}Data`].offset
    }
    if (params.sort === undefined) {
      params.sort = this[`${path}Data`].sort
    }
    if (Array.isArray(params.sort)) {
      params.sort = params.sort
        .filter(v => Array.isArray(v) && v[0] && v[1])
        .map(v => v.join(':'))
        .join(',')
    }

    requestOptions = {
      ...(requestOptions || {}),
      params: {
        ...(requestOptions.params || {}),
        ...params
      }
    }

    if (this[`${path}Data`].restrictAccount) {
      requestOptions.params.restrict_account = this[`${path}Data`].restrictAccount
    }
    return requestOptions
  }

  const syncQueryStringMixin = []
  if (syncQueryString) {
    syncQueryStringMixin.push(
      createSyncQueryStringMixin(
        [
          {
            path: `${path}Data.limit`,
            name: 'limit',
            default: defaultLimit,
            transform: (v) => v * 1
          },
          {
            path: `${path}Data.offset`,
            name: 'offset',
            default: 0,
            transform: (v) => v * 1
          },
          {
            path: `${path}Data.sort`,
            name: 'sort',
            default: null,
            transform: (v) => v ? v.split(',').map(r => r.split(':')) : [],
            stringify: (v) => v.map(vv => vv.join(':')).join(',')
          }
        ],
        {
          namespace: syncQueryString.namespace || undefined
        }
      )
    )
  }
  return {
    mixins: [
      Model.mixin({ prefix }),
      ...syncQueryStringMixin
    ],
    data () {
      return {
        [`${path}Data`]: {
          limit: defaultLimit,
          offset: 0,
          totalCount: null,
          loading: false,
          latestRequestId: 0,
          sort: defaultSort,
          restrictAccount: null
        },
        [`${path}LastRequestOptions`]: null
      }
    },
    computed: {
      // Return true for this if either loadingPath or this pagination are loading
      [`${path}Loading`] () {
        if (loadingPath && !!this[loadingPath]) {
          return true
        }
        return this[`${Model.entity}Loading`] || this[`${path}Data`].loading
      },
      // Returns everything in data merged any other computed fields
      // This can be bound directly to the paginator component
      [path] () {
        const pagination = {
          ...this[`${path}Data`],
          sortFields: fromPairs(this[`${path}Data`].sort || []),
          loading: this[`${path}Loading`]
        }
        pagination.allowPrevious = !!(pagination.offset && pagination.offset > 0)
        pagination.allowNext = !!(pagination.totalCount && pagination.totalCount > (+pagination.offset + +pagination.limit))
        return pagination
      },
      [`${camelCase(prefix || Model.entity)}Paginator`] () {
        return {
          refresh: () => this[`${path}Load`](),
          previous: () => this[`${path}Previous`]({}, { debounced: true }),
          next: () => this[`${path}Next`]({}, { debounced: true }),
          'update:limit': (value) => {
            if (this[`${path}Data`].limit + '' !== value + '') {
              this[`${path}Data`].limit = value
              this[`${path}Load`]()
            }
          },
          'update:offset': (value) => {
            if (value < 0) {
              value = 0
            }
            if (this[`${path}Data`].offset + '' !== value + '') {
              this[`${path}Data`].offset = value
              this[`${path}Load`]()
            }
          },
          'update:sort': (value) => {
            // Value should be an array like this:
            // [
            //   ['first_name', 1],
            //   ['last_name', -1]
            // ]
            if (!Array.isArray(value)) {
              value = []
            }
            // Remove any unnecessary entries in this array
            value = value.filter(v => Array.isArray(v) && v[0] && v[1])
            if (!isEqual(this[`${path}Data`].sort || [], value)) {
              this[`${path}Data`].sort = value
              this[`${path}Load`]()
            }
          }
        }
      }
    },
    methods: {
      async [`${path}Load`] (requestOptions = {}) {
        // Give every request an ID so if it's not the latest one then it can be ignored
        const requestId = this[`${path}Data`].latestRequestId = (this[`${path}Data`].latestRequestId || 0) + 1
        requestOptions = prepareRequestOptions.call(this, requestOptions)
        this[`${path}LastRequestOptions`] = requestOptions
        try {
          const result = await this[`${camelCase(Model.entity)}FetchMany`](
            requestOptions,
            { force: true } // Always force with pagination, never use a cached result
          )

          if (this[`${path}Data`].latestRequestId > requestId) {
            // Ignore the request because another one has been made
            return
          }

          if (!isNaN(get(result, 'response.data.limit') * 1)) {
            this[`${path}Data`].limit = +result.response.data.limit
          }
          if (!isNaN(get(result, 'response.data.offset') * 1)) {
            this[`${path}Data`].offset = +result.response.data.offset
          }
          if (!isNaN(get(result, 'response.data.total_count') * 1)) {
            this[`${path}Data`].totalCount = +result.response.data.total_count
            // If there's at least one result and we're paginated past it, =
            // start over at 0 and reload
            if (
              result.response.data.total_count > 0 &&
              this[`${path}Data`].offset >= result.response.data.total_count
            ) {
              this[`${path}Data`].offset = 0
              return this[`${path}Load`](requestOptions)
            }
          }
          if (scroller && get(this, scroller)) {
            get(this, scroller).scrollTop = 0
          }
          this[`${path}Data`].loading = false
          return result
        } catch (e) {
          this[`${path}Data`].loading = false
          throw e
        }
      },
      async [`${path}Previous`] (requestOptions, options = {}) {
        if (this[path].allowPrevious) {
          this[`${path}Data`].offset -= this[`${path}Data`].limit
          if (this[`${path}Data`].offset < 0) {
            this[`${path}Data`].offset = 0
          }
          if (get(options, 'debounced')) {
            this[`${path}Data`].loading = true
            clearTimeout(this.loadDebounceTimeout)
            this.loadDebounceTimeout = setTimeout(() => this[`${path}Load`](requestOptions), 300)
          } else {
            await this[`${path}Load`](requestOptions)
          }
        }
      },
      async [`${path}Next`] (requestOptions, options = {}) {
        if (this[path].allowNext) {
          this[`${path}Data`].offset += +this[`${path}Data`].limit
          if (this[`${path}Data`].offset < 0) {
            this[`${path}Data`].offset = 0
          }
          if (get(options, 'debounced')) {
            this[`${path}Data`].loading = true
            clearTimeout(this.loadDebounceTimeout)
            this.loadDebounceTimeout = setTimeout(() => this[`${path}Load`](requestOptions), 300)
          } else {
            await this[`${path}Load`](requestOptions)
          }
        }
      }
    }
  }
}
