<template>
  <div>
    <editor-card
      v-if="record.type === 'PROMOTIONAL'"
      name="Form"
    >
      <p
        class="mb-0"
      >Require a form to be filled out before an offer can be activated?</p>
      <v-chip
        v-if="record.webform_id"
        class="my-2 mr-3"
        close
        @click:close="$emit('change', ['webform_id', null])"
      >
        <form-ref
          :record-id="record.webform_id"
        />
      </v-chip>
      <v-btn
        v-if="!record.webform_id"
        color="primary"
        class="mt-1"
        @click="selectResource"
      >Select Form</v-btn>

      <v-checkbox
        v-if="record.webform_id"
        class="mt-2"
        label="Skip showing this Form in the SmartOffer if the user has already filled it out before?"
        :input-value="!!record.webform_skip_if_finished"
        readonly
        dense
        hide-details
        @click="!disabled && $emit('change', ['webform_skip_if_finished', !record.webform_skip_if_finished])"
        v-bind="{ disabled }"
      />
    </editor-card>
    <editor-card
      name="Authentication"
      class="mb-3"
    >
      <div
        v-if="record.webform_id"
      >
        <p class="mb-0">The selected form's
          <a :href="`/${$store.getters.account?._id}/forms/${record.webform_id}?step=4`"
          target="_blank">
            authentication settings</a>
        will apply.</p>
      </div>
      <div
        v-else
      >
        <p class="mb-2">Require new contacts to sign up via:</p>
        <v-checkbox
          label="SMS"
          v-model="requireVerification"
          value="phone"
          multiple
          dense
          hide-details
          v-bind="{ disabled }"
          @change="handleAuthChange('phone', $event)"
        />
        <v-checkbox
          label="Email"
          v-model="requireVerification"
          value="email"
          multiple
          dense
          hide-details
          v-bind="{ disabled }"
          @change="handleAuthChange('email', $event)"
        />
      </div>
    </editor-card>
    <editor-card
      name="POS Integration"
      v-if="hasPosIntegration"
    >
      <div>
        <v-row>
          <v-col
            md="6"
          >
            <v-text-field
              label="Discount Percent"
              outlined
              dense
              :value="record.discount_percent || null"
              suffix="%"
              v-bind="{ disabled }"
              @input="$emit('change', ['discount_percent', $event])"
              :rules="numberFieldValidationRules"
            />
          </v-col>
          <v-col
            md="6"
          >
            <v-text-field
              label="Discount Amount"
              outlined
              dense
              :value="record.discount_amount || null"
              prefix="$"
              v-bind="{ disabled }"
              @input="$emit('change', ['discount_amount', $event])"
              :rules="numberFieldValidationRules"
            />
          </v-col>
        </v-row>
      </div>
    </editor-card>
    <editor-card
      name="Filter"
    >
      <div>
        <v-btn
          color="primary"
          :disabled="disabled"
          @click="editFilter()"
        >{{ hasFilter ? 'Edit' : 'Add' }} Filter</v-btn>
      </div>
    </editor-card>
    <editor-card
      name="Tracking"
      class="mb-3"
      no-padding
    >
      <div class="pt-5 px-4">
        <v-text-field
          label="Estimated value per redemption"
          persistent-placeholder
          prefix="$"
          type="tel"
          style="max-width: 200px"
          outlined
          dense
          :rules="numberFieldValidationRules"
          :value="record.redemption_value || null"
          v-bind="{ disabled }"
          @input="$emit('change', ['redemption_value', (!!($event * 1)) ? $event * 1 : null])"
        />
      </div>
    </editor-card>
  </div>
</template>

<script>
import EditorCard from 'ui/components/EditorCard.vue'
import createValidationMixin from 'ui/mixins/createValidationMixin'
import { isEmpty as filterIsEmpty } from 'shared/filter'
import FilterEditorDialog from 'ui/editors/FilterEditorDialog'
import FormRef from 'ui/references/Webform.vue'
import FormPickerDialog from 'ui/pickers/dialogs/WebformPickerDialog.vue'

export default {
  mixins: [
    createValidationMixin({
      numberField: {
        constraints: {
          numericality: {
            strict: true,
            notValid: 'must be a number'
          }
        }
      }
    })
  ],
  components: {
    EditorCard,
    FormRef
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasPosIntegration () {
      return this.$restrict({ 'addons.type': { $in: ['NCRSILVER'] } })
    },
    hasFilter () {
      return !filterIsEmpty(this.record?.filter)
    },
    requireVerification: {
      get () {
        return this.record?.require_verification || []
      },
      set (value) {
        this.$emit('change', ['require_verification', value])
      }
    }
  },
  methods: {
    handleAuthChange (key, checkboxArray) { // force at least 1 selected
      const otherKey = key === 'phone' ? 'email' : 'phone'
      if ((checkboxArray || []).length === 0) {
        this.requireVerification = [otherKey]
      }
    },
    async selectResource () {
      const result = await this.$openWindow({
        component: FormPickerDialog
      })
      if (result?.record?._id) {
        this.$emit('change', ['webform_id', result?.record?._id])
      }
    },
    async editFilter () {
      const result = await this.$openWindow({
        component: FilterEditorDialog,
        props: {
          filter: this.record.filter
        }
      })
      if (result?.record) {
        this.$emit('change', ['filter', result.record.filter])
      }
    }
  }
}
</script>
