import { get } from 'lodash'
import Models, { countAllRecords } from '../models'

const ComponentRegistry = {
  // When the Model.mixin is used in a component a "references" property should be
  // provided which contains all of the paths on the component instance that
  // contain reference to one or more records.  These result of these paths should
  // either be a single record or array of records.
  // Periodically the store will purge "itself" of unused records so the portal
  // process takes up less ram.  This background process will determine which
  // records to keep by creating a list of ids by iterating over all these components.
  // If references are not specified in a component then the data will disappear from
  // that component periodically.
  // This array is objects of { entity, component, references }
  componentsReferencingRecords: [],
  registerReference (params) {
    // Only add this record if there isn't an existing one like this
    if (!this.componentsReferencingRecords.find(r =>
      r.component === params.component &&
      r.entity === params.entity &&
      JSON.stringify(r.references) === JSON.stringify(params.references)
    )) {
      this.componentsReferencingRecords.push(params)
    }
  },
  getAllReferencedIds () {
    const all = {}
    this.componentsReferencingRecords.forEach(({ entity, component, references }) => {
      all[entity] = all[entity] || []
      try {
        references.forEach(path => {
          const value = get(component, path)
          if (Array.isArray(value)) {
            all[entity] = all[entity].concat(value.filter(v => !!v).map(v => v.$id || v._id).filter(v => !!v))
          } else if (value && (value.$id || value._id)) {
            all[entity].push(value.$id || value._id)
          }
        })
      } catch (e) {
        console.log('error with', entity, component, references)
        throw e
      }
    })
    return all
  },
  // Anytime data is updated (server through web socket or client side via http
  // request, new records are often added to the store conditionally on whether
  // they would be referenced by a component.  This is indicated by the Model.mixin
  // when an insertFilter is provided.
  // This is an array of objects of { entity, component, filter, insertId() }
  // where filter is a method that determines whether a component would use the record
  // and insertId() is a method that inserts the id of the record to the component's Model mixin data
  componentsListeningForInserts: [],
  registerListener (params) {
    this.componentsListeningForInserts.push(params)
  },
  getListenersForRecord (entity, record) {
    // List of components that have regisetered listeners for this model entity
    return this.componentsListeningForInserts.filter(listener => {
      if (listener.entity !== entity) {
        return false
      }
      return !!listener.filter.bind(listener.component)(record)
    })
  },
  destroyComponent (component) {
    this.componentsReferencingRecords = this.componentsReferencingRecords.filter(row => row.component !== component)
    this.componentsListeningForInserts = this.componentsListeningForInserts.filter(row => row.component !== component)
  },
  // Call this method periodically to remove all unused records
  evictUnusedRecords () {
    if (
      (typeof window !== 'undefined' && window.preventEvictRecords) ||
      (typeof global !== 'undefined' && global.preventEvictRecords)
    ) {
      return
    }
    const referencedIds = this.getAllReferencedIds()
    const modelsToEvict = Object.entries(Models).filter(([name, model]) => {
      return !!model.evictUnused
    })
    let evictCount = 0
    const evictEntities = []
    modelsToEvict.forEach(([name, model]) => {
      const beforeCount = model.all().length
      const referencedIdsForEntity = (referencedIds[model.entity] || []).map(i => i + '')
      model.delete(record => !referencedIdsForEntity.includes((record.$id || record._id) + ''))
      const afterCount = model.all().length
      evictCount += beforeCount - afterCount
      if (beforeCount > afterCount) {
        evictEntities.push(model.entity)
      }
    })
    console.log('evict', evictCount, 'records from', evictEntities, countAllRecords(), 'remaining')
  },
  countAllRecords () {
    return countAllRecords()
  }
}

setInterval(() => {
  ComponentRegistry.evictUnusedRecords()
}, 60000)

// TO-DO: Update implementetion for registry, window object is undefined on mobile
// window.ComponentRegistry = ComponentRegistry

export default ComponentRegistry
