import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { sync } from 'vuex-router-sync'
import routes from './routes'
import {
  applyAccountGuardToRouter
} from 'ui/router'
import restrict from 'shared/util/restrict'
import openWindow from 'ui/plugins/OWindows/openWindow.js'
import { startCase } from 'lodash'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (!(to.meta && to.meta.noAutoScroll)) {
      window.scrollTo(0, 0) // Seems like needed for ios
      return { x: 0, y: 0 }
    }
  }
})

sync(store, router)

applyAccountGuardToRouter(store, router)

router.enforceRoadblockAndWelcome = (to, from) => {
  if (
    ( // if the current account does not have module to be navigated to.
      to?.name !== from?.name &&
      to?.meta?.roadblock &&
      !restrict(store.getters.environment, to?.meta?.restrict || {})
    ) ||
    ( // If account is in trial mode and this route has a roadblock, show it
      store.getters['roadblock/accountExpirationRoadblock']
    )
  ) {
    store.commit('roadblock/setRoadblockUi', true)
  } else {
    store.commit('roadblock/setRoadblockUi', false)
    if (
      to?.name !== from?.name &&
      to?.meta?.welcome &&
      store.getters.account?._id &&
      store.getters.account?.status !== 'SIGNUP' &&
      store.getters.account?.settings?.show_welcome_dialogs &&
      (
        !store.getters.account?.activated_at ||
        (
          store.getters.account?.activated_at &&
          (new Date()).getTime() - (new Date(store.getters.account.activated_at)).getTime() < 24 * 3600 * 21 * 1000 // 3 weeks
        )
      ) &&
      !window.sessionStorage.getItem('welcome-dialog-' + to.name)
    ) {
      // Show welcome dialogs for active accounts up to 3 weeks after activation time
      setTimeout(async () => {
        const component = await to.meta.welcome()
        openWindow({
          component: component.default
        })
      }, 1000)
    }
  }
}

router.beforeEach((to, from, next) => {
  router.enforceRoadblockAndWelcome(to, from)
  next()
})

router.afterEach((to, from) => {
  const title = to.meta?.label || startCase(to.name)
  document.title = title
    ? `${title} - Patch Portal`
    : 'Patch Portal'
})

window.$getRouter = () => router

export default router
export { routes }
