import openWindow from 'ui/plugins/OWindows/openWindow'
import ContactEditor from '@/modules/Contacts/ContactEditor'

export default (action) => {
  const props = {
    ...action,
    initialData: action
  }
  openWindow({
    component: ContactEditor,
    props
  })
}
