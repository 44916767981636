import Editor from './Editor'
import TestDialog from './TestDialog'

const oWindow = (block) => {
  return {
    width: 700,
    height: 520
  }
}

export {
  Editor,
  TestDialog,
  oWindow
}

export default {
  Editor,
  oWindow
}
