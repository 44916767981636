<template>
  <div>
    <div
      class="text-center"
      style="font-size: 30px; line-height: 34px"
    >
      <strong>Welcome to Patch!</strong>
    </div>
    <div
      class="text-center mt-2 mb-6"
      style="font-size: 16px; line-height: 18px;"
    >Tell us a little bit about your business.</div>
    <v-card
      class="pa-7 pt-4"
    >
      <v-btn
        icon
        @click="$emit('back')"
        v-bind="{ disabled }"
      >
        <v-icon>fa fa-arrow-left</v-icon>
      </v-btn>
      <v-form
        @submit.prevent="next"
        class="mt-3"
      >
        <p>What industry are you operating in?</p>
        <v-select
          :value="account.settings && account.settings.category"
          outlined
          dense
          :items="categoryItems"
          @input="$emit('change', ['account', 'settings.category', $event])"
          v-bind="{ disabled }"
        />
        <p>What is your current Average Order Value (AOV)? Patch defaults all accounts to $100 per order, if you feel this is not in line with your business you can update it below. Unsure? That's ok! You can always update this later in your account settings.</p>
        <v-text-field
          :value="account.settings.rfm && account.settings.rfm.average_order_value / 100"
          class="mb-5"
          dense
          outlined
          :placeholder="aovPlaceholder"
          prefix="$"
          suffix="Dollars"
          type="number"
          @input="$emit('change', ['account', 'settings.rfm.average_order_value', $event * 100])"
          hide-details
          v-bind="{ disabled }"
        />
        <p>Tell us about your current marketing efforts so we can better assist you in setting up your account.</p>
        <v-checkbox
          label="I currently use Email marketing"
          class="ml-2"
          v-model="marketingChannels"
          value="EMAIL"
          dense
          hide-details
          multiple
          v-bind="{ disabled }"
        />
        <v-text-field
          v-if="marketingChannels.includes('EMAIL')"
          class="my-4"
          label="Who are you currently using for Email marketing?"
          v-model="providers.EMAIL"
          outlined
          dense
          hint="*Required"
          persistent-hint
          v-bind="{ disabled }"
        />
        <v-checkbox
          label="I currently use SMS marketing"
          class="ml-2"
          v-model="marketingChannels"
          value="SMS"
          dense
          hide-details
          multiple
          v-bind="{ disabled }"
        />
        <v-text-field
          v-if="marketingChannels.includes('SMS')"
          class="my-4"
          label="Who are you currently using for SMS marketing?"
          v-model="providers.SMS"
          outlined
          dense
          hint="*Required"
          persistent-hint
          v-bind="{ disabled }"
        />
        <v-checkbox
          label="I currently use another CRM or Marketing Software"
          class="ml-2"
          v-model="marketingChannels"
          value="CRM"
          dense
          hide-details
          multiple
          v-bind="{ disabled }"
        />
        <v-text-field
          v-if="marketingChannels.includes('CRM')"
          class="my-4"
          label="Who are you currently using for CRM or Marketing Software?"
          v-model="providers.CRM"
          outlined
          dense
          hint="*Required"
          persistent-hint
          v-bind="{ disabled }"
        />
        <div
          class="d-flex mt-8 align-center"
        >
          <div
            class="flex-grow-1 pr-4"
            style="font-size: 12px; line-height: 14px; opacity: 0.5"
          >
            If you have additional contacts outside of your marketing database please reach out to your Customer Success rep at (888) 605-4429 for help importing your external list(s).
          </div>
          <v-btn
            type="submit"
            color="primary"
            :loading="disabled"
            :disabled="!valid"
          >Continue</v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import accountCategories from 'shared/data/accountCategories'

export default {
  props: {
    account: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      marketingChannels: [],
      providers: {
        EMAIL: null,
        SMS: null,
        CRM: null
      }
    }
  },
  computed: {
    valid () {
      return !!this.account?.settings?.category &&
        (!this.marketingChannels.includes('EMAIL') || !!this.providers.EMAIL) &&
        (!this.marketingChannels.includes('SMS') || !!this.providers.SMS) &&
        (!this.marketingChannels.includes('CRM') || !!this.providers.CRM)
    },
    categoryItems () {
      let categories = accountCategories.map(c => ({
        text: c.name,
        value: c.value
      }))

      if (this.account?.settings?.category && !categories.find(c => c.value + '' === this.account.settings.category + '')) {
        categories = [
          {
            text: this.account.settings.category + ' (invalid)',
            value: this.account.settings.category
          },
          ...categories
        ]
      }

      return categories
    },
    aovPlaceholder () {
      let placeholder = ''
      if (Number.isInteger(this.account.settings?.rfm?.average_order_value)) {
        placeholder = (this.account.settings?.rfm?.average_order_value / 100) + ''
      } else {
        placeholder = '100'
      }
      return placeholder
    }
  },
  methods: {
    next () {
      if (this.marketingChannels.length) {
        let notes = 'Current Marketing:'
        this.marketingChannels.forEach(channel => {
          notes += '\n' + channel + ': ' + this.providers[channel]
        })
        this.$emit('change', ['account', '__citygro.notes', notes])
      }
      this.$emit('next')
    }
  }
}
</script>
