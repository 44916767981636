// Returns an object of all schemas added based on the Square addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact._square_id': {
      type: 'text',
      // immutable: true, // TODO: SetFields in the automation doesn't work if this is immutable
      name: 'Square Customer ID'
    },
    'contact._square_version': {
      type: 'text', // TODO: after we move away from automation, should be type: 'number', cast: 'int'
      // immutable: true, // TODO: SetFields in the automation doesn't work if this is immutable
      name: 'Square Customer Version'
    },
    'contact._square_created_at': {
      type: 'timestamp',
      immutable: true,
      name: 'Square Created At'
    }
  }
}

export {
  getSchemas
}

export default {
  getSchemas
}
