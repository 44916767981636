export default [
  {
    name: 'Beauty',
    value: 'beauty'
  },
  {
    name: 'Clothing',
    value: 'clothing'
  },
  {
    name: 'Cafe/Dessert',
    value: 'cafe/dessert'
  },
  {
    name: 'Electronics',
    value: 'electronics'
  },
  {
    name: 'Entertainment',
    value: 'entertainment'
  },
  {
    name: 'Furniture',
    value: 'furniture'
  },
  {
    name: 'Gym/Spa Services',
    value: 'gym/spa services'
  },
  {
    name: 'Handcrafts',
    value: 'handcrafts'
  },
  {
    name: 'Jewelry',
    value: 'jewelry'
  },
  {
    name: 'Painting',
    value: 'painting'
  },
  {
    name: 'Photography',
    value: 'photography'
  },
  {
    name: 'Restaurants',
    value: 'restaurants'
  },
  {
    name: 'Retail',
    value: 'retail'
  },
  {
    name: 'Groceries',
    value: 'groceries'
  },
  {
    name: 'Sports',
    value: 'sports'
  },
  {
    name: 'Toys',
    value: 'toys'
  },
  {
    name: 'Services',
    value: 'services'
  },
  {
    name: 'Virtual Services',
    value: 'virtual services'
  },
  {
    name: 'Waivers',
    value: 'waivers'
  },
  {
    name: 'Other',
    value: 'other'
  }
]
