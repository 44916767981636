export default ({
  url = null, // Will default to current hostname/protocol if not provided
  clientId, // Required
  clientVersion, // Required
  store = null, // Optionally provide reference to a VueX store to sync with socket
  onopen = null, // Optional function
  onclose = null, // Optional function
  onerror = null, // Optional function
  onmessage = null // Optional function
}) => {
  url = url || (((window.location.protocol === 'https:') ? 'wss://' : 'ws://') + window.location.host + `/socket_${clientId.toLowerCase()}`)

  url += `?client_id=${clientId}&client_version=${clientVersion}&use_modern=true`

  const socketManager = {
    url,
    socket: null,
    backoffSeconds: 5,
    getBackoffSeconds () {
      const backoffSeconds = this.backoffSeconds
      // Maximum of 60 seconds backoff
      if (this.backoffSeconds < 60) {
        this.backoffSeconds += 5
      }
      return backoffSeconds
    },
    open () {
      if (this.socket) {
        if ([WebSocket.OPEN, WebSocket.CONNECTING].includes(this.socket.readyState)) {
          console.warn('Socket is already OPEN or CONNECTING')
          return
        }
        this.socket.close()
      }
      this.socket = new WebSocket(this.url)

      // Check if it's connected after 10 seconds and if not, initiate reconnect
      clearTimeout(this.connectingTimeout)
      this.connectingTimeout = setTimeout(() => {
        if (this.socket.readyState !== WebSocket.OPEN) {
          this.close()
          this.open()
        }
      }, this.backoffSeconds * 1000)

      // Set up heartbeat to keep socket in sync with store
      clearTimeout(this.heartbeatInterval)
      this.heartbeatInterval = setInterval(() => {
        this.sendState()
      }, 20000)

      this.socket.onopen = event => {
        console.info('[SOCKET] onopen', event)
        if (typeof onopen === 'function') {
          onopen(event)
        }
        this.backoffSeconds = 5 // Reset backoff seconds on a successful connection
        clearTimeout(this.connectingTimeout)
        this.sendState()
      }

      this.socket.onmessage = event => {
        if (window.$verbose) {
          console.info('[SOCKET] onmessage', event)
        }

        let data = event.data
        if (typeof data === 'string') {
          try {
            data = JSON.parse(data)
          } catch (e) {
            console.info('Socket json parse error', e)
          }
        }

        if (typeof onmessage === 'function') {
          onmessage(event, data)
        }

        this.onMessage(event, data)
      }

      this.socket.onerror = event => {
        console.info('[SOCKET] onerror', event)
        if (typeof onerror === 'function') {
          onerror(event)
        }
        this.close()
        this.connectingTimeout = setTimeout(() => {
          this.open()
        }, this.getBackoffSeconds() * 1000)
      }

      this.socket.onclose = event => {
        console.info('[SOCKET] onclose', event)
        if (typeof onclose === 'function') {
          onclose(event)
        }
        clearTimeout(this.connectingTimeout)
        this.connectingTimeout = setTimeout(() => {
          this.open()
        }, this.backoffSeconds * 1000)
      }
    },
    close () {
      clearTimeout(this.connectingTimeout)
      if (!this.socket) {
        console.warn('No existing socket')
        return
      }
      if (this.socket.readyStatus !== WebSocket.CLOSED) {
        this.socket.close()
      }
      this.socket = null
    },
    send (data) {
      if (this.socket?.readyState === WebSocket.CLOSED) {
        this.open()
        console.warn('Socket not connected, attempting to connect.')
        return
      }
      if (this.socket?.readyState !== WebSocket.OPEN) {
        console.warn('Socket not connected.')
        return
      }
      if (typeof data !== 'string') {
        data = JSON.stringify(data)
      }
      if (this.socket) {
        this.socket.send(data)
      } else {
        console.warn('Could not acquire socket instance')
      }
    },
    sendState (additionalState = {}) {
      const data = {
        ...(store?.getters?.socketState || {}),
        ...additionalState
      }
      if (window.$verbose) {
        console.log('[SOCKET] sendState', data)
      }
      this.send({
        event: 'state',
        data
      })
    },
    async onMessage (event, data) {
      switch (data.event) {
        case 'getState':
          this.sendState()
          break
        case 'dispatch':
          if (store) {
            store.dispatch(data.action, data.data)
          }
          break
      }
    }
  }

  return socketManager
}
