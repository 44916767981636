var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.options)?_c('field-select',_vm._b({ref:"focusTarget",on:{"input":_vm.handleInput}},'field-select',_vm.propsOverride,false)):((
    _vm.schema.type === 'punchcard' ||
    _vm.schema.input_type === 'plus_minus' ||
    _vm.fieldProps.input_type === 'plus-minus' || // all existing fieldProps from the FieldEditor use this shape
    (
      _vm.inputType === 'increment' ||
      _vm.schema.input_type === 'increment'
    )
  ))?_c('div',{staticClass:"cg-field-number-plus_minus"},[_c('v-text-field',_vm._b({ref:"focusTarget",attrs:{"type":"tel"},on:{"input":_vm.handleInput},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-btn',{staticStyle:{"margin-top":"-2px"},attrs:{"icon":"","small":"","disabled":_vm.disabled || _vm.isMin},on:{"click":function($event){return _vm.incrementValue(-1 * _vm.increment)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-minus")])],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-btn',{staticStyle:{"margin-top":"-2px"},attrs:{"icon":"","small":"","disabled":_vm.disabled || _vm.isMax},on:{"click":function($event){return _vm.incrementValue(_vm.increment)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-plus")])],1)]},proxy:true}])},'v-text-field',_vm.vuetifyComponentProps,false))],1):_c('v-text-field',_vm._b({ref:"focusTarget",attrs:{"type":"tel"},on:{"input":_vm.handleInput}},'v-text-field',_vm.vuetifyComponentProps,false))
}
var staticRenderFns = []

export { render, staticRenderFns }