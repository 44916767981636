import Action from '../Action'
import colors from 'shared/colors'
import { render } from 'shared/smart-text'
import moment from 'moment-timezone'

class SelectByEvents extends Action {
  static get title () {
    return 'Select By Events'
  }

  static get class () {
    return 'SelectByEvents'
  }

  static get color () {
    return colors.warning
  }

  static get restrict () {
    return { 'user.level': { $gte: 5 } }
  }

  static getDefaultProps () {
    return {
      start_at: '{{ time({"offset":"-7D"}) }}',
      end_at: '{{ time() }}',
      aggregations: [
        {
          key: 'event_count',
          op: '$count'
        }
      ]
    }
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { account, sequence, block, blocks, paths } = environment
    const errors = []
    if (!block?.props?.event) {
      errors.push({
        message: 'Event is required',
        path: 'event'
      })
    }

    let startAt = block?.props?.start_at
    let endAt = block?.props?.end_at
    if (!startAt) {
      errors.push({
        message: 'From time is required',
        path: 'start_at'
      })
    }
    if (!endAt) {
      errors.push({
        message: 'To time is required',
        path: 'start_at'
      })
    }
    if (startAt && startAt.includes('{{')) {
      startAt = render(
        startAt,
        {},
        {
          timezone: account?.settings?.timezone
        }
      )
    }

    if (endAt && endAt.includes('{{')) {
      endAt = render(
        endAt,
        {},
        {
          timezone: account?.settings?.timezone
        }
      )
    }

    if (moment(startAt).isAfter(moment(endAt))) {
      errors.push({
        message: 'Start time cannot be after end time',
        path: 'start_at'
      })
    }

    if (moment(startAt).isBefore(moment(endAt).subtract(1, 'year'))) {
      errors.push({
        message: 'Start/end times cannot more than a year apart',
        path: 'start_at'
      })
    }

    return super.getErrors(environment).concat(errors)
  }
}

export default SelectByEvents
