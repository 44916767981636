const light = {
  // old
  // primary: '#2196F3',
  // secondary: '#03A9F4',
  // accent: '#E91E63',
  // new
  primary: '#B9359B',
  secondary: '#912791',
  accent: '#1BBC2D',
  error: '#F44336',
  warning: '#F57C00',
  info: '#1976D2',
  success: '#08B910',
  text: '#000000',
  default: '#909090',
  bg: '#f5f5f5',
  materialGray: '#9E9E9E',
  medgrey: '#A2A2A2',
  maroonRed: '#C7007C',
  goldYellow: '#F7BF00',
  lightPink: '#F845A5',
  lightPurple: '#776AFF',
  lightBlue: '#2196F3',
  lightGreen: '#4DE7A7',

  // These shouldn't be used
  periwinkle: '#746AFF',
  maintext: '#232323',
  chart1: '#08B910',
  chart2: '#2126F3',
  chart3: '#F57C00',
  chart4: '#238DD1',
  chart5: '#F44336',
  chart6: '#9C27B0',
  chart7: '#1976D2',
  chart8: '#E91E63'
}

const dark = {
  ...light,
  text: '#FFFFFF',
  bg: '#000000'
}

export {
  light,
  dark
}

export default light
