import { getAccountFriendlyName } from 'shared/util/friendlyName'

export default {
  props: {
    account: {
      type: Object,
      default: null
    },
    size: {
      type: [String, Number],
      default: 40
    },
    color: {
      type: [String, Number],
      default: null
    },
    icon: {
      type: String,
      default: undefined
    }
  },
  computed: {
    friendlyName () {
      return getAccountFriendlyName(this.account)
    },
    accountName () {
      return this.account?.name
    }
  }
}
