const entity = 'Document'
const path = 'document'

const schemas = {
  _id: { type: '_id', name: 'ID' },
  account_id: { type: 'number', name: 'Account' },
  name: { type: 'text', name: 'Name' },
  type: { type: 'text', name: 'Type' },
  smartlink_id: { type: 'text', name: 'Smartlink' },
  created_at: { type: 'timestamp', name: 'Created At' },
  updated_at: { type: 'timestamp', name: 'Updated At' },
  tags: { type: 'text', name: 'Tags' }
}

const defaultValues = {
  'settings.redirect_url_label': 'Click here to finish',
  'settings.finish_message': '<p>Thank you for signing our Document!</p>'
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path,
  defaultValues
}
