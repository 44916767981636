const operators = {
  '=': '$eq',
  '!=': '$ne',
  '<': '$lt',
  '>': '$gt',
  empty: 'empty',
  '!empty': '!empty'
}

const buildQueryRule = ({ path, operator, value }) => {
  return { [path]: { [operators[operator]]: value } } // No changes needed for ID...
}

export default {
  buildQueryRule,
  operators
}

export {
  buildQueryRule,
  operators
}
