import Action from '../Action'
import colors from 'shared/colors'

class ActivateOffer extends Action {
  static get title () {
    return 'Activate Offer'
  }

  static get class () {
    return 'ActivateOffer'
  }

  static get restrict () {
    return { 'addons.type': 'OFFERS' }
  }

  static get color () {
    return colors.maroonRed
  }

  static getDefaultProps () {
    return {
      duration: { days: 2 },
      extend: true,
      redemption_limit: 1
    }
  }
}

export default ActivateOffer
