/**
 * Recursively remove node from json object
 * @param {*} json - The entire eb json object
 * @param {*} node - Reference to a node in the object that should be removed
 */
const destroyNodeByReference = (json, node) => {
  return {
    ...json,
    children: (json.children || [])
      .filter(n => n !== node)
      .map(child => destroyNodeByReference(child, node))
  }
}

export default destroyNodeByReference
