import colors from 'shared/colors'
import days from 'shared/data/days'

const entity = 'Account'
const path = 'account'

const schemas = {
  name: { name: 'Name', type: 'text' },
  display_name: { name: 'Display Name', type: 'text' },
  _id: { name: 'ID', type: 'number' },
  identifier: { name: 'Identifier', type: 'text' },
  settings: { name: 'Settings', type: 'object', unselectable: true }, // Only allow schemas below this to be selected
  'settings.short_name': { name: 'Short Name', type: 'text' },
  'settings.phone': { name: 'Phone Number', type: 'text' },
  'settings.address': { name: 'Address', type: 'object', unselectable: true }, // Only allow schemas below this to be selected
  'settings.address.streetlines': { name: 'Address', type: 'text' },
  'settings.address.city': { name: 'City', type: 'text' },
  'settings.address.state': { name: 'State', type: 'text' },
  'settings.address.zip': { name: 'Zip', type: 'text' },
  'settings.website': { name: 'Website URL', type: 'text' },
  'settings.business_days': {
    name: 'Business Days',
    type: 'text',
    multiple: true,
    options: days.map(day => ({ label: day.text, value: day.value }))
  }
}

const defaultValues = {
  'settings.rfm.days_until_lost': 365,
  'settings.rfm.average_order_value': 10000,
  'settings.style.background_color': '#fbfbfb',
  'settings.style.content_background_color': '#ffffff',
  'settings.style.color': '#000000',
  'settings.style.button_background_color': colors.primary,
  'settings.style.button_color': '#ffffff',
  'settings.style.link_color': colors.lightBlue,
  'settings.style.input_color': '#000000',
  'settings.style.input_background_color': '#ffffff'
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path,
  defaultValues
}
