<template>
  <div>
    <editor-card
      name="Design"
      class="mb-3"
    >
      <div>
        <div
          class="d-flex align-center mb-4"
        >
          <div
            class="flex-grow-1"
          >
            <file-picker
              :types="['image']"
              label="Header Image"
              :value="record.image"
              :disabled="disabled"
              @input="$emit('change', ['image', $event || null])"
              tag="Offer Header"
            />
          </div>
          <div class="pl-3">
            <v-tooltip
              top
              max-width="350"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                >fa fa-info-circle</v-icon>
              </template>
              <div>This image is displayed on the top of an offer.  May be up to 572px wide and exactly 220px tall.  MUST be a transparent PNG.</div>
            </v-tooltip>
          </div>
        </div>
        <div
          class="d-flex align-center mb-4"
        >
          <div
            class="flex-grow-1"
          >
            <file-picker
              :types="['image']"
              label="Background Image"
              :value="record.image_background"
              :disabled="disabled"
              @input="$emit('change', ['image_background', $event || null])"
              tag="Offer Background"
            />
          </div>
          <div class="pl-3">
            <v-tooltip
              top
              max-width="350"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                >fa fa-ifno-circle</v-icon>
              </template>
              <div>This image is displayed behind the text of an offer.  May be up to 640px wide and exactly 960px tall.  May be a transparent PNG.</div>
            </v-tooltip>
          </div>
        </div>
        <v-row class="mt-8">
          <v-col
            :md="6"
          >
            <div
              class="d-flex align-center"
            >
              <div
                class="flex-grow-1"
              >Button Color</div>
              <div>
                <color-picker
                  :value="argbToRgba(record.color_button)"
                  @input="$emit('change', ['color_button', rgbaToArgb($event)])"
                  :account="$store.getters.account"
                  v-bind="{ disabled }"
                  alpha
                />
              </div>
            </div>
          </v-col>
          <v-col
            :md="6"
          >
            <div
              class="d-flex align-center"
            >
              <div
                class="flex-grow-1"
              >Button Text Color</div>
              <div>
                <color-picker
                  :value="argbToRgba(record.color_button_text)"
                  @input="$emit('change', ['color_button_text', rgbaToArgb($event)])"
                  :account="$store.getters.account"
                  v-bind="{ disabled }"
                  alpha
                />
              </div>
            </div>
          </v-col>
          <v-col
            :md="6"
          >
            <div
              class="d-flex align-center"
            >
              <div
                class="flex-grow-1"
              >Font Color</div>
              <div>
                <color-picker
                  :value="argbToRgba(record.color_font)"
                  @input="$emit('change', ['color_font', rgbaToArgb($event)])"
                  :account="$store.getters.account"
                  v-bind="{ disabled }"
                  alpha
                />
              </div>
            </div>
          </v-col>
          <v-col
            :md="6"
          >
            <div
              class="d-flex align-center"
            >
              <div
                class="flex-grow-1"
              >Background Color</div>
              <div>
                <color-picker
                  :value="argbToRgba(record.color_background)"
                  @input="$emit('change', ['color_background', rgbaToArgb($event)])"
                  :account="$store.getters.account"
                  v-bind="{ disabled }"
                  alpha
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </editor-card>
  </div>
</template>

<script>
import EditorCard from 'ui/components/EditorCard'
import FilePicker from 'ui/pickers/FilePicker'
import ColorPicker from 'ui/pickers/ColorPicker'
import rgbaToArgb from 'shared/util/colors/rgbaToArgb'
import argbToRgba from 'shared/util/colors/argbToRgba'

export default {
  components: {
    EditorCard,
    FilePicker,
    ColorPicker
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // Sort of a hack to force UI to display alpha in color picker
    // When Vuetify updates the color picker to be a little more robust this will improve.
    argbToRgba,
    rgbaToArgb
  }
}
</script>
