import get from 'lodash/get'
import moment from 'moment-timezone'

export default (value, schema, options = {}) => {
  const format = get(schema, 'format') || get(options, 'timestampFormat') || get(options, 'format') || 'MMM D, YYYY, h:mm a (z)'
  if (value &&
    (
      !schema?.type ||
      (
        schema.type === 'date' &&
        ((value || '') + '').length >= 10
      ) ||
      (
        schema.type === 'timestamp' &&
        ((value || '') + '').length >= 20
      )
    )
  ) {
    return moment(value)
      .tz(get(options, 'timezone') || moment.tz.guess() || 'UTC')
      .format(format)
  }
  return ''
}
