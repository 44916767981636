import Editor from './Editor'
import EditorContent from './Editor/EditorContent'
import Preview from './Preview'
import TestDialog from './TestDialog'
import PreviewDialog from './PreviewDialog'

const oWindow = (block) => {
  return {
    width: '100%',
    height: '100%',
    persistent: true
  }
}

const editorConfig = {
  noPadding: true
}

export {
  Editor,
  EditorContent,
  Preview,
  TestDialog,
  PreviewDialog,
  oWindow,
  editorConfig
}

export default {
  Editor,
  oWindow
}
