const entity = 'Device'
const path = 'device'

const schemas = {
  _id: { type: 'number', name: 'ID' },
  name: { type: 'text', name: 'Nickname' },
  device_name: { type: 'text', name: 'Name (Deprecated)' }, // TODO: Remove after K4
  device_model: { type: 'text', name: 'Model (Deprecated)' }, // TODO: Remove after K4
  device_identifier: { type: 'text', name: 'Identifier' },
  account_id: { type: 'number', name: 'Account', immutable: true },
  account: { type: 'object', name: 'This Account Settings', immutable: true },
  'account.kiosk_flow_id': { type: 'text', name: 'Active Flow', immutable: true },
  'account.last_active_at': { type: 'timestamp', name: 'Last Active at', immutable: true },
  'account.last_download_at': { type: 'timestamp', name: 'Last Download at', immutable: true },
  platform: { type: 'text', name: 'Platform', immutable: true },
  platform_version: { type: 'text', name: 'Platform Version', immutable: true },
  app_name: { type: 'text', name: 'App Name', immutable: true },
  app_version: { type: 'text', name: 'App Version', immutable: true },
  js_version: { type: 'text', name: 'JS Version (Deprecated)', immutable: true }, // TODO: Remove after K4
  last_heartbeat_at: { type: 'timestamp', name: 'Last Heartbeat at', immutable: true },
  updated_at: { type: 'timestamp', name: 'Last Updated at' },
  online: { type: 'checkbox', name: 'Online' },
  admin: { type: 'checkbox', name: 'Admin' }
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path
}
