// Returns an object of all schemas added based on the revel addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact._revel_id': {
      type: 'number',
      immutable: true,
      name: 'Revel Customer ID'
    }
  }
}

export {
  getSchemas
}

export default {
  getSchemas
}
