import Action from '../Action'
import { get } from 'lodash'
import colors from 'shared/colors'

class TriggerCustom extends Action {
  static get title () {
    return 'Trigger Custom'
  }

  static get class () {
    return 'TriggerCustom'
  }

  static get restrict () {
    return { 'user.level': { $gte: 9 } }
  }

  static get color () {
    return colors.lightPink
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []
    const blockId = get(block, 'props.block_id')
    if (!blockId) {
      errors.push({
        message: 'Block to trigger is required',
        path: 'block_id'
      })
    }
    return super.getErrors(environment).concat(errors)
  }
}

export default TriggerCustom
