import Trigger from '../Trigger'

class GoalHit extends Trigger {
  static get title () {
    return 'Conversion'
  }

  static get class () {
    return 'GoalHit'
  }

  static get restrict () {
    return { not: 'available' }
  }
}

export default GoalHit
