import flattenDeep from 'lodash/flattenDeep'
import get from 'lodash/get'
import union from 'lodash/union'
import uniq from 'lodash/uniq'

const operators = {
  '=': '$eq',
  '!=': '$ne',
  '<': '$lt',
  '>': '$gt',
  '>=': '$gte',
  '<=': '$lte',
  has: '$eq', // For groups maybe?
  '!has': '$ne',
  '%': '$mod',
  empty: 'empty',
  '!empty': '!empty',
  in: '$in', // For groups maybe?
  '!in': '$nin'
}

const buildQueryRule = ({ path, operator, value, schema }) => {
  const queryOperator = operators[operator]

  if (value === undefined) {
    value = null
  }

  // TODO: Move this logic outside of just number fields...
  // The value for the $in/$nin operators need to be arrays
  // These are currently only used on the groups field
  // One can test whether contact belongs specifically to a group by
  // using the '=' operator.  Using $in tests whether contact is in that group
  // or in a child group of that group
  // It would be much better someday to add a custom operator just for groups that
  // is executed outside of this filter library and turns ['groups', '$in', 123]
  // into ['$or', ['groups', '=', 123], ['groups', '=', 456], ...]
  if (['$in', '$nin'].includes(queryOperator)) {
    if (!Array.isArray(value)) {
      value = [value]
    }

    const options = get(schema, 'options') || []
    if (options.length) {
      value = uniq(flattenDeep(value.map((_value) => {
        let ret = [_value * 1]
        options.forEach((option) => {
          if (option.value + '' === _value + '' && (get(option, 'children') || []).length) {
            ret = union(ret, option.children)
          }
        })
        return ret
      })))
    }
  } else if (queryOperator === '$mod') {
    // $mod must be array of length 2 integers
    if (
      !(Array.isArray(value) && value.length === 2) ||
      value[0] === 0 ||
      isNaN(value[0] * value[1])
    ) {
      throw new Error('Value must be array of exactly 2 numbers with the first integer not 0.')
    }
  }

  return { [path]: { [queryOperator]: value } }
}

export default {
  buildQueryRule,
  operators
}

export {
  buildQueryRule,
  operators
}
