import add from 'lodash/add'
import toNumber from 'lodash/toNumber'
import get from 'lodash/get'

const parseParams = ({ params = {}, options = {} } = {}) => {
  return {
    value: toNumber(get(params, 'value')) || 0,
    param: toNumber(get(params, 'param')) || 0
  }
}

export default (args) => {
  const { value, param } = parseParams(args)
  return add(value, param)
}

const humanizeCall = (args) => {
  const { param } = parseParams(args)
  return `Add ${param}`
}

export {
  humanizeCall
}
