<template>
  <v-card
    class="offer-preview"
  >
    <v-card-title>Offer Preview</v-card-title>
    <v-card-text>
      <offer
        style="
          width: 256px;
          height: 584px;
          margin: auto;
        "
        :env="env"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import Offer from 'offer/src/components/Offer'
import { mapGetters } from 'vuex'

export default {
  components: {
    Offer
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      contact: {}
    }
  },
  computed: {
    ...mapGetters([
      'account',
      'schemas'
    ]),
    env () {
      return {
        offer: this.value,
        account: this.account,
        schemas: this.schemas,
        contact: this.contact
      }
    }
  }
}
</script>
