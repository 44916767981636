<template>
  <v-app-bar-nav-icon
    v-if="$vuetify.breakpoint.mdAndDown"
  >
    <v-badge
      color="primary"
      :content="totalIndicatorCount"
      overlap
      offset-x="18"
      offset-y="18"
      :value="totalIndicatorCount > 0"
    >
      <v-btn
        icon
        @click="$store.commit('setAppDrawerLeft', !$store.state.appDrawerLeft)"
      >
        <v-icon>fa-bars</v-icon>
      </v-btn>
    </v-badge>
  </v-app-bar-nav-icon>
</template>

<script>
import { Indicator } from 'ui/models'
export default {
  computed: {
    totalIndicatorCount () {
      return this.inboxMessageCount
    },
    inboxMessageCount () {
      const indicator = Indicator.find('MESSENGER')
      if (indicator && indicator.value) {
        return (+indicator.value.unread + +indicator.value.snoozed)
      }
      return 0
    }
  }
}
</script>
