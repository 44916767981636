import Model from './Model'

class Token extends Model {
  static entity = 'tokens'
  static socketInsertOnCreated = 'always'

  static fields () {
    return {
      _id: this.attr(undefined),
      name: this.attr(undefined),
      entity: this.attr(undefined),
      entity_id: this.attr(undefined),
      created_at: this.attr(undefined),
      last_seen_at: this.attr(undefined),
      ends_with: this.attr(undefined)
    }
  }
}

export default Token
