import actions from './actions'
import triggers from './triggers'
import BlockIcon from './BlockIcon'
import { upperFirst, camelCase } from 'lodash'

const getUi = (slug) => {
  if (typeof slug !== 'string') {
    throw new Error('Event slug must be a string')
  }
  slug = upperFirst(camelCase(slug))
  return actions[slug] || triggers[slug]
}

export {
  actions,
  triggers,
  BlockIcon,
  getUi
}
