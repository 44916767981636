import transformFilterRules from './transformFilterRules'

// Runs an entire filter through a transformation function for every rule.

const transformFilter = (filter = {}, options) => {
  if (filter && filter.where && Array.isArray(filter.where) && filter.where.length) {
    filter = {
      ...filter,
      where: transformFilterRules(filter.where, options)
    }
  }
  return filter
}

export default transformFilter
