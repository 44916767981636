const separate = (hex) => {
  if (!hex) {
    return null
  }

  hex = hex.replace(/\W+/g, '')

  // Default alpha to 1 if not provided
  if (hex.length === 6) {
    hex = 'ff' + hex
  }

  hex = hex.replace(/\W+/g, '')
  return [
    parseInt(hex.substr(0, 2), 16),
    parseInt(hex.substr(2, 2), 16),
    parseInt(hex.substr(4, 2), 16),
    parseInt(hex.substr(6, 2), 16)
  ]
}

const hexToRgba = (hex, def = '') => {
  hex = hex || def
  if (!hex) {
    return null
  }

  const [a, r, g, b] = separate(hex)
  return `rgba(${r},${g},${b},${parseFloat(a, 10) / 255.0})`
}

const hexToRgb = (hex, def = '') => {
  hex = hex || def
  if (!hex) {
    return null
  }

  const [, r, g, b] = separate(hex)
  return `rgb(${r}, ${g}, ${b})`
}
const rgbToHex = (rgb) => {
  if (!rgb) {
    return ''
  }
  const sep = rgb.indexOf(',') > -1 ? ',' : ' ' // separator can be ',' or ' '
  // Turn "rgb(r,g,b)" into [r,g,b]
  rgb = rgb.substr(4).split(')')[0].split(sep)

  let r = (+rgb[0]).toString(16)
  let g = (+rgb[1]).toString(16)
  let b = (+rgb[2]).toString(16)

  if (r.length === 1) {
    r = '0' + r
  }
  if (g.length === 1) {
    g = '0' + g
  }
  if (b.length === 1) {
    b = '0' + b
  }
  return '#' + r + g + b
}

hexToRgba.getContrastColor = (hex, def = '') => {
  hex = hex || def
  if (!hex) {
    return null
  }

  // eslint-disable-next-line no-unused-vars
  const [_, r, g, b] = separate(hex)
  const isBrighter = (r + g + b) >= 512
  return isBrighter
    ? '#ff000000'
    : '#ffffffff'
}

export { hexToRgb, hexToRgba, rgbToHex }

export default hexToRgba
