import { getUserFriendlyName } from 'shared/util/friendlyName'
import AvatarColorsMixin from './AvatarColorsMixin'
import { User } from 'ui/models'

export default {
  mixins: [
    AvatarColorsMixin
  ],
  props: {
    user: {
      type: Object,
      default: null
    },
    userId: {
      type: [Number, String],
      default: null
    },
    size: {
      type: [String, Number],
      default: 40
    },
    color: {
      type: [String, Number],
      default: null
    },
    icon: {
      type: String,
      default: undefined
    },
    forceIcon: {
      type: Boolean,
      default: false
    },
    noTooltip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    record () {
      return this.user || User.find(this.userId)
    },
    friendlyName () {
      return getUserFriendlyName(this.record)
    },
    friendlyNameOrPhone () { // Used by AvatarColorsMixin
      return getUserFriendlyName(this.record)
    },
    image () {
      if (this.record?.photo) {
        let filename = this.record.photo
        if (filename.includes('_szfull.')) {
          filename = filename.replace('_szfull.', '_szthumb.')
        }
        return `https://s.citygro.com/cg/images/${filename}`
      }
      return undefined
    }
  },
  created () {
    if (!this.record && this.userId) {
      User.fetchOne(this.userId, {
        multiplex: true,
        request: {
          hideErrors: true
        }
      })
    }
  }
}
