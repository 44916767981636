const updateNodeByPath = (json, path, node) => {
  const pathParts = path.split('-')
  if (!pathParts.length) {
    return json
  }
  const index = pathParts.shift() * 1
  return {
    ...json,
    children: [
      ...(json.children || []).slice(0, index),
      ...(
        pathParts.length
          ? [updateNodeByPath(json.children[index], pathParts.join('-'), node)]
          : (Array.isArray(node) ? node : [node])
      ),
      ...(json.children || []).slice(index + 1)
    ]
  }
}

export default updateNodeByPath
