import BaseField from './_BaseField'

class Image extends BaseField {
  // static sanitize ({ value }) {
  // Sanitization of images will happen server-side.  If it's not server
  // Images will only be able to be set by passing in a base64 encoded string
  // }
}

export default Image
