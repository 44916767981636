import Model from './Model'

class Webform extends Model {
  static entity = 'webforms'

  static fields () {
    return {
      _id: this.attr(undefined),
      name: this.attr(undefined),
      account_id: this.attr(undefined),
      display_name: this.attr(undefined),
      description: this.attr(undefined),
      tags: this.attr(undefined),
      type: this.attr(undefined),
      settings: this.attr(undefined),
      elements: this.attr(undefined),
      ops: this.attr(undefined),
      permissions: this.attr(undefined),
      stats: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      smartlink_id: this.attr(undefined)
    }
  }
}

export default Webform
