import Action from '../Action'
import colors from 'shared/colors'

class AbTest extends Action {
  static get title () {
    return 'A/B Test'
  }

  static get class () {
    return 'AbTest'
  }

  static get color () {
    return colors.warning
  }

  static getDefaultProps () {
    return {
      variations: [
        {
          name: 'Variation 1',
          key: 'v-1',
          weight: 25
        },
        {
          name: 'Variation 2',
          key: 'v-2',
          weight: 25
        }
      ]
    }
  }

  static restrictFromBlockClasses () {
    return ['SelectContacts']
  }

  static restrictFromBlockCount () {
    return 1
  }

  static getPathTypes ({ block = {}, paths = [] } = {}) {
    return [
      ...(block?.props?.variations || [])
        .filter(variation => !(paths || []).find(p => p?.class === variation.key))
        .map(variation => {
          return {
            name: variation.name,
            class: variation.key,
            color: colors.success
          }
        })
    ]
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { block } = environment
    const errors = []
    const percentage = (block?.props?.variations || []).reduce((sum, item) => sum + +item.weight, 0)
    if (!(percentage > 0)) {
      errors.push({
        message: 'Total split percent must be greater than 0.',
        path: 'variations'
      })
    }
    return super.getErrors(environment).concat(errors)
  }
}

export default AbTest
