import { omit } from 'lodash'
import moment from 'moment-timezone'

export default (models) => {
  const emptyAccountData = () => {
    Object.values(omit(models, ['Account', 'AccountUser', 'User'])).forEach(model => model.deleteAll())
  }

  const registerModels = (database) => {
    Object.values(models).map(m => database.register(m))
  }

  /**
   *
   * @param {*} recordArrays Expects an object keyed by entity names
   * {
   *   "contacts": [
   *     { "_id": "deadbeef" }
   *   ]
   * }
   *
   * @param {Boolean} options.force - Force all records to be added to the store
   *  instead of looking for components that need them
   */
  const addRecordsToStore = (recordArrays, { force } = {}) => {
    if (!recordArrays) {
      return
    }
    const ComponentRegistry = require('ui/store/ComponentRegistry').default
    Object.entries(recordArrays).forEach(([entity, records]) => {
      const Model = modelForEntity(entity)
      if (Model) {
        records.forEach(record => {
          const listeners = ComponentRegistry.getListenersForRecord(entity, record)
          if (listeners.length || force) {
            const existingRecord = Model.find(record[Model.primaryKey])
            if (window.$verbose) {
              console.log('existing record', existingRecord)
            }
            // Merge with existing record in case fields are missing due to permissions
            // For example, users returned from other endpoints have less fields than users returned
            // from session endpoint and blasts don't include the references to blocks
            if (['users', 'blasts'].includes(Model.entity)) {
              if (existingRecord) {
                record = {
                  ...existingRecord,
                  ...record
                }
              }
            }
            if (existingRecord?.updated_at && record) {
              if (!record.updated_at) {
                console.log('incoming record has no timestamp', record)
                return
              } else if (!moment(record.updated_at).isAfter(moment(existingRecord.updated_at))) {
                if (window.$verbose) {
                  console.log('record', Model.entity, existingRecord._id, 'is newer locally')
                }
                return
              }
            }

            Model.insert({
              data: Model.transformIncomingData({ data: [record] })
            })
            listeners.forEach(listener => listener.insertId(record._id))
          }
        })
      }
    })
  }
  const bulkAddRecordsToStore = (recordArrays, { force } = {}) => {
    if (!recordArrays) {
      return
    }
    Object.entries(recordArrays).forEach(([entity, records]) => {
      const Model = modelForEntity(entity)
      if (Model && records) {
        Model.insert({
          data: Model.transformIncomingData({ data: records })
        })
      }
    })
  }

  const countAllRecords = () => {
    return Object.entries(models).reduce((sum, [name, model]) => {
      return model.all().length + sum
    }, 0)
  }

  const modelForEntity = (entity) => {
    return Object.values(models).find(model => model.entity === entity)
  }
  return {
    emptyAccountData,
    registerModels,
    addRecordsToStore,
    bulkAddRecordsToStore,
    countAllRecords,
    modelForEntity
  }
}
