import Model from './Model'
import fileTypes from 'shared/data/fileTypes'

class File extends Model {
  static entity = 'files'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      filename: this.attr(undefined),
      user_id: this.attr(undefined),
      namespace: this.attr(undefined),
      size: this.attr(undefined),
      library: this.attr(undefined),
      permissions: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }

  get fileExtension () {
    if (!this.filename) {
      return null
    }
    const parts = this.filename.split('.')
    if (parts.length < 2) {
      return null
    }
    return parts.pop().toLowerCase().split('?')[0]
  }

  get fileType () {
    if (!this.filename) {
      return null
    }

    if (this.filename.startsWith('data:')) {
      const mime = this.filename.split(';base64,')[0].replace('data:', '')
      if (mime.startsWith('image')) {
        return 'image'
      }
    }

    for (var [type, extensions] of Object.entries(fileTypes)) {
      for (var extension of extensions) {
        if (extension === this.fileExtension) {
          return type
        }
      }
    }

    return null
  }

  get fileUrl () {
    if (!this.filename) {
      return null
    }
    if (/^https?:/.test(this.filename)) {
      return this.filename
    }
    if (this.filename.startsWith('data:')) {
      return this.filename
    }

    let dir = ''
    if (!(
      this.filename.startsWith('cg/') ||
      this.filename.startsWith('edn/') ||
      this.filename.startsWith('files/')
    )) {
      dir = 'edn/public/'
    }

    return `https://s.citygro.com/${dir}${this.filename}`
  }

  get fileIcon () {
    const iconMapByExtension = {
      pdf: 'fas fa-file-pdf',
      doc: 'fas fa-file-word',
      docx: 'fas fa-file-word',
      xls: 'fas fa-file-excel',
      xlsx: 'fas fa-file-excel',
      csv: 'fas fa-file-csv',
      vcf: 'fas fa-address-card'
    }

    const iconMapByType = {
      document: 'fa-file',
      image: 'fas fa-file-image',
      video: 'fas fa-file-video',
      audio: 'fas fa-file-audio',
      vcard: 'fas fa-address-card'
    }

    return iconMapByExtension[this.fileExtension] ||
      iconMapByType[this.fileType] ||
      'fas fa-file'
  }

  get friendlyName () {
    return (this.filename || '').split('_')
      .filter((part, index) => {
        return index > 2 || isNaN(part * 1)
      })
      .join('_')
  }
}

export default File
