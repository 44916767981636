import button from './button/Properties'
import divider from './divider/Properties'
import heading from './heading/Properties'
import image from './image/Properties'
import row from './row/Properties'
import snippet from './snippet/Properties'
import social from './social/Properties'
import spacer from './spacer/Properties'
import text from './text/Properties'

export default {
  button,
  divider,
  heading,
  image,
  row,
  snippet,
  social,
  spacer,
  text
}
