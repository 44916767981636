import BaseField from './_BaseField'
import get from 'lodash/get'

class Password extends BaseField {
  // static sanitize ({ value, options }) {
  //   // This should just pass the value through.  Dehydration will ignore anything that isn't { $private }
  //   // or { hash }
  //   return value
  // }

  // No need for an unsanitize method.  If it's been hashed on the server it will be
  // converted to '*' with the "hydrate" method.  This is just a failsafe to ensure the hash
  // is not returned.
  static unsanitize ({ value }) {
    if (get(value, 'hash')) {
      return '*'
    }
    if (typeof value === 'string') {
      return value
    } else if (value) {
      return ''
    }
    return null
  }

  // We're only going to run password ops under all circumstances of the value passed in is
  // an object with a $private prop.  Everything else will be ignored
  static shouldRun ({ value }) {
    if (get(value, '$private') || get(value, 'hash')) {
      return true
    }
    return false
  }
}

export default Password
