import windowManager from './windowManager'
import OWindowWrapper from './OWindowWrapper'

const escapeListener = (e) => {
  if (
    (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27)
    // (e.target.nodeName === 'BODY')
  ) {
    e.preventDefault()
    const focusedItem = windowManager.getFocusedItem()
    const vm = focusedItem && (focusedItem.vm || focusedItem.vNode.componentInstance)
    if (focusedItem && !focusedItem.persistent && !focusedItem.oWindow?.persistent) {
      if (vm && typeof vm.requestClose === 'function') {
        vm.requestClose()
      } else {
        windowManager.remove(focusedItem)
      }
    }
    return false
  }
}

export default {
  data () {
    return {
      oWindows: windowManager.stack
    }
  },
  watch: {
    oWindows () {
      this.$emit('oWindowCountUpdated', this.oWindows.length)
    }
  },
  mounted () {
    window.addEventListener('keydown', escapeListener, true)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', escapeListener)
  },
  render (createElement) {
    return createElement(
      'div',
      {
        style: { // Used to constrain windows to not be able to be moved to negative coords
          position: 'absolute',
          left: 0,
          top: 0
        },
        attrs: {
          id: 'o-windows-container'
        }
      },
      this.oWindows.map(windowStackItem => {
        const {
          component,
          props = {}, // Props passed into component
          persistent,
          overlay,
          oWindow = {} // Dimensions/Position/Etc
        } = windowStackItem
        // Cache the element so it remains upon redraw
        windowStackItem.$el = windowStackItem.$el || createElement(
          OWindowWrapper,
          {
            parent: windowStackItem.parent,
            key: windowStackItem.id,
            props: {
              windowStackItem,
              persistent,
              overlay,
              ...(component.oWindow || {}),
              ...(oWindow || {})
            },
            on: {
              async close (v) { // For when OWindowWrapper emits a resolve from clicking on overlay
                windowManager.remove(windowStackItem)
                await new Promise(resolve => setTimeout(resolve, 10)) // Allow window to be removed before resolving
                windowStackItem.resolve(v)
              },
              mounted () {
                // At this point it the component should exist, add it to the stack item
                // This is a hack because I can't seem to find any other created/mounted callbacks
                // For the createElement event
                windowStackItem.vm = windowStackItem.vNode && windowStackItem.vNode.componentInstance
              }
            },
            scopedSlots: {
              default: p => {
                windowStackItem.vNode = createElement(
                  component,
                  {
                    props,
                    on: {
                      async close (v) {
                        windowManager.remove(windowStackItem)
                        await new Promise(resolve => setTimeout(resolve, 10)) // Allow window to be removed before resolving
                        windowStackItem.resolve(v)
                      }
                    }
                  }
                )
                return windowStackItem.vNode
              }
            }
          }
        )
        return windowStackItem.$el
      })
    )
  }
}
