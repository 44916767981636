/**
 * Unsanitization is useful for situations where we want to work with friendlier and standardized types
 * that are different than what we use internally.
 *
 * Some use cases for unsanitization:
 * - Passwords: anytime there's a stored password we want to just return '*' rather than the hash
 * - Dates: Internally we store dates like { y, m, d, v } but in API we just want a YYYY-MM-DD string
 * - Rendering smart text should pass values through the unsanitize function
 */

import fieldTypes from '../fieldTypes'

export default ({
  value,
  schema,
  schemas, // passthru for object type fields
  options = {}
}) => {
  const fieldType = fieldTypes.getClassForSchema(schema)
  if (fieldType) {
    if (schema.multiple) {
      if (!Array.isArray(value)) {
        value = [value].filter(v => ![null, undefined].includes(v))
      }
      if (!value.length) {
        return null
      }
      return value.map(v => fieldType.unsanitize({
        value: v,
        schema,
        schemas,
        options
      }))
    } else {
      return fieldType.unsanitize({
        value,
        schema,
        schemas,
        options
      })
    }
  }

  return value
}
