import Trigger from '../Trigger'

class ContactUpdated extends Trigger {
  static get title () {
    return 'Contact Updated'
  }

  static get class () {
    return 'ContactUpdated'
  }
}

export default ContactUpdated
