<template>
  <v-card
    style="width: 100%; height: 100%;"
    class="cg-editor report-editor"
  >
    <v-toolbar
      dark
      class="drag-handle flex-grow-0"
      flat
    >
      <v-toolbar-title
        class="flex-grow-1"
      >
        <v-text-field
          style="max-width: 300px"
          outlined
          flat
          dense
          maxlength="40"
          :value="recordChanged.name || ''"
          :placeholder="initialLoading ? '' : 'Report name'"
          @input="handleChange('record', 'name', ($event || '').substr(0, 40))"
          hide-details
        />
      </v-toolbar-title>

      <v-spacer />

      <!-- <v-switch
        class="mr-3 ml-2"
        :label="'Enable' + (recordChanged.active ? 'd' : '')"
        color="success"
        hide-details
        :input-value="recordChanged.active"
        readonly
        @click="handleChange('record', 'active', !recordChanged.active)"
      /> -->

      <v-btn
        icon
        @click="requestClose"
        v-bind="{ disabled }"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <div
      v-if="initialLoading"
      class="d-flex justify-center pa-5"
    >
      <v-progress-circular
        indeterminate
        color="gray"
      />
    </div>
    <v-container
      v-else
      fluid
    >
      <report-type
        :disabled="disabled || !!recordChanged._id"
        :value="recordChanged.type"
        @input="handleChange('record', 'type', $event)"
      />
      <component
        v-if="reportType && reportType.editor"
        :is="reportType.editor"
        :value="recordChanged.config"
        @change="handleChange('record', 'config.' + $event[0], $event[1])"
      />
    </v-container>
    <div
      class="footer pa-3"
    >
      <v-btn
        color="primary"
        outlined
        @click="requestClose"
        v-bind="{ disabled }"
      >Cancel</v-btn>
      <v-btn
        color="primary"
        class="ml-3"
        @click="saveAndClose"
        v-bind="{ disabled }"
      >Save &amp; Close</v-btn>
    </div>
  </v-card>
</template>

<style lang="scss">
.report-editor {
  height: 100%;
  .v-stepper__header {
    height: 72px;
  }
  .v-stepper__items {
    height: calc(100% - 72px);
    overflow-y: auto;
  }
}
</style>

<script>
import { Report } from 'ui/models'
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import ReportType from './ReportType'
import reportTypes from './types'

const Model = Report

const Editor = {
  name: 'ReportEditor',
  oWindow: {
    width: 1000,
    height: 1100
  },
  mixins: [
    Report.mixin({
      references: ['record']
    }),
    createChangeTrackerMixin({ path: ['record'] })
  ],
  components: {
    ReportType
  },
  props: {
    recordId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      initialLoading: true,
      loading: false,
      step: 1
    }
  },
  computed: {
    disabled () {
      return this.loading || (this.recordChanged && this.recordChanged.running)
    },
    record () {
      if (this.recordId) {
        return Report.find(this.recordId)
      }
      return null
    },
    reportType () {
      return this.recordChanged && this.recordChanged.type && reportTypes[this.recordChanged.type]
    }
  },
  watch: {
  },
  async created () {
    await this.loadRecord()
  },
  methods: {
    async requestClose () {
      if (this.loading) {
        return
      }
      if (this.recordChanges.length) {
        if ((await this.$confirm('Do you want to close without saving your changes?', { title: 'Close Without Saving?', persistent: true, overlay: true })) !== 'Yes') {
          return
        }
      }
      this.$emit('close')
    },
    async loadRecord () {
      if (this.recordId) {
        await this.$manageLoading('loading', Report.fetchOne(this.recordId, { force: true }))
      }
      this.initialLoading = false
    },
    async save ({ close = false } = {}) {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        const result = await Model.save(this.recordChanged)
        const record = result.entities && result.entities[Model.entity] && result.entities[Model.entity][0]
        if (close === true) {
          this.$emit('close', record)
          return
        }
        this.clearChanges()
        if (!this.recordId) {
          // If this is a created contact, reopen the editor so the contactId prop will be set
          if (record && record._id) {
            this.$openWindow({
              component: Editor,
              props: {
                recordId: record._id
              }
            })
            this.$emit('close', record)
          }
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        throw e
      }
    },
    async saveAndClose () {
      await this.save({ close: true })
    }
  }
}

export default Editor
</script>
