import toNumber from 'lodash/toNumber'

export default (value, schema, options = {}) => {
  if (['', null, undefined].includes(value)) {
    return ''
  }
  if (isNaN(value * 1)) {
    return ''
  }
  return toNumber(value)
}
