import Model from './Model'

class Invoice extends Model {
  static entity = 'invoices'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      notes: this.attr(undefined),
      number: this.attr(undefined),
      invoice_on: this.attr(undefined),
      due_on: this.attr(undefined),
      paid_at: this.attr(undefined),
      status: this.attr(undefined),
      total: this.attr(undefined),
      total_paid: this.attr(undefined),
      items: this.attr(undefined),
      data: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

export default Invoice
