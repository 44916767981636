import Model from './Model'

class PaymentSource extends Model {
  static entity = 'payment_sources'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      type: this.attr(undefined),
      default: this.attr(undefined),
      data: this.attr(undefined),
      notes: this.attr(undefined),
      verified: this.attr(undefined),
      failed_charge_at: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }

  get displayName () {
    if (this.type === 'CARD') {
      return `Credit Card: ****${this.data?.last4 || ''} Exp ${this.data.exp_month || '?'}/${this.data.exp_year || '?'}${this.name ? ' (' + this.name + ')' : ''}`
    } else if (this.type === 'ACH') {
      return `ACH: ${this.data?.bank_name || this.data?.stripe_data?.bank_name}${this.data?.last4 ? ' *****' + this.data.last4 : ''}${this.name ? ' (' + this.name + ')' : ''}` // TODO Add account name
    } else if (this.type === 'CHECK') {
      return 'Check'
    } else if (this.type === 'SHOPIFY') {
      return 'Shopify Account'
    } else if (this.type === 'OTHER') {
      return 'Other Payment Source'
    }
  }

  get displayIcon () {
    if (this.type === 'CARD') {
      return 'fas fa-credit-card'
    } else if (this.type === 'ACH') {
      return 'fas fa-university'
    } else if (this.type === 'CHECK') {
      return 'fas fa-money-check-alt'
    } else if (this.type === 'SHOPIFY') {
      return 'fab fa-shopify'
    } else if (this.type === 'OTHER') {
      return 'fas fa-money-check'
    }
  }
}

export default PaymentSource
