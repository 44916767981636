import Model from './Model'

class Tag extends Model {
  static entity = 'tags'
  static socketInsertOnCreated = 'always'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      type: this.attr(undefined),
      name: this.attr(undefined),
      alias: this.attr(undefined),
      count: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      // below added by Thomas for Groups Page
      created_by: this.attr(undefined),
      permissions: this.attr(undefined)
    }
  }
}

export default Tag
