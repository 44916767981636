const get = require('lodash/get')

const parseParams = ({ params = {}, options = {}, context = {} } = {}) => {
  return {
    preview: get(params, 'preview') || get(options, 'preview') || false,
    hydrateKey: get(options, 'hydrateKey'),
    url: get(params, 'url') || get(params, 'value'), // If a URL prop is specified then it will take priority
    id: get(params, 'id'),
    afterStage: get(params, 'afterStage') // true in if this function if function isn't the first stage in the variable
  }
}

/**
 * If a pre-hydrated smartlink url was generated this returns that url
 */

const link = ({ params, options = {}, context = {} }) => {
  const { preview, hydrateKey, url, id } = parseParams({ params, options })
  if (!url && !id) {
    // If no URL/Id were passed in to this method then we don't need to assume a link was created/exists
    return ''
  }
  if (preview) {
    return `https://${get(options, 'smartlinkDomain') || 'c-g.co'}/123456789`
  }
  if (hydrateKey && get(context, `__hydration.${hydrateKey}`)) {
    return context.__hydration[hydrateKey] // This should simply be a URL
  }
  return '[LINK NOT ALLOWED]'
}

export default link

const humanizeCall = (args) => {
  const { id, url, afterStage } = parseParams(args)
  let name
  if (id) {
    name = `SmartLink #${id}`
  } else if (url) {
    name = `SmartLink: ${url}`
  } else if (afterStage) {
    name = 'Turn into SmartLink'
  } else {
    name = 'Invalid SmartLink'
  }

  if (name.length > 25) {
    name = name.substr(0, 25) + '...'
  }

  return name
}

const getLengths = (args) => {
  return {
    shortest: 27,
    longest: 27
  }
}

export {
  humanizeCall,
  parseParams,
  getLengths
}
