import JSON5 from 'json5'
import * as methods from './methods'

export default (variableText) => {
  variableText = variableText.replace('{{', '').replace('}}', '').trim()
  const possibleMethods = Object.keys(methods)
  const matchFunctionRegex = new RegExp(`((${possibleMethods.map(method => `(${method})`).join('|')})\\((.*)?\\))`)
  const matches = (matchFunctionRegex.exec(variableText) || []).filter(m => !!m && m !== variableText)

  const method = matches.find(match => possibleMethods.includes(match))
  if (!method) {
    return
  }

  let params = matches.find(match => match && !possibleMethods.includes(match))
  if (typeof params !== 'number' &&
      typeof params !== 'boolean' &&
      typeof params !== 'string' &&
      params !== undefined &&
      params !== null
  ) {
    params = (params || '') + ''
  }

  if (typeof params === 'string') {
    params = params.trim()
  }

  if (params) {
    // If the parameter is a string rather than JSON object, remove any quotes around it and then stringify
    // This is necessary because JSON parsing will expect slashes to be double slashes.
    if (!(
      (params.startsWith('{') && params.endsWith('}')) ||
      (params.startsWith('[') && params.endsWith(']'))
    ) &&
      typeof params !== 'number' &&
      typeof params !== 'boolean'
    ) {
      if (['"', "'"].includes(params[0]) && ['"', "'"].includes(params[params.length - 1])) {
        params = params.substring(1, params.length - 1)
      }
      params = JSON.stringify(params)
    }
    try {
      params = JSON5.parse(params)
    } catch (e) {
      console.log('error in smart text evaluation', variableText, params, e)
      params = { error: 'There was an error evaluating the params: ' + e.message + params }
    }
  }

  return {
    method,
    params
  }
}
