export default ({ account = {} } = {}) => {
  return {
    title: { name: 'Calendar Item Title', type: 'text' },
    tags: {
      name: 'Calendar Item Tags',
      type: 'text',
      multiple: true
    },
    calendar_item_end_at: { name: 'Calendar Item End At', type: 'timestamp' },
    calendar_item_run_end_at: { name: 'Calendar Item Run End At', type: 'timestamp' }
  }
}
