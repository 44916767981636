<template>
  <fixed-menu
    class="cg-schemas-picker"
    v-model="menu"
    :props="{
      fixed: true,
      offsetY: true,
      minWidth: $vuetify.breakpoint.smAndDown ? 150 : 300,
      closeOnContentClick: false,
      left,
      top: pickerDropdownTop
    }"
  >
    <template v-slot:activator="{ on }">
      <tooltip-button
        ref="activator"
        v-on="on"
        v-bind="{
          badgeValue: filterType !== 'all' || tagsSelected.length > 0,
          badgeDot: true,
          icon: 'filter',
          iconSize: '20',
          buttonSize: 'large',
          tooltip: 'Type of Filter',
          caret: true
        }"
      />
    </template>

      <v-card
        style="
          max-height: 500px;
          overflow-y: auto;
        "
      >
        <v-card-text
          class="pt-0"
        >
          <p class="mt-2">Type</p>
          <v-radio-group
            v-model="filterType"
            @change="handleInput"
            hide-details
          >
            <v-radio
              v-for="item in groupOptions"
              :key="item.text"
              :label="item.text"
              :value="item.value"
              hide-details
            ></v-radio>
          </v-radio-group>
          <template
            v-if="tagOptions.length > 0"
          >
            <v-divider class="mt-4"/>
            <p class="mt-2">Tags</p>
            <v-checkbox
              v-for="item in tagOptions"
              :key="item.text"
              v-model="tagsSelected"
              :label="item.text"
              :value="item.value"
              @change="handleInput"
              hide-details
            />
          </template>
        </v-card-text>
      </v-card>

  </fixed-menu>
</template>

<script>
import PickerDropdownMixin from 'ui/pickers/PickerDropdownMixin'
import TooltipButton from 'ui/components/TooltipButton'

export default {
  mixins: [
    PickerDropdownMixin
  ],
  components: {
    TooltipButton
  },
  props: {
    allTagsObj: {
      type: Array
    }
  },
  data () {
    return {
      tags: [],
      tagsSelected: [],
      filterType: 'all'
    }
  },
  computed: {
    groupOptions () {
      return [
        {
          text: 'All Segments',
          value: 'all'
        },
        {
          text: 'Patch Segments',
          value: 'account:global'
        },
        {
          text: 'My Segments',
          value: 'account:self'
        },
        {
          text: 'Favorites',
          value: 'favorite'
        }
      ]
    },
    tagOptions () {
      return this.allTags.map(tag => ({
        text: tag.replace('TAG:', ''),
        value: tag
      }))
    },
    allTags () {
      return (this.allTagsObj || this.tags || []).map(tagObj => `TAG:${tagObj._id || ''}`)
    }
  },
  async mounted () {
    if (this.allTagsObj === undefined) {
      const result = await this.$http('/v2/filters/tags')
      this.tags = result.data || []
    }
  },
  methods: {
    handleInput () {
      this.$emit('filters', [this.filterType, ...this.tagsSelected])
    }
  },
  watch: {
    allTags () {
      // remove any selected tags if they no longer exist
      this.tagsSelected = this.tagsSelected.filter(tag => (this.allTags || []).includes(tag))
    }
  }
}
</script>
