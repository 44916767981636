// Returns an object of all schemas added based on the roller addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact._roller_id': {
      type: 'number',
      immutable: true,
      name: 'Roller Customer ID'
    },
    'contact._roller_created_at': {
      type: 'timestamp',
      immutable: true,
      name: 'Created At (Roller)'
    },
    'contact.document_participants': {
      type: 'object',
      multiple: true,
      name: 'Waiver Participants'
    },
    'contact.document_participants.first_name': {
      type: 'text',
      name: 'First Name'
    },
    'contact.document_participants.last_name': {
      type: 'text',
      name: 'Last Name'
    },
    'contact.document_participants.birthday': {
      type: 'date',
      name: 'Birthday'
    },
    'contact.document_participants.expires_at': {
      type: 'timestamp',
      name: 'Expires At'
    },
    'contact.document_participants.external_data': {
      type: 'object',
      name: 'External Data',
      hidden: true,
      immutable: true
    }
  }
}

export {
  getSchemas
}

export default {
  getSchemas
}
