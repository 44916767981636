import moment from 'moment'
import { toNumber, fromPairs } from 'lodash'

// These functions all accept an offset as a string like '-5Y4M3W2D1H15m3s'

// These methods are used often in the smarttext time method as well as anywhere that
// parses a relative time like the event log API endpoint

// Accepts a string like '-5Y4M3W2D1H15m3s' and applies the offset to timestamp
// Also accepts an object like { years: 5, months: 4, Y: 5, M: 4, .... } and ADDS that to the timestamp
const applyOffset = (offset = '', timestamp = null) => {
  timestamp = timestamp || moment()
  if (offset) {
    const intervalParts = { Y: 'y', M: 'M', W: 'w', D: 'd', h: 'h', m: 'm', s: 's' }
    if (typeof offset === 'object') {
      const method = offset.dir === '-' ? 'subtract' : 'add';
      ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'].forEach((key) => {
        if (offset[key] && !isNaN(offset[key] * 1)) {
          timestamp[method](offset[key] * 1, key)
        }
      })
      Object.keys(intervalParts).forEach(key => {
        if (offset[key] && !isNaN(offset[key] * 1)) {
          timestamp[method](offset[key] * 1, intervalParts[key])
        }
      })
    } else {
      let dir = offset[0]
      if (dir !== '-') {
        dir = '+'
      }
      if (offset[0] === '+' || offset[0] === '-') {
        offset = offset.substr(1)
      }

      const matches = offset.match(/([0-9.]+[YMWDhms])/g)
      if (matches && matches.length > 0) {
        matches.forEach(function (match) {
          var gran = match.match(/[YMWDhms]/g)
          if (gran && gran.length) {
            if (dir === '-') {
              timestamp.subtract(1 * match.match(/[0-9.]+/g), intervalParts[gran[0]])
            } else {
              timestamp.add(1 * match.match(/[0-9.]+/g), intervalParts[gran[0]])
            }
          }
        })
      }
    }
  }
  return timestamp
}

const shortToLongKeys = {
  Y: 'years',
  M: 'months',
  W: 'weeks',
  D: 'days',
  h: 'hours',
  m: 'minutes',
  s: 'seconds'
}

// Converts "5Y4M3D" into { Y: 5, M: 4, D: 3 }
// fullKeys = return as { years: 5, months: 4, days: 3 }
const parseOffset = (offset = '', fullKeys = false) => {
  const matches = offset.match(/([0-9]+[YMWDhm]{1})?/g).filter(v => !!v)
  const offsetParts = matches.map(match => {
    const num = toNumber(match.match(/[0-9]+/)[0])
    const unit = match.match(/[YMWDhm]{1}/)[0]
    return [unit, num]
  })
  const offsetObject = fromPairs(
    offsetParts
      .filter(([unit, num]) => !!num && !!unit)
      .map(([unit, num]) => {
        if (fullKeys) {
          return [shortToLongKeys[unit], num]
        }
        return [unit, num]
      })
  )

  if (offset.startsWith('-')) {
    offsetObject.dir = '-'
  } else {
    offsetObject.dir = '+'
  }

  return offsetObject
}

// Converts { dir: '+', Y: 5, M: 4, D: 3, }
const stringifyOffset = (offset = {}) => {
  const filteredOffset = Object.entries(offset)
    .filter(([key, value]) => {
      return ['Y', 'M', 'W', 'D', 'h', 'm'].includes(key) && value > 0
    })
  if (!filteredOffset.length) {
    return ''
  }
  let stringOffset = '+'
  if (offset.dir === '-') {
    stringOffset = '-'
  }

  stringOffset += filteredOffset.map(([key, value]) => value + key).join('')

  return stringOffset
}

// Takes an offset and returns "3 years 5 days 2 minutes ago"
const humanizeOffset = (value = '') => {
  const humanizeMap = {
    Y: 'year',
    M: 'month',
    W: 'week',
    D: 'day',
    h: 'hour',
    m: 'minute'
  }

  const offsetParts = parseOffset(value)

  const offsetText = Object.entries(offsetParts).map(([unit, num]) => {
    if (unit === 'dir') {
      return ''
    }
    return `${num} ${humanizeMap[unit] ? (humanizeMap[unit] + (num > 1 ? 's' : '')) : unit}`
  }).filter(v => !!v).join(', ')

  if (offsetParts.dir === '-') {
    return `${offsetText} ago`
  } else {
    return `in ${offsetText}`
  }
}

export {
  applyOffset,
  parseOffset,
  stringifyOffset,
  humanizeOffset
}
