import Action from '../Action'
import colors from 'shared/colors'
import { get } from 'lodash'

class EmailOut extends Action {
  static get title () {
    return 'Send Email'
  }

  static get class () {
    return 'EmailOut'
  }

  static getPathTypes () {
    return [
      { name: 'Always', class: 'Always', color: null }
      // { name: 'On Open', class: 'Message.Opened', color: null },
      // { name: 'On Bounce', class: 'Message.Bounced', color: null },
      // { name: 'On Click', class: 'Smartlink.Clicked', color: null },
      // { name: 'On Opt-out', class: 'Message.Unsubscribed', color: null },
      // { name: 'On Deliver', class: 'Message.Delivered', color: null }
    ]
  }

  static get color () {
    return colors.info
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []
    const text = get(block, 'props.content.text')
    const html = get(block, 'props.content.html')
    const ebon = get(block, 'props.content.ebon')
    const gatewayId = get(block, 'props.gateway_id')
    const emailAliasId = get(block, 'props.content.email_alias_id')
    if (!text && !html && !ebon) {
      errors.push({
        message: 'Message content is empty.',
        path: 'content.text'
      })
    }
    if (!gatewayId && !emailAliasId) {
      errors.push({
        message: '"Send From" address is required.',
        path: 'gateway_id'
      })
    }
    return super.getErrors(environment).concat(errors)
  }
}

export default EmailOut
