import Model from './Model'
import moment from 'moment-timezone'

class ScheduledMessage extends Model {
  static entity = 'scheduled_messages'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      contact_id: this.attr(undefined),
      conversation_id: this.attr(undefined),
      content: this.attr(undefined),
      gateway_id: this.attr(undefined),
      send_at: this.attr(undefined),
      unprocessed: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

setInterval(() => {
  // Only do this if a store is even connected
  if (!(
    ScheduledMessage &&
    typeof ScheduledMessage.store === 'function' &&
    !!ScheduledMessage.store()
  )) {
    return
  }
  const sentMessages = ScheduledMessage
    .query()
    .where(record => record.unprocessed && record.send_at && moment(record.send_at).isBefore(moment()))
    .limit(100)
    .get()

  if (sentMessages.length) {
    const ids = sentMessages.map(record => record._id)
    ScheduledMessage.fetchMany({
      params: {
        _id: ids.join(',')
      },
      force: true
    })
  }
}, 15 * 1000)

export default ScheduledMessage
