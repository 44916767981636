/**
 * Adds the necessary methods to a vm to display a "You have unsaved changes"
 * popup and prevent data loss.
 *
 * It's important that this is added as a mixin BEFORE createChangeTrackerMixin
 * for the best default behavior.
 * @param {Function} options.checkIsRouteLeaving - compare to and from params and return true if leaving editor
 */

export default (options = {}) => {
  return {
    data () {
      return {
        // Prevent duplicate popups from showing
        $confirmPreventChangeLossPopup: null
      }
    },
    computed: {
      allChanges () { // Override this
        console.warn('createPreventChangeLossMixin: computed.allChanges has not been overridden.')
        return []
      }
    },
    methods: {
      // This method is called by modals
      async requestClose () {
        if (this.$confirmPreventChangeLossPopup) {
          return
        }
        const _hasChanges = await this.$hasChanges()
        if (
          _hasChanges &&
          !(await this.$changeLossConfirmation())
        ) {
          return
        }
        await this.$clearChanges()
        this.$emit('close')
      },
      // Override this to display an alternate change loss confirmation
      // Return true to continue without saving
      async $changeLossConfirmation () {
        this.$confirmPreventChangeLossPopup = this.$confirm('Do you want to leave this without saving?  Your changes will be lost.', {
          title: 'Close without saving?',
          persistent: true,
          overlay: true,
          buttons: [
            { color: 'warning', name: 'Discard Changes', value: 'Yes', outlined: true, small: true },
            { color: 'primary', name: 'Continue Editing', small: true }
          ]
        })
        const result = await this.$confirmPreventChangeLossPopup
        this.$confirmPreventChangeLossPopup = null
        return result === 'Yes'
      },
      // Return true if there are unsaved changes
      async $hasChanges () {
        return this.allChanges.length > 0
      },
      async $clearChanges () {
        // Override this if needing to clear before continuing route change
      }
    },
    async beforeRouteUpdate (to, from, next) {
      // Middlewareish way of making it possible to define an extra beforeRoute function
      // within the vm config
      const _next = async (val) => {
        if (val === false) {
          next(false)
          return
        }
        if (options.checkIsRouteLeaving && !options.checkIsRouteLeaving(to, from)) {
          next()
          return
        }
        if (this.$confirmPreventChangeLossPopup) {
          next(false)
          return
        }
        const _hasChanges = await this.$hasChanges()
        if (
          _hasChanges &&
          !(await this.$changeLossConfirmation())
        ) {
          next(false)
          return
        }
        await this.$clearChanges()
        next()
      }

      if (options.beforeRouteUpdate) {
        options.beforeRouteUpdate.call(this, to, from, _next)
      } else {
        _next()
      }
    },
    async beforeRouteLeave (to, from, next) {
      // Middlewareish way of making it possible to define an extra beforeRoute function
      // within the vm config
      const _next = async (val) => {
        if (val === false) {
          next(false)
          return
        }
        if (options.checkIsRouteLeaving && !options.checkIsRouteLeaving(to, from)) {
          next()
          return
        }
        if (this.$confirmPreventChangeLossPopup) {
          next(false)
          return
        }
        const _hasChanges = await this.$hasChanges()
        if (
          _hasChanges &&
          !(await this.$changeLossConfirmation())
        ) {
          next(false)
          return
        }
        await this.$clearChanges()
        next()
      }

      if (options.beforeRouteLeave) {
        options.beforeRouteLeave.call(this, to, from, _next)
      } else {
        _next()
      }
    }
  }
}
