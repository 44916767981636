import buildQuery from './buildQuery'
import isEmpty from './isEmpty'
import { sanitizeValue } from 'shared/field-ops'
import sift from 'sift'
import jsonStableStringify from 'fast-json-stable-stringify'
import get from 'lodash/get'
import { render } from 'shared/smart-text'

let testObjectGlobalOptions = {}
const setGlobalOptions = (config) => {
  testObjectGlobalOptions = config
}

const sanitizedCacheMap = {}
const sanitizedCacheMapExpirations = {}
const getSanitizedObject = (object, options) => {
  let result
  const hash = options.cacheSanitized ? jsonStableStringify(object) : undefined
  if (hash && sanitizedCacheMap[hash]) {
    result = sanitizedCacheMap[hash]
  }
  if (!result) {
    result = sanitizeValue({ value: object, schema: { type: 'object' }, schemas: get(options, 'schemas') || {}, options })
  }
  if (hash) {
    sanitizedCacheMap[hash] = result
    if (sanitizedCacheMapExpirations[hash]) {
      clearTimeout(sanitizedCacheMapExpirations[hash])
    }
    sanitizedCacheMapExpirations[hash] = setTimeout(() => {
      delete sanitizedCacheMap[hash]
      delete sanitizedCacheMapExpirations[hash]
    }, 30000)
  }
  return result
}

/**
 * Provide a object (such as a contact) with a filter and determine if the
 * contact passes the filter. Returns TRUE if the contact passes the filter
 */
const testObject = (object, filter, options = {}) => {
  // If filter is empty then the object passes
  if (isEmpty(filter)) {
    if (filter && filter.select_all === false) {
      return false
    }
    return true
  }

  options = {
    ...testObjectGlobalOptions,
    ...options
  }

  // It's possible to pass in a list of schemas as part of the filter object to instruct how strict
  // comparisons should happen
  // Generally nested schemas should be passed in individually as paths rather than as a schema
  // containing sub-schemas
  // Because this resides in filter but affects both the object and filter (via options.schemas) this
  // logic needs to go here

  if (filter && filter.schemas && Array.isArray(filter.schemas) && filter.schemas.length) {
    options.schemas = {
      ...(options.schemas || {})
    }

    filter.schemas.forEach(schema => {
      options.schemas[schema.path || schema.key] = schema // rename schema.key to schema.path
    })
  }

  // It's possible to provide a payload of values to have smart text run on and be set on the object
  // before the filter runs.  Some use cases are time() or random numbers to be used when the filter
  // runs making it possible to just check whether "Today is Tuesday" instead of having to set Tuesday
  // to a data field / payload before running the filter
  // It's also useful for manipulating or combining field values before filtering on them
  // Payload could look like this:
  // {
  //   "__tempField03fw3": "{{ time({ format: 'dddd' }) }}"
  // }
  // Then a filter rule would look like this so the filter would only pass on Tuesdays:
  // ['__tempField03fw3', '=', 'Tuesday']

  if (filter && filter.payload && typeof filter.payload === 'object') {
    object = {
      ...object
    }
    Object.entries(filter.payload).forEach(([key, value]) => {
      object[key] = render(value || '', options.context, options)
    })
  }

  const query = buildQuery(
    filter,
    options
  )

  object = getSanitizedObject(object, options)

  const result = [object].filter(sift(query))

  return result && result[0] === object
}

testObject.setGlobalOptions = setGlobalOptions

export default testObject
