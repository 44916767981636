<template>
  <v-card
    class="cg-editor"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>Cash Out</v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        :disabled="loading"
        @click="$emit('close')"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      v-if="discount"
    >
      <v-card
        class="pa-3"
      >
        <p><strong>Cash Out Successful</strong></p>
        <p>This is the code the customer will use at checkout to redeem their Loyalty Cash.</p>
        <v-text-field
          style="max-width: 400px"
          label="Discount Code"
          readonly
          :value="discount.code"
          hint="This code has been sent to the customer via text and email."
          persistent-hint
          dense
          outlined
          :append-outer-icon="copied ? 'fa fa-check' : 'fas fa-clipboard'"
          @click:append-outer="copyCode"
        />
      </v-card>
    </v-container>
    <v-container
      v-else-if="pointsRedeemValue > 0"
      fluid
      class="pb-0"
    >
      <v-card>
        <div
          class="pa-3"
        >
          <p class="mb-0">Once the cash out request is complete, a text and email message will be sent to the customer with the generated code. Message and Data rates may apply.</p>
        </div>
        <v-simple-table>
          <thead>
            <tr>
              <th>Points</th>
              <th>Cash Out Value</th>
              <th
                class="text-center"
                style="width: 200px"
              >Cash Out</th>
              <th>New Cash Out Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ points }}</td>
              <td>{{ amountFormatted }}</td>
              <td>
                <v-text-field
                  style="width: 260px"
                  prefix="$"
                  suffix="Dollars"
                  v-model="cashOutAmount"
                  outlined
                  dense
                  :hide-details="!(cashOutAmount * 1 > amount * 1)"
                  type="tel"
                  :rules="[() => cashOutAmount * 1 > amount * 1 ? 'Cash value cannot exceed ' + amountFormatted : true]"
                />
              </td>
              <td
                :class="{ 'error--text': cashOutAmount * 1 > amount * 1 }"
              >{{ newAmount }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-container>
    <v-container
      v-else
      fluid
    >
      <p>You have not set up the point redemption value in <router-link :to="{ name: 'LoyaltySettings' }">Loyalty Settings</router-link>.  Please establish this value before cashing out.</p>
    </v-container>
    <div
      class="d-flex footer pa-3"
      v-if="discount"
    >
      <v-spacer />
      <v-btn
        color="primary"
        class="ml-2"
        @click="$emit('close')"
        :disabled="loading"
      >Close</v-btn>
    </div>
    <div
      class="d-flex footer pa-3"
      v-else-if="pointsRedeemValue > 0"
    >
      <v-spacer />
      <v-btn
        color="primary"
        outlined
        :disabled="loading"
        @click="$emit('close')"
      >Cancel</v-btn>
      <v-btn
        color="primary"
        class="ml-2"
        @click="save"
        :disabled="loading || !valid"
      >Cash Out{{ valid ? ' ' + cashOutAmountFormatted : '' }}</v-btn>
    </div>
  </v-card>
</template>

<script>
import { Contact } from 'ui/models'

export default {
  oWindow: {
    width: 900,
    height: 320,
    persistent: true,
    overlay: true
  },
  props: {
    contactId: {
      type: String,
      required: true
    },
    onUpdate: {
      type: Function,
      default: () => () => {}
    }
  },
  data () {
    return {
      loading: false,
      cashOutAmount: null,
      discount: null,
      copied: false
    }
  },
  computed: {
    contact () {
      return Contact.getFlattened(this.contactId)
    },
    points () {
      return this.contact?.punchcard || 0
    },
    pointsRedeemValue () {
      return this.$store.getters.account?.settings?.loyalty?.points_redeem_value || 0
    },
    amount () {
      if (!this.pointsRedeemValue) {
        return 0
      }
      return this.points / this.pointsRedeemValue
    },
    amountFormatted () {
      return window.Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.amount)
    },
    cashOutAmountFormatted () {
      return window.Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.cashOutAmount)
    },
    newAmount () {
      if (!(this.cashOutAmount > 0)) {
        return this.amount
      }
      const newPoints = this.points - (this.cashOutAmount * this.pointsRedeemValue)
      return window.Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(newPoints / this.pointsRedeemValue)
    },
    valid () {
      return !isNaN(this.cashOutAmount * 1) && this.cashOutAmount > 0 && this.cashOutAmount * 1 <= this.amount * 1
    }
  },
  watch: {
    copied (value) {
      if (value) {
        setTimeout(() => {
          this.copied = false
        }, 2000)
      }
    }
  },
  methods: {
    async save () {
      if (this.loading || !this.valid) {
        return
      }
      if ((await this.$confirm(
        `Are you sure you want to cash out ${this.cashOutAmountFormatted} dollars from this customer profile?  Your username will be logged with this changed and displayed in the Loyalty Ledger.  Click Yes to continue.`,
        {
          title: 'Cash Out Loyalty Points?',
          buttons: [
            {
              name: 'Cancel',
              value: 'cancel',
              outlined: true,
              color: 'warning'
            },
            {
              name: 'Yes, Cash Out ' + this.cashOutAmountFormatted,
              value: 'Yes',
              color: 'primary'
            }
          ],
          oWindow: {
            width: 400,
            height: 300,
            persistent: true,
            overlay: true
          }
        }
      )) !== 'Yes') {
        return
      }
      this.loading = true
      try {
        const result = await this.$http({
          method: 'POST',
          url: `/v2/contacts/${this.contactId}/cash_out`,
          data: {
            amount: this.cashOutAmount
          }
        })
        if (result?.data?.data) {
          this.discount = result.data.data
        }
        this.onUpdate()
      } finally {
        this.loading = false
      }
    },
    copyCode () {
      navigator.clipboard.writeText(this.discount.code)
      this.copied = true
    }
  }
}
</script>
