import fieldTypes from '../fieldTypes'
import { render } from 'shared/smart-text'

export default ({
  value,
  schema,
  schemas, // Passthru for Object type fields
  options = {}
}) => {
  let result = value
  const fieldType = fieldTypes.getClassForSchema(schema)
  if (fieldType) {
    // If it's an array type field and the value isn't an array, make it an array
    if (schema.multiple && !Array.isArray(value)) {
      if (value) {
        value = [value]
      } else {
        value = []
      }
    }
    if (
      schema.multiple &&
      Array.isArray(value)
    ) {
      if (value.length) {
        let schemaValueMap
        const singleItemSchema = {
          ...schema,
          options: undefined,
          multiple: undefined
        }
        if (
          schema.options &&
          schema.options.length
        ) {
          schemaValueMap = {}
          schema.options.forEach((item) => {
            const sanitizedValue = fieldType.sanitize({
              value: item.value,
              schema: singleItemSchema,
              schemas,
              options
            })
            schemaValueMap[item.value] = sanitizedValue
            schemaValueMap[item.label] = sanitizedValue
          })
        }
        value = value.reduce(
          (reduced, item) => {
            if (typeof item === 'string') {
              // Run it through smartText
              item = render(item, options.context, options)
            }
            let itemValue = fieldType.sanitize({
              value: item,
              schema: singleItemSchema,
              schemas,
              options
            })

            // This allows for mongoids to be cast server-side
            if (options && typeof options.afterSanitize === 'function') {
              itemValue = options.afterSanitize({
                value: itemValue,
                schema: singleItemSchema,
                options
              })
            }

            if (
              schemaValueMap &&
              itemValue !== null
            ) {
              itemValue = schemaValueMap[itemValue]
            }
            if (
              itemValue ||
              (
                itemValue === null &&
                options.allow_null
              )
            ) {
              reduced = [
                ...reduced,
                itemValue
              ]
            }
            return reduced
          },
          []
        )
        result = value.length ? value : null
      } else {
        result = null
      }
    } else {
      if (typeof value === 'string') {
        value = render(value, options.context, options)
      }

      result = fieldType.sanitize({
        value,
        schema,
        schemas,
        options
      })

      // This allows for mongoids to be cast server-side
      // 9/11/2020 - This used to go BEFORE sanitize but I moved it after
      if (options && typeof options.afterSanitize === 'function') {
        result = options.afterSanitize({
          value: result,
          schema,
          options
        })
      }
    }
  }

  return result
}
