import component from '../text/Text'

export default {
  id: 'unsubscribe',
  name: 'Opt-out',
  icon: 'far fa-bell-slash',
  component,
  factory () {
    return {
      type: 'text',
      props: {
        padding: '10px'
      },
      content: '<p style="text-align: center">No longer want to receive emails?  Click <a href="{{ _url_unsubscribe }}">here</a> to unsubscribe.</p>'
    }
  }
}
