<template>
  <div class="cg-card-editor">
    <div class="d-flex justify-center py-4">
      <vue-ccard
        style="width: 375px;"
        :number="formattedNumber"
        :holder="''"
        :exp="((recordChanged.data && recordChanged.data.exp_month) || '') + '/' + ((recordChanged.data && recordChanged.data.exp_year) || '')"
        :cvc="(recordChanged.private && recordChanged.private.cvc) || ''"
        :is-typing-cvc="isTypingCvc"
      />
    </div>
    <v-row
      class="flex-wrap pt-6"
    >
      <v-col
        cols="12"
        md="8"
        class="py-0"
      >
        <v-text-field
          label="Credit Card Number"
          name="number"
          :value="recordChanged.private && recordChanged.private.number"
          @input="$handleChange('record', 'private.number', $event)"
          outlined
          dense
          v-bind="{ disabled }"
        />
      </v-col>
      <v-col
        cols="6"
        md="4"
        class="py-0"
      >
        <v-text-field
          label="Security Code"
          name="cvc"
          :value="recordChanged.private && recordChanged.private.cvc"
          @input="$handleChange('record', 'private.cvc', $event)"
          @focus="isTypingCvc = true"
          @blur="isTypingCvc = false"
          outlined
          dense
          v-bind="{ disabled }"
        />
      </v-col>
      <v-col
        cols="6"
        md="4"
        class="py-0"
      >
        <v-select
          label="Expiration Month"
          name="expire_month"
          :value="((recordChanged.data && recordChanged.data.exp_month) || '') + ''"
          @input="$handleChange('record', 'data.exp_month', $event)"
          outlined
          dense
          :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']"
          v-bind="{ disabled }"
        />
      </v-col>
      <v-col
        cols="6"
        md="4"
        class="py-0"
      >
        <v-select
          label="Year"
          name="expire_year"
          :value="((recordChanged.data && recordChanged.data.exp_year) || '') + ''"
          @input="$handleChange('record', 'data.exp_year', $event)"
          outlined
          dense
          :items="years"
          v-bind="{ disabled }"
        />
      </v-col>
      <v-col
        cols="6"
        md="4"
        class="py-0"
      >
        <v-text-field
          label="Postal Code"
          :value="recordChanged.data && recordChanged.data.address_zip"
          @input="$handleChange('record', 'data.address_zip', $event)"
          outlined
          dense
          v-bind="{ disabled }"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-checkbox
          v-if="!recordChanged._id"
          class="mt-0"
          label="I understand that a temporary $5 hold will be placed on this card for verification purposes.  This amount is typically returned after a few days."
          :input-value="recordChanged.allowTemporaryAuth"
          @click="$handleChange('record', 'allowTemporaryAuth', !recordChanged.allowTemporaryAuth)"
          dense
          v-bind="{ disabled }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.cg-card-editor {
  .vue-c-card {
    .card-number {
      height: 33px !important;
    }
    .card-exp {
      width: 90px !important;
    }
    .card-cvc {
      width: 90px !important;
    }
  }
}
</style>

<script>
import VueCcard from 'vue-ccard'
import { formatCardNumber } from '@phenax/cc-number-formatter'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueCcard
  },
  data () {
    return {
      isTypingCvc: false
    }
  },
  computed: {
    recordChanged () {
      return this.value || {}
    },
    formattedNumber () {
      if (this.recordChanged?.private?.number) {
        return formatCardNumber(this.recordChanged.private.number)
      }
      return '**** **** **** ****'
    },
    years () {
      const year = (new Date()).getFullYear()
      const years = []
      for (let i = year; i < year + 12; i++) {
        years.push(i)
      }
      return years.map(v => v + '')
    }
  },
  methods: {
    $handleChange (key, path, value) {
      this.$emit('change', [key, path, value])
    }
  }
}
</script>
