import Model from './Model'

class Filter extends Model {
  static entity = 'filters'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      favorite: this.attr(undefined),
      name: this.attr(undefined),
      object: this.attr(undefined),
      tags: this.attr(undefined),
      type: this.attr(undefined),
      default: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      permissions: this.attr(undefined)
    }
  }
}

export default Filter
