import Action from '../Action'
import colors from 'shared/colors'

class SlackMessage extends Action {
  static get title () {
    return 'Slack Message'
  }

  static get class () {
    return 'SlackMessage'
  }

  static get color () {
    return colors.info
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []
    const text = block?.props?.payload?.text
    const url = block?.props?.url
    if (!url || !url.startsWith('https://')) {
      errors.push({
        message: 'Invalid Slack Webhook URL',
        path: 'payload.url'
      })
    }
    if (!text) {
      errors.push({
        message: 'Message Text is empty.',
        path: 'payload.text'
      })
    }
    return super.getErrors(environment).concat(errors)
  }
}

export default SlackMessage
