import round from 'lodash/round'
import toNumber from 'lodash/toNumber'
import get from 'lodash/get'

const parseParams = ({ params = {}, options = {} } = {}) => {
  return {
    value: toNumber(get(params, 'value')) || 0,
    param: toNumber(get(params, 'param') || get(params, 'precision')) || 0
  }
}

export default (args) => {
  const { value, param } = parseParams(args)
  return round(value, param)
}

const humanizeCall = (args) => {
  const { param } = parseParams(args)
  return `Round ${param} decimal places`
}

export {
  humanizeCall
}
