import Model from './Model'

class BlockTask extends Model {
  static entity = 'block_tasks'

  static evictUnused = true

  static fields () {
    return {
      _id: this.attr(undefined),
      priority: this.attr(undefined),
      contact_id: this.attr(undefined),
      block_id: this.attr(undefined),
      sequence_id: this.attr(undefined),
      account_id: this.attr(undefined),
      class: this.attr(undefined),
      type: this.attr(undefined),
      payload: this.attr(undefined),
      from_block_id: this.attr(undefined),
      process_id: this.attr(undefined),
      route_id: this.attr(undefined),
      allowed: this.attr(undefined),
      run_at: this.attr(undefined),
      done_at: this.attr(undefined),
      dt: this.attr(undefined),
      unprocessed: this.attr(undefined),
      status: this.attr(undefined),
      error: this.attr(undefined),
      trace: this.attr(undefined),
      created_at: this.attr(undefined),
      requeued_at: this.attr(undefined),
      attempted_at: this.attr(undefined),
      processData: this.attr(undefined),
      profiling: this.attr(undefined),
      phase: this.attr(undefined),
      worker_id: this.attr(undefined)
    }
  }
}

export default BlockTask
