<template>
  <div
    class="text-center"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>
    <div
      v-else
      class="text-center"
      style="max-width: 400px; display: inline-block;"
    >
      <v-alert
        v-if="shopifyPaymentSource"
        color="warning"
        dark
      >Only ONE Shopify Payment Source is allowed to exist per-account.  All existing Shopify payment sources will be removed.  You may encounter an error when creating this Payment Source.</v-alert>
      <v-alert
        v-if="!addonType"
        color="error"
        dark
      >No Patch Package Addon Type could be found.  Payment terms cannot be generated.</v-alert>
      <v-alert
        v-else-if="!gateway"
        color="error"
        dark
      >No default SMS gateway could be found.  Payment terms cannot be generated.</v-alert>
      <div
        v-else
      >
        <p>A Shopify &quot;Recurring Application Charge&quot; will be generated with the following terms:</p>
        <v-card
          class="pa-3 mb-3"
        >{{ terms }}</v-card>
        <p>After saving, a "Confirm" button will appear next to the Payment Source.  The Shopify account owner will need to click this button to confirm and accept the payment source.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { PaymentSource, Addon, AddonType, Gateway } from 'ui/models'
import getShopifyRecurringApplicationChargeTerms from 'shared/util/getShopifyRecurringApplicationChargeTerms'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    environment: {
      type: String,
      default: 'production'
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    shopifyPaymentSource () {
      return PaymentSource.query().where(s => s.type === 'SHOPIFY').first()
    },
    addon () {
      // It's possible to add the charge without an addon existing yet
      // (say an account is upgrading, want to add charge before the addon)
      return Addon.query().where(addon => addon.type === 'BUNDLE-PATCH-FULL').first() || { type: 'BUNDLE-PATCH-FULL' }
    },
    addonType () {
      return AddonType.find('BUNDLE-PATCH-FULL')
    },
    gateway () {
      return Gateway.defaultForChannel('SMS')
    },
    terms () {
      return getShopifyRecurringApplicationChargeTerms(this.addon, this.addonType, this.gateway)
    }
  },
  async created () {
    const addonTypes = AddonType.all()
    if (!addonTypes.length) {
      this.loading = true
      try {
        await AddonType.fetchMany()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
