import { get } from 'lodash'
import { addRecordsToStore, Event } from 'ui/models'

export default {
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      text: null
    }
  },
  methods: {
    requestClose () {
      if (this.loading) {
        return
      }
      this.$emit('close')
    },
    async save () {
      if (this.loading || !this.contactId) {
        return
      }
      const promise = Event.save({
        contact_id: this.contactId,
        event: 'note',
        data: {
          text: this.text
        }
      })
      const response = await this.$manageLoading('loading', promise)
      let data = get(response, 'entities.events.0')
      if (!data.account_id) {
        data = get(response, 'response.data') // For now I need to do this until /v2/events can be updated to return { data: event } instead of just { ...event }
      }
      data = [data]
      addRecordsToStore({ events: data }, { force: true })
      this.$emit('close')
    }
  }
}
