import Model from './Model'

class Device extends Model {
  static entity = 'devices'

  // Stop evicting these so it doesn't clear the one stored in the device getter of the store
  // static evictUnused = true

  static fields () {
    return {
      _id: this.attr(undefined),
      name: this.attr(undefined),
      device_identifier: this.attr(undefined),
      client_id: this.attr(undefined),
      user_id: this.attr(undefined),
      account_id: this.attr(undefined),
      account_ids: this.attr(undefined),
      // If this device belongs to a CG admin
      // This will no longer be necessary when the kiosk has the ability to
      // allow admins to select accounts that aren't linked to the user
      // This prevents the device from showing up in UI for non-admins
      admin: this.attr(undefined),
      online: this.attr(undefined),
      last_heartbeat_at: this.attr(undefined),
      last_active_at: this.attr(undefined),
      last_download_at: this.attr(undefined),
      platform: this.attr(undefined),
      platform_version: this.attr(undefined),
      push: this.attr(undefined),
      device_name: this.attr(undefined),
      device_model: this.attr(undefined),
      app_name: this.attr(undefined),
      app_version: this.attr(undefined),
      js_version: this.attr(undefined),
      settings: this.attr(undefined),
      updated_at: this.attr(undefined),
      created_at: this.attr(undefined),
      // single entry from the accounts[] field on the server for this particular account
      account: this.attr(undefined),
      // settings for every account this device has attached to
      accounts: this.attr(undefined)
    }
  }
}

export default Device
