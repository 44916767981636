// Prepares & sanitizes all rules for a filter based on the type of operator, the type of field, etc.
// Ensures parameters searching number fields are cast as numbers, date field values are cast as dates, etc

import { sanitizeValue } from 'shared/field-ops'
import schemaForRulePath from '../transform/schemaForRulePath'
import { render } from 'shared/smart-text'
import { get, fromPairs } from 'lodash'
import fieldTypes from '../fieldTypes'

const sanitizeRule = (rule, options = {}) => {
  // Interpret these rules as "empty"
  // It would be better to throw an error on these but up until this point we haven't done so and
  // there are likely a significant number of filters that would no longer pass if this were changed
  // These filters would silently fail on the kiosk and result in undesired behavior.  Once we have a
  // better strategy for catching exceptions on the kiosk and resolving them we can adjust this.
  if (!rule || !rule[0] || !rule[1]) {
    return null
  }

  let operator = rule[1]
  let value = rule[2]

  const { schema, path } = schemaForRulePath(((rule[0] || '') + '').trim(), options)

  // If we're saying it's = '' or != '' then the comparison will simply be empty/!empty
  if (['=', '!='].includes(operator) && [undefined, null, ''].includes(value)) {
    operator = { '=': 'empty', '!=': '!empty' }[operator]
  }

  // Ensure operator is valid for the field type
  const fieldType = (schema && schema.type && fieldTypes[schema.type]) ? fieldTypes[schema.type] : fieldTypes.text
  if (!fieldType.operators[operator]) {
    throw new Error(`Invalid ${(schema && schema.type) || 'text'} operator "${operator}" for path "${path}"`)
  }

  // empty operators don't need a 3rd argument
  if (['empty', '!empty'].includes(operator)) {
    value = undefined
  } else if (operator === '%') {
    // Mod must be array with 2 numbers
    // Currently SmartText can't be used here but it's possible in the future
    if (value && Array.isArray(value) && value.length === 2 && !isNaN(value[0] * value[1])) {
      value = value.map((a) => a * 1)
    } else {
      // This should cause the filter to fail and not return any results
      throw new Error(`Value must be array of exactly 2 numbers with the first integer not 0. ${JSON.stringify(value)}`)
    }
  } else if (schema) {
    // Sanitize the value (this will run it through smart text)
    if (value !== undefined) {
      // If this field is an object and the value is an array then we can assume it's
      // a rule-set that will be passed into $elemMatch, so we can run sanitizeFilter
      // on this as if it's a filter for just this object value
      let schemas
      // If it's an object type field then get all subschemas ready
      if (schema.type === 'object') {
        schemas = fromPairs(
          Object.entries((options && options.schemas) || {})
            .filter(([path]) => path.startsWith(rule[0] + '.'))
            .map(([path, _schema]) => [path.replace(rule[0] + '.', ''), _schema])
        )
      }
      if (schema.type === 'object' && Array.isArray(value)) {
        const sanitizeFilter = require('./sanitizeFilter').default
        const objectFilter = sanitizeFilter({ where: value }, {
          ...options,
          schemas
        })
        value = objectFilter.where
      } else {
        const previousValue = value
        value = sanitizeValue({ value, schema, schemas, options })
        // For array fields: How do I prevent them from being turned into an array?
        // It's undesirable for ['groups', '=', 123] to compare groups[] with [123].  The spec is to
        // see if groups[] contains 123.
        // One possibly rugged solution is to check if the before value is NOT an array and the after IS and
        // just take the top value if before value is not...?
        if (!Array.isArray(previousValue) && Array.isArray(value)) {
          value = value[0]
        }
      }
    }
  } else if (typeof value === 'string') {
    // If there's no schema for the path but it's a string, run it through smarttext
    value = render(value, get(options, 'context'), options)
  }

  rule = [path, operator]
  if (value !== undefined) {
    rule.push(value)
  }

  return rule
}

export default sanitizeRule
