<template>
  <v-container
    class="align-start rounded-b-lg"
    style="background-color: #00000000; position: relative;"
  >
    <v-row style="padding: 3px !important;">
      <v-col
        v-for="(indicator, index) in indicators"
        :key="`contact-indicator-${index}`"
        cols="3"
        style="padding: 3px !important;"
      >
        <contact-indicator v-bind="indicator" />
      </v-col>
    </v-row>
    <div
      v-if="loading"
      class="d-flex align-center justify-center"
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(128, 128, 128, 0.2)"
    >
      <v-progress-circular
        color="primary"
        indeterminate
      />
    </div>
  </v-container>
</template>

<script>
import ContactIndicator from './ContactIndicator'
import { Contact } from 'ui/models'
import formatIndicatorValue from 'portal/src/modules/Dashboard/cards/FormatIndicatorValue'
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'

export default {
  components: {
    ContactIndicator
  },
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      stats: null
    }
  },
  computed: {
    ...mapGetters(['account', 'timezone']),
    contact () { // We want original contact before changes are saved
      return Contact.getFlattened(this.contactId)
    },
    indicators () {
      let loyaltyValue = (this.contact?.punchcard || 0)
      const hasLoyalty = this.$restrict({ 'addons.type': { $in: ['SHOPIFY', 'NCRSILVER'] } })
      if (hasLoyalty) {
        loyaltyValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
          (this.contact?.punchcard || 0) / (this.account?.settings?.loyalty?.points_redeem_value || 100)
        )
      }
      return [
        {
          label: 'Customer Since',
          value: (this.stats?.first_purchase_at ? moment(this.stats.first_purchase_at).tz(this.timezone || 'UTC').format('MM/DD/YY') : 'NA'),
          value2: (this.stats?.first_purchase_at ? moment(this.stats.first_purchase_at).tz(this.timezone || 'UTC').fromNow(true) : '')
        }, {
          label: `Total ${hasLoyalty ? 'Purchases' : 'Checkins'}`,
          value: this.stats?.total_purchases || 0
        }, {
          label: `Loyalty ${hasLoyalty ? 'Cash' : 'Points'}`,
          value: loyaltyValue,
          valueColor: hasLoyalty ? this.$vuetify.theme.currentTheme.success : undefined
        }, {
          label: 'Current Bucket',
          tooltip: 'The current bucket this customer falls into is based on their RFM scoring. These buckets include New, Promising, Loyal, At-Risk, Lost, and any sub-segments of these buckets.',
          value: this.rfmBucket
        }, {
          label: 'Last Purchase',
          value: (this.stats?.last_purchase_at ? moment(this.stats.last_purchase_at).tz(this.timezone || 'UTC').format('MM/DD/YY') : 'NA'),
          value2: (this.stats?.last_purchase_at ? moment(this.stats.last_purchase_at).tz(this.timezone || 'UTC').fromNow(true) : '')
        }, {
          label: 'Total Revenue',
          value: formatIndicatorValue(`$${(this.stats?.total_revenue || 0) / 100}`),
          valueColor: this.$vuetify.theme.currentTheme.success
        }, {
          label: 'AOV',
          tooltip: 'Average Order Value is calculated using Total Revenue divided by the Total Number of Orders for the Customer.',
          value: formatIndicatorValue(`$${((this.stats?.total_revenue || 0) / (this.stats?.total_purchases || 1)) / 100}`),
          valueColor: this.$vuetify.theme.currentTheme.success
        }, {
          label: 'RFM Score', // Example: 524 (R=5, F=2, M=4).
          tooltip: 'Recency, Frequency, and Monetary Value for this customer is based on their total purchases, time since last purchase, and total revenue spent. RFM Scores are on a scale of 1-5 for each, with the highest score possible being 5|5|5.',
          value: (this.contact?._rfm ? `${this.contact?._rfm?.r || 0}${this.contact?._rfm?.f || 0}${this.contact?._rfm?.m || 0}` : 'Unknown')
        }
      ].map(indicator => {
        if (!this.loading) {
          return indicator
        }
        indicator = {
          ...indicator,
          value: '-'
        }
        if (indicator.value2) {
          indicator.value2 = '-'
        }
        return indicator
      })
    },
    rfmBucket () {
      const rfmBuckets = {
        1.5: { name: 'Lost', subSegment: 'Loyalists' },
        2.5: { name: 'At-Risk', subSegment: 'Loyalists' },
        3.5: { name: 'Loyal', subSegment: '' },
        4.5: { name: 'Loyal', subSegment: '' },
        5.5: { name: 'Champions', subSegment: '' },
        1.4: { name: 'Lost', subSegment: 'Loyalists' },
        2.4: { name: 'At-Risk', subSegment: 'Loyalists' },
        3.4: { name: 'Loyal', subSegment: '' },
        4.4: { name: 'Loyal', subSegment: '' },
        5.4: { name: 'Loyal', subSegment: '' },
        1.3: { name: 'Lost', subSegment: 'Promising' },
        2.3: { name: 'At-Risk', subSegment: 'Promising' },
        3.3: { name: 'Promising', subSegment: '' },
        4.3: { name: 'Promising', subSegment: '' },
        5.3: { name: 'Promising', subSegment: '' },
        1.2: { name: 'Lost', subSegment: 'Promising' },
        2.2: { name: 'At-Risk', subSegment: 'Promising' },
        3.2: { name: 'Promising', subSegment: '' },
        4.2: { name: 'Promising', subSegment: '' },
        5.2: { name: 'Promising', subSegment: '' },
        1.1: { name: 'Lost', subSegment: 'First-Timers' },
        2.1: { name: 'At-Risk', subSegment: 'First-Timers' },
        3.1: { name: 'At-Risk', subSegment: 'First-Timers' },
        4.1: { name: 'New', subSegment: '' },
        5.1: { name: 'New', subSegment: '' }
      }
      const rfmBucket = rfmBuckets[`${this.contact?._rfm?.r || ''}.${this.contact?._rfm?.f || ''}`] || null
      return (rfmBucket ? `${rfmBucket.name}${rfmBucket.subSegment ? ` ${rfmBucket.subSegment}` : ''}` : 'Unknown')
    }
  },
  methods: {
    async loadData () {
      if (this.loading || !this.contact?._id) {
        return
      }
      this.loading = true
      try {
        const response = await this.$http({
          method: 'GET',
          url: `/v2/contacts/${this.contact._id}/stats`
        })
        this.stats = response?.data || null
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.loadData()
  }
}
</script>
