import { fromPairs, flatten, intersection } from 'lodash'

import { getSchemasForAddons } from './addons'
import entities from './entities'

/**
 * Method that makes it easy to get all contact schemas for example
 * like this:
 * const contactSchemas = filterSchemas(path => path.startsWith('contact.'))
 * Returns {
 *   'contact.first_name': {
 *     type: 'text',
 *     name: 'First Name'
 *   }
 * }
 * @param {Object} schemas - schemas to filter
 * @param {Function} fn - filtering function
 */
const filterSchemas = (schemas, fn) => { // (path, value) fill be passed into fn
  return fromPairs(
    Object.entries(schemas).filter(([path, value]) => fn(path, value))
  )
}

/**
 * Returns all schemas based on the parameters
 * @param {[Addon]} options.addons
 * @param {Schemas} options.customSchemas
 */
const getSchemas = (options = {}) => {
  const schemas = fromPairs(
    flatten(
      Object.values(entities)
        .map(({ schemas = {}, getSchemas, entity = '', path = '' }) => {
          if (typeof getSchemas === 'function') {
            schemas = getSchemas(options)
          }
          return Object.entries(schemas).map(([schemaPath, schema]) => {
            schema = { ...schema }
            if (entity && schema.name) {
              schema.full_name = `${entity} ${schema.full_name || schema.name}`
            }
            schema.path = `${path ? path + '.' : ''}${schemaPath}`
            if (schema.parent) {
              schema.parent = `${path ? path + '.' : ''}${schema.parent}`
            }
            return [schema.path, schema]
          })
        })
    )
  )

  // If addons are specified, get all of those schemas
  let allSchemas = {
    ...schemas,
    ...getSchemasForAddons(options)
  }

  // Remove any schemas that require addons that this account does not have
  // I placed this here so add-ons could add schemas that require other add-ons
  const addonTypes = (options.addons || []).map(addon => addon.type)
  allSchemas = fromPairs(
    Object.entries(allSchemas)
      .filter(([path, schema]) => !(schema.addons && schema.addons.length) || intersection(schema.addons, addonTypes).length)
      .map(([path, schema]) => {
        schema = {
          ...schema,
          global: true,
          key: schema.key || path.split('.')[path.split('.').length - 1] // Key is needed for legacy reasons but is deprecated
        }
        return [path, schema]
      })
  )

  // Last, custom schemas can override any other schemas
  // Currently these are only contact-based schemas but may extend to
  // general fields and other entities later on
  return {
    ...allSchemas,
    ...(options.customSchemas || {})
  }
}

export {
  filterSchemas,
  getSchemas
}

// By default return all schemas that don't need an account environment
// to determine
export default getSchemas
