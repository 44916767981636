<template>
  <v-card
    :style="{ height: height + 'px' }"
    class="no-select snippet-card"
    v-on="$listeners.click ? { click: () => $emit('click') } : {}"
  >
    <div
      class="template-content"
      :class="{ 'with-overlay': overlay }"
    >
      <div
        class="content"
      >
        <card-preview
          :value="computedRecord"
        />
      </div>
      <div
        v-if="overlay"
        class="overlay align-center justify-center"
      >
        <div style="width: 150px;">
          <v-btn
            color="primary"
            block
            @click="$emit('select')"
          >{{ selectText }}</v-btn>
        </div>
      </div>
    </div>
    <div
      class="template-title d-flex align-center justify-center"
    >
      <div
        class="text-left flex-grow-1 px-3"
      >{{ record && record.name }}</div>
      <div
        class="pr-1"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              @click.stop="preview"
            >
              <v-icon>fa fa-eye</v-icon>
            </v-btn>
          </template>
          <span>Preview</span>
        </v-tooltip>
      </div>
    </div>
  </v-card>
</template>

<style lang="scss">
.snippet-card {
  .template-content {
    height: calc(100% - 50px);
    position: relative;
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      display: none;
      width: 100%;
      height: 100%;
    }
    .content {
      height: 100%;
      pointer-events: none;
      user-select: none;
    }
    &.with-overlay {
      &:hover {
        .overlay {
          display: flex;
        }
        .content {
          opacity: 0.4;
        }
      }
    }
  }
  .template-title {
    height: 50px;
    text-align: center;
    font-weight: bold;
    border-top: 1px $lightBorder solid;
  }
}
</style>

<script>
import { Snippet } from 'ui/models'
import CardPreview from 'portal/src/modules/Settings/Templates/CardPreview'
import { PreviewDialog as EmailPreviewDialog } from 'ui/blocks/actions/EmailOut'

export default {
  components: {
    CardPreview
  },
  props: {
    recordId: {
      type: String,
      required: true
    },
    overlay: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 380
    },
    selectText: {
      type: String,
      default: 'Select'
    }
  },
  computed: {
    record () {
      return Snippet.find(this.recordId)
    },
    computedRecord () {
      return {
        content: {
          ebon: this.record?.content
        }
      }
    }
  },
  methods: {
    preview () {
      if (this.$listeners.preview) {
        this.$emit('preview')
      } else {
        this.$openWindow({
          component: EmailPreviewDialog,
          props: {
            value: this.computedRecord,
            type: 'html'
          }
        })
      }
    }
  }
}
</script>
