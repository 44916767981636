<template>
  <v-container
    fluid
    class="align-center justify-center"
  >
    <div
      v-if="$vuetify.breakpoint.mdAndUp || !selectType"
      style="max-width: 550px; margin: 0 auto;"
      class="pt-0 pt-md-10"
    >
      <div
        class="mt-0 mt-md-10 text-center"
      >
        <h4 style="font-size: 24px;">Give your SmartOffer a name.</h4>
      </div>
      <div
        class="mt-6"
      >
        <v-text-field
          class="mt-4"
          :value="(record && record.name) || ''"
          @input="$emit('change', ['name', ($event || '').substr(0, 40)])"
          outlined
          flat
          dense
          placeholder="My SmartOffer"
          label="SmartOffer Name"
          hint="The name chosen here will not be displayed is for internal use only."
          persistent-hint
          v-bind="{ disabled }"
        />
      </div>
      <div
        v-if="$vuetify.breakpoint.smAndDown"
        class="text-right"
      >
        <v-btn
          color="primary"
          small
          @click="selectType = true"
          :disabled="!record.name"
        >Continue</v-btn>
      </div>
    </div>
    <div
      class="pt-md-10"
      v-if="$vuetify.breakpoint.mdAndUp || selectType"
    >
      <div class="mb-6 mt-md-8 text-center">
        <h4 style="font-size: 24px;">What kind of SmartOffer are you creating?</h4>
      </div>
      <div style="max-width: 550px; margin: 0 auto">
        <v-row>
          <v-col
            :cols="12"
            :sm="6"
          >
            <icon-card
              style="margin: 0 auto;"
              class="fill-height"
              icon="fas fa-bullhorn"
              iconSize="50"
              color="success"
              title="Promotional"
              description="Offers available by text, email, or by clicking a link."
              :disabled="disabled || !record.name"
              @click="$emit('change', ['type', 'PROMOTIONAL'])"
              :active="record.type === 'PROMOTIONAL'"
            />
          </v-col>
          <v-col
            :cols="12"
            :sm="6"
          >
            <icon-card
              style="margin: 0 auto;"
              class="fill-height"
              icon="fas fa-heart"
              iconSize="50"
              color="info"
              title="Loyalty"
              description="Offers available to customers who have the required check-ins or points."
              :disabled="disabled || !record.name"
              @click="$emit('change', ['type', 'LOYALTY'])"
              :active="record.type === 'LOYALTY'"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import IconCard from 'ui/components/IconCard'

export default {
  components: {
    IconCard
  },
  props: {
    record: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectType: false
    }
  }
}
</script>
