<template>
  <div>
    <adjust-points
      v-bind="{ contactId }"
      @updated="onUpdatePoints"
    />
    <br />
    <loyalty-history
      v-bind="{ contactId }"
      ref="history"
    />
  </div>
</template>

<script>
import AdjustPoints from './AdjustPoints.vue'
import LoyaltyHistory from './LoyaltyHistory.vue'

export default {
  components: {
    AdjustPoints,
    LoyaltyHistory
  },
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  methods: {
    onUpdatePoints () {
      if (this.$refs.history) {
        this.$refs.history.loadData()
      }
    }
  }
}
</script>
