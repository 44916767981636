import openWindow from 'ui/plugins/OWindows/openWindow'
import DocumentViewer from 'ui/viewers/DocumentViewer'
import ContactEditor from '@/modules/Contacts/ContactEditor'

export default (action) => {
  const props = {
    ...action,
    initialData: action,
    contactEditor: ContactEditor
  }
  openWindow({
    component: DocumentViewer,
    props
  })
}
