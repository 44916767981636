<template>
  <div>
    <editor-card
      name="Promote"
    >
      <p v-if="record.type === 'LOYALTY'">This offer will only be visible on the Kiosk; For your customers to redeem this offer, you must have a Kiosk Flow with an Offers page. Be sure to train your cashiers on how Loyalty Points and Check-in Count based Loyalty offers work, to assist in customer understanding.</p>
      <p v-else>Promote this offer by creating a Patch Blast or by promoting the offer link below:</p>

      <div v-if="record.smartlink_id">
        <strong>Shareable SmartLink</strong>
        <div class="smartlink-code pa-4">
          <smartlink-ref
            :record-id="record.smartlink_id"
            link
          />
        </div>
      </div>
      <div v-else-if="record.type !== 'LOYALTY'">
        <p>No smartlink has been created for this offer.</p>
      </div>
    </editor-card>
    <editor-card
      v-if="record.type !== 'LOYALTY'"
      name="Activation"
    >
      <div>
        <div
          class="d-flex align-center"
        >
          <div
            class="flex-grow-1"
          >
            <v-checkbox
              class="mt-0"
              label="Checking this box will hide the offer for all contacts who have not activated the offer. SmartOffers can be activated from the offer itself or automatically activated when sending a Blast."
              :input-value="!record.auto_activate"
              readonly
              dense
              hide-details
              @click="!disabled && $emit('change', ['auto_activate', !record.auto_activate])"
              v-bind="{ disabled }"
            />
          </div>
          <div>
            <v-tooltip
              top
              max-width="350"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                >fa fa-info-circle</v-icon>
              </template>
              <div>This expiration will be overridden by the offer's expiration date (if set) in Step 1.</div>
            </v-tooltip>
          </div>
        </div>
        <template
          v-if="!record.auto_activate"
        >
          <v-card class="my-5">
            <v-card-text>
              <strong>Expires After Duration</strong>
              <v-row>
                <v-col
                  md="6"
                >
                  <v-text-field
                    :value="(record.activation_props && record.activation_props.duration && record.activation_props.duration.days) || null"
                    :rules="numberFieldValidationRules"
                    @input="$emit('change', ['activation_props.duration.days', $event])"
                    suffix="Days"
                    placeholder="0"
                    dense
                    outlined
                    persistent-placeholder
                    class="mb-0"
                    v-bind="{ disabled }"
                  />
                </v-col>
                <v-col
                  md="6"
                >
                  <v-text-field
                    :value="(record.activation_props && record.activation_props.duration && record.activation_props.duration.hours) || null"
                    :rules="numberFieldValidationRules"
                    @input="$emit('change', ['activation_props.duration.hours', $event])"
                    suffix="Hours"
                    placeholder="0"
                    dense
                    outlined
                    persistent-placeholder
                    class="mb-0"
                    v-bind="{ disabled }"
                  />
                </v-col>
              </v-row>
              <v-checkbox
                class="mt-0"
                label="Extend the expiration of this activation until 11:59 PM on the day it expires."
                :input-value="!!(record.activation_props && record.activation_props.extend)"
                readonly
                dense
                hide-details
                @click="!disabled && $emit('change', ['activation_props.extend', !(record.activation_props && record.activation_props.extend)])"
                v-bind="{ disabled }"
              />
            </v-card-text>
          </v-card>
          <v-text-field
            label="Kiosk Based Coupon Code"
            outlined
            dense
            :value="record.coupon_code"
            @input="$emit('change', ['coupon_code', $event])"
            v-bind="{ disabled }"
          >
            <v-tooltip
              slot="append-outer"
              top
              max-width="350"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                >fa fa-info-circle</v-icon>
              </template>
              <div>Your contacts may see this offer on the Kiosk when they use the value you enter in this field.</div>
            </v-tooltip>
          </v-text-field>
        </template>
      </div>
    </editor-card>
  </div>
</template>

<style lang="scss">
.smartlink-code {
  background-color: $bg;
  border: 1px $lightBorder solid;
  border-radius: 3px;
}
</style>

<script>
import EditorCard from 'ui/components/EditorCard.vue'
import SmartlinkRef from 'ui/references/Smartlink'
import createValidationMixin from 'ui/mixins/createValidationMixin'

export default {
  mixins: [
    createValidationMixin({
      numberField: {
        constraints: {
          numericality: {
            strict: true,
            notValid: 'must be a number'
          }
        }
      }
    })
  ],
  components: {
    EditorCard,
    SmartlinkRef
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    offersV2 () {
      return this.record?.offersV2 || false
    }
  }
}
</script>
