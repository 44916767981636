<script>
import VTextField from 'vuetify/lib/components/VTextField'
export default {
  name: 'FieldCustomWrapper',
  mixins: [
    VTextField
  ],
  computed: {
    isDirty () {
      return (
        VTextField.options.computed.isDirty.call(this) ||
        // allows for multiple value fields
        ((this.lazyValue?.length || 0) > 0)
      )
    }
  },
  methods: {
    genInput () {
      return this.$createElement('div', {
        staticClass: 'cg-field-custom-wrapper-content',
        id: this.computedId,
        style: {
          width: '100%'
        }
      }, [
        this.$slots.default
      ])
    }
  }
}
</script>
