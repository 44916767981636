import Model from './Model'
import base62 from 'base62/lib/ascii'

class Smartlink extends Model {
  static entity = 'smartlinks'

  static fields () {
    return {
      _id: this.attr(undefined),
      name: this.attr(undefined),
      domain: this.attr(undefined),
      account_id: this.attr(undefined),
      links: this.attr(undefined),
      untraceable: this.attr(undefined),
      referral: this.attr(undefined),
      last_hit_at: this.attr(undefined),
      last_hit_by: this.attr(undefined),
      hit_count: this.attr(undefined),
      manual: this.attr(undefined),
      untraceable_key: this.attr(undefined),
      senders: this.attr(undefined),
      ignore_addresses: this.attr(undefined),
      permissions: this.attr(undefined),
      settings: this.attr(undefined),
      tags: this.attr(undefined),
      test: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }

  get url () {
    if (process.env.NODE_ENV !== 'production') {
      return `http://${this.domain}.localhost:3000/${this.untraceable_key}`
    }
    return `https://${this.domain}/${this.untraceable_key}`
  }

  static suffixForAccount (accountId) {
    return '-' + base62.encode(accountId || '')
  }
}

export default Smartlink
