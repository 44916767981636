import getStagesAndFragmentsFromVariable from './getStagesAndFragmentsFromVariable'
import deconstructMethodCall from './deconstructMethodCall'
import transformVariables from './transformVariables'
import * as methods from './methods'
import { isStaticText } from './methods/staticText'
import get from 'lodash/get'
import md5 from 'md5'
import stringify from 'fast-json-stable-stringify'

/**
 * Renders (interpolates) the text provided with all variables filled in based on the values provided in the context.
 * If !!options.analyze then an array of analysis objects for each found variable will be returned with descriptions of all
 *    method calls (if no context is provided) otherwise only the method calls that get reached based on the passed in context.
 */

export default (text = '', context, options = {}) => {
  const analysis = { variables: [] }
  const renderedText = transformVariables(text, (variable) => {
    const variableAnalysis = { variable, stages: [] }
    analysis.variables.push(variableAnalysis)

    let allMethodCalls = []

    const stages = getStagesAndFragmentsFromVariable(variable)
    if (!stages.length) {
      if (get(options, 'analyze')) {
        return analysis
      }
      return ''
    }

    const finalResult = stages.reduce((currentValue, { stage, fragments }, stageIndex) => {
      const stageAnalysis = {
        stage,
        fragments: [],
        ...(context ? { valueIn: currentValue } : {})
      }
      variableAnalysis.stages.push(stageAnalysis)

      // Validate the fallbacks
      // If no fallbacks are truthy or 0/false, return an empty string
      if (!fragments.filter(p => p === 0 || p === false || !!(p || '')).length) {
        if (context) {
          stageAnalysis.valueOut = currentValue || ''
        }
        return currentValue || ''
      }

      const methodCalls = fragments.map((fragment, fragmentIndex) => {
        fragment = fragment.replace('{{', '').replace('}}', '').trim()
        const methodCall = deconstructMethodCall(fragment) || {
          method: isStaticText(fragment) ? 'staticText' : 'get',
          params: {
            param: fragment,
            ...(isStaticText(fragment) ? {} : { direct: true })
          }
        }

        if (typeof methodCall.params !== 'object') {
          methodCall.params = { param: methodCall.params || '' } // Ensure params is always an object for methods like staticText and get that normally just take strings
        }

        if (stageIndex > 0) {
          methodCall.params.afterStage = true
        }

        return {
          ...methodCall,
          fragment,
          ...(methodCall.method === 'link' ? { hydrateKey: createHydrateKey(stageIndex, fragmentIndex, methodCall.method, methodCall.params) } : {})
        }
      })
      allMethodCalls = allMethodCalls.concat(methodCalls)
      stageAnalysis.fragments = methodCalls

      // Link needs to be the only fragment of a stage and there can only be one link function in a variable
      if ((methodCalls.find(d => d.method === 'link') && methodCalls.length > 1) || allMethodCalls.filter(d => d.method === 'link').length > 1) {
        throw new Error('SmartLinks cannot be combined with any other SmartText')
      }

      // Only run this if we're not analyzing without a context
      if (!(get(options, 'analyze') && !context)) {
        stageAnalysis.fragments = []

        for (var partIndex = 0; partIndex < methodCalls.length; partIndex++) {
          let methodCall = methodCalls[partIndex]
          if (!(methodCall && methodCall.method && methods[methodCall.method]) && methods[methodCall.method].default) {
            stageAnalysis.fragments.push({}) // Placeholder
            continue
          }

          methodCall = {
            ...methodCall,
            params: {
              value: currentValue,
              ...methodCall.params
            }
          }
          let result = methods[methodCall.method].default({
            params: methodCall.params,
            options: {
              ...options,
              ...(methodCall.hydrateKey ? { hydrateKey: methodCall.hydrateKey } : {})
            },
            context
          })

          stageAnalysis.fragments.push(methodCall)

          if (result || result === 0 || result === false) {
            if (typeof result === 'string') {
              // Prevent html injection simply by not allowing chevrons
              result = result.replace(/</g, '&lt;').replace(/>/g, '&gt;')
            }
            if (context) {
              stageAnalysis.valueOut = result
            }
            return result
          }
        }
      }
    }, undefined)

    return finalResult || ''
  })

  if (get(options, 'analyze')) {
    return {
      ...analysis,
      result: renderedText
    }
  }

  return renderedText
}

const createHydrateKey = (stageIndex, fragmentIndex, name, obj) => {
  return md5(stageIndex + '---' + fragmentIndex + '--- ' + name + '---' + stringify(obj))
}

export {
  createHydrateKey
}
