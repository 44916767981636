const entity = 'Offer'
const path = 'offer'

const schemas = {
  _id: { type: 'number', name: 'ID' },
  account_id: { type: 'number', name: 'Account ID' },
  name: { type: 'text', name: 'Name' },
  display_name: { type: 'text', name: 'Display Name' },
  type: { type: 'text', name: 'Type', options: [{ name: 'Promotional', value: 'PROMOTIONAL' }, { name: 'Loyalty', value: 'LOYALTY' }] },
  smartlink_id: { type: 'text', name: 'Smartlink' },
  description: { type: 'text', name: 'Description' },
  active: { type: 'checkbox', name: 'Enabled' },
  punchcard_key: { type: 'text', name: 'Punchcard Key' },
  auto_activate: { type: 'checkbox', name: 'Auto Activate' },
  start_at: { type: 'timestamp', name: 'Start Time' },
  end_at: { type: 'timestamp', name: 'End Time' },
  total_use_limit: { type: 'number', name: 'Total Use Limit' },
  customer_use_limit: { type: 'number', name: 'Customer Use Limit ' },
  redemption_value: { type: 'text', name: 'Redemption Value' },
  coupon_code: { type: 'text', name: 'Coupon Code' },
  discount_amount: { type: 'number', name: 'Discount Amount' },
  discount_percent: { type: 'number', name: 'Discount Percent' },
  points: { type: 'number', name: 'Points' },
  offersV2: { type: 'checkbox', name: 'Offers V2' },
  tags: { type: 'text', name: 'Tags' },
  stats: { type: 'object', name: 'Stats', hidden: true },
  'stats.redemption_count': { type: 'number', name: '# Redemptions' },
  'stats.last_redemption_at': { type: 'timestamp', name: 'Last Redemption Time' },
  'stats.last_redemption_by': { type: 'text', name: 'Last Redemption Contact' },
  'stats.activation_count': { type: 'number', name: '# Activations' },
  'stats.last_activation_at': { type: 'timestamp', name: 'Last Activation Time' },
  'stats.last_activation_by': { type: 'text', name: 'Last Activation Contact' }
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path
}
