import moment from 'moment-timezone'

const faultMessages = {
  INACTIVE: () => 'The offer is not enabled.',
  BEFORE_START: ({ offer, timezone = 'UTC' }) => `This offer does not begin until ${moment(offer.start_at).tz(timezone).format('LLL')}.`,
  AFTER_END: ({ offer, timezone = 'UTC' }) => `This offer ended at ${moment(offer.end_at).tz(timezone).format('LLL')}.`,
  TOTAL_USE_LIMIT: ({ offer }) => `This offer has reached its total redemption limit of ${offer.total_use_limit}.`,
  NOT_FOUND: () => 'This offer was not found.'
}

export default faultMessages
