import component from './Button'

export default {
  id: 'button',
  name: 'Button',
  icon: 'fas fa-square',
  component,
  factory () {
    return {
      props: {
        padding: '10px',
        buttonBorderRadius: 3,
        buttonPadding: '10px'
      },
      content: 'Button'
    }
  }
}
