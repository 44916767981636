import md5 from 'md5'

export default ({
  messageId,
  contactId,
  rootPath = 'https://mailgro.citygro.com/mailgro',
  salt = 'gwhodgiawhdgoaiwdhgaHFIWFHiawgaoigahwodv'
}) => {
  messageId = messageId || 'aaaa1111bbbb2222cccc3333'
  let hash = md5(salt + messageId + (contactId || ''))
  if (contactId && contactId.includes('test')) {
    hash = 'this-is-a-test-url'
  }
  const params = [
    `m=${messageId}`,
    `h=${hash}`
  ]
  if (contactId) {
    params.push(`c=${contactId}`)
  }
  return {
    _url_hash: hash,
    ...(contactId ? {
      _url_manage: `${rootPath}/manage?${params.join('&')}`,
      _url_unsubscribe: `${rootPath}/unsubscribe?${params.join('&')}`
    } : {}),
    _url_tracker_image: `${rootPath}/track?${params.join('&')}`,
    _url_archive: `${rootPath}/archive?${params.join('&')}`
  }
}
