import Model from './Model'

class Snippet extends Model {
  static entity = 'snippets'

  static fields () {
    return {
      _id: this.attr(undefined),
      name: this.attr(undefined),
      account_id: this.attr(undefined),
      class: this.attr(undefined),
      tags: this.attr(undefined),
      content: this.attr(undefined),
      permissions: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

export default Snippet
