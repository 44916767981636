import { dark, light } from 'shared/colors'

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: null
    },
    size: {
      type: [String, Number],
      default: 40
    },
    color: {
      type: String
    },
    icon: {
      type: String,
      default: 'fa-user'
    },
    iconColor: {
      type: String,
      default: '#ffffff'
    },
    forceIcon: {
      type: Boolean,
      default: false
    },
    noTooltip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    initials () {
      if (!this.name || this.forceIcon || this.image) {
        return ''
      }
      const nameParts = this.name.split(' ')
      let initials = (nameParts[0][0] || '')
      if (nameParts.length === 1) {
        initials += (nameParts[0][1] || '')
        return initials
      }
      initials += (nameParts[nameParts.length - 1][0] || '')
      return (initials + '').toUpperCase()
    },
    theme () {
      if (this.$store && this.$store.state && this.$store.state.appDarkMode) {
        return 'dark'
      }
      return 'light'
    },
    themeColors () {
      return {
        dark,
        light
      }[this.theme]
    },
    avatarStyles () {
      const styles = {
        width: this.size + 'px',
        height: this.size + 'px',
        fontSize: (this.size / (this.size < 30 ? 2 : 2.1)) + 'px'
      }
      if (this.image) {
        styles.backgroundImage = `url(${this.image})`
        styles.backgroundSize = 'cover'
        styles.backgroundPosition = 'center'
      } else {
        styles.backgroundColor = this.themeColors[this.color || 'asdf'] || this.color || '#aaa'
        styles.border = '1px rgba(255, 255, 255, 0.3) solid'
      }
      return styles
    },
    iconStyles () {
      return {
        fontSize: (this.size / 2) + 'px',
        color: this.iconColor
      }
    }
  }
}
