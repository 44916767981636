import component from './Column'

export default {
  id: 'column',
  name: 'Column',
  hidden: true,
  component,
  factory () {
    return {
    }
  }
}
