import { Contact, Event } from 'ui/models'
import { min, max, orderBy } from 'lodash'

export default {
  mixins: [
    Contact.mixin({ references: ['contact'] }),
    Event.mixin({
      references: ['events'],
      insertFilter: function (record) { // Filter incoming events from socket
        // Only allow an insert if the event is after the earliest event in this list
        // and is for the same contact
        return record.contact_id === (this.contactId || (this.contact && this.contact._id)) &&
          this.events.length &&
          record._id > min(this.events.map(e => e._id))
      }
    })
  ],
  props: {
    contactId: {
      type: String,
      required: true
    },
    activityListProps: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false,
      eventTypes: [], // Add one or more to filter by event type
      eventsLimit: 25,
      hasMoreEvents: true,
      isReady: false, // indicates ready to show in UI (no scrolling jumps will happen, etc)
      defaultGatewayId: null, // Used to auto-select "From" dropdown for ComposeMessage based on most recent message
      defaultFrom: null, // Used to auto-select "From" dropdown for ComposeMessage based on most recent message
      defaultSubject: null
    }
  },
  computed: {
    contact () {
      if (!this.contactId && !(this.conversation && this.conversation.contact_id)) {
        return null
      }
      return Contact.find(this.contactId || this.conversation.contact_id)
    },
    events () {
      return orderBy(
        (this.eventsRecords || [])
          .filter(e => !this.eventTypes.length || this.eventTypes.includes(e.event)),
        '_id',
        'asc'
      )
    }
  },
  watch: {
    events (newValue, previousValue) {
      // Scroll to bottom if new list of events has greater max id
      if (
        newValue &&
        newValue.length &&
        previousValue &&
        previousValue.length &&
        newValue.length > previousValue.length &&
        max(newValue.map(e => e._id)) > max(previousValue.map(e => e._id))
      ) {
        this.scrollToBottom({ animated: !!this.isReady }) // Never want to animiate if not initial load first
        this.determineDefaultGatewayId()
      } else if (newValue && newValue.length && !(previousValue && previousValue.length)) {
        this.scrollToBottom()
        this.determineDefaultGatewayId()
      }
    },
    loading () {
      this.$emit('loading', this.loading)
    },
    eventTypes () {
      this.hasMoreEvents = true
    }
  },
  methods: {
    scrollToBottom ({ animated } = {}) {
      console.warn('Override scrollToBottom method')
    },
    determineDefaultGatewayId () {
      // Find most recent message.in/message.out event and pull gateway id
      // Events are sorted ascending
      const sortedEvents = orderBy(
        this.events,
        ['_id'],
        ['desc']
      )

      const messageEvent = sortedEvents.find(e => {
        return ['message.in', 'message.out'].includes(e.event) &&
          e?.data?.gateway_id
      })
      if (messageEvent) {
        this.defaultGatewayId = (messageEvent.data && messageEvent.data.gateway_id) || this.defaultGatewayId
        this.defaultFrom = (messageEvent.event === 'message.out' ? messageEvent?.data?.from : messageEvent?.data?.to) || null
        this.defaultSubject = messageEvent?.data?.content?.subject
        if (this.defaultSubject && !this.defaultSubject.startsWith('Re:')) {
          this.defaultSubject = 'Re: ' + this.defaultSubject
        }
      }
      if (!this.defaultGatewayId) {
        // Look for default messenger gateway
        const defaultGateway = (this.gateways || []).find(g => !!g.messenger)
        if (defaultGateway) {
          this.defaultGatewayId = defaultGateway._id
        }
      }
    },
    // Pull more events and
    async loadMoreEvents (requestOptions = {}, options = {}) {
      if (!(this.contactId || (this.contact && this.contact._id))) {
        // No sense loading anything if there's no contact.
        throw new Error('Contact ID is required to load events')
      }
      // Never pass a max_id on initial load in case new message came in
      this.loading = true
      try {
        const result = await this.eventsFetchMany({
          params: {
            contact_id: this.contactId || this.contact._id,
            limit: this.eventsLimit,
            ...(this.eventTypes && this.eventTypes.length ? { events: this.eventTypes.join(',') } : {}),
            ...(this.events.length && this.isReady ? { max_id: min(this.events.map(e => e._id)) } : {})
          },
          ...requestOptions
        }, {
          force: true,
          appendResult: true,
          ...options
        })

        if (!(result.entities.events && result.entities.events.length && result.entities.events.length >= this.eventsLimit)) {
          this.hasMoreEvents = false
        }

        if (!this.isReady) {
          this.isReady = true
        }
        this.loading = false
        this.$emit('loaded', result.entities && result.entities.events)
        return result
      } catch (e) {
        this.loading = false
        throw e
      }
    },
    // Completely reload the conversation and all events
    async reload () {
      this.isReady = false
      this.hasMoreEvents = true
      this.eventsEmpty() // Remove all stored events from this component
      await this.loadMoreEvents()
    }
  }
}
