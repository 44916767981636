import Op from './Op'

class Unset extends Op {
  static run ({ value }) {
    return undefined
  }

  static shouldRun ({ value }) {
    return value !== undefined
  }
}

export default Unset
