import Inc from './Inc'

class Dec extends Inc {
  static run (params) {
    const { value, opValue } = this.getValues(params)
    return value - opValue
  }
}

export default Dec
