import padStart from 'lodash/padStart'

const operators = {
  '<': '$lt',
  '>': '$gt',
  empty: 'empty',
  '!empty': '!empty',
  '=': '$eq',
  '!=': '$ne',
  '>=': '$gte',
  '<=': '$lte'
}

const buildQueryRule = ({ path, operator, value }) => {
  operator = operators[operator]

  if (typeof value === 'string') {
    // Value needs to be YYYY-MM-DD or MM-DD format
    if (value.length < 10) {
      // Split into parts.  Assuming MMDD or MM-DD format
      const parsedValue = value.replace(/-/g, '')
      if (parsedValue.length === 4 && !isNaN(parsedValue * 1)) {
        value = {
          m: parsedValue.substr(0, 2),
          d: parsedValue.substr(2, 2)
        }
      }
      // Otherwise an error should be thrown here
    } else {
      value = { v: value }
    }
  }

  // Safe to assume that this field should be an object at this point.
  if (typeof value !== 'object' || !value || (!value.y && !value.m && !value.v)) {
    throw new Error(`Invalid date value ${JSON.stringify(value)} at path "${path}"`)
  }

  if (['$eq', '$ne'].indexOf(operator) > -1 && (value.y || value.m) && !(value.y && value.m && value.d)) {
    const rules = [];
    ['y', 'm', 'd'].forEach((key) => {
      if (value[key] && !isNaN(value[key] * 1) && value[key] > 0) {
        rules.push({ [path + '.' + key]: { [operator]: value[key] * 1 } })
      }
    })
    const conjunction = { $eq: '$and', $ne: '$or' }[operator]
    return { [conjunction]: rules }
  }

  value.v = value.v || (value.y ? value.y : '0000') + '-' + padStart(value.m, 2, '0') + '-' + padStart(value.d, 2, '0')

  return { [path + '.v']: { [operator]: value.v || null } }
}

export default {
  buildQueryRule,
  operators
}

export {
  buildQueryRule,
  operators
}
