import { get } from 'lodash'

/**
 * Get node by path relative to eb json children array
 * @param {*} json - The entire email builder json object
 * @param {*} path - Path looking like 2.0.1 representing section/column/element indexes
 */
const getNodeByPath = (json = {}, path) => {
  if (!path) {
    return
  }
  path = path.replace(/-/g, '.children.')
  return get((json.children || []), path)
}

export default getNodeByPath
