<template>
  <tr
    class="discount-code-row"
    :style="{ opacity: expired ? 0.5 : 1 }"
  >
    <td class="px-2"><span style="max-width: 200px; overflow: hidden; text-overflow: ellipsis;">
      <v-tooltip
        top
        slot="append"
        max-width="300"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="type !== 'GIFT_CARD'"
            v-on="on"
            x-small
            icon
            @click="copyCode"
          >
            <v-icon
              x-small
            >{{ copied ? 'fa fa-check' : 'fas fa-clipboard' }}</v-icon>
          </v-btn>
        </template>
        <div>
          <div>{{ copied ? 'Copied to clipboard:' : 'Copy to clipboard:' }}</div>
          <div>{{ codeFormatted }}</div>
        </div>
      </v-tooltip>
      <span :style="expired ? { textDecoration: 'line-through' } : {}">{{ codeFormatted }}</span></span></td>
    <td class="px-2">{{ valueFormatted }}</td>
    <td class="px-2"><span>{{ typeName }}</span></td>
    <td class="px-2">
      <span
        v-if="disabledByUser"
      >Deleted by <user-ref style="display: inline-block;margin-left: -8px;" :record-id="disabledByUser" /></span>
      <span
        v-else
      >{{ expirationFormatted }}</span>
    </td>
    <td class="px-2">
      <v-btn
        icon
        x-small
        color="primary"
        @click="viewEventData"
      >
        <v-icon x-small>fa fa-ellipsis-h</v-icon>
      </v-btn>
    </td>
    <td
      v-if="showStatus"
      class="text-right px-2"
    >
      <div
        v-if="loading"
        class="text-left"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>
      <v-btn
        v-else-if="type !== 'GIFT_CARD'"
        outlined
        x-small
        color="warning"
        @click="disable"
      >Delete</v-btn>
    </td>
  </tr>
</template>

<style lang="scss">
.discount-code-row {
  td {
    > span {
      display: inline-block; white-space: nowrap;
    }
  }
}
</style>

<script>
import EventViewer from 'ui/viewers/EventViewer'
import { Event } from 'ui/models'
import UserRef from 'ui/references/User'
import objectIdToTimestamp from 'shared/util/time/objectIdToTimestamp.js'
import moment from 'moment-timezone'

export default {
  components: {
    UserRef
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    expired: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      copied: false
    }
  },
  computed: {
    giftCard () {
      return this.event?.data?.external_data?.gift_card || {}
    },
    priceRule () {
      return this.event?.data?.external_data?.price_rule || {}
    },
    discountCode () {
      return this.event?.data?.external_data?.discount_code || this.event?.data?.external_data || {}
    },
    type () {
      if (this.event?.data?.type === 'GIFT_CARD') {
        return 'GIFT_CARD'
      } else if (this.discountCode?.free_shipping) {
        return 'FREE_SHIPPING'
      } else if (this.priceRule.value_type === 'percentage' || this.discountCode?.discount_type === 'percent') {
        if (this.priceRule.target_type === 'shipping_line') {
          return 'FREE_SHIPPING'
        }
        return 'PERCENT'
      } else if (this.priceRule.value_type === 'fixed_amount' || ['fixed_cart', 'fixed_product'].includes(this.discountCode?.discount_type)) {
        return 'AMOUNT'
      }
      return null
    },
    code () {
      if (this.type === 'GIFT_CARD') {
        return this.giftCard?.last_characters
      }
      return this.discountCode?.code
    },
    codeFormatted () {
      if (this.type === 'GIFT_CARD') {
        return `*******${this.code}`
      }
      return this.code
    },
    value () {
      if (this.type === 'GIFT_CARD') {
        return this.giftCard.initial_value
      } else if (['AMOUNT', 'PERCENT'].includes(this.type)) {
        return this.priceRule?.value * -1 || this.discountCode?.amount
      }
      return 0
    },
    valueFormatted () {
      if (['GIFT_CARD', 'AMOUNT'].includes(this.type)) {
        return window.Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.value)
      } else if (this.type === 'PERCENT') {
        return this.value + '%'
      } else if (this.type === 'FREE_SHIPPING') {
        return 'N/A'
      }
      return this.value
    },
    expiration () {
      return this.priceRule?.ends_at || (this.discountCode?.date_expires_gmt ? this.discountCode?.date_expires_gmt + 'Z' : null) || null // TODO: Normalize this
    },
    disabledByUser () {
      if (!this.showStatus && this.event?.data?.disabled_by) {
        return this.event.data.disabled_by
      }
      return null
    },
    expirationFormatted () {
      if (!this.showStatus) {
        if (this.event?.data?.converted?.event_id) {
          return 'Used ' + this.$timestamp(moment.unix(objectIdToTimestamp(this.event.data.converted.event_id)), 'lll')
        } else if (this.event?.data?.disabled_at) {
          return `${this.expiration ? 'Exp' : 'Disabled'} ${this.$timestamp(this.event.data.disabled_at, 'lll')}`
        } else if (this.expiration) {
          return `Exp ${this.$timestamp(this.expiration, 'lll')}`
        }
        return '--'
      }

      if (!this.expiration) {
        return 'NO EXPIRATION'
      }
      return this.$timestamp(this.expiration, 'lll')
    },
    typeName () {
      return {
        AMOUNT: 'Fixed Amount',
        PERCENT: 'Percentage',
        GIFT_CARD: 'Gift Card',
        FREE_SHIPPING: 'Free Shipping'
      }[this.type] || this.type
    }
  },
  watch: {
    copied (value) {
      if (value) {
        setTimeout(() => {
          this.copied = false
        }, 2000)
      }
    }
  },
  methods: {
    copyCode () {
      navigator.clipboard.writeText(this.codeFormatted)
      this.copied = true
    },
    viewEventData () {
      if (!this.event?._id) {
        return
      }
      this.$openWindow({
        component: EventViewer,
        props: {
          eventId: this.event._id
        }
      })
    },
    async disable () {
      this.loading = true
      if ((await this.$confirm(
        'Are you sure you want to disable this discount code? This cannot be undone and a new discount code will have to be created.',
        {
          title: 'Disable Discount Code',
          buttons: [
            {
              name: 'Cancel',
              color: 'warning',
              outlined: true
            },
            {
              name: 'Confirm',
              color: 'primary',
              value: 'CONFIRM'
            }
          ]
        }
      )) !== 'CONFIRM') {
        this.loading = false
        return
      }
      try {
        const result = await this.$http({
          method: 'DELETE',
          url: `/v2/contacts/${this.event.contact_id}/discount_codes/${this.event._id}`
        })

        if (result.data.event) {
          Event.insert({
            data: result.data.event
          })
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
