import Model from './Model'

class Import extends Model {
  static entity = 'imports'
  static socketInsertOnCreated = 'always'

  static fields () {
    return {
      _id: this.attr(undefined),
      name: this.attr(undefined),
      account_id: this.attr(undefined),
      records: this.attr(undefined),
      records_processed: this.attr(undefined),
      status: this.attr(undefined),
      accept_liability: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      created_by: this.attr(undefined),
      settings: this.attr(undefined),
      columns: this.attr(undefined)
    }
  }
}

export default Import
