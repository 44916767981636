import Model from './Model'

class Template extends Model {
  static entity = 'templates'

  static fields () {
    // The template resource needs to have all of the "content-like" components
    // moved inside of the "content" field so the model doesn't need to be
    // maintained anytime a new type of channel is added
    // I think the best way to do this is build the new portal to use the
    // "content" field and have the server keep both places in sync
    return {
      _id: this.attr(undefined),
      name: this.attr(undefined),
      account_id: this.attr(undefined),
      allow_multiple: this.attr(undefined),
      class: this.attr(undefined),
      channel: this.attr(undefined),
      gateway_id: this.attr(undefined),
      content: this.attr(undefined),
      tags: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      permissions: this.attr(undefined)
    }
  }
}

export default Template
