// DEPRECATED, PLEASE DON'T USE
// Only used by @citygro/api

/* global Modernizr */

const cryptoObj = (typeof window === 'object') && (window.crypto || window.msCrypto) // support IE11
const random = (cryptoObj && window.Modernizr && Modernizr.crypto)
  ? () => {
    // @link https://stackoverflow.com/questions/13694626/
    const arr = new Uint32Array(2)
    cryptoObj.getRandomValues(arr)
    const mantissa = (arr[0] * Math.pow(2, 20)) + (arr[1] >>> 12)
    return (mantissa * Math.pow(2, -52))
  }
  : Math.random

export default random
