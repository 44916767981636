import { isEmpty, testObject } from 'shared/filter'

/**
 * @param ebon - the email builder object notation or equivalent
 * @param payload - the payload object
 * @param options - options object containing filter testObject options, such as timezone and schemas
 */
const removeFilteredMessageNodes = (ebon, payload, options) => {
  // Target children of every object
  return {
    ...ebon,
    children: (ebon.children || [])
      .filter(child =>
        isEmpty(child.props?.filter) ||
        testObject(payload, child.props?.filter, options)
      ).map(child => removeFilteredMessageNodes(child, payload, options))
  }
}

export default removeFilteredMessageNodes
