<template>
  <div class="contact-subscriptions elevation-1">
    <v-simple-table
      dense
    >
      <thead>
        <tr>
          <th>Product</th>
          <th>Status</th>
          <th>Start</th>
          <th>End</th>
          <th>Next Billing</th>
          <th>
            <!-- <v-btn
              small
              icon
              :disabled="loading"
              @click="loadData"
              class="float-right"
            >
              <v-icon small>fa-redo</v-icon>
            </v-btn> -->
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="loading"
        >
          <td
            colspan="6"
            class="pa-2 text-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </td>
        </tr>
        <tr
          v-else-if="!subscriptionRecords.length"
        >
          <td colspan="6" class="text-center pa-2">
            This contact does not have any subscriptions.
          </td>
        </tr>
        <tr
          v-for="subscriptionRecord in subscriptionRecords"
          :key="subscriptionRecord._id"
        >
          <td style="font-size: 8px;">{{ subscriptionRecord.product_id }}</td>
          <td>{{ subscriptionRecord.status }}</td>
          <td>{{ subscriptionRecord.start_at ? $timestamp(subscriptionRecord.start_at, 'L') : '-' }}</td>
          <td>{{ subscriptionRecord.end_at ? $timestamp(subscriptionRecord.end_at, 'L') : '-' }}</td>
          <td>{{ subscriptionRecord.next_billing_at ? $timestamp(subscriptionRecord.next_billing_at, 'L') : '-' }}</td>
          <td
            class="text-right"
          >
            <div class="pa-2">
              &nbsp;
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { Contact } from 'ui/models'

export default {
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      startingBlockIds: [],
      timeouts: []
    }
  },
  computed: {
    contact () {
      return Contact.find(this.contactId)
    },
    subscriptionRecords () {
      return (this.contact?.data?._subscriptions || [])
    }
  }
}
</script>
