import OWindowsContainer from './OWindowsContainer'
import openWindow from './openWindow'

const VueOWindowsPlugin = {
  install (Vue, options) {
    Vue.mixin({
      methods: {
        $openWindow (params) {
          return openWindow.call(this, params)
        }
      }
    })

    Vue.component('o-windows-container', OWindowsContainer)

    // This way worked great except it ended up with 2 different root Vue components
    // and Vuetify settings weren't carried through.

    // const containerDiv = document.createElement('div')
    // containerDiv.id = 'o-windows-container'
    // document.body.appendChild(containerDiv)

    // const OWindowsContainerConstructor = Vue.extend(OWindowsContainer)

    // new OWindowsContainerConstructor().$mount(containerDiv)
  }
}

export default VueOWindowsPlugin
