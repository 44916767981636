<template>
  <div>
    <p>Please select the type of report to run</p>
    <v-select
      label="Report Type"
      :value="value"
      :items="reportTypes"
      outlined
      dense
      v-bind="{ readonly, disabled }"
      @input="$emit('input', $event)"
      style="max-width: 400px;"
    />
  </div>
</template>

<script>
import reportTypes from './types'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    reportTypes () {
      return Object.entries(reportTypes)
        .filter(([key, type]) => {
          return !type.restrict || this.$restrict(type.restrict)
        })
        .map(([key, type]) => ({
          text: type.name,
          value: key
        }))
    }
  }
}
</script>
