import { round } from 'lodash'

const toCash = ({ params = {}, options = {}, context = {} } = {}) => {
  const pointsRedeemValue = options?.loyalty?.points_redeem_value || 100
  let value
  if (params.value) {
    value = params.value
  }
  value = value || null
  value *= 1
  if (!value) {
    value = 0
  }

  const cashValue = value / pointsRedeemValue

  let finalValue = round(cashValue, 2) + ''
  if (!finalValue.includes('.')) {
    finalValue += '.'
  }
  const parts = finalValue.split('.')
  for (var i = 0; i < 2 - parts[1].length; i++) {
    finalValue += '0'
  }
  return finalValue
}

export default toCash

const humanizeCall = ({ params = {} }) => {
  return 'As Cash'
}

export {
  humanizeCall
}
