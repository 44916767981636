var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.inputType === 'select')?_c('field-multiple-value-select',_vm._b({ref:"focusTarget",on:{"input":_vm.handleInput}},'field-multiple-value-select',_vm.props,false)):(_vm.inputType === 'checkbox')?_c('field-multiple-value-checkbox',_vm._b({ref:"focusTarget",on:{"input":_vm.handleInput}},'field-multiple-value-checkbox',_vm.props,false)):_c('field-custom-wrapper',_vm._b({attrs:{"errorMessages":_vm.topLevelErrorMessage}},'field-custom-wrapper',{
    ..._vm.vuetifyComponentProps,
    prefix: undefined,
    suffix: undefined
  },false),[_c('div',{ref:"multipleFieldsParent",staticClass:"pl-4 pt-8 pb-4"},[_vm._l((_vm.multipleValuesButSomeAreHiddenBecauseTheyAreNotInTheFieldOptions),function(item,$index){return _c('div',{key:$index,class:{
        [item.show ? 'd-flex' : 'd-none']: true,
        'mb-3': _vm.hideDetails
      }},[(_vm.schema.type === 'object')?_c('field-object',_vm._b({staticClass:"flex-grow-1",on:{"input":function($event){return _vm.handleMultipleInput($index, $event)}}},'field-object',{
          ..._vm.props,
          value: item.value
        },false)):_c('field-primitive',_vm._b({staticClass:"flex-grow-1",on:{"input":function($event){return _vm.handleMultipleInput($index, $event)}}},'field-primitive',{
          ..._vm.props,
          label: null,
          value: item.value
        },false)),(!_vm.props.disabled)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeItem($index)}}},[_c('v-icon',[_vm._v("fa fa-times")])],1):_vm._e()],1)}),(!_vm.props.disabled)?_c('div',{staticClass:"text-right pr-9",class:_vm.isValueEmpty ? 'mt-4' : null},[_c('v-btn',{ref:"addMoreButton",attrs:{"outlined":"","primary":"","color":_vm.addMoreButtonColor},on:{"click":_vm.addItem}},[_vm._v("+ Add More")])],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }