var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offer pa-4 pt-0 d-flex flex-column fill-height",staticStyle:{"border":"1px solid #000","background-repeat":"no-repeat","background-position":"bottom","background-size":"cover","white-space":"normal"},style:({
    backgroundColor: _vm.offerColors.color_background,
    color: _vm.offerColors.color_font,
    ...(_vm.imageStyles.background || {}),
  })},[_c('div',{staticClass:"offer-logo mb-4",staticStyle:{"height":"110px","min-height":"110px","text-align":"center","background-repeat":"no-repeat","background-position":"50%","background-size":"contain"},style:(_vm.imageStyles.logo)}),_c('h2',{staticClass:"flex-grow-0"},[_vm._v(_vm._s(_vm.offer.display_name || _vm.offer.name))]),_vm._t("default",function(){return [_c('p',{staticClass:"flex-grow-1 pt-5",staticStyle:{"overflow-y":"auto","white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.offer.description))]),(_vm.expirationDate)?_c('countdown',{staticClass:"flex-grow-0 mb-3 text-center",attrs:{"time":_vm.expirationDate}}):_vm._e(),((
        !_vm.hideButtons &&
        _vm.env.offer.punchcard_key &&
        _vm.env.offer.points &&
        _vm.env.offer.points * 1 > 0 &&
        _vm.env.points !== undefined &&
        _vm.env.pendingPoints !== undefined
      ))?_c('div',{staticClass:"loyalty text-center fine-print flex-grow-0 mb-2"},[_c('span',[_vm._v("Points: ")]),_c('strong',[_vm._v(_vm._s(_vm.env.points))]),_vm._v("   "),_c('span',[_vm._v("Pending: ")]),_c('strong',[_vm._v(_vm._s(_vm.env.pendingPoints))])]):_vm._e()]}),_vm._t("buttons",function(){return [(!_vm.hideButtons)?_c('v-btn',{staticClass:"redeem-button flex-grow-0 px-1 py-1",staticStyle:{"display":"block","font-weight":"800","min-height":"44px","height":"auto"},style:(_vm.buttonStyles),attrs:{"large":"","disabled":_vm.disabled,"color":_vm.offerColors.color_button},on:{"click":function($event){return _vm.$emit('redeem')}}},[_c('div',[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.redeemButtonText))]),(_vm.redeemButtonSubText)?_c('span',{staticClass:"label fine-print"},[_vm._v(_vm._s(_vm.redeemButtonSubText))]):_vm._e()])]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }