import gsmCharsets from './gsmCharsets'
import punycode from 'punycode' // eslint-disable-line node/no-deprecated-api

export default ({ text, allowUnicode = false }) => {
  let unicode = false
  let length = 0
  let foundUnicode = false

  // Split text into code points (the character A takes the same number of points as 😂 for example)
  const codePoints = punycode.ucs2.decode(text)

  // Test if there are unicode characters, if so length is just mb_strlen, otherwise length is more complicated
  for (let i = 0; i < codePoints.length; i++) {
    const character = punycode.ucs2.encode([codePoints[i]])
    if (gsmCharsets.gsm7BitBasic.indexOf(character) > -1) {
      length++
    } else if (gsmCharsets.gsm7BitExtended.indexOf(character) > -1) {
      length += 2
    } else {
      foundUnicode = true
      if (allowUnicode) {
        unicode = true
        // Length in unicode characters gets the number of groups of 4 hex characters in UTF-16 which
        // is the actual length the aggregator will interpret this message as.
        length = text.length
        break
      } else {
        length++ // Represents the ? character that will replace this one
      }
    }
  }

  return {
    unicode,
    foundUnicode,
    length
  }
}
