import Op from './Op'
import isEqual from 'lodash/isEqual'

class Pull extends Op {
  static run ({ value, op }) {
    if (!value || !Array.isArray(value)) {
      return value
    }
    let opValue = op?.value
    if (!Array.isArray(opValue) && [null, '', undefined].includes(opValue)) {
      opValue = [null, '', undefined]
    }
    if (Array.isArray(opValue)) {
      return value.filter(v => !opValue.includes(v))
    } else {
      return value.filter(v => !isEqual(v, opValue))
    }
  }

  static shouldRun ({ value, op }) {
    if (!Array.isArray(value)) {
      return false
    }
    let opValue = op?.value
    if (!Array.isArray(opValue) && [null, '', undefined].includes(opValue)) {
      opValue = [null, '', undefined]
    }
    if (Array.isArray(opValue)) {
      return value.filter(v => opValue.includes(v)).length > 0
    } else {
      return !!value.find(v => isEqual(v, opValue))
    }
  }
}

export default Pull
