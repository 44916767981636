import transformFilter from '../transform'
import sanitizeRule from './sanitizeRule'

export default (filter, options) => {
  const transformedFilter = transformFilter(filter, {
    ...options,
    transformRules: (rules, _options) => {
      // Filter out unnecessary rules in a group of rules
      if (!Array.isArray(rules)) {
        rules = [rules]
      }
      if (!rules.length || (rules.length === 1 && ['AND', 'OR'].includes(rules[0]))) {
        return []
      }
      rules = rules.filter(r => !!r && (['AND', 'OR'].includes(r) || (Array.isArray(r) && r.length))) // Remove empty rules
      return rules
    },
    transformRule: (rule, _options) => {
      return sanitizeRule(rule, _options)
    }
  })

  return transformedFilter
}
