import analyze from './analyze'
import render from './render'
import getPathsUsed from './getPathsUsed'
import deconstructMethodCall from './deconstructMethodCall'

export {
  analyze,
  render,
  getPathsUsed,
  deconstructMethodCall
}

export default {
  analyze,
  render,
  getPathsUsed,
  deconstructMethodCall
}
