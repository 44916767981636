const entity = 'Keyword'
const path = 'keyword'

const schemas = {
  _id: { type: 'number', name: 'ID' },
  name: { type: 'text', name: 'Name' },
  gateway_id: { type: 'number', name: 'Gateway' },
  hit_count: { type: 'number', name: '# Hits' },
  last_hit_at: { type: 'timestamp', name: 'Last Hit Time' }
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path
}
