import Model from './Model'

class Block extends Model {
  static entity = 'blocks'

  static evictUnused = true

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      sequence_id: this.attr(undefined),
      name: this.attr(undefined),
      type: this.attr(undefined), // ACTION or TRIGGER
      class: this.attr(undefined), // The type of Block this is
      props: this.attr(undefined), // All block settings
      active: this.attr(undefined),
      attrs: this.attr(undefined), // UI Characteristics in Automation editor
      stats: this.attr(undefined), // Overall block stats
      needs_review: this.attr(undefined), // Whether the "Approve" button shows above the block on the canvas
      last_run_at: this.attr(undefined), // Last time block was run
      next_run_at: this.attr(undefined),
      runs: this.attr(undefined), // Number of times block has been run
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      created_by: this.attr(undefined),
      updated_by: this.attr(undefined),
      comment: this.attr(undefined)
    }
  }
}

export default Block
