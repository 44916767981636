<template>
  <v-card
    class="snooze-picker cg-editor with-footer"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>Select Quick Reply</v-toolbar-title>

      <v-spacer />

      <v-btn
        v-if="linkToSettings"
        icon
        :disabled="loading"
        @click="$emit('close')"
        :to="{ name: 'TemplateSettings', query: { 'templates.filter': 'TAG:Quick Reply' } }"
      >
        <v-icon>fa fa-cog</v-icon>
      </v-btn>

      <v-btn
        icon
        :disabled="loading"
        @click="$emit('close')"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
    >
      <div
        v-if="recommendedTemplates.length"
        class="mb-3"
      >
        <strong>Recommended:</strong>
        <v-list
          dense
        >
          <v-list-item
            v-for="{ template, tags } in recommendedTemplates"
            :key="template._id"
            @click="select(template)"
            :disabled="loading"
          >
            <v-list-item-content>
              <v-list-item-title>{{ template.name }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-chip
                  v-for="tag of tags"
                  :key="tag"
                  x-small
                  color="warning"
                  class="mr-1"
                >{{ tag }}</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <strong
        v-if="recommendedTemplates.length && otherTemplates.length"
      >Other Quick Replies</strong>

      <v-list
        dense
        v-if="otherTemplates.length"
      >
        <v-list-item
          v-for="template in otherTemplates"
          :key="template._id"
          @click="select(template)"
          :disabled="loading"
        >
          <v-list-item-content>
            <v-list-item-title>{{ template.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div
        v-if="!initialLoading && !otherTemplates.length && !recommendedTemplates.length"
      >No Quick Replies have been set up for this account. To set up your first Quick Reply, visit the <router-link @click="$emit('close')" :to="{ name: 'TemplateSettings', query: { tag: 'Quick Reply' } }">Template Settings</router-link> section.</div>
    </v-container>
    <div class="footer pa-2">
      <div
        v-if="loading"
        class="text-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>
      <div v-else>
        <v-btn
          color="primary"
          outlined
          @click="$emit('close')"
          :disabled="loading"
        >Cancel</v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import QuickReplyDialogMixin from './shared/QuickReplyDialogMixin'

export default {
  oWindow: {
    width: 400,
    height: 500,
    persistent: true,
    overlay: true
  },
  mixins: [
    QuickReplyDialogMixin
  ]
}
</script>
