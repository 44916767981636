const entity = 'Sequence'
const path = 'sequence'

const schemas = {
  _id: { type: '_id', name: 'ID' },
  name: { type: 'text', name: 'Name' },
  account_id: { type: 'number', name: 'Account ID' },
  last_run_at: { type: 'timestamp', name: 'Last Run Time' },
  next_run_at: { type: 'timestamp', name: 'Next Run Time ' },
  channels: { type: 'text', name: 'Channels', multiple: true },
  status: { type: 'text', name: 'Status' },
  active: { type: 'checkbox', name: 'Enabled' },
  class: { type: 'text', name: 'Campaign or Automation', hidden: true },
  archived: { type: 'checkbox', name: 'Archived' },
  tags: { type: 'text', name: 'Tags' },
  type: { type: 'text', name: 'Type' },
  stats: { type: 'object', name: 'Stats', hidden: true },
  'stats.tasks': { type: 'number', name: 'Tasks' },
  'stats.tasks_done': { type: 'number', name: 'Tasks Done' },
  'stats.sent': { type: 'number', name: '# Sent' },
  'stats.contacts': { type: 'number', name: '# Contacts' },
  'stats.opened': { type: 'number', name: '# Opened' },
  'stats.last_opened_at': { type: 'timestamp', name: 'Last Opened Time' },
  'stats.clicked': { type: 'number', name: '# Clicked' },
  'stats.last_clicked_at': { type: 'timestamp', name: 'Last Clicked Time' },
  'stats.bounced': { type: 'number', name: '# Bounced' },
  'stats.complained': { type: 'number', name: '# Complained', hidden: true },
  'stats.unsubscribed': { type: 'number', name: '# Opt-outs' },
  'stats.converted': { type: 'number', name: '# Conversions' },
  'stats.revenue': { type: 'number', name: 'Total Revenue' }
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path
}
