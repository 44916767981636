class Base {
  static sanitize ({ value }) {
    return value
  }

  static unsanitize ({ value }) {
    return value
  }

  static shouldRun ({ value }) {
    return true // By default all fields should run
  }
}

export default Base
