import checkbox from './checkbox'
import date from './date'
import _id from './_id'
import number from './number'
import object from './object'
import text from './text'
import time from './time'
import timestamp from './timestamp'

const punchcard = number

export default {
  checkbox,
  date,
  _id,
  number,
  object,
  punchcard,
  text,
  time,
  timestamp
}

export {
  checkbox,
  date,
  _id,
  number,
  object,
  punchcard,
  text,
  time,
  timestamp
}
