import * as actions from './actions'
import * as triggers from './triggers'
import { upperFirst, camelCase } from 'lodash'

/**
 * @param {String} slug - Such as message.out or MessageOut
 */
const getDefinition = (slug) => {
  if (typeof slug !== 'string') {
    return null
  }
  slug = upperFirst(camelCase(slug))
  return actions[slug] || triggers[slug]
}

const getInstance = (props = {}) => {
  const slug = props.block && props.block.class
  const Definition = getDefinition(slug)
  if (!Definition) {
    return null
  }
  return new Definition(props)
}

export {
  getDefinition,
  getInstance,
  actions,
  triggers
}

export default {
  actions,
  triggers
}
