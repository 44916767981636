// Set the name of the hidden property and the change event for visibility
let hidden, visibilityChange
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

let lastTs = Date.now()
// Theoretically this should be called every 15 seconds so it can fail
// up to 3 times before forcing a reload
const checkIfTabHasBeenActiveInLast60Seconds = () => {
  if (Date.now() - lastTs > 60000) {
    console.log('WAS HIBERNATING, RELOAD')
    // window.location.reload()
  }
  lastTs = Date.now()
}
// If the page is hidden, pause the video;
// if the page is shown, play the video
function handleVisibilityChange () {
  if (document[hidden]) {
    // Called when tab is hidden
  } else {
    // Called when tab becomes visible
    checkIfTabHasBeenActiveInLast60Seconds()
  }
}

// Warn if the browser doesn't support addEventListener or the Page Visibility API
if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
  console.warn('Unable to watch visibility in this browser')
} else {
  // Handle page visibility change
  document.addEventListener(visibilityChange, handleVisibilityChange, false)
}

// Check every 15 seconds whether tab is active.
setInterval(() => {
  checkIfTabHasBeenActiveInLast60Seconds()
}, 15000)
