<template>
  <tr>
    <td>
      <v-progress-circular
        v-if="loadingRedeemEvent"
        indeterminate
        size="20"
        color="primary"
      />
      <span
        v-else-if="redeemEvent"
      >{{ redeemEventCode || 'Code Not Found' }}</span>
      <v-btn
        v-else-if="value && value.data && value.data.redeem_event_id"
        icon
        @click="loadRedeemEvent"
      >
        <v-icon small>fa fa-eye</v-icon>
      </v-btn>
    </td>
    <td :class="{ 'success--text': points > 0, 'error--text': points < 0 }">{{ points > 0 ? '+' : '' }}{{ amount }}</td>
    <td>{{ type }}</td>
    <td>
      <user-avatar
        v-if="value.data && value.data.user_id"
        :user-id="value.data.user_id"
        size="25"
      />
      <v-tooltip
        v-else
        top
        :disabled="!((value.data && ['LOYALTYCASH', 'LOYALTY_CASH'].includes(value.data.client_id)) || value.sequence_id)"
      >
        <template v-slot:activator="{ on }">
          <div
            style="width: 25px; height: 25px; border-radius: 13px"
            class="d-flex align-center justify-center"
            :style="{ backgroundColor: $vuetify.theme.currentTheme.primary }"
            v-on="on"
          >
            <v-icon
              v-if="value.data && ['LOYALTYCASH', 'LOYALTY_CASH'].includes(value.data.client_id) && points < 0"
              color="white"
              x-small
            >fa fa-money-bill-wave</v-icon>
            <v-icon
              v-else-if="value.data && ['LOYALTYCASH', 'LOYALTY_CASH'].includes(value.data.client_id) && points > 0"
              color="white"
              small
            >fas fa-gift</v-icon>
            <v-icon
              v-else-if="value.sequence_id"
              color="white"
              x-small
            >fas fa-robot</v-icon>
            <v-icon
              v-else-if="value.data && value.data.order_event_id"
              color="white"
              x-small
            >fas fa-shopping-cart</v-icon>
            <v-icon
              v-else
              color="white"
              small
            >fas fa-gift</v-icon>
          </div>
        </template>
        <div>
          <span
            v-if="value.data && ['LOYALTYCASH', 'LOYALTY_CASH'].includes(value.data.client_id)"
          >Loyalty Cash Widget</span>
          <div
            v-else-if="value.sequence_id"
          >Automation:
            <sequence-ref
              :record-id="value.sequence_id"
            />
          </div>
        </div>
      </v-tooltip>
    </td>
    <td>
      <div style="display: inline-block; white-space: nowrap;">{{ value && value.at ? $timestamp(value.at) : '--' }}</div>
    </td>
    <td>
      <v-btn
        icon
        color="primary"
        small
        @click="viewEventData"
      >
        <v-icon small>fa fa-ellipsis-h</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { Event } from 'ui/models'
import EventViewer from 'ui/viewers/EventViewer'
import { UserAvatar } from 'ui/components/Avatar'
import SequenceRef from 'ui/references/Sequence'

export default {
  mixins: [
    Event.mixin({
      references: ['redeemEvent']
    })
  ],
  components: {
    UserAvatar,
    SequenceRef
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loadingRedeemEvent: false,
      redeemEvent: null
    }
  },
  computed: {
    pointsRedeemValue () {
      return this.$store.getters.account?.settings?.loyalty?.points_redeem_value || 0
    },
    points () {
      const op = (this.value?.data?.ops || []).find(op => op.key === 'punchcard')
      if (!op) {
        return 0
      }

      if (op.op === '$inc') {
        return op.value || 0
      } else if (op.op === '$dec') {
        return -1 * (op.value || 0)
      } else if (op.op === '$set') {
        const from = this.value?.data?.from?.punchcard || 0
        const to = op.value
        return to - from
      }
      return 0
    },
    amount () {
      if (!this.pointsRedeemValue) {
        return this.points + ' points'
      }

      return window.Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.points / this.pointsRedeemValue)
    },
    type () {
      if (this.value?.data?.redeem_event_id) {
        return 'Cashed Out'
      } else if (this.value?.data?.user_id || this.value?.sequence_id) {
        return 'Adjustment'
      } else if (this.points > 0) {
        return 'Cash Earned'
      }
      return 'Adjustment'
    },
    redeemEventCode () {
      if (this.redeemEvent?.data?.external_data?.gift_card) {
        return '********' + (this.redeemEvent.data.external_data.gift_card.last_characters || '')
      } else if (this.redeemEvent?.data?.external_data?.discount_code) {
        return this.redeemEvent?.data?.external_data.discount_code.code
      }
      return ''
    }
  },
  methods: {
    async loadRedeemEvent () {
      if (this.loadingRedeemEvent || !this.value.data?.redeem_event_id) {
        return
      }
      this.loadingRedeemEvent = true
      try {
        await Event.fetchOne(this.value.data.redeem_event_id)
        this.redeemEvent = Event.find(this.value.data.redeem_event_id)
      } finally {
        this.loadingRedeemEvent = false
      }
    },
    viewEventData () {
      this.$openWindow({
        component: EventViewer,
        props: {
          eventId: this.value._id
        }
      })
    }
  }
}
</script>
