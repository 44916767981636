<template>
  <v-card>
    <div
      class="pa-3 d-flex pb-0"
    >
      <div
        class="flex-grow-1"
      >
        <div>
          <strong>Available Discount Codes</strong>
        </div>
      </div>
      <div>
        <v-btn
          icon
          :disabled="loading"
          @click="$emit('reload')"
          small
        >
          <v-icon small>fa fa-redo</v-icon>
        </v-btn>
      </div>
    </div>
    <v-simple-table
      :style="{ opacity: loading ? 0.4 : 1 }"
    >
      <thead>
        <tr>
          <th class="px-2">Code</th>
          <th class="px-2">Value</th>
          <th class="px-2">Type</th>
          <th class="px-2">Expiration</th>
          <th class="px-2">Event Data</th>
          <th
            class="text-center"
          >Active</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="!loading && !(events && events.length)"
        >
          <td
            colspan="6"
            class="text-center"
          >No discount codes found.</td>
        </tr>
        <discount-code-row
          v-for="event in events"
          :key="event._id"
          :event="event"
          show-status
        />
      </tbody>
    </v-simple-table>
    <div
      class="text-right pa-2"
    >
      <v-btn
        color="success"
        small
        @click="createDiscount"
        :disabled="loading"
      >Generate Code</v-btn>
    </div>
  </v-card>
</template>

<script>
import DiscountCodeRow from './DiscountCodeRow.vue'
import CreateCodeDialog from './CreateCodeDialog'

export default {
  components: {
    DiscountCodeRow
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    events: {
      type: Array,
      default: () => []
    },
    contactId: {
      type: String,
      required: true
    }
  },
  methods: {
    async createDiscount () {
      this.$openWindow({
        component: CreateCodeDialog,
        props: {
          contactId: this.contactId,
          onUpdate: () => {
            this.$emit('reload')
          }
        }
      })
    }
  }
}
</script>
