import Model from './Model'
import { getUserFriendlyName } from 'shared/util/friendlyName'

class User extends Model {
  static entity = 'users'
  static primaryKey = '_uid'

  static fields () {
    return {
      _id: this.attr(undefined),
      _uid: this.attr(undefined),
      first_name: this.attr(undefined),
      last_name: this.attr(undefined),
      email: this.attr(undefined),
      phone: this.attr(undefined),
      sms_on: this.attr(undefined),
      email_on: this.attr(undefined),
      photo: this.attr(undefined),
      level: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      last_seen_at: this.attr(undefined),
      force_password_reset: this.attr(undefined)
    }
  }

  get friendlyName () {
    return getUserFriendlyName(this)
  }

  get fullName () { // User's name without email/phone involved
    return `${this.first_name || ''} ${this.last_name || ''}`.trim() || ''
  }
}

export default User
