const segments = [
  { name: 'At-Risk Loyalists', value: 'atRisk_loyalists', sub: true, rf: ['2.4', '2.5'] },
  { name: 'At-Risk Promising', value: 'atRisk_promising', sub: true, rf: ['2.2', '2.3'] },
  { name: 'At-Risk First-Timers', value: 'atRisk_firstTimers', sub: true, rf: ['2.1', '3.1'] },
  { name: 'Lost Loyalists', value: 'lost_loyalists', sub: true, rf: ['1.4', '1.5'] },
  { name: 'Lost Promising', value: 'lost_promising', sub: true, rf: ['1.2', '1.3'] },
  { name: 'Lost First-Timers', value: 'lost_firstTimers', sub: true, rf: ['1.1'] },
  { name: 'Loyal', value: 'loyal_', sub: true, rf: ['3.4', '3.5', '4.4', '4.5', '5.4'] },
  { name: 'Loyal Champions', value: 'loyal_champions', sub: true, rf: ['5.5'] },
  { name: 'New', value: 'new_', sub: true, rf: ['4.1', '5.1'] },
  { name: 'Promising', value: 'promising_', sub: true, rf: ['3.2', '3.3', '4.2', '4.3', '5.2', '5.3'] },
  { name: 'At-Risk', value: 'atRisk', rf: ['2.1', '2.2', '2.3', '2.4', '2.5', '3.1'] },
  { name: 'Lost', value: 'lost', rf: ['1.1', '1.2', '1.3', '1.4', '1.5'] },
  { name: 'Loyal', value: 'loyal', rf: ['3.4', '3.5', '4.4', '4.5', '5.4', '5.5'] },
  { name: 'New', value: 'new', rf: ['4.1', '5.1'] },
  { name: 'Promising', value: 'promising', rf: ['3.2', '3.3', '4.2', '4.3', '5.2', '5.3'] }
]

const getSegment = ({ r, f }) => {
  const value = `${r}.${f}`
  const segment = segments.find(s => !s.sub && s.rf && s.rf.includes(value))
  return segment
}

const getSubSegment = ({ r, f }) => {
  const value = `${r}.${f}`
  const segment = segments.find(s => !!s.sub && s.rf && s.rf.includes(value))
  return segment
}

export default segments

export {
  segments,
  getSegment,
  getSubSegment
}
