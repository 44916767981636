import Model from './Model'

class Addon extends Model {
  static entity = 'addons'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      type: this.attr(undefined),
      config: this.attr(undefined),
      quantity: this.attr(undefined),
      price: this.attr(undefined),
      self: this.attr(undefined),
      start_at: this.attr(undefined),
      end_at: this.attr(undefined),
      credential: this.attr(undefined),
      active: this.attr(undefined),
      description: this.attr(undefined),
      bill_to: this.attr(undefined),
      error: this.attr(undefined),
      meta: this.attr(undefined),
      available_before_start: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

export default Addon
