// Returns an object of all schemas added based on the revel addon

const getSchemas = ({ addon } = {}) => {
  return {
    'blast.stats.redeemed': { type: 'number', name: '# Redemptions' },
    'contact.redemption_count': { name: '# Offers Redeemed', type: 'number', cast: 'int', immutable: true },
    'contact.redemptions': { name: 'Offers Redeemed', type: 'object', multiple: true, immutable: true, input_type: 'redemption', strict: true, hidden: true },
    'contact.redemptions._id': { name: 'Redemption ID', type: '_id', immutable: true, parent: 'contact.redemptions' },
    'contact.redemptions.offer_id': { name: 'Offer ID', type: 'number', cast: 'int', resource: 'offers', parent: 'contact.redemptions' },
    'contact.redemptions.t': { name: 'Redeemed Time', type: 'timestamp', parent: 'contact.redemptions' },
    'sequence.stats.redeemed': { type: 'number', name: '# Redemptions' }
  }
}

export {
  getSchemas
}

export default {
  getSchemas
}
