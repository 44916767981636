import { orderBy, fromPairs } from 'lodash'

const legacySchemasToNew = (schemas = [], pathPrefix = '') => {
  let newSchemas = {}
  if (!Array.isArray(schemas) && typeof schemas === 'object') {
    schemas = Object.entries(schemas).map(([key, schema]) => ({ ...schema, key }))
  }
  if (!Array.isArray(schemas)) {
    throw new Error('Legacy schemas must be array or object')
  }

  schemas.forEach(schema => {
    const path = (pathPrefix ? `${pathPrefix}.` : '') + schema.key
    newSchemas[path] = {
      ...schema,
      path
    }
    if (schema.type === 'object') {
      newSchemas = {
        ...newSchemas,
        ...legacySchemasToNew(schema.schemas, path)
      }
    }
  })

  return newSchemas
}

const newSchemasToLegacy = (schemas = {}) => {
  schemas = Object.entries(schemas).map(([path, schema]) => ({ path, schema }))
  // This step is necessary so that object type schemas will be created
  // before their child schemas
  schemas = orderBy(schemas, ['path'])

  const legacySchema = {
    type: 'object',
    schemas: {}
  }

  schemas.forEach(({ path, schema }) => {
    const pathParts = path.split('.')
    let parentSchema = legacySchema
    if (pathParts.length > 1) {
      // Dig for the schema object relative to the path
      for (let i = 0; i < pathParts.length - 1; i++) {
        const key = pathParts[i]
        parentSchema.schemas = parentSchema.schemas || {}
        if (!parentSchema.schemas[key]) {
          parentSchema.schemas[key] = {
            key,
            type: 'object'
          }
        }
        parentSchema = parentSchema.schemas[key]
      }
    }
    parentSchema.schemas = parentSchema.schemas || {}
    const finalKey = pathParts[pathParts.length - 1]
    parentSchema.schemas[finalKey] = {
      ...schema,
      key: finalKey
    }
  })

  return legacySchema.schemas
}

const oldFieldsToSchemas = (fields = []) => {
  return fromPairs(
    fields
      .map(field => [`contact.${field.key}`, {
        ...field,
        path: `contact.${field.key}`,
        full_name: 'Contact ' + field.name
      }])
  )
}

export {
  legacySchemasToNew,
  newSchemasToLegacy,
  oldFieldsToSchemas
}
