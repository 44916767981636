import get from 'lodash/get'

export default (value, schema, options = {}) => {
  if (value === true) {
    return 'Yes'
  } else if (value === false) {
    return 'No'
  } else if (!!value && typeof value !== 'boolean') {
    return 'Invalid'
  } else if (!value) {
    if (get(options, 'emptyNull')) {
      return ''
    }
    return 'Unselected'
  }
}
