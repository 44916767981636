import { fromPairs } from 'lodash'

/**
 * fieldConfigurations = {
 *   myField: {
 *     name: 'Name of the field used in error messages, defaults to "Value" if not provided',
 *     constraints: {
 *       validate.js constraints
 *     }
 *   }
 * }
 */
export default (fieldConfigurations = {}) => {
  const validate = require('validate.js')

  const mixin = {}
  if (Object.keys(fieldConfigurations || {}).length > 0) {
    mixin.computed = fromPairs(
      Object.entries(fieldConfigurations).map(([key, fieldConfiguration]) => {
        // Validate.js converts a camel case key into a field name separated by spaces
        // This is not desirable because sometimes keys are cryptic slug names
        // Default to 'Value' unless overridden.
        const fieldName = fieldConfiguration.name || 'Value'
        return [
          `${key}ValidationRules`,
          () => [
            value => {
              const result = validate({ [fieldName]: value }, { [fieldName]: fieldConfiguration.constraints }, fieldConfiguration.options || {})
              if (result?.[fieldName]?.length) {
                return result[fieldName][0]
              }
              return true
            }
          ]
        ]
      })
    )
  }
  return mixin
}
