<template>
  <div>
    <v-card
      v-if="!loading"
      class="mt-1 mb-5"
    >
      <v-card-text
        v-if="!events.length"
      >Contact does not have any orders yet.</v-card-text>
      <v-card-text
        v-else
      >
        <v-row>
          <v-col cols="6">
            <strong
              class="pt-2"
              style="font-size: 16px"
            >{{
              (selectedOrder)
              ? 'Shopify Order #' + displayShopifyOrderId
              : 'Select an Order to View'
              }}</strong>
          </v-col>
          <v-col cols="4"></v-col>
          <v-col cols="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a
                  :href="contactLink"
                  target="_blank"
                  v-on="on"
                >
                  <v-icon dense class="px-0 ml-2">fa fa-user</v-icon>
                </a>
              </template>
              <span>Shopify Customer Page</span>
            </v-tooltip>
          </v-col>
          <v-col cols="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a
                  :href="cartLink"
                  target="_blank"
                  v-on="on"
                >
                  <v-icon dense class="px-0">fas fa-shopping-cart</v-icon>
                </a>
              </template>
              <span>Shopify Order Page</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            Order
          </v-col>
          <v-col cols="8">
            <v-select
              :items="eventItems"
              :value="selectedOrder._id"
              @input="selectEvent"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      class="tracking-card my-4"
      v-if="selectedOrder && !loading"
    >
      <v-card-text>
        <v-row>
          <strong class="pl-2 pt-2" style="font-size: 16px">Tracking</strong>
        </v-row>
        <v-row>
          <v-col cols="4">
            <strong>Status</strong>
            <p class="text-uppercase">{{trackingStatus}}</p>
          </v-col>
          <v-col cols="4">
            <strong>Delivery Date</strong>
            <p>{{deliveryDate}}</p>
          </v-col>
          <v-col cols="4">
            <strong>Shipping Carrier</strong>
            <p>{{ trackingCompany }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Order Tracking Number</v-col>
          <v-col cols="8">
            <v-text-field
              outlined
              dense
              hide-details
              readonly
              :value="trackingNumber || 'N/A'"
            >
              <v-tooltip
                top
                slot="append"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    :color="$store.getters.color"
                    v-on="on"
                    @click="copyCode(trackingNumber)"
                    :disabled="!trackingNumber"
                  >{{
                    (lastCopied && (trackingNumber === lastCopied))
                      ? 'fa fa-check'
                      : 'fas fa-clipboard'
                  }}</v-icon>
                </template>
                <div>{{
                  (lastCopied && (trackingNumber === lastCopied))
                    ? 'Copied to clipboard.'
                    : 'Copy to clipboard.'
                }}</div>
              </v-tooltip>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Order Status Page Url</v-col>
          <v-col cols="8">
            <v-text-field
              outlined
              dense
              hide-details
              readonly
              :value="orderStatusUrl || 'N/A'"
            >
              <v-tooltip
                top
                slot="append"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    :color="$store.getters.color"
                    v-on="on"
                    @click="copyCode(orderStatusUrl)"
                    :disabled="!orderStatusUrl"
                  >{{
                    (lastCopied && (orderStatusUrl === lastCopied))
                      ? 'fa fa-check'
                      : 'fas fa-clipboard'
                  }}</v-icon>
                </template>
                <div>{{
                  (lastCopied && (orderStatusUrl === lastCopied))
                    ? 'Copied to clipboard.'
                    : 'Copy to clipboard.'
                }}</div>
              </v-tooltip>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      class="details-card my-5"
      v-if="selectedOrder && !loading"
    >
      <v-card-text>
        <v-row>
          <strong class="pl-2 pt-2" style="font-size: 16px">Order Details</strong>
        </v-row>
        <v-row>
          <v-col cols="3">
            <strong>Order Total</strong>
            <p>{{orderTotal}}</p>
          </v-col>
          <v-col cols="9">
            <strong>Payment Status</strong>
            <p
              class="text-uppercase"
              :class="statusColor"
            >{{selectedOrder.data.status}}</p>
          </v-col>
        </v-row>
        <v-simple-table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Item Total</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in selectedOrder.data.line_items"
              :key="index"
            >
              <td
                class="name-td"
              >{{item.name}}</td>
              <td>{{item.quantity}}</td>
              <td>{{ $formatCurrency(item.price / 100) }}</td>
              <td>{{ $formatCurrency(item.price * item.quantity / 100) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<style lang="scss">
.name-td {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import { Event, Addon } from 'ui/models'
import { mapGetters } from 'vuex'

export default {
  mixins: [
    Event.mixin({ references: ['events'] })
  ],
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      selectedOrder: null,
      lastCopied: null
    }
  },
  async created () {
    await Event.fetchMany({
      params: {
        contact_id: this.contactId,
        event: 'order.finished'
      }
    })
    if (this.events.length) {
      this.selectedOrder = this.events[0]
    }
    this.loading = false
  },
  computed: {
    ...mapGetters(['account']),
    displayShopifyOrderId () {
      if (this.selectedOrder?.data?.external_data?.id) {
        return this.selectedOrder.data.external_data.id
      }
      return ''
    },
    trackingCompany () {
      return this.associatedFulfillment?.data?.external_data?.tracking_company || 'N/A'
    },
    contact () {
      return this.$store.$db().model('contacts').find(this.contactId)
    },
    addon () {
      return Addon.query()
        .where(r => r.type === 'SHOPIFY')
        .first()
    },
    events () {
      const contactId = this.contactId
      return Event.query()
        .orderBy('at', 'desc')
        .where(e =>
          e.event === 'order.finished' &&
          e.contact_id === contactId
        )
        .get()
    },
    eventItems () {
      return (this.events || []).map((event, index) => {
        const id = event.data?.external_data?.id || 'no external order id ' + (index + 1)
        const date = this.$moment(event.at).format('MMM Do')
        const count = (event.data?.line_items?.length || 0)
        return {
          text: `#${id}, ${date}, ${count} items`,
          value: event._id
        }
      })
    },
    contactLink () {
      let website = this.addon?.config?.shop
      const shopifyId = this.contact?.data._shopify_id
      if (website[website.length - 1] !== '/') {
        website = website + '/'
      }
      return (website && shopifyId)
        ? `https://${website}admin/customers/${shopifyId}`
        : null
    },
    cartLink () {
      let website = this.addon?.config?.shop
      const orderId = this.selectedOrder?.data?.external_data?.id
      if (website[website.length - 1] !== '/') {
        website = website + '/'
      }
      return (website && orderId)
        ? `https://${website}admin/orders/${orderId}`
        : null
    },
    orderTotal () {
      return this.$formatCurrency(this.selectedOrder?.data?.total_amount / 100)
    },
    statusColor () {
      let color = 'success--text'
      if (this.selectedOrder.data?.status !== 'paid') {
        color = 'warning--text'
      }
      return color
    },
    trackingStatus () {
      let status = 'N/A'
      if (this.associatedOrderDelivered) {
        status = this.associatedOrderDelivered.data?.external_data?.status
      } else if (this.associatedOrderOutForDelivery) {
        status = this.associatedOrderOutForDelivery.data?.external_data?.status
      } else if (this.associatedOrderShipped) {
        status = this.associatedOrderShipped.data?.external_data?.status
      }
      return status
    },
    deliveryDate () {
      let date = 'N/A'
      if (this.associatedOrderDelivered) {
        date = this.$moment(this.associatedOrderDelivered.data.external_data.estimated_delivery_at)
          .format('MMM Do, YYYY')
      } else if (this.associatedOrderOutForDelivery) {
        date = this.$moment(this.associatedOrderOutForDelivery.data.external_data.estimated_delivery_at)
          .format('MMM Do, YYYY')
      } else if (this.associatedOrderShipped) {
        date = this.$moment(this.associatedOrderShipped.data.external_data.estimated_delivery_at)
          .format('MMM Do, YYYY')
      }
      return date
    },
    associatedOrderShipped () {
      const orderId = this.selectedOrder?._id
      return Event.query()
        .where(e =>
          e.event === 'order.shipped' &&
          e.data.order_event_id === orderId
        )
        .first() || null
    },
    associatedOrderOutForDelivery () {
      const orderId = this.selectedOrder?._id
      return Event.query()
        .where(e =>
          e.event === 'order.out_for_delivery' &&
          e.data.order_event_id === orderId
        )
        .first() || null
    },
    associatedOrderDelivered () {
      const orderId = this.selectedOrder?._id
      return Event.query()
        .where(e =>
          e.event === 'order.delivered' &&
          e.data.order_event_id === orderId
        )
        .first() || null
    },
    associatedFulfillment () {
      const orderId = this.selectedOrder?._id
      return Event.query()
        .where(e =>
          e.event === 'order.fulfillment' &&
          e.data.order_event_id === orderId
        )
        .first() || null
    },
    trackingNumber () {
      return this.associatedFulfillment?.data?.external_data?.tracking_number || null
    },
    orderStatusUrl () {
      return this.selectedOrder?.data?.external_data?.order_status_url || null
    }
  },
  methods: {
    async loadData () {
      await this.eventPaginationLoad()
      this.initialLoad = false
    },
    selectEvent (eventId) {
      this.selectedOrder = this.events.find((event) => event._id === eventId)
    },
    async copyCode (item) {
      await navigator.clipboard.writeText(item)
      this.lastCopied = item
    }
  },
  watch: {
    lastCopied (value) {
      if (value) {
        setTimeout(() => {
          this.lastCopied = null
        }, 2000)
      }
    }
  }
}
</script>
