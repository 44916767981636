import Trigger from '../Trigger'

class Custom extends Trigger {
  static get title () {
    return 'Custom'
  }

  static get class () {
    return 'Custom'
  }

  static get restrict () {
    return { 'user.level': { $gte: 9 } }
  }

  static getDescription ({ block = {} } = {}) {
    return (block && block.props && block.props.event) || ''
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []
    const eventName = (block && block.props && block.props.event) || ''

    // Event Name is not required but if it exists then it can't be greater than 15
    if (eventName && eventName.length > 15) {
      errors.push({
        message: 'Event Name cannot be more than 15 characters.'
      })
    }
    return super.getErrors(environment).concat(errors)
  }
}

export default Custom
