import windowManager from './windowManager'

const components = {}

export default function ({
  component,
  props,
  persistent,
  overlay,
  oWindow = {}
}) {
  if (typeof component === 'string') {
    if (!components[component]) {
      throw new Error('Invalid Component Name: ' + component)
    }
    component = components[component]
  }
  // Create a deferred object and include it on the stack along with reslove/reject
  const deferred = {}
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })

  persistent = persistent || component.oWindow?.persistent
  overlay = overlay || component.oWindow?.overlay

  const windowInstance = windowManager.add({
    parent: this,
    component,
    props,
    persistent,
    overlay,
    oWindow: {
      ...(component.oWindow || {}),
      ...(oWindow || {})
    },
    ...deferred
  })

  // return the promise by default with ability to access window instance
  windowInstance.promise.windowInstance = windowInstance
  return windowInstance.promise
}
