// Returns an object of all schemas added based on the roller addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact._smartwaiver_created_at': {
      type: 'timestamp',
      immutable: true,
      name: 'Created At (Smartwaiver)'
    },
    'contact.document_participants': {
      type: 'object',
      multiple: true,
      name: 'Waiver Participants'
    },
    'contact.document_participants.first_name': {
      type: 'text',
      name: 'First Name'
    },
    'contact.document_participants.last_name': {
      type: 'text',
      name: 'Last Name'
    },
    'contact.document_participants.birthday': {
      type: 'date',
      name: 'Birthday'
    }
  }
}

export {
  getSchemas
}

export default {
  getSchemas
}
