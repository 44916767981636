import { fieldValue } from 'shared/field'

export default (objectValues, objectSchema, options) => {
  const formattedValues = []
  objectSchema?.schemas?.forEach(schema => {
    const value = objectValues?.[schema.key || '']
    if (value) {
      formattedValues.push(
        options.returnObject
          ? {
              label: schema.name || schema.key,
              value: fieldValue(value, schema)
            }
          : `${schema.name || schema.key}: ${fieldValue(value, schema)}`
      )
    }
  })
  return options.returnObject
    ? formattedValues
    : formattedValues.join('; ')
}
