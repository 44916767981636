// Provide a filter and receive back a MongoDB query object
import buildQueryRules from './buildQueryRules'
import buildQueryRule from './buildQueryRule'
import schemaForRulePath from '../transform/schemaForRulePath'
import { sanitizeValue } from 'shared/field-ops'
import sanitize from '../sanitize'
import transformFilter from '../transform'
import isEmpty from '../isEmpty'
import uniq from 'lodash/uniq'
import get from 'lodash/get'

const buildQuery = (filter, options = {}) => {
  filter = sanitize(filter, options)
  const schemas = get(options, 'schemas') || {}
  // For server-side queries so paths don't start with "contact"
  let contactPathPrefix = 'contact.'
  if (get(options, 'rootPathIsContact')) {
    contactPathPrefix = ''
  }

  if (filter && filter.select_all === false && isEmpty(filter)) {
    return { $and: [{ _id: '0000aaaa00001e4f00001a4e' }] } // A very random id very unlikely to exist so query returns 0/false
  }

  const query = { $and: [] }

  if (filter?.accounts && filter.accounts.length) {
    query.$and.push({ [contactPathPrefix + 'account_id']: { $in: uniq(filter.accounts.map(id => id * 1)) } })
  }

  if (filter?.where && filter.where.length) {
    const whereQuery = transformFilter({ where: filter.where }, {
      ...options,
      transformRules: (rules, _options) => {
        rules = buildQueryRules(rules, _options)
        return rules
      },
      transformRule: (rule, _options) => {
        if (typeof options.transformRule === 'function') {
          rule = options.transformRule(rule)
        }
        return buildQueryRule(rule, _options)
      }
    }).where
    if (whereQuery && ((whereQuery.$and && whereQuery.$and.length) || (whereQuery.$or && whereQuery.$or.length))) {
      query.$and.push(whereQuery)
    }
  }

  /**
  GROUPS
    New filters haven't utilized this property for several years now.
  */

  if (filter?.groups && filter.groups.length) {
    const groups = []
    let groupOptions = []
    if (schemas.groups && schemas.groups.options && schemas.groups.options.length) {
      groupOptions = schemas.groups.options
    }

    for (const i in filter.groups) {
      groups.push(filter.groups[i] * 1)
      groupOptions.forEach((groupOption) => {
        if (groupOption.value + '' === filter.groups[i] + '' && groupOption.children && groupOption.children.length) {
          for (const ff in groupOption.children) {
            if (groups.indexOf(groupOption.children[ff] * 1) === -1) {
              groups.push(groupOption.children[ff] * 1)
            }
          }
        }
      })
    }

    if (groups.length) {
      query.$and.push({ [contactPathPrefix + 'groups']: { $in: groups } })
    }

    // @TODO: Get recursive groups
  }

  /**
  CONTACTS
  */

  if (filter?.contacts && filter.contacts.length) {
    const contacts = []
    for (const j in filter.contacts) {
      contacts.push(filter.contacts[j])
    }
    if (contacts.length) {
      query.$and.push({ [contactPathPrefix + '_id']: { $in: contacts } })
    }
  }

  /**
  CHANNEL
  */

  if (filter?.channel && filter.channel === 'SMS') {
    const smsOnPath = contactPathPrefix + 'sms_on'
    const contactPrefix = smsOnPath.startsWith('contact.') ? 'contact.' : ''
    query.$and.push({
      $and: [
        { [smsOnPath]: true },
        { [contactPrefix + 'phone']: { $exists: true } },
        { [contactPrefix + 'phone']: { $ne: '' } },
        {
          $or: [
            { [contactPrefix + 'sms_bounce']: null },
            { [contactPrefix + 'sms_bounce']: false }
          ]
        }
      ]
    })
  } else if (filter?.channel && filter.channel === 'EMAIL') {
    const emailOnPath = contactPathPrefix + 'email_on'
    const contactPrefix = emailOnPath.startsWith('contact.') ? 'contact.' : ''
    query.$and.push({
      $and: [
        { [emailOnPath]: true },
        { [contactPrefix + 'email']: { $exists: true } },
        { [contactPrefix + 'email']: { $ne: '' } },
        {
          $or: [
            { [contactPrefix + 'email_bounce']: null },
            { [contactPrefix + 'email_bounce']: false }
          ]
        }
      ]
    })
  }

  /**
  SEARCH
  */
  if (filter?.search) {
    const logic = []
    const fulltextTerms = [filter.search.replace(/[^\w\s]/g, '')]
    let fulltextPossible = false

    let select = []
    if (filter.searchFields && filter.searchFields.length) {
      select = filter.searchFields
    } else if (filter.select && filter.select.length > 0) {
      select = filter.select
    } else {
      select = ['first_name', 'last_name', 'email', 'phone']
    }

    select.forEach((key) => {
      let { schema, path } = schemaForRulePath(key, options)
      // For server-side queries so paths don't start with "contact"
      if (get(options, 'rootPathIsContact') && path.startsWith('contact.')) {
        path = path.replace('contact.', '')
      }
      schema = schema || { type: 'text', key }
      if (['mixed', 'text'].includes(schema.type) && schema.key !== 'phone' && ((filter.search || '') + '').length > 1) {
        // This makes it possible to still search for phone numbers without a "1" in front
        let sanitizedTerm = sanitizeValue({ value: filter.search, schema, options })
        if (!sanitizedTerm) {
          return
        }
        const regex = { $regex: sanitizedTerm, $options: 'i' }
        logic.push({ [path]: regex })
        fulltextPossible = true
        if (sanitizedTerm + '' !== filter.search + '') {
          if (schema.key === 'email') {
            sanitizedTerm = sanitizedTerm.replace(/[^\w\s]/g, '')
          }
          fulltextTerms.push(sanitizedTerm)
        }
      } else if (['mixed', 'number'].includes(schema.type) && !isNaN(filter.search * 1)) {
        logic.push({ [path]: filter.search * 1 })
      }
    })

    if (logic.length) {
      query.$and.push({ $or: logic })
    }

    // In order to be using full-text I need to be searching multiple fields and at least of them needs to be text
    if (fulltextPossible && options.fulltext) {
      query.$and.push({ $text: { $search: fulltextTerms.join(' ') } })
    }
  }

  return query.$and.length ? query : {}
}

export default buildQuery
