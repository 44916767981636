import Model from './Model'

class Indicator extends Model {
  static entity = 'indicators'

  static fields () {
    return {
      _id: this.attr(undefined),
      value: this.attr(undefined)
    }
  }
}

export default Indicator
