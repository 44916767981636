import Editor from './Editor'
import TestDialog from './TestDialog'

const oWindow = (block) => {
  return {
    width: 1200,
    height: 1200
  }
}

export {
  oWindow,
  TestDialog,
  Editor
}

export default {
  oWindow,
  Editor
}
