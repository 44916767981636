import isEmpty from '../isEmpty'
import buildQueryRules from '../buildQuery/buildQueryRules'

const operators = {
  '=': '$eq',
  '!=': '$ne',
  empty: 'empty',
  '!empty': '!empty'
}

const buildQueryRule = ({ path, operator, value, schema }, options = {}) => {
  // If there are a mini set of rules passed in as the value, check that they are
  // not empty
  if (Array.isArray(value)) {
    // If value is an array then it's going to be a list of rules and I just need to transform those
    value = buildQueryRules(value, schema.schemas || {}, options)
    if (isEmpty({ where: value })) {
      throw new Error(`Invalid rules provided for ${path}`)
    }
  } else if (typeof value === 'object') {
    // Assume the object passed in will just be a DB query object and compare as provided
  } else {
    throw new Error(`Invalid parameter provided for ${path}`)
  }
  if (operator === '=') {
    return { [path]: { $elemMatch: value } }
  } else if (operator === '!=') {
    return { [path]: { $not: { $elemMatch: value } } }
  }
}

export default {
  buildQueryRule,
  operators
}

export {
  buildQueryRule,
  operators
}
