import component from './Text'

export default {
  id: 'text',
  name: 'Text',
  icon: 'fas fa-font',
  component,
  factory () {
    return {
      props: {
        padding: '10px'
      },
      content: '<p>This is a new Text block.  Change the text.</p>'
    }
  }
}
