/**
 * Returns a string containing the terms for the recurring application charge being added to shopify.
 * Added here since it's used by both server and portal
 * @param {Addon} addon - The Addon record for the BUNDLE-PATCH-FULL addon.
 * @param {AddonType} addonType - The AddonType record for the BUNDLE-PATCH-FULL addon (used for defaults if nothing defined in addon)
 * @param {Gateway} gateway - Merged Gateway/AccountGateway object containing overage_price and settings.price
 * @returns String of terms
 */

const getGatewayOveragePrices = (gateway) => {
  const perSmsCents = gateway?.overage_price || gateway?.settings?.price || 3.5
  const perMmsCents = perSmsCents * 3
  return {
    perSmsCents,
    perMmsCents
  }
}

const getRecurringApplicationChargeTerms = (addon, addonType, gateway) => {
  let minimumAmount = addon?.config?.minimum_amount
  if ([undefined, null, ''].includes(minimumAmount)) {
    minimumAmount = addonType?.config?.minimum_amount || 29500
  }
  let minimumContacts = addon?.config?.minimum_contacts
  if ([undefined, null, ''].includes(minimumContacts)) {
    minimumContacts = addonType?.config?.minimum_contacts || 29500
  }
  let perContactCents = addon?.config?.per_contact_cents
  if ([undefined, null, ''].includes(perContactCents)) {
    perContactCents = addonType?.config?.per_contact_cents || 1
  }

  const {
    perSmsCents,
    perMmsCents
  } = getGatewayOveragePrices(gateway)

  minimumAmount = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(minimumAmount / 100)

  const terms = `You will be charged ${perContactCents} cent${perContactCents === 1 ? '' : 's'} per contact based on the number of contacts in your database, and includes email messaging. Minimum fee of ${minimumAmount} covers up to ${minimumContacts} contacts.
        
Additional usage charges per text message sent and received will be charged at ${perSmsCents} cent${perSmsCents === 1 ? '' : 's'} per SMS message and ${perMmsCents} cent${perMmsCents === 1 ? '' : 's'} per MMS message.`

  return terms
}

export default getRecurringApplicationChargeTerms

export {
  getGatewayOveragePrices
}
