var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-5"},_vm._l((_vm.objectSchemas),function(schema){return _c('div',{key:schema.path,staticClass:"pb-2"},[_c('field-primitive',_vm._b({on:{"input":function($event){return _vm.$emit('input', {
        ..._vm.value,
        [schema.key]: $event
      })}}},'field-primitive',{
        ..._vm.props,
        schema,
        label: schema.name || schema.key,
        value: _vm.value?.[schema.key]
      },false))],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }