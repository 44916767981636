import Trigger from '../Trigger'

class ContactRfm extends Trigger {
  static get title () {
    return 'Contact RFM'
  }

  static get class () {
    return 'ContactRfm'
  }

  static get restrict () {
    return { 'user.level': { $gte: 5 } }
  }
}

export default ContactRfm
