import render from './render'
import * as methods from './methods'
import min from 'lodash/min'
import max from 'lodash/max'

export default (text = '', options = {}, context = null) => {
  const analysis = render(text, context, {
    ...options,
    analyze: true
  })

  return {
    ...analysis,
    variables: (analysis.variables || []).map(variable => {
      variable = {
        ...variable,
        stages: (variable.stages || []).map(stage => {
          stage = {
            ...stage,
            fragments: (stage.fragments || []).map(fragment => {
              if (fragment.method && methods[fragment.method]) {
                const callParams = { params: fragment.params, options, context }
                fragment.humanized = (typeof methods[fragment.method].humanizeCall === 'function' ? methods[fragment.method].humanizeCall(callParams) : fragment.fragment)
                if (typeof methods[fragment.method].getLengths === 'function') {
                  fragment = {
                    ...fragment,
                    ...methods[fragment.method].getLengths(callParams)
                  }
                }
              }
              return fragment
            })
          }

          stage.humanized = stage.fragments.map(f => f.humanized).join(' OR ')
          const shortestValues = stage.fragments.map(f => f.shortest || null).filter(v => ![undefined, null].includes(v))
          if (shortestValues.length) {
            stage.shortest = min(shortestValues)
          }
          const longestValues = stage.fragments.map(f => f.longest || null).filter(v => ![undefined, null].includes(v))
          if (longestValues.length) {
            stage.longest = max(longestValues)
          }
          return stage
        })
      }

      variable.humanized = variable.stages.map(stage => {
        if (stage.fragments.length > 1 && variable.stages.length > 1) {
          return `(${stage.humanized})`
        }
        return stage.humanized
      }).join(' | ')

      let lastStageWithShortLength
      let lastStageWithLongLength
      variable.stages.forEach(stage => {
        if (![null, undefined, ''].includes(stage.shortest)) {
          lastStageWithShortLength = stage
        }
        if (![null, undefined, ''].includes(stage.longest)) {
          lastStageWithLongLength = stage
        }
      })

      // The last stage of the variable that has lengths is the one of concern
      if (lastStageWithShortLength) {
        variable.shortest = lastStageWithShortLength.shortest || 0
      }
      if (lastStageWithLongLength) {
        variable.longest = lastStageWithLongLength.longest || 0
      }

      if (variable.stages.length) {
        variable.valueOut = variable.stages[variable.stages.length - 1].valueOut
      }

      return variable
    })
  }
}
