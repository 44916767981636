import Trigger from '../Trigger'

class OfferRedeemed extends Trigger {
  static get title () {
    return 'Offer Redeemed'
  }

  static get class () {
    return 'OfferRedeemed'
  }

  static get restrict () {
    return { 'addons.type': 'OFFERS' }
  }

  // These are deprecated in favor of shared/schemas/events/*
  get schemas () {
    return [
      { key: 'offer_id', name: 'Offer ID', type: 'number', resource: 'offers' },
      { key: 'flow_id', name: 'Kiosk Flow ID', type: 'text', resource: 'flows' },
      { key: 'device_id', name: 'Kiosk Device ID', type: 'number' },
      { key: 'client_id', name: 'Client ID', type: 'text' },
      { key: 'reference_number', name: 'Reference # (unique per day)', type: 'number' }
    ]
  }

  static getDefaultProps () {
    return {
      occur: {
        frequency: 'EVERY',
        n: 1,
        start: 1
      }
    }
  }
}

export default OfferRedeemed
