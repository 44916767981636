import Model from './Model'
import { mapGetters } from 'vuex'

class Fieldset extends Model {
  static entity = 'fieldsets'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      default: this.attr(false),
      class: this.attr(undefined),
      fields: this.attr(undefined),
      tags: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }

  /**
   * Adds the ability to pull default fieldset fields for a specific entity and
   * sets to vm.selectedSchemaPaths
   *
   * @param {String} entity - Sets vm.selectedSchemaPaths to whatever the default fieldset is for that entity
   */
  static mixinForDefault ({ entity }) {
    const FS = this
    return {
      created () {
        const defaultFieldset = FS.query().where(r => r.default && r.class === entity).first()
        if (defaultFieldset && defaultFieldset.fields) {
          this.selectedSchemaPaths = defaultFieldset.fields
        }
      },
      computed: {
        ...mapGetters(['schemas']),
        selectedSchemaPathsFilteredByHidden () {
          return this.selectedSchemaPaths.filter((fieldName) => {
            const schema = this.schemas[fieldName]
            return schema && !schema?.hidden
          })
        }
      }
    }
  }
}

export default Fieldset
