import interact from 'interactjs'

export default (el, { onResize = () => {} } = {}) => {
  interact(el)
    .resizable({
      // resize from all edges and corners
      edges: { left: true, right: true, bottom: true, top: true },

      listeners: {
        move (event) {
          const target = event.target
          let x = (parseFloat(target.getAttribute('data-x')) || 0)
          let y = (parseFloat(target.getAttribute('data-y')) || 0)

          // update the element's style
          target.style.width = event.rect.width + 'px'
          target.style.height = event.rect.height + 'px'

          // translate when resizing from top or left edges
          x += event.deltaRect.left
          y += event.deltaRect.top

          // Restrict to x/y of screen in case body scrolls
          if (target.offsetTop + y < 0 || target.offsetTop + y > window.innerHeight - 100) {
            y -= event.deltaRect.top
          }

          target.style.webkitTransform = target.style.transform =
            'translate(' + x + 'px,' + y + 'px)'

          target.setAttribute('data-x', x)
          target.setAttribute('data-y', y)
          onResize(el)
        }
      },
      modifiers: [
        // keep the edges inside the parent
        // interact.modifiers.restrictEdges({
        //   outer: 'parent'
        // }),

        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 250, height: 200 }
        }),
        interact.modifiers.restrictRect({
          restriction: document.body,
          elementRect: { top: 0.9, left: 0.8, bottom: 0.1, right: 0.2 }
        })
      ]
    })
    .draggable({
      allowFrom: '.drag-handle',
      // keep the element within the area of it's parent
      modifiers: [
        // interact.modifiers.restrictRect({
        //   restriction: 'parent',
        //   endOnly: true
        // })
        interact.modifiers.restrictRect({
          restriction: document.body,
          elementRect: { top: 0, left: 0.8, bottom: 0.1, right: 0.2 }
        })
      ],
      // enable autoScroll
      autoScroll: true,

      listeners: {
        // call this function on every dragmove event
        move (event) {
          const target = event.target
          // keep the dragged position in the data-x/data-y attributes
          const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
          let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

          // Restrict to x/y of screen in case body scrolls
          if (target.offsetTop + y < 0 || target.offsetTop + y > window.innerHeight - 100) {
            y -= event.dy
          }

          // translate the element
          target.style.webkitTransform =
            target.style.transform =
              'translate(' + x + 'px, ' + y + 'px)'

          // update the posiion attributes
          target.setAttribute('data-x', x)
          target.setAttribute('data-y', y)
        }
      }
    })
}
