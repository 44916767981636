import Model from './Model'

class Conversation extends Model {
  static entity = 'conversations'

  static evictUnused = true

  // Socket updates should "insert" into the store based on a provided function
  // in a mixin.
  static socketInsertOnUpdated = 'conditional'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      contact_id: this.attr(undefined),
      assigned_user_id: this.attr(undefined),
      status: this.attr(undefined),
      tags: this.attr(undefined),
      incoming_count: this.attr(undefined),
      outgoing_count: this.attr(undefined),
      last_activity_at: this.attr(undefined),
      snoozed_at: this.attr(undefined),
      unsnooze_at: this.attr(undefined),
      last_gateway_id: this.attr(undefined),
      last_message_at: this.attr(undefined),
      last_message_text: this.attr(undefined),
      finished_event_id: this.attr(undefined),
      started_at: this.attr(undefined),
      archived_at: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

export default Conversation
