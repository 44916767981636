const operators = {
  '=': '$eq',
  '!=': '$ne',
  empty: 'empty',
  '!empty': '!empty'
}

const buildQueryRule = ({ path, operator, value }) => {
  return { [path]: { [operators[operator]]: value } }
}

export default {
  buildQueryRule,
  operators
}

export {
  buildQueryRule,
  operators
}
