import component from './Social'

export default {
  id: 'social',
  name: 'Social',
  icon: 'fas fa-hashtag',
  component,
  factory () {
    return {
      props: {
        padding: '10px',
        iconSize: 30,
        iconPadding: 15,
        icons: [
          {
            platform: 'facebook',
            iconSet: 'color-bg',
            url: 'https://www.facebook.com'
          },
          {
            platform: 'twitter',
            iconSet: 'color-bg',
            url: 'https://www.twitter.com'
          },
          {
            platform: 'instagram',
            iconSet: 'color-bg',
            url: 'https://www.instagram.com'
          }
        ]
      }
    }
  }
}
