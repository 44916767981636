const generateComputedForProp = (key) => {
  return {
    [key]: {
      get () {
        return this.node?.props?.[key]
      },
      set (value) {
        const props = {
          ...(this.node.props || {})
        }
        if ([null, undefined].includes(value)) {
          if (Object.keys(props).includes(key)) {
            delete props[key]
          }
        } else {
          props[key] = value
        }
        this.$emit('input', {
          ...this.node,
          props
        })
      }
    }
  }
}

export default generateComputedForProp
