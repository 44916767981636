import openContactEditor from './openContactEditor'
import openDocumentViewer from './openDocumentViewer'
import queryString from 'query-string'

const actionTypes = {
  openContactEditor,
  openDocumentViewer
}

// Listen for window messages
window.addEventListener('message', (event) => {
  if (event && event.data && event.data.source && event.data.source.includes('vue-devtools')) {
    return
  }
  if (event && event.data && event.data.type && event.data.type.includes('webpack')) {
    return
  }

  if (event?.data?.type !== 'chatHeight') { // Suppress chat widget messages
    console.log('window message', event)
  }
}, false)

// Listen for service worker messages if possible
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', event => {
    console.log('service worker message', event.data)
    if (event.data && event.data.type === 'cg-action') {
      if (event.data.action && event.data.action.params) {
        actionHandler.handleAction(event.data.action.params)
      }
    }
  })
}

const parse = (str) => {
  const obj = {}
  str.split(',').forEach(item => {
    const [key, value] = item.split(':')
    obj[key] = value
  })
  return obj
}

const actionHandler = {
  queuedActions: [],
  isReady: false,
  handleAction (action) {
    /**
     * Incoming "action" will be a url encoded json string or an object already
     * "_type:openContactEditor,tabName:Loyalty,contactId:deadbeef"
     * {
     *   "_type": "openContactEditor",
     *   "contactId": "deadbeef",
     *   "tabName": "Loyalty"
     * }
     */

    if (typeof action === 'string') {
      action = parse(action)
    }
    if (!this.isReady) {
      this.queuedActions.push(action)
      return
    }
    try {
      if (!actionTypes[action._type || '_']) {
        console.log('invalid action', action._type)
        return
      }
      actionTypes[action._type](action)
    } catch (e) {
      console.log('error running action', action, e)
    }
  },
  ready () {
    this.isReady = true
    this.queuedActions.forEach(action => {
      this.handleAction(action)
    })
    this.queuedActions = []
    // Look for query string actions
    this.parseQueryString()
  },
  parseQueryString () {
    const qs = queryString.parse(window.location.search)
    if (!(qs && qs._a)) {
      return
    }
    let actions = qs._a
    if (!Array.isArray(actions)) {
      actions = [actions]
    }
    console.log('query string actions', actions)
    actions.forEach(action => {
      this.handleAction(action)
    })
  }
}

export default actionHandler
