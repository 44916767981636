<template>
  <div>
    <available-discount-codes
      v-bind="{ loading, events: availableEvents, contactId }"
      @reload="loadData"
    />
    <past-discount-codes
      class="mt-4"
      v-bind="{ loading, events: pastEvents }"
      @reload="loadData"
    />
  </div>
</template>

<script>
import AvailableDiscountCodes from './AvailableDiscountCodes.vue'
import PastDiscountCodes from './PastDiscountCodes.vue'
import { Event } from 'ui/models'
import moment from 'moment-timezone'

const discountCodeIsActive = (event) => {
  if (event?.data?.disabled_at) {
    return false
  }
  if (event?.data?.external_data?.price_rule?.ends_at && moment(event?.data?.external_data?.price_rule?.ends_at).isBefore(moment())) {
    return false
  }
  if (event?.data?.converted) {
    return false
  }
  return true
}

export default {
  mixins: [
    Event.mixin({
      references: ['events']
    })
  ],
  components: {
    AvailableDiscountCodes,
    PastDiscountCodes
  },
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    availableEvents () {
      return Event.query()
        .where(e =>
          e.contact_id === this.contactId &&
          e.event === 'discount.created' &&
          discountCodeIsActive(e)
        )
        .orderBy('_id', 'desc')
        .get()
    },
    pastEvents () {
      return Event.query()
        .where(e =>
          e.contact_id === this.contactId &&
          e.event === 'discount.created' &&
          !discountCodeIsActive(e)
        )
        .orderBy('_id', 'desc')
        .get()
    },
    events () {
      return [
        ...this.availableEvents,
        ...this.pastEvents
      ]
    }
  },
  methods: {
    async loadData () {
      if (!this.contactId || this.loading) {
        return
      }
      this.loading = true
      try {
        await Event.fetchMany({
          params: {
            contact_id: this.contactId,
            event: 'discount.created'
          }
        })
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.loadData()
  }
}
</script>
