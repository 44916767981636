import set from 'lodash/set'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { getClassForOp } from './opTypes'

/**
 * Run Op
 *
 * Accepts a contact and a single op
 * Assumes the op and contact are already sanitized (if needed to be)
 * Simply runs the specified op on the contact, no questions asked
 * Makes no assumptions on whether the op should or should not be run.
 */
const runOp = ({
  op,
  schema = null, // This has nothing to do with running the op but can be passed in so the setValue callback can have it
  payload = {},
  options = {}
}) => {
  const path = get(op, 'key')
  const opClass = getClassForOp(op)
  if (!opClass || !path) {
    return payload
  }

  const value = get(payload, path)
  const newValue = opClass.run({
    value,
    op
  })

  // Set the path and then cloneDeep the base property (so it triggers reactivity)
  // This MAY have performance issues with images stored in objects / arrays but
  // those should almost never happen
  set(payload, path, newValue)

  const baseKey = path.split('.')[0].split('[')[0] // Get the first key in the path
  if (baseKey !== path) {
    payload[baseKey] = cloneDeep(payload[baseKey])
    if (typeof options.setValue === 'function') {
      options.setValue({ path: baseKey, value: payload[baseKey], schema })
    }
  } else {
    if (typeof options.setValue === 'function') {
      options.setValue({ path, value: newValue, schema })
    }
  }

  // Because why not...
  return {
    ...payload
  }
}

export default runOp
