import Editor from './Editor'

const oWindow = (block) => {
  return {
    width: 400
  }
}

export {
  oWindow,
  Editor
}

export default {
  oWindow,
  Editor
}
