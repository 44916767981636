import createSocketManager from 'ui/plugins/socket/createSocketManager'
import store from '@/store'

const socketManager = createSocketManager({
  clientId: 'PORTAL',
  clientVersion: process?.env?.VUE_APP_CLIENT_VERSION || global?.VUE_APP_CLIENT_VERSION,
  store,
  onmessage: (event, data) => {
    if (data.event === 'record') {
      if (data?.data?.entity) {
        store.dispatch('handleRecordUpdate', data.data)
      }
    }
  }
})

window.$getSocketManager = () => socketManager

socketManager.open()

export default socketManager
