import Model from './Model'

class EventLog extends Model {
  static entity = 'events'

  static evictUnused = true

  static fields () {
    return {
      _id: this.attr(undefined),
      event: this.attr(undefined),
      account_id: this.attr(undefined),
      contact_id: this.attr(undefined),
      at: this.attr(undefined),
      data: this.attr(undefined),
      stats: this.attr(undefined),
      sequence_id: this.attr(undefined),
      block_id: this.attr(undefined),
      unprocessed: this.attr(undefined)
    }
  }
}

export default EventLog
