import padStart from 'lodash/padStart'

const operators = {
  '=': '$eq',
  '!=': '$ne',
  '<': '$lt',
  '>': '$gt',
  empty: 'empty',
  '!empty': '!empty'
}

const buildQueryRule = ({ path, operator, value }) => {
  operator = operators[operator]

  if (typeof value === 'string') {
    value = { v: value }
  }

  // Safe to assume that this field should be an object at this point.
  if (typeof value !== 'object' || !value || (!value.h && !value.m && !value.v)) {
    throw new Error(`Invalid time value "${JSON.stringify(value)}" at path "${path}"`)
  }

  if (['$eq', '$ne'].indexOf(operator) > -1 && (value.h || value.m) && !(value.h && value.m)) {
    const rules = [];
    ['h', 'm'].forEach((key) => {
      if (value[key] && !isNaN(value[key] * 1) && value[key] > 0) {
        rules.push({ [path + '.' + key]: { [operator]: value[key] * 1 } })
      }
    })
    const conjunction = { $eq: '$and', $ne: '$or' }[operator]
    return { [conjunction]: rules }
  }

  value.v = value.v || padStart(value.h, 2, '0') + ':' + padStart(value.m, 2, '0') + ':00'

  return { [path + '.v']: { [operator]: value.v || null } }
}

export default {
  buildQueryRule,
  operators
}

export {
  buildQueryRule,
  operators
}
