import { round } from 'lodash'

export default (value) => {
  if (value === null || value === undefined) {
    return ''
  }
  const numericalValue = Number(`${value}`.replace(/[^\d.-]/g, '') || 0)
  const stringValue = `${value}`

  if (stringValue.indexOf('<span') > -1) {
    return value
  } else if (stringValue.includes('M') || stringValue.includes('B') || stringValue.includes('K')) {
    return value
  } else if (stringValue.indexOf('$') > -1) {
    const nf = new Intl.NumberFormat('en-US', {
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    })
    return nf.format(numericalValue)
  } else if (stringValue.indexOf('%') > -1) {
    return `${round(stringValue.replace('%', ''), 2)}%`
  } else if (stringValue.indexOf('-') > -1) {
    return `-${round(stringValue.replace('-', ''), 2)}`
  } else if (stringValue.indexOf('+') > -1) {
    return `+${round(stringValue.replace('+', ''), 2)}`
  } else if (!isNaN(stringValue * 1)) { // Hmm...
    if (numericalValue % 1 !== 0) {
      const nf = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      })
      return nf.format(numericalValue)
    } else {
      const nf = new Intl.NumberFormat()
      return nf.format(numericalValue)
    }
  } else {
    return value
  }
}
