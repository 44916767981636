import { getContactFriendlyName } from 'shared/util/friendlyName'
import { pick } from 'lodash'
import { dark, light } from 'shared/colors'

export default {
  computed: {
    theme () {
      if (this.$store && this.$store.state && this.$store.state.appDarkMode) {
        return 'dark'
      }
      return 'light'
    },
    themeColors () {
      return {
        dark,
        light
      }[this.theme]
    },
    computedColor () {
      if (this.color) {
        return this.color
      }
      const friendlyName = (this.friendlyNameOrPhone || '').replace(/\W/g, '')
      if (friendlyName) {
        const firstChar = ((friendlyName + '') || '_')[0].toUpperCase()
        let index = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '_'].indexOf(firstChar)
        if (index < 0) {
          index = 0
        }
        return this.colorOptions[index % this.colorOptions.length]
      }
      return null
    },
    friendlyNameOrPhone () {
      return getContactFriendlyName(this.contact)
    },
    colorOptions () {
      return Object.values(pick(this.themeColors, [
        'primary',
        'secondary',
        'accent',
        'error',
        'warning',
        'info',
        'gray',
        'success',
        'lightPurple',
        'yellow',
        'lightGreen',
        'periwinkle'
      ]))
    }
  }
}
