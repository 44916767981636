<template>
  <div
    class="contact-opt-in-actions"
    v-if="contact"
  >
    <v-menu
      v-for="({ actions, status, icon, buttonClass }, $iconTypeIndex) in iconTypes"
      :key="$iconTypeIndex"
      offset-y
      flat
      rounded="0"

    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
          :class="{ [buttonClass]: true }"
        >
          <span style="position: relative;">
            <v-icon>fa {{ icon }}</v-icon>
            <v-icon
              color="white"
              class="menu-caret"
            >fa fa-caret-down</v-icon>
            <div
              v-if="status.icon && !status.hideIcon"
              class="small-icon-wrapper"
              :class="{ [status.color + '-color']: true }"
            >
              <v-icon
                color="white"
              >fa {{ status.icon }}</v-icon>
            </div>
          </span>
        </v-btn>
      </template>
      <div
        class="contact-opt-in-actions-menu"
      >
        <v-list
          dense
          flat
          class="py-0"
        >
          <div class="pa-2">
            <v-alert
              :color="status.color"
              outlined
              dense
              class="mb-0"
            >{{ status.message }}</v-alert>
          </div>
          <v-list-item
            v-for="(action, $index) in actions"
            :key="$index"
            :disabled="action.disabled"
            @click="save(action.action)"
          >
            <v-list-item-icon
              class="mr-2"
            >
              <v-icon
                small
                :disabled="action.disabled"
              >fa {{ action.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ action.name }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<style lang="scss">
.contact-opt-in-actions {
  display: inline-block;
  white-space: nowrap;
  .small-icon-wrapper {
    position: absolute;
    top: -5px;
    left: 17px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    color: #fff;
    border: 1px #fff solid;
    font-size: 9px;
    line-height: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.warning-color {
      background-color: $warning;
    }
    &.success-color {
      background-color: $success;
    }
    &.error-color {
      background-color: $error
    }
    .v-icon {
      font-size: 9px !important;
      width: auto;
      height: auto;
    }
  }
  .menu-caret {
    position: absolute;
    top: 7px;
    left: 18px;
    font-size: 12px !important;
  }
  .email-button {
    .small-icon-wrapper {
      left: 18px;
    }
    .menu-caret {
      left: 20px;
    }
  }
}
.contact-opt-in-actions-menu {
  max-width: 340px;
  .v-list-item {
    border-top: 1px $lightBorder solid;
  }
}
</style>

<script>
import { Contact } from 'ui/models'
import get from 'lodash/get'

export default {
  mixins: [Contact.mixin({ references: ['contact'] })],
  props: {
    contactId: {
      type: String,
      default: ''
    }
  },
  computed: {
    contact () {
      return Contact.getFlattened(this.contactId) || {}
    },
    iconTypes () {
      return [
        {
          status: this.smsStatus,
          actions: this.smsActions,
          buttonClass: 'sms-button',
          icon: 'fa-sms'
        },
        {
          status: this.emailStatus,
          actions: this.emailActions,
          buttonClass: 'email-button',
          icon: 'fa-envelope'
        }
      ]
    },
    smsStatus () {
      return this.getStatus('sms')
    },
    emailStatus () {
      return this.getStatus('email')
    },
    smsActions () {
      const actions = []
      if (this.contact.phone) {
        actions.push({
          name: `${this.contact.sms_on ? 'Opt out of' : 'Opt in to'} SMS marketing messages.`,
          icon: this.contact.sms_on ? 'fa-bell-slash' : 'fa-check',
          action: () => {
            return this.contactsSave({ ...this.contact, sms_on: !this.contact.sms_on })
          }
        })
      }
      return actions
    },
    emailActions () {
      const actions = []
      if (this.contact.email) {
        actions.push({
          name: `${this.contact.email_on ? 'Opt out of' : 'Opt in to'} Email marketing messages.`,
          icon: this.contact.email_on ? 'fa-bell-slash' : 'fa-check',
          action: () => {
            return this.contactsSave({ ...this.contact, email_on: !this.contact.email_on })
          }
        })
      }
      return actions
    }
  },
  methods: {
    getStatus (channel) {
      channel = channel.toLowerCase()
      const label = (channel === 'sms') ? 'SMS' : 'Email'
      const addressLabel = (channel === 'sms') ? 'a phone number' : 'an email address'
      const result = {
        icon: 'fa-times',
        hideIcon: true,
        message: `This contact has not opted in to receive ${label} marketing messages.`
      }
      if (this.contact) {
        const address = get(this.contact, (channel === 'sms') ? 'phone' : 'email')
        const channelBounce = get(this.contact, `${channel}_bounce`)
        const channelOn = get(this.contact, `${channel}_on`)
        if (channelOn === true) {
          result.color = 'success'
          result.icon = 'fa-check'
          result.hideIcon = false
          if (!address) {
            result.color = 'warning'
            result.icon = 'fa-exclamation-triangle'
            result.message = `This contact does not have ${addressLabel}.`
          } else if (channelBounce) {
            result.color = 'warning'
            result.icon = 'fa-check'
            result.message = `This contact has had an ${label} message bounce recently.`
          } else {
            result.icon = 'fa-check'
            result.message = `This contact is ready to receive ${label} marketing messages.`
          }
        } else if (channelOn === false) {
          result.color = 'error'
          result.icon = 'fa-bell-slash'
          result.hideIcon = false
          result.message = `This contact has OPTED OUT of receiving ${label} marketing messages.`
        }
      }
      return result
    },
    async save (action) {
      if (typeof action === 'function') {
        await action()
      }
    }
  }
}
</script>
