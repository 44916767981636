const staticText = ({ params = {} } = {}) => {
  let str = ((params.param || '') + '').trim()

  str = extractText(str)

  return str || ''
}

export default staticText

const humanizeCall = (args) => {
  return '"' + staticText(args) + '"'
}

// Returns true if the text begins/ends with quotes
const isStaticText = (text) => {
  text = ((text || '') + '').trim()
  return (text.startsWith('"') && text.endsWith('"')) || (text.startsWith("'") && text.endsWith("'"))
}

const extractText = (text) => {
  if (isStaticText(text)) {
    text = text.substr(1, text.length - 2)
  }
  return text
}

const getLengths = (args) => {
  const len = staticText(args).length
  return {
    shortest: len,
    longest: len
  }
}

export {
  humanizeCall,
  isStaticText,
  extractText,
  getLengths
}
