import { isEqual, uniq, flatten } from 'lodash'
import { Contact } from 'ui/models'
import ContactEditor from 'portal/src/modules/Contacts/ContactEditor'
import { analyze } from 'shared/smart-text'

export default {
  mixins: [
    Contact.mixin({ references: ['previewContacts'] })
  ],
  data () {
    return {
      previewContactType: 'longest',
      previewContactLoadingRandom: false,
      previewContactLoadingLongest: false,
      previewContactLongestMessageContactId: null,
      previewContactRandomContactIds: [],
      previewContactRandomContactIndex: 0,
      previewContactCachedVariables: []
    }
  },
  computed: {
    // Override this to enable preview, typically going to be based on whether the message is SMS
    previewContactEnabled () {
      return false
    },
    // Override and return the message content that the preview needs to be based on
    previewContactMessageContent () {
      return null
    },
    // Override and return the message filter that the preview contacts need to be pulled from
    previewContactFilter () {
      return null
    },
    previewContactId () {
      if (this.previewContactType === 'longest') {
        return this.previewContactLongestMessageContactId || null
      } else if (this.previewContactType === 'random') {
        return this.previewContactRandomContactIds?.[this.previewContactRandomContactIndex] || null
      }
      return null
    },
    previewContact () {
      if (this.previewContactId) {
        return Contact.getFlattened(this.previewContactId)
      }
    },
    previewContacts () {
      return [
        ...(this.previewContactLongestMessageContactId ? [{ _id: this.previewContactLongestMessageContactId }] : []),
        ...(this.previewContactRandomContactIds || []).map(id => ({ _id: id }))
      ]
    },
    // Returns a list of smart-text variables used in the message.  Anytime this changes a new request for longest contact needs to happen
    previewContactSmartTextVariables () {
      return uniq(
        flatten(
          Object.entries(this.previewContactMessageContent || {})
            .map(([key, value]) => {
              // No way to analyze these
              if (typeof value !== 'string' || ['channel'].includes(key)) {
                return []
              }
              const analysis = analyze(value)
              return (analysis?.variables || []).map(v => v.variable)
            })
        )
      )
    },
    previewContactProps () {
      return {
        loading: this.previewContactLoadingRandom || this.previewContactLoadingLongest,
        contact: this.previewContact,
        type: this.previewContactType,
        enabled: this.previewContactEnabled && !!this.previewContactSmartTextVariables?.length,
        disabled: !(this.previewContactEnabled && this.previewContactSmartTextVariables?.length),
        variables: this.previewContactSmartTextVariables,
        randomIndex: this.previewContactRandomContactIndex,
        randomIds: this.previewContactRandomContactIds,
        longestId: this.previewContactLongestMessageContactId,
        filter: this.previewContactFilter
      }
    },
    previewContactOn () {
      const vm = this
      return {
        'update:type': (value) => {
          vm.previewContactType = value
        },
        randomDec: () => {
          return vm.previewContactRandomDec()
        },
        randomInc: () => {
          return vm.previewContactRandomInc()
        },
        openContactEditor: () => {
          return vm.previewContactOpenEditor()
        }
      }
    }
  },
  watch: {
    previewContactType (type) {
      if (type === 'random' && !this.previewContactRandomContactIds?.length) {
        this.previewContactLoadRandom()
      }
    },
    previewContactSmartTextVariables (newVariables, oldVariables) {
      if (this.previewContactEnabled && !isEqual(newVariables, oldVariables)) {
        this.previewContactType = 'longest'
        this.previewContactRandomContactIds = []
        clearTimeout(this.updateLongestTimeout)
        this.previewContactLoadingLongest = true
        this.updateLongestTimeout = setTimeout(() => {
          this.previewContactUpdateLongestIfNecessary()
        }, 500)
      }
    },
    previewContactFilter (newFilter, oldFilter) {
      if (isEqual(newFilter, oldFilter)) {
        return
      }
      this.previewContactType = 'longest'
      this.previewContactRandomContactIds = []
      clearTimeout(this.updateLongestTimeout)
      this.previewContactLoadingLongest = true
      // Heavily debounce this since if filter is updating then we're not on the content step anyway
      this.updateLongestTimeout = setTimeout(() => {
        this.previewContactUpdateLongestIfNecessary()
      }, 3000)
    }
  },
  created () {
    clearTimeout(this.updateLongestTimeout)
    this.previewContactLoadingLongest = true
    // Heavily debounce this since if filter is updating then we're not on the content step anyway
    this.updateLongestTimeout = setTimeout(() => {
      this.previewContactUpdateLongestIfNecessary()
    }, 500)
  },
  methods: {
    async previewContactUpdateLongestIfNecessary () {
      const variables = (this.previewContactSmartTextVariables || [])
      // No sense making any request if there are no variables
      if (!variables?.length) {
        this.previewContactLongestMessageContactId = null
        this.previewContactLoadingLongest = false
        return
      }
      // If there's a previous entry with these same variables, use that
      const previousEntry = (this.previewContactCachedVariables.find(entry => isEqual(entry.variables, variables) && isEqual(entry.filter, this.previewContactFilter)))
      if (previousEntry) {
        if (previousEntry.contact) {
          Contact.insert({
            data: Contact.transformIncomingData({ data: previousEntry.contact })
          })
          this.previewContactLongestMessageContactId = previousEntry.contact?._id
        }
        this.previewContactLoadingLongest = false
        return
      }

      const entry = {
        variables,
        filter: this.previewContactFilter
      }

      this.previewContactCachedVariables.push(entry)

      try {
        this.previewContactLoadingLongest = true
        const response = await this.$http({
          url: '/v2/contacts/longest_from_variables',
          method: 'POST',
          data: {
            filter: entry.filter,
            variables
          }
        })
        if (
          response.data.contact?._id
        ) {
          entry.contact = response.data.contact
          Contact.insert({
            data: Contact.transformIncomingData({ data: response.data.contact })
          })
          this.previewContactLongestMessageContactId = response.data.contact._id
        } else {
          this.previewContactLongestMessageContactId = null
        }
      } finally {
        this.previewContactLoadingLongest = false
      }
    },
    async previewContactLoadRandom () {
      try {
        this.previewContactLoadingRandom = true
        const response = await Contact.fetchMany({
          url: '/contacts/random',
          params: {
            count: 50,
            ...(this.previewContactFilter ? { filter: JSON.stringify(this.previewContactFilter) } : {})
          }
        })
        this.previewContactRandomContactIds = (response.entities?.contacts || []).map(c => c._id)
        this.previewContactRandomContactIndex = 0
      } finally {
        this.previewContactLoadingRandom = false
      }
    },
    previewContactRandomDec () {
      this.previewContactRandomContactIndex--
      if (this.previewContactRandomContactIndex < 0) {
        this.previewContactRandomContactIndex = Math.max(0, this.previewContactRandomContactIds.length - 1)
      }
      // this.$emit('input', this.previewContactRandomContactIds[this.previewContactRandomContactIndex])
    },
    previewContactRandomInc () {
      this.previewContactRandomContactIndex++
      if (this.previewContactRandomContactIndex > this.previewContactRandomContactIds.length - 1) {
        this.previewContactRandomContactIndex = 0
      }
      // this.$emit('input', this.previewContactRandomContactIds[this.previewContactRandomContactIndex])
    },
    previewContactOpenEditor () {
      if (!this.previewContactId) {
        return
      }
      this.$openWindow({
        component: ContactEditor,
        props: { contactId: this.previewContactId }
      })
    }
  }
}
