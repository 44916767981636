import segments from 'shared/data/segments'

export default () => {
  const rfmOptions = [
    {
      label: 'Lead',
      value: 'LEAD'
    }
  ]
  for (var i = 1; i <= 5; i++) {
    for (var j = 1; j <= 5; j++) {
      for (var k = 1; k <= 5; k++) {
        rfmOptions.push({
          label: `${i}${j}${k}`,
          value: `${i}${j}${k}`
        })
      }
    }
  }
  return {
    'to.r': { name: 'Recency', type: 'number' },
    'to.f': { name: 'Frequency', type: 'number' },
    'to.m': { name: 'Monetary', type: 'number' },
    'to.value': { name: 'RFM Value', type: 'text', options: rfmOptions.filter(v => v.value !== 'LEAD') },
    'to.avg': { name: 'RFM Average', type: 'number' },
    'to.segment': { name: 'RFM Segment', type: 'text', options: segments.filter(v => !v.sub).map(v => ({ label: v.name, value: v.value })) },
    'to.sub_segment': { name: 'RFM Sub-Segment', type: 'text', options: segments.filter(v => !!v.sub).map(v => ({ label: v.name, value: v.value })) },
    'from.r': { name: 'Recency (previous)', type: 'number' },
    'from.f': { name: 'Frequency (previous)', type: 'number' },
    'from.m': { name: 'Monetary (previous)', type: 'number' },
    'from.value': { name: 'RFM Value (previous)', type: 'text', options: rfmOptions },
    'from.avg': { name: 'RFM Average (previous)', type: 'number' },
    'from.segment': { name: 'RFM Segment (previous)', type: 'text', options: segments.filter(v => !v.sub).map(v => ({ label: v.name, value: v.value })) },
    'from.sub_segment': { name: 'RFM Sub-Segment (previous)', type: 'text', options: segments.filter(v => !!v.sub).map(v => ({ label: v.name, value: v.value })) }
  }
}
