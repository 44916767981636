import Model from './Model'

class Field extends Model {
  static entity = 'fields'
  static socketInsertOnCreated = 'always'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      key: this.attr(undefined),
      type: this.attr(undefined),
      input_type: this.attr(undefined),
      data_mask: this.attr(undefined),
      mask: this.attr(undefined),
      placeholder: this.attr(undefined),
      multiple: this.attr(undefined),
      unique: this.attr(undefined),
      immutable: this.attr(undefined),
      format: this.attr(undefined),
      options: this.attr(undefined),
      schemas: this.attr(undefined), // This should go away in favor of a flattened list
      created_by: this.attr(undefined),
      updated_by: this.attr(undefined),
      cast: this.attr(undefined),
      replace: this.attr(undefined),
      validation: this.attr(undefined),
      searchable: this.attr(undefined),
      permissions: this.attr(undefined),
      value_by_account: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      note: this.attr(undefined)
    }
  }
}

export default Field
