import Action from '../Action'
import colors from 'shared/colors'

class ShopifyDiscount extends Action {
  static get title () {
    return 'Discount Code'
  }

  static get class () {
    return 'ShopifyDiscount'
  }

  static get restrict () {
    return { 'addons.type': 'SHOPIFY', 'account.settings.address.country': 'US' }
  }

  static get color () {
    return colors.maroonRed
  }

  static getDefaultProps () {
    return {
      type: 'PERCENT',
      assign_to_customer: true,
      once_per_customer: true,
      shipping_exclude_maximum: true,
      duration_extend_midnight: true
    }
  }
}

export default ShopifyDiscount
