<template>
  <v-card
    class="cg-editor with-footer"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title
        v-if="!loading && !(record && record._id)"
      >Create SmartLink</v-toolbar-title>
      <v-toolbar-title
        v-else-if="!loading"
        class="flex-grow-1"
      >
        <v-text-field
          style="max-width: 300px"
          outlined
          flat
          dense
          maxlength="40"
          placeholder="Give this SmartLink a name"
          :value="(recordChanged && recordChanged.name) || ''"
          @input="$handleChange('record', 'name', ($event || '').substr(0, 40))"
          hide-details
          @keyup.enter="save()"
          :disabled="loading"
        />
      </v-toolbar-title>

      <v-spacer />

      <v-btn
        v-if="recordChanges.length"
        icon
        :disabled="loading"
        @click="save"
      >
        <v-icon>fa fa-save</v-icon>
      </v-btn>

      <permissions-btn
        v-if="$store.getters.isMultiAccount && record && record._id && record.account_id === $store.getters.account._id"
        @input="$handleChange('record', 'permissions', $event)"
        :record="recordChanged"
        :small="false"
      />

      <v-btn
        icon
        :disabled="loading"
        @click="close()"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
    >
      <v-card
        v-if="!(record && record._id)"
        class="mb-3"
      >
        <v-card-title>Give this SmartLink a name</v-card-title>
        <v-card-text>
          <p>This optional name will not be visible to customers and will only be referenced internally.</p>
          <v-text-field
            label="SmartLink Name"
            :value="recordChanged.name"
            @input="$handleChange('record', 'name', $event)"
            outlined
            dense
            hide-details
          />
        </v-card-text>
      </v-card>
      <redirect-options-card
        :record="recordChanged"
        @change="$handleChange('record', $event[0], $event[1])"
        :disabled="loading"
        class="mb-3"
      />
      <clicks-card
        v-if="record && record._id"
        :record="recordChanged"
        class="mb-3"
        :disabled="loading"
      />
      <advanced-options-card
        v-if="showAdvanced"
        :record="recordChanged"
        @change="$handleChange('record', $event[0], $event[1])"
        :disabled="loading"
      />
      <v-btn
        v-else
        color="primary"
        text
        @click="showAdvanced = true"
      >Advanced Options</v-btn>
    </v-container>
    <div class="footer pa-2 d-flex align-center">
      <div
        v-if="url"
        class="d-flex flex-grow-1"
      >
        <v-text-field
          :value="url"
          outlined
          dense
          readonly
          hide-details
        />
        <v-tooltip
          bottom
          slot="append-outer"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-2"
              v-on="on"
              icon
              @click="copy"
            >
              <v-icon>fa fa-clipboard</v-icon>
            </v-btn>
          </template>
          <div>Copy</div>
        </v-tooltip>
      </div>
      <v-spacer />
      <v-btn
        color="primary"
        outlined
        @click="close()"
        :disabled="loading"
      >Cancel</v-btn>
      <v-btn
        color="primary"
        class="ml-2"
        @click="saveAndClose"
        :disabled="loading || !recordChanges.length"
      >Save &amp; Close</v-btn>
    </div>
  </v-card>
</template>

<script>
import createPreventChangeLossMixin from 'ui/mixins/createPreventChangeLossMixin'
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import { Smartlink } from 'ui/models'
import { PermissionsBtn } from 'ui/components/Permissions'
import RedirectOptionsCard from './RedirectOptionsCard.vue'
import ClicksCard from './ClicksCard.vue'
import AdvancedOptionsCard from './AdvancedOptionsCard.vue'

export default {
  oWindow: {
    width: 700,
    height: 700,
    persistent: true
  },
  mixins: [
    createPreventChangeLossMixin(),
    createChangeTrackerMixin({ path: 'record' })
  ],
  components: {
    PermissionsBtn,
    RedirectOptionsCard,
    ClicksCard,
    AdvancedOptionsCard
  },
  props: {
    recordId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      copied: false,
      showAdvanced: false,
      savedRecordId: null
    }
  },
  computed: {
    record () {
      return Smartlink.find(this.recordId || this.savedRecordId)
    },
    url () {
      if (!this.record?.url) {
        return null
      }

      let url = this.record.url
      if (this.$store?.getters?.account?._id !== this.record?.account_id) {
        url += Smartlink.suffixForAccount(this.$store.getters.account._id)
      }
      return url
    }
  },
  methods: {
    async copy () {
      if (!this.url) {
        return
      }
      await navigator.clipboard.writeText(this.url)
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 3000)
    },
    async save () {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        const result = await Smartlink.save({
          ...this.recordChanged,
          manual: true // Makes this show up in the smartlinks page
        })
        if (result?.entities?.smartlinks?.[0]?._id) {
          this.savedRecordId = result.entities.smartlinks[0]._id
        }
        this.$clearChanges()
      } finally {
        this.loading = false
      }
    },
    async saveAndClose () {
      if (this.loading) {
        return
      }
      await this.save()
      this.close()
    },
    close () {
      // Tell parent page to reload (if it listens for this) if this is a new record
      if (this.savedRecordId && !this.recordId) {
        this.$emit('close', { record: this.record })
      } else {
        this.$emit('close')
      }
    }
  },
  async created () {
    if (this.recordId) {
      this.loading = true
      try {
        await Smartlink.fetchOne(this.recordId)
        this.loading = false
        if (!this.record) {
          this.$emit('close')
          return
        }
      } catch (e) {
        this.$emit('close')
        throw e
      }
      // Auto-show advanced options if there is data in any of them
      if (this.record?.referral || this.record?.untraceable || this.record?.ignore_address?.length || this.record?.settings?.og_image) {
        this.showAdvanced = true
      }
    }
  }
}
</script>
