<template>
  <div class="cg-contact-compose d-flex pa-3">
    <div class="flex-grow-1 d-flex flex-column">
      <div class="d-flex pb-2">
        <div style="flex: 3">
          <v-select
            dense
            outlined
            class="mt-0 pt-0"
            :items="gatewayItems"
            v-model="gatewayIdEmailAliasId"
            placeholder="Send From"
            hide-details
            :menu-props="{ top: true, offsetY: true }"
            :disabled="loading || disabled"
          />
        </div>
        <div style="flex: 3;" class="pl-2">
          <v-text-field
            v-if="channel === 'EMAIL'"
            label="Subject"
            v-model="subject"
            dense
            outlined
            hide-details
            :disabled="loading || disabled"
          />
        </div>
      </div>
      <div
        class="content-input flex-grow-1 d-flex flex-column"
        :style="loading || disabled ? { opacity: 0.4 } : {}"
        v-if="gateways.length"
      >
        <smart-text-input
          class="flex-grow-1"
          ref="textInput"
          v-model="text"
          :disabled="loading || disabled"
          @ctrl-enter="onKeyEnter"
          @paste-attachment="pasteAttachment"
          @parse="onParse"
          :parse-watch="gatewayId"
          :autofocus="$vuetify.breakpoint.lgAndUp"
          :schemas="$store.getters.schemas"
          :placeholder="!gatewayId ? 'Please select where to send from' : 'Type a message...'"
        />
        <v-style
          v-if="renderedMessage && renderedMessage.accountNameAdded"
        >.CodeMirror-placeholder { padding-left: {{ accountNameWidgetWidth }}px !important; }</v-style>
        <div class="pl-3">
          <component
            :is="channel === 'SMS' ? 'sms-out-attachments' : 'message-out-attachments'"
            v-if="(attachments && attachments.length) || (attachmentsPending && attachmentsPending.length)"
            v-bind="{ disabled }"
            v-model="attachments"
            :attachments-pending="attachmentsPending"
          />
        </div>
      </div>
    </div>
    <div
      v-if="gateways.length"
      class="pl-3 pr-1 pt-1 d-flex flex-column justify-space-between"
    >
      <div class="text-center">
        <v-menu
          v-if="gatewayId && gatewayId !== 'note'"
          :value="menu && !(loading || disabled)"
          @input="menu = $event"
          top
          offset-y
          :disabled="loading"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="default"
              v-on="on"
              :disabled="loading"
            >
              <v-icon small>fa-ellipsis-v</v-icon>
            </v-btn>
          </template>
          <v-list
            class="py-0"
          >
            <v-list-item
              v-if="$restrict({ 'addons.type': 'CONVERSATION-QUICKREPLY' })"
              @click="selectQuickReply"
            >
              <v-list-item-title>Select Quick Reply</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="addNote"
              :disabled="disabled || !contactId"
            >
              <v-list-item-title>Add Note</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="sendTest"
              :disabled="disabled"
            >
              <v-list-item-title>Send Test</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="text-center">
        <div
          class="text-center"
          v-if="allowAttachments"
        >
          <v-btn
            icon
            @click="openFileManager"
            :disabled="loading || disabled"
          >
            <v-icon>far fa-image</v-icon>
          </v-btn>
        </div>
        <div>
          <v-tooltip
            top
            :max-width="300"
          >
            <template v-slot:activator="{ on }">
              <div
                v-on="bounceWarning ? on : {}"
              >
                <v-badge
                  :value="bounceWarning"
                  bordered
                  color="error"
                  overlap
                  content="!"
                >
                  <v-btn
                    icon
                    color="primary"
                    @click="send"
                    :disabled="loading || disabled || !messageValid"
                  >
                    <v-icon>far fa-paper-plane</v-icon>
                  </v-btn>
                </v-badge>
              </div>
            </template>
            <div
              v-if="bounceWarning"
            >Messages to this {{ gateway.channel === 'SMS' ? 'number' : 'address' }} may not be delivered due to previous messages having bounced.</div>
            <span v-else />
          </v-tooltip>
        </div>
        <div v-if="channel === 'SMS'" style="line-height: 14px;margin-top:4px;">
          <div
            style="font-size: 10px;text-align: center;display: inline-block;"
          >
            <character-count
              :rendered-message="renderedMessage"
              allow-multiple
              dense
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.cg-contact-compose {
  min-height: 150px;
  width: 100%;
  // textarea {
  //   line-height: 18px;
  //   font-size: 14px;
  // }
  .content-input {
    border: 1px currentColor solid;
    border-radius: 4px;
    &:focus {
      border-color: $primary;
    }
  }
}
</style>

<script>
import ComposeMessageMixin from './shared/ComposeMessageMixin'
import ComposeMessageWebUiMixin from './shared/ComposeMessageWebUiMixin'
import CharacterCount from 'ui/blocks/actions/SmsOut/Editor/CharacterCount'
import NoteDialog from './NoteDialog'
import SmartTextInput from 'ui/components/SmartTextInput'
import SmsOutAttachments from 'ui/blocks/actions/SmsOut/Editor/Attachments'
import MessageOutAttachments from 'ui/blocks/actions/MessageOut/Editor/Attachments'

export default {
  mixins: [
    ComposeMessageMixin,
    ComposeMessageWebUiMixin
  ],
  components: {
    CharacterCount,
    SmartTextInput,
    SmsOutAttachments,
    MessageOutAttachments
  },
  data () {
    return {
      menu: false,
      useNoteDialog: true
    }
  },
  methods: {
    onKeyEnter () {
      // Send when only enter is pressed
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.send()
        return false
      }
    },
    addNote () {
      this.$openWindow({
        component: NoteDialog,
        props: {
          contactId: this.contactId
        }
      })
    }
  }
}
</script>
