<template>
  <div class="help-menu">
    <v-menu
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>fa fa-question-circle</v-icon>
        </v-btn>
      </template>

      <v-list
        style="min-width: 200px"
        class="pa-4"
        dense
      >
        <div
          v-for="(list, l) in lists"
          :key="l"
          class="pb-2"
        >
          <v-list-item-title
            v-if="list.title"
            class="label"
            style="color: #a2a2a2; font-size: 14px;"
          >
            {{ list.title }}
          </v-list-item-title>
          <v-list
            v-for="(item, i) in list.items"
            :key="i"
            class="pa-0"
            dense
          >
            <v-list-item
              class="pa-0"
              style="font-size: 16px;"
              v-bind="item.props"
              v-on="item.on">{{ item.text }}
            </v-list-item>
          </v-list>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import { Account } from 'ui/models'

const targetedLinks = {
  Home: 'https://help.patchretention.com/hc/en-us/articles/4824952529687-Dashboards-Home-Overview',
  DashboardRetention: 'https://help.patchretention.com/hc/en-us/articles/5117107492247-Dashboards-Retention-Overview',
  DashboardMarketing: 'https://help.patchretention.com/hc/en-us/articles/5256636230935-Dashboards-Marketing-Overview',
  DashboardTools: 'https://help.patchretention.com/hc/en-us/articles/5271514442775-Dashboards-Tools-Overview',
  Messenger: 'https://help.patchretention.com/hc/en-us/sections/4824330661143-Messenger-Two-Way-SMS-Communication',
  AutoReplies: 'https://help.patchretention.com/hc/en-us/articles/6328697121815-Auto-Replies',
  LoyaltyCash: 'https://help.patchretention.com/hc/en-us/sections/4824271862807-Loyalty-Rewards-Program',
  TextChat: 'https://help.patchretention.com/hc/en-us/sections/4824302052503-TextChat-Widget',
  Reports: 'https://help.patchretention.com/hc/en-us/sections/4824408361495-Reports',
  Settings: 'https://help.patchretention.com/hc/en-us/sections/5281253144727-General-Settings',
  ShopifySettings: 'https://help.patchretention.com/hc/en-us/articles/5951210240919-Account-Settings-Shopify',
  LoyaltySettings: 'https://help.patchretention.com/hc/en-us/articles/5950370940695-Account-Settings-Loyalty',
  AccountSettings: 'https://help.patchretention.com/hc/en-us/articles/4824879008919-Account-Settings-Account-Details',
  EmailSnippetSettings: 'https://help.patchretention.com/hc/en-us/articles/5281982498199-Account-Settings-Email-Snippets',
  // 'Account Settings - Add Email Domain to Account': 'https://help.patchretention.com/hc/en-us/articles/6130131500567-Account-Settings-Add-Email-Domain-to-Account',
  TemplateSettings: 'https://help.patchretention.com/hc/en-us/articles/6129889677975-Account-Settings-Templates',
  WebPushSettings: 'https://help.patchretention.com/hc/en-us/articles/6126052150935-Account-Settings-Web-Push-Notifications',
  SmsSettings: 'https://help.patchretention.com/hc/en-us/articles/6119317339031-Account-Settings-SMS-Settings',
  RfmSettings: 'https://help.patchretention.com/hc/en-us/articles/5951153075223-Account-Settings-RFM-Recency-Frequency-Monetary-',
  ProfileAccount: 'https://help.patchretention.com/hc/en-us/articles/5950953354647-Account-Settings-Personal-Profile',
  UsageSettings: 'https://help.patchretention.com/hc/en-us/articles/5950891350551-Account-Settings-Message-Usage',
  ImportSettings: 'https://help.patchretention.com/hc/en-us/articles/5950337846167-Account-Settings-Imports',
  GroupSettings: 'https://help.patchretention.com/hc/en-us/articles/5949989009559-Account-Settings-Groups',
  GeneralFieldSettings: 'https://help.patchretention.com/hc/en-us/articles/5949949834391-Account-Settings-General-Fields-Account-Level-',
  FileSettings: 'https://help.patchretention.com/hc/en-us/articles/5949881963287-Account-Settings-File-Manager',
  FieldSettings: 'https://help.patchretention.com/hc/en-us/articles/5842496317463-Account-Settings-Fields-Contact-Level',
  EmailSettings: 'https://help.patchretention.com/hc/en-us/articles/5281857261719-Account-Settings-Email-Settings',
  BillingSettings: 'https://help.patchretention.com/hc/en-us/articles/5281418590103-Account-Settings-Billing',
  APISettings: 'https://help.patchretention.com/hc/en-us/articles/5281242836119-Account-Settings-API-Access',
  'Adding Admin Users': 'https://help.patchretention.com/hc/en-us/articles/4938308955159-Adding-Admins-Setting-up-limited-admins',
  // Waivers: '',
  Automations: 'https://help.patchretention.com/hc/en-us/articles/6167988216471-Automations-Main-VIew-Customer-Journeys',
  'Automation Stats': 'https://help.patchretention.com/hc/en-us/articles/6207707599639-Automation-Performance-Stats-Customer-Journeys',
  Blasts: 'https://help.patchretention.com/hc/en-us/sections/4824235049495-SMS-Email-Marketing-Blasts',
  Contacts: 'https://help.patchretention.com/hc/en-us/sections/5024185199895-Contacts-CRM-',
  // 'Kiosk Device List': '',
  // Kiosk: '',
  Offers: 'https://help.patchretention.com/hc/en-us/articles/6167027552663-SmartOffers',
  Smartlinks: 'https://help.patchretention.com/hc/en-us/articles/6167200202007-SmartLinks',
  AutomationEditor: 'https://help.patchretention.com/hc/en-us/sections/4671932642199-Customer-Journey-Campaigns-Automations',
  BlastEditor: 'https://help.patchretention.com/hc/en-us/sections/4824235049495-SMS-Email-Marketing-Blasts',
  'Email Editor': 'https://help.patchretention.com/hc/en-us/articles/5088343077271-Email-Editor-Overview',
  'SMS Editor': 'https://help.patchretention.com/hc/en-us/articles/6253964742039-SMS-MMS-Content-Editor-',
  // KioskFlowEditor: ''
  Forms: 'https://help.patchretention.com/hc/en-us/articles/19209610554647-Patch-Forms-Overview',
  FormsAutomaticInstallation: 'https://help.patchretention.com/hc/en-us/articles/19211190062615-Forms-Automatic-Installation'
}

export default {
  props: {
    path: {
      type: String,
      required: false
    },
    propLabel: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters(['account']),
    showWelcomeDialogs () {
      return this.account?.settings?.show_welcome_dialogs || false
    },
    lists () {
      const lists = {
        help: {
          title: 'Help',
          items: []
        },
        contact: {
          title: 'Contact',
          items: []
        },
        preferences: {
          title: '',
          items: []
        }
      }

      // Help list
      lists.help.items.push({
        text: 'Help Center',
        props: {
          href: 'https://help.patchretention.com/hc/en-us',
          target: '_blank'
        }
      })
      if (this.helpCenterLink) {
        lists.help.items.push({
          text: 'About ' + this.label,
          props: {
            href: this.helpCenterLink,
            target: '_blank'
          }
        })
        // If the user needs information about fonts
        if (
          this.label === 'Template Settings' ||
          this.label === 'Blasts' ||
          this.label === 'Automations' ||
          this.label === 'Email Snippets') {
          lists.help.items.push({
            text: 'Fonts best practices',
            props: {
              href: 'https://help.patchretention.com/hc/en-us/articles/9483073463703-Email-Editor-Fonts-Fallback-Fonts',
              target: '_blank'
            }
          })
        }
      }
      lists.help.items.push({
        text: 'Support Form',
        props: {
          href: 'https://help.patchretention.com/hc/en-us/requests/new',
          target: '_blank'
        }
      })
      lists.help.items.push({
        text: 'YouTube Videos',
        props: {
          href: 'https://www.youtube.com/channel/UCO_QnoHGhWB3lOKGRJn7CgQ/videos',
          target: '_blank'
        }
      })

      // Contact support list
      const isTrialAccount = this.$restrict({ 'addons.type': 'BUNDLE-PATCH-TRIAL' })
      if (isTrialAccount) {
        lists.contact.items.push({
          text: 'Schedule a Demo',
          props: {
            href: 'https://calendly.com/patchretention/patch-questions',
            target: '_blank'
          }
        })
      } else {
        lists.contact.items.push({
          text: 'Chat with a Success Agent',
          on: {
            click: this.openTextChat
          },
          props: {
            tag: 'button'
          }
        })
      }

      const showPreferences = this.$restrict({ 'addons.type': { $in: ['BUNDLE-PATCH-TRIAL', 'BUNDLE-PATCH-FULL'] } })
      if (showPreferences) {
        // Preferences list
        lists.preferences.title = 'Preferences'
        lists.preferences.items.push({
          text: this.showWelcomeDialogs ? 'Hide Welcome Dialogs' : 'Show Welcome Dialogs',
          on: {
            click: this.toggleWelcomeDialogs
          },
          props: {
            tag: 'button'
          }
        })
      }

      return lists
    },
    primaryKey () {
      return this.path || this.$route?.name || this.$route?.meta?.name
    },
    helpCenterLink () {
      return targetedLinks[this.primaryKey]
    },
    label () {
      return (
        this.propLabel ||
        this.$route?.meta?.label ||
        this.$route?.label ||
        this.$route?.meta?.name ||
        this.$route?.name
      )
    }
  },
  methods: {
    ...mapActions({
      openTextChat: 'widgets/openTextChat'
    }),
    async toggleWelcomeDialogs () {
      await Account.save({
        ...this.account,
        settings: {
          ...this.account.settings,
          show_welcome_dialogs: !this.account?.settings?.show_welcome_dialogs
        }
      })
    }
  }
}
</script>
