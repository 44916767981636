import Editor from './Editor'

const oWindow = (block) => {
  return {
    width: '1200',
    height: '900'
  }
}

export {
  Editor,
  oWindow
}

export default {
  Editor,
  oWindow
}
