import get from 'lodash/get'

export default ({ params = {}, options = {}, context }) => {
  if (typeof params !== 'object') {
    params = { param: params }
  }

  const path = params.path || params.param || ''
  if (!path) {
    return ''
  }

  let result

  const valueAtPath = get(context, path)

  if (
    !path ||
    !valueAtPath ||
    (valueAtPath.indexOf('http') > -1) ||
    (valueAtPath.indexOf('data:') > -1) ||
    (valueAtPath.indexOf('blob:') > -1)
  ) {
    result = valueAtPath
  } else if (
    valueAtPath.includes('cg-local-base64:')
  ) {
    result = get(window.base64ImageCache, valueAtPath)
  } else if (
    path.includes('account.') ||
    path.includes('general.')
  ) {
    result = `${options.assetsPath}/${valueAtPath}`
  } else if (
    path.includes('contact.')
  ) {
    result = `${options.contactPath}/${valueAtPath}`
  }
  return result
}

const humanizeCall = (params) => {
  return 'Call to imageUrl'
}

export {
  humanizeCall
}
