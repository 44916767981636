import Model from './Model'

class Flow extends Model {
  static entity = 'flows'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      active: this.attr(undefined),
      approved: this.attr(undefined),
      channel: this.attr(undefined),
      settings: this.attr(undefined),
      pages: this.attr(undefined),
      permissions: this.attr(undefined),
      created_at: this.attr(undefined),
      created_by: this.attr(undefined),
      updated_at: this.attr(undefined),
      updated_by: this.attr(undefined)
    }
  }
}

export default Flow
