import * as account from './account'
import * as blast from './blast'
import * as contact from './contact'
import * as device from './device'
import * as document from './document'
import * as event from './event'
import * as filter from './filter'
import * as flow from './flow'
import * as _import from './import'
import * as keyword from './keyword'
import * as offer from './offer'
import * as sequence from './sequence'
import * as smartlink from './smartlink'
import * as webform from './webform'
import { get } from 'lodash'

const entities = {
  account,
  blast,
  contact,
  device,
  document,
  event,
  filter,
  flow,
  _import,
  keyword,
  offer,
  sequence,
  smartlink,
  webform
}

// Return the stored value or the default value for that path
const getProperty = (entity, record, path) => {
  return get(record, path) || entities[entity]?.defaultValues?.[path]
}

export {
  getProperty,
  account,
  blast,
  contact,
  device,
  document,
  event,
  filter,
  flow,
  _import,
  keyword,
  offer,
  sequence,
  smartlink,
  webform
}

export default entities
