<template>
  <v-card>
    <div
      class="pa-3 d-flex"
    >
      <div
        class="flex-grow-1"
      >
        <strong>Loyalty Program</strong>
      </div>
      <div>
        <v-btn
          icon
          @click="loadData"
          :disabled="loading"
        >
          <v-icon>fa fa-redo</v-icon>
        </v-btn>
      </div>
    </div>
    <v-simple-table
      :style="{ opacity: loading ? 0.4 : 1 }"
    >
      <thead>
        <tr>
          <th>Points</th>
          <th>Cash Out Value</th>
          <th>Adjust Points</th>
          <th>New Points</th>
          <th>New Cash Out Value
            <v-tooltip
              bottom
              max-width="300"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  x-small
                >fa fa-info-circle</v-icon>
              </template>
              <span>Cash value per point is set within your Loyalty program settings.</span>
            </v-tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ points }}</td>
          <td>{{ amount }}</td>
          <td>
            <field-ui
              class="mt-1 mb-1"
              style="width: 150px"
              dense
              :label="null"
              outlined
              hide-details
              :disabled="loading"
              v-model="adjustPoints"
              :schema="fieldSchema"
            />
          </td>
          <td>{{ newPoints }}</td>
          <td>{{ newAmount }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <div
      class="text-right pa-3"
    >
      <v-tooltip
        top
        :disabled="pointsRedeemValue > 0"
      >
        <template v-slot:activator="{ on }">
          <span
            v-on="on"
          >
            <v-btn
              color="success"
              outlined
              :disabled="loading || points <= 0 || !pointsRedeemValue"
              @click="cashOut"
            >Cash Out</v-btn>
          </span>
        </template>
        <div>You must set up redemption value in Loyalty Settings to Cash Out</div>
      </v-tooltip>
      <v-btn
        class="ml-2"
        color="primary"
        :disabled="loading || !adjustPoints"
        @click="savePoints"
      >Save</v-btn>
    </div>
  </v-card>
</template>

<script>
import { Contact } from 'ui/models'
import FieldUi from 'ui/components/Field'
import CashOutDialog from './CashOutDialog'

export default {
  components: {
    FieldUi
  },
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      adjustPoints: 0
    }
  },
  computed: {
    contact () {
      return Contact.getFlattened(this.contactId) || {}
    },
    points () {
      return this.contact?.punchcard || 0
    },
    pointsRedeemValue () {
      return this.$store.getters.account?.settings?.loyalty?.points_redeem_value || 0
    },
    amount () {
      if (!this.pointsRedeemValue) {
        return 'N/A'
      }
      return window.Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.points / (this.pointsRedeemValue || 1))
    },
    fieldSchema () {
      return this.$store.getters.schemas['contact.punchcard']
    },
    newPoints () {
      return this.points + this.adjustPoints
    },
    newAmount () {
      if (!this.pointsRedeemValue) {
        return 'N/A'
      }
      return window.Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.newPoints / (this.pointsRedeemValue || 1))
    }
  },
  methods: {
    async loadData () {
      if (this.loading) {
        return
      }
      this.loading = true
      this.adjustPoints = 0
      try {
        await Contact.fetchOne(this.contactId, { force: true })
      } finally {
        this.loading = false
      }
    },
    async cashOut () {
      this.$openWindow({
        component: CashOutDialog,
        props: {
          contactId: this.contactId,
          onUpdate: () => {
            this.$emit('updated')
          }
        }
      })
    },
    async savePoints () {
      if (this.loading) {
        return
      }

      const adjustAmount = Math.abs(this.adjustPoints)
      if (!(await this.$confirm(
        `Are you sure you want to ${this.adjustPoints > 0 ? 'add' : 'remove'} ${adjustAmount} loyalty points to this customer profile?  Your username will be logged with this change and displayed in the ledger below.  Click "Confirm" to continue.`,
        {
          title: 'Adjust Loyalty Points',
          buttons: [
            {
              name: 'Cancel',
              value: 'Cancel',
              color: 'warning',
              outlined: true
            },
            {
              name: 'Confirm',
              value: 'Confirm',
              color: 'primary'
            }
          ],
          oWindow: {
            width: 500,
            height: 260,
            overlay: true
          }
        }
      ) === 'Confirm')) {
        return
      }
      this.loading = true
      try {
        await Contact.save(
          this.contact,
          {
            data: [
              {
                key: 'punchcard',
                op: this.adjustPoints < 0 ? '$dec' : '$inc',
                value: adjustAmount
              }
            ]
          }
        )
        this.adjustPoints = 0
        this.$emit('updated')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
