import { Extension } from '@tiptap/core'

const FontSize = Extension.create({
  name: 'fontSize',

  addOptions: {
    types: ['textStyle']
  },

  addGlobalAttributes () {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: element => element.style.fontSize.replace(/['"]+/g, ''),
            renderHTML: attributes => {
              if (!attributes.fontSize) {
                return {}
              }
              return {
                style: `font-size: ${(attributes.fontSize + '').replace('px', '')}px`
              }
            }
          }
        }
      }
    ]
  },

  addCommands () {
    return {
      setFontSize: fontSize => ({ chain }) => {
        return chain()
          .setMark('textStyle', { fontSize })
          .run()
      },
      unsetFontSize: () => ({ chain }) => {
        return chain()
          .setMark('textStyle', { fontSize: null })
          .removeEmptyTextStyle()
          .run()
      }
    }
  }
})

export default FontSize
