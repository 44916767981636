<template>
  <div>
    <v-select
      label="Date Range"
      :value="value.date_range"
      @input="$emit('change', ['date_range', $event])"
      :items="dateRangeItems"
      outlined
      dense
      :hint="dateRangePreview"
      :persistent-hint="!!dateRangePreview"
      :class="{ 'mb-3': !!dateRangePreview }"
    />
  </div>
</template>

<script>
import dateRangeItems from 'shared/data/dateRanges'
import moment from 'moment-timezone'

export default {
  components: {
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    dateRangeItems () {
      const now = moment().tz(this.$store.getters.timezone)
      return dateRangeItems({ now })
        .filter(i =>
          [
            'today',
            'yesterday',
            'last_7_days',
            'this_week',
            'last_week',
            'last_30_days',
            'this_month',
            'last_month'
          ].includes(i.value)
        ).map(item => {
          return {
            ...item,
            text: item.name
          }
        })
    },
    dateRangePreview () {
      if (!(this.value && this.value.date_range)) {
        return
      }
      const item = this.dateRangeItems.find(i => i.value === this.value.date_range)
      if (!item) {
        return null
      }
      const now = moment().tz(this.$store.getters.timezone)
      const { from, to } = item.calc({ now })
      return `${from.format('lll')} - ${to.format('lll')} -- ${this.$store.getters.timezone} timezone`
    }
  }
}
</script>
