import Action from '../Action'
import colors from 'shared/colors'

class ConversationStart extends Action {
  static get title () {
    return 'Start Conversation'
  }

  static get class () {
    return 'ConversationStart'
  }

  static getPathTypes () {
    return [
      {
        name: 'When Started',
        class: 'New',
        color: colors.success
      },
      {
        name: 'When Already Exists',
        class: 'Exists',
        color: colors.warning
      },
      {
        name: 'Always',
        class: 'Always',
        color: null
      }
    ]
  }

  static get restrict () {
    return { 'addons.type': 'MESSENGER' }
  }

  static get color () {
    return colors.lightPink
  }

  static get noConfiguration () {
    return true
  }
}

export default ConversationStart
