import Action from '../Action'

class SetPayload extends Action {
  static get title () {
    return 'Set Payload'
  }

  static get class () {
    return 'SetPayload'
  }

  static get restrict () {
    return { hide: 'fom all' }
  }
}

export default SetPayload
