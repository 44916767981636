const moment = require('moment-timezone')

const dateRangeOptions = ({ now }) => [
  {
    name: 'Today',
    value: 'today',
    calc () {
      return {
        from: now.clone().startOf('day'),
        to: now.clone().endOf('day')
      }
    }
  },
  {
    name: 'Yesterday',
    value: 'yesterday',
    calc () {
      return {
        from: now.clone().subtract(1, 'day').startOf('day'),
        to: now.clone().subtract(1, 'day').endOf('day')
      }
    }
  },
  {
    name: 'Last 7 Days',
    value: 'last_7_days',
    calc () {
      return {
        from: now.clone().subtract(7, 'days').startOf('day'),
        to: now.clone().subtract(1, 'day').endOf('day')
      }
    }
  },
  {
    name: 'This Week',
    value: 'this_week',
    calc () {
      return {
        from: now.clone().isoWeekday(1).startOf('day'), // Monday
        to: now.clone().isoWeekday(7).endOf('day')
      }
    }
  },
  {
    name: 'Last Week',
    value: 'last_week',
    calc () {
      return {
        from: now.clone().subtract(7, 'days').isoWeekday(1).startOf('day'),
        to: now.clone().subtract(7, 'days').isoWeekday(7).endOf('day')
      }
    }
  },
  {
    name: 'Last 30 Days',
    value: 'last_30_days',
    calc () {
      return {
        from: now.clone().subtract(30, 'days').startOf('day'),
        to: now.clone().subtract(1, 'day').endOf('day')
      }
    }
  },
  {
    name: 'This Month',
    value: 'this_month',
    calc () {
      return {
        from: now.clone().startOf('month'),
        to: now.clone().endOf('month')
      }
    }
  },
  {
    name: 'Last Month',
    value: 'last_month',
    calc () {
      return {
        from: now.clone().subtract(1, 'month').startOf('month'),
        to: now.clone().subtract(1, 'month').endOf('month')
      }
    }
  },
  {
    name: 'Last 90 Days',
    value: 'last_90_days',
    calc () {
      return {
        from: now.clone().subtract(90, 'days').startOf('day'),
        to: now.clone().subtract(1, 'day').endOf('day')
      }
    }
  },
  {
    name: `This Quarter (Q${now.clone().format('Q, YYYY')})`,
    value: 'this_quarter',
    calc () {
      return {
        from: now.clone().startOf('quarter'),
        to: now.clone().endOf('quarter')
      }
    }
  },
  {
    name: `Last Quarter (Q${now.clone().subtract(1, 'quarters').format('Q, YYYY')})`,
    value: 'last_quarter',
    calc () {
      return {
        from: now.clone().subtract(1, 'quarters').startOf('quarter'),
        to: now.clone().subtract(1, 'quarters').endOf('quarter')
      }
    }
  },
  {
    name: `2Q ago (Q${now.clone().subtract(2, 'quarters').format('Q, YYYY')})`,
    value: 'quarter_2_ago',
    calc () {
      return {
        from: now.clone().subtract(2, 'quarters').startOf('quarter'),
        to: now.clone().subtract(2, 'quarters').endOf('quarter')
      }
    }
  },
  {
    name: `3Q ago (Q${now.clone().subtract(3, 'quarters').format('Q, YYYY')})`,
    value: 'quarter_3_ago',
    calc () {
      return {
        from: now.clone().subtract(3, 'quarters').startOf('quarter'),
        to: now.clone().subtract(3, 'quarters').endOf('quarter')
      }
    }
  },
  {
    name: `4Q ago (Q${now.clone().subtract(4, 'quarters').format('Q, YYYY')})`,
    value: 'quarter_4_ago',
    calc () {
      return {
        from: now.clone().subtract(4, 'quarters').startOf('quarter'),
        to: now.clone().subtract(4, 'quarters').endOf('quarter')
      }
    }
  },
  {
    name: 'Last 365 Days',
    value: 'last_365_days',
    calc () {
      return {
        from: now.clone().subtract(365, 'days').startOf('day'),
        to: now.clone().subtract(1, 'day').endOf('day')
      }
    }
  },
  {
    name: 'This Year',
    value: 'this_year',
    calc () {
      return {
        from: now.clone().startOf('year'),
        to: now.clone().endOf('year')
      }
    }
  },
  {
    name: 'Last Year',
    value: 'last_year',
    calc () {
      return {
        from: now.clone().subtract(1, 'year').startOf('year'),
        to: now.clone().subtract(1, 'year').endOf('year')
      }
    }
  },
  {
    name: 'Custom',
    value: 'custom',
    calc ({ value }) {
      // Value is the string of ISODateFrom,ISODateTo
      const parts = (value || '').split(',')
      let from = now.clone()
      let to = now.clone()
      if (parts[0]) {
        from = moment.tz(parts[0], now.tz())
      }
      if (parts[1]) {
        to = moment.tz(parts[1], now.tz())
      }
      return {
        from,
        to
      }
    }
  }
]

export default dateRangeOptions

const cardRefreshRanges = {
  minutely: [
    'this_week',
    'this_month',
    'this_quarter',
    'this_year'
  ],
  daily: [
    'last_7_days',
    'last_30_days',
    'last_90_days',
    'last_365_days'
  ],
  weekly: [
    'last_week'
  ],
  monthly: [
    'last_month'
  ],
  quarterly: [
    'last_quarter'
  ],
  yearly: [
    'last_year'
  ]
}

export {
  cardRefreshRanges
}
