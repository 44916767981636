import component from './Heading'

export default {
  id: 'heading',
  name: 'Heading',
  icon: 'fas fa-heading',
  component,
  factory () {
    return {
      props: {
        padding: '10px',
        fontSize: 26,
        headingType: 'h1'
      },
      content: 'Heading'
    }
  }
}
