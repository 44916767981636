<template>
  <v-card
    class="cg-editor"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>Verify Bank Account</v-toolbar-title>

      <v-spacer />
      <v-progress-circular
        v-if="loading"
        color="white"
        indeterminate
      />
      <v-btn
        icon
        @click="requestClose"
        :disabled="loading"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
    >
      <p style="font-size: 14px">Two small deposits were made to your bank account.  Please enter the amounts of these deposits.  These deposits can take several days to appear in your bank account.</p>
      <v-alert
        v-if="error"
        color="error"
        dark
      >{{ error }}</v-alert>

      <v-row
        class="flex-wrap"
      >
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            label="Deposit #1"
            placeholder="0.01"
            :value="recordChanged.data.amount1"
            @input="$handleChange('record', 'data.amount1', $event)"
            dense
            outlined
            :disabled="loading"
            hide-details
            persistent-placeholder
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            label="Deposit #2"
            placeholder="0.02"
            :value="recordChanged.data.amount2"
            @input="$handleChange('record', 'data.amount2', $event)"
            dense
            outlined
            :disabled="loading"
            hide-details
            persistent-placeholder
          />
        </v-col>
        <v-col
          cols="12"
          class="mt-0"
        >
          <v-checkbox
            class="mt-0"
            label="Make Default Payment Method"
            :input-value="recordChanged.default"
            @click="$handleChange('record', 'default', !recordChanged.default)"
            dense
            :disabled="loading"
            hide-details
          />
        </v-col>
      </v-row>
    </v-container>
    <div
      v-if="!loading"
      class="footer pa-2"
    >
      <v-btn
        color="warning"
        outlined
        @click="requestClose"
        :disabled="loading"
      >Cancel</v-btn>
      <v-btn
        color="primary"
        class="ml-2"
        @click="save()"
        :disabled="loading || !(recordChanged.data && recordChanged.data.amount1 && recordChanged.data.amount2)"
      >Verify</v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { PaymentSource } from 'ui/models'
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'

export default {
  oWindow: {
    width: 500,
    height: 320,
    persistent: true,
    overlay: true
  },
  mixins: [
    createChangeTrackerMixin({ path: 'record' })
  ],
  props: {
    recordId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      error: null
    }
  },
  computed: {
    ...mapGetters(['account']),
    record () {
      return PaymentSource.find(this.recordId)
    }
  },
  methods: {
    requestClose () {
      if (this.loading) {
        return
      }
      this.$emit('close')
    },
    async save () {
      this.loading = true
      this.error = null
      try {
        await PaymentSource.save({
          ...this.recordChanged
        })

        this.$clearChanges()
        this.$emit('close', { record: this.record })
      } catch (e) {
        this.error = e.response?.data?.error || e.message
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    if (this.recordId) {
      this.loading = true
      try {
        await PaymentSource.fetchOne(this.recordId, { force: true })
      } catch (e) {
        this.$emit('close')
        throw e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
