import get from 'lodash/get'

export default (value, schema, options) => {
  const cdnUrl = get(options, 'cdnUrl') || 'https://s.citygro.com/cg/images'
  return (
    (
      !value ||
      value.indexOf('http') > -1 ||
      value.indexOf('data:') > -1
    )
      ? value || ''
      : `${cdnUrl}/${value}`
  )
}
