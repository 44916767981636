<template>
  <div>
    <editor-card>
      <div
        slot="name"
        class="pa-3"
      >
        <div
          class="d-flex flex-wrap"
        >
          <div
            class="flex-grow-1"
          >
            <strong>
              {{ record.type === 'LOYALTY' ? 'Loyalty Offer' : 'Promotional Offer' }}
            </strong>
          </div>
          <div>
            <v-btn
              color="primary"
              x-small
              outlined
              @click="$emit('edit-type')"
            >Change</v-btn>
          </div>
        </div>
      </div>
      <div>
        <v-text-field
          label="Title"
          hint="Displayed in offer, seen publicly"
          persistent-hint
          :value="record.display_name"
          @input="$emit('change', ['display_name', $event])"
          outlined
          dense
          class="mb-4"
          v-bind="{ disabled }"
        />
        <v-textarea
          label="Fine Print"
          :value="record.description"
          @input="$emit('change', ['description', $event])"
          outlined
          dense
          v-bind="{ disabled }"
        />
      </div>
    </editor-card>
    <editor-card
      name="Limits"
    >
      <div>
        <v-text-field
          label="Per-Customer Redemption Limit"
          hint="The total # of times an offer can be used by an individual contact"
          persistent-hint
          type="tel"
          :value="record.customer_use_limit || null"
          placeholder="Unlimited"
          dense
          outlined
          @input="$emit('change', ['customer_use_limit', (!!($event * 1)) ? $event * 1 : null])"
          :rules="numberFieldValidationRules"
          class="mb-3"
          v-bind="{ disabled }"
        >
          <v-tooltip
            slot="append-outer"
            top
            max-width="350"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                small
              >fa fa-info-circle</v-icon>
            </template>
            <div>This represents how many times a customer can use the offer before it becomes unavailable. keep at Unlimited for ongoing loyalty offers.</div>
          </v-tooltip>
        </v-text-field>

        <v-text-field
          label="Total Redemption Limit"
          hint="The total # of times an offer can be used, regardless of which contacts redeem the offer text field"
          persistent-hint
          type="tel"
          :value="record.total_use_limit || null"
          placeholder="Unlimited"
          dense
          outlined
          @input="$emit('change', ['total_use_limit', (!!($event * 1)) ? $event * 1 : null])"
          :rules="numberFieldValidationRules"
          v-bind="{ disabled }"
        >
          <v-tooltip
            slot="append-outer"
            top
            max-width="350"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                small
              >fa fa-info-circle</v-icon>
            </template>
            <div>This represents how many total times (all customers) this offer can be redeemed before it becomes unavailable.</div>
          </v-tooltip>
        </v-text-field>
        <v-checkbox
          class="mt-0"
          label="Has a Start Date/Time"
          :input-value="!!record.start_at"
          dense
          readonly
          @click="!disabled && (!!record.start_at ? $emit('change', ['start_at', null]) : $emit('change', ['start_at', (new Date()).toISOString()]))"
          v-bind="{ disabled }"
        />

        <field-ui
          v-if="!!record.start_at"
          :schema="{ name: 'Start Date/Time', type: 'timestamp' }"
          :value="record.start_at"
          @input="$emit('change', [
            'start_at',
            ($event.toISOString && $event.toISOString()) || $event
          ])"
          dense
          outlined
          v-bind="{ disabled }"
        />

        <v-checkbox
          class="mt-0"
          label="Has an Expiration Date/Time"
          :input-value="!!record.end_at"
          dense
          readonly
          @click="!disabled && (!!record.end_at ? $emit('change', ['end_at', null]) : $emit('change', ['end_at', (new Date()).toISOString()]))"
          v-bind="{ disabled }"
        />

        <field-ui
          v-if="!!record.end_at"
          :schema="{ name: 'Expiration Date/Time', type: 'timestamp' }"
          :value="record.end_at"
          @input="$emit('change', [
            'end_at',
            ($event.toISOString && $event.toISOString()) || $event
          ])"
          dense
          outlined
          v-bind="{ disabled }"
        />
      </div>
    </editor-card>
    <editor-card
      name="Loyalty Options"
      v-if="record.type === 'LOYALTY'"
    >
      <div>
        <v-row>
          <v-col
            :cols="12"
            :md="6"
            class="pb-0"
          >
            <v-text-field
              label="How many points required?"
              placeholder="0"
              persistent-placeholder
              outlined
              dense
              :value="record.points"
              @input="$emit('change', ['points', (!!($event * 1)) ? $event * 1 : null])"
              :rules="numberFieldValidationRules"
              v-bind="{ disabled }"
            >
              <v-tooltip
                slot="append-outer"
                top
                max-width="350"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small
                  >fa fa-info-circle</v-icon>
                </template>
                <div>How many punchcard points a customer must have to make this offer available to redeem.</div>
              </v-tooltip>
            </v-text-field>
          </v-col>
          <v-col
            :cols="12"
            :md="6"
            class="pb-0"
          >
            <v-select
              label="Punchcard Name"
              outlined
              dense
              :value="record.punchcard_key || null"
              @input="$emit('change', ['punchcard_key', $event])"
              :items="punchcardItems"
              v-bind="{ disabled }"
            >
              <v-tooltip
                slot="append-outer"
                top
                max-width="350"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small
                  >fa fa-info-circle</v-icon>
                </template>
                <div>The name of the punchcard on which a customer must have points to make this offer available to redeem.</div>
              </v-tooltip>
            </v-select>
          </v-col>
        </v-row>
        <v-checkbox
          label="Deduct points on redemption"
          :input-value="!!record.deduct_points"
          readonly
          dense
          hide-details
          @click="!disabled && $emit('change', ['deduct_points', !record.deduct_points])"
          v-bind="{ disabled }"
        />
        <v-checkbox
          label="Don't add points to this punchcard upon redemption"
          :input-value="!!record.skip_points"
          readonly
          dense
          hide-details
          @click="!disabled && $emit('change', ['skip_points', !record.skip_points])"
          v-bind="{ disabled }"
        />
      </div>
    </editor-card>
    <editor-card
      name="Redemption Options"
      class="mb-3"
    >
      <div>
        <div
          class="d-flex align-center mb-5"
        >
          <div class="flex-grow-1">
            <v-checkbox
              class="mt-0"
              label="Require contact to redeem on Kiosk"
              :input-value="record.channel === 'KIOSK'"
              readonly
              dense
              hide-details
              @click="!disabled && (record.channel === 'KIOSK' ? $emit('change', ['channel', null]) : $emit('change', ['channel', 'KIOSK']))"
              v-bind="{ disabled }"
            />
          </div>
          <div>
            <v-tooltip
              top
              max-width="300"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                >fa fa-info-circle</v-icon>
              </template>
              <div>How do you want your customers to redeem this offer?  On the web (SMS, Email), or in your store at the Kiosk?</div>
            </v-tooltip>
          </div>
        </div>
        <v-text-field
          label="Redemption Instructions (optional)"
          placeholder="Please present this coupon at the point of sale."
          dense
          outlined
          persistent-placeholder
          :value="record.redemption_instructions"
          @input="$emit('change', ['redemption_instructions', $event])"
          v-bind="{ disabled }"
        />
        <v-text-field
          label="Allowable Redemption Time"
          placeholder="Unlimited"
          style="max-width: 300px"
          type="tel"
          :rules="numberFieldValidationRules"
          dense
          outlined
          persistent-placeholder
          suffix="Minutes"
          :value="record.redemption_timeout_minutes"
          @input="$emit('change', ['redemption_timeout_minutes', (!!($event * 1)) ? $event * 1 : null])"
          v-bind="{ disabled }"
        />
        <v-row>
          <v-col
            :cols="12"
            :md="record.barcode_type ? 6 : 12"
          >
            <v-select
              label="Barcode Type"
              style="max-width: 300px"
              :items="barcodeItems"
              :value="record.barcode_type || null"
              @input="$emit('change', ['barcode_type', $event])"
              outlined
              dense
              v-bind="{ disabled }"
            />
          </v-col>
          <v-col
            :cols="12"
            :md="6"
            v-if="record.barcode_type"
          >
            <v-text-field
              label="Barcode Content"
              dense
              outlined
              persistent-placeholder
              :value="record.barcode"
              @input="$emit('change', ['barcode', $event])"
              v-bind="{ disabled }"
            />
          </v-col>
        </v-row>
        <v-checkbox
          class="mt-0"
          label="Require a security code for this offer to be redeemed on Kiosk"
          :input-value="!!record.require_security_code"
          readonly
          dense
          hide-details
          @click="!disabled && $emit('change', ['require_security_code', !record.require_security_code])"
          v-bind="{ disabled }"
        />
      </div>
    </editor-card>
  </div>
</template>

<script>
import EditorCard from 'ui/components/EditorCard'
import createValidationMixin from 'ui/mixins/createValidationMixin'
import FieldUi from 'ui/components/Field'

export default {
  mixins: [
    createValidationMixin({
      numberField: {
        constraints: {
          numericality: {
            strict: true,
            notValid: 'must be a number'
          }
        }
      }
    })
  ],
  components: {
    EditorCard,
    FieldUi
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    barcodeItems () {
      return [
        {
          text: 'None',
          value: null
        },
        'QR',
        'CODE128',
        'EAN-13',
        'EAN-8',
        'EAN-5',
        'EAN-2',
        'UPC-A',
        'CODE39',
        'ITF-14',
        'MSI',
        'PHARMACODE'
      ]
    },
    punchcardItems () {
      return [
        {
          text: 'None / Don\'t Use',
          value: null
        },
        ...Object.entries(this.$store.getters.schemas)
          .filter(([path, schema]) => path.startsWith('contact.') && schema.type === 'punchcard')
          .map(([path, schema]) => ({
            text: schema.name,
            value: path.replace('contact.', '')
          }))
      ]
    }
  }
}
</script>
