import secondsToText from 'shared/util/time/secondsToText'

export default () => ({
  user_id: { name: 'Rep', type: 'text', resource: 'users' },
  gateway_id: { name: 'Gateway', type: 'number', resource: 'gateways' },
  direction: { name: 'First Message Direction', type: 'text', options: [{ label: 'Incoming', value: 'in' }, { label: 'Outgoing', value: 'out' }] },
  message_count: { name: 'Total Messages', type: 'number' },
  message_in_count: { name: 'Total Incoming Messages', type: 'number' },
  message_out_count: { name: 'Total Outgoing Messages', type: 'number' },
  completion_dt: { name: 'Time To Complete (seconds)', type: 'number', render: ({ value }) => secondsToText(value) } // Why does this have a render function?
})
