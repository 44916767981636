import capitalize from 'lodash/capitalize'
import get from 'lodash/get'

const parseParams = ({ params = {}, options = {} } = {}) => {
  return {
    value: get(params, 'param') || get(params, 'value') || ''
  }
}

export default (args) => {
  const { value } = parseParams(args)
  return capitalize(value)
}

const humanizeCall = (args) => {
  return 'Capitalize'
}

export {
  humanizeCall
}
