/**
 * Accepts a string of variable text
 * Returns an array of variable parts
 * contact.first_name || contact.last_name || 'you or || you' => ['contact.first_name', 'contact.last_name', '\'you\'']
 *
 */
export default (text) => {
  text = ((text + '') || '').trim()
  if (text.startsWith('{{')) {
    text = text.replace('{{', '')
  }
  if (text.endsWith('}}')) {
    text = text.replace('}}', '')
  }
  text = text.trim()

  // Tokenize everything within sets of quotes so it can be safely ignored
  const tokenizedMatches = []
  const quotesRegex = new RegExp(/"(.*?)"|'(.*?)'/, 'ig')
  text = text.replace(quotesRegex, (match) => {
    tokenizedMatches.push(match)
    return `t0k3nized-${tokenizedMatches.length - 1}-match`
  })

  // Now that we don't have anything within quotes it is safe to split by the || or "or"

  // convert all "||" to " or "
  text = text.replace(/\s*\|\|\s*/g, function (str) {
    return str.replace(/\s*\|\|\s*/g, ' or ')
  })

  return text.split(/\s*\|\s*/g).map(stage => {
    let fragments = stage.split(/\b\s*or\s*\b(?=(?:[^"]*"[^"]*")*[^"]*$)/ig)

    tokenizedMatches.forEach((tokenizedMatch, index) => {
      stage = stage.replace(`t0k3nized-${index}-match`, tokenizedMatch)
    })

    fragments = fragments.map(fragment => {
      fragment = ((fragment || '') + '').trim()
      tokenizedMatches.forEach((tokenizedMatch, index) => {
        fragment = fragment.replace(`t0k3nized-${index}-match`, tokenizedMatch)
      })
      return fragment
    })

    return {
      stage,
      fragments
    }
  })
}
