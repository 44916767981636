// Returns an object of all schemas added based on the shopify addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact._wordpress_id': {
      type: 'number',
      immutable: true,
      name: 'Wordpress User ID'
    },
    'contact._wordpress_role': {
      name: 'Wordpress Role',
      type: 'text',
      immutable: true
    },
    'contact._wordpress_created_at': {
      name: 'Wordpress Created At',
      type: 'timestamp',
      immutable: true
    }
  }
}

// This is a mapping of fields that we use to reference the shopify customer directly, such as when we want to prevent certain orders from syncing.
const customerSchemas = {
  id: {
    name: 'Customer ID',
    type: 'number'
  },
  first_name: {
    name: 'First Name',
    type: 'text'
  },
  last_name: {
    name: 'Last Name',
    type: 'text'
  },
  email: {
    name: 'Email Address',
    type: 'text'
  }
}

export {
  getSchemas,
  customerSchemas
}

export default {
  getSchemas
}
