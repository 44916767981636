import Action from '../Action'
import colors from 'shared/colors'

class SelectContacts extends Action {
  static get title () {
    return 'Select Contacts'
  }

  static get class () {
    return 'SelectContacts'
  }

  static getDefaultProps () {
    return {
      filter: {
        select_all: false
      }
    }
  }

  static get color () {
    return colors.warning
  }
}

export default SelectContacts
