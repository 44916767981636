export default (value) => {
  if (!(
    typeof value === 'string' && (
      value.length === 10 ||
      (value.length === 11 && value.startsWith('1'))
    )
  )) {
    return value
  }

  const formattedValue = (value + '').replace(/\D/g, '').substr(-10)

  const match = formattedValue.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  return value
}
