import Editor from './Editor'
import FilterCounter from './FilterCounter'

const oWindow = (block) => {
  return {
    width: 1100,
    height: 900
  }
}

export {
  oWindow,
  Editor,
  FilterCounter
}

export default {
  oWindow,
  Editor
}
