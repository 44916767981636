import EditorCard from 'ui/components/EditorCard'
import FilterUi from 'ui/components/Filter'
import TempOldFilterAlert from './TempOldFilterAlert.vue'
import { Addon } from 'ui/models'
import { get } from 'lodash'
import { isEmpty as filterIsEmpty } from 'shared/filter'

export default {
  components: {
    EditorCard,
    FilterUi,
    TempOldFilterAlert
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      forceOldFilter: false
    }
  },
  computed: {
    canForceOldFilter () {
      return this.allowFilterIfNull.length > 0 && filterIsEmpty(this.value?.filter)
    },
    allowFilterIfNull () {
      return []
    },
    allowFilter () {
      // Always display filter if there's one set since this trumps all other props
      const value = this.value || {}
      if (Object.keys(value).includes('filter') && !filterIsEmpty(value.filter)) {
        return true
      }
      if (this.forceOldFilter) {
        return false
      }
      for (let i = 0; i < this.allowFilterIfNull.length; i++) {
        if (![null, undefined].includes(get(value, this.allowFilterIfNull[i]))) {
          return false
        }
      }
      return true
    },
    // Return a function that generates schemas
    createBlockSchemas () {
      return () => []
    },
    filterSchemas () {
      const contactSchemas = Object.entries(this.$store.getters.schemas || {})
        .filter(([path]) => {
          return path.startsWith('contact.')
        })
        .map(([path, schema]) => {
          return {
            ...schema,
            group: 'Contact Fields'
          }
        })

      const triggerEventSchemas = [
        {
          path: 'trigger_event.at',
          group: 'Trigger Event Fields',
          name: 'Event Time',
          type: 'timestamp'
        },
        ...Object.entries(this.createBlockSchemas({
          account: this.$store.getters.account,
          schemas: this.$store.getters.schemas,
          addons: Addon.all()
        }))
          .map(([path, schema]) => {
            return {
              ...schema,
              path: 'trigger_event.' + path,
              group: 'Trigger Event Fields'
            }
          })
      ]

      return [
        ...triggerEventSchemas,
        {
          type: 'checkbox',
          name: 'Currently Paused In This Automation',
          path: 'paused_in_automation',
          group: 'Other Fields'
        },
        ...contactSchemas
      ]
        .filter(schema => !schema.hidden)
    }
  },
  methods: {
    resetOldFilterParams () {
      this.allowFilterIfNull.forEach(path => this.$emit('change', [path, null]))
    }
  }
}
