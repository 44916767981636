<template>
  <v-card>
    <v-card-title>Redirect Options</v-card-title>
    <v-card-text>
      <p>What should this SmartLink redirect to?</p>
      <v-select
        label="Redirect to a..."
        :value="link && link.type"
        outlined
        dense
        :items="linkTypes"
        @input="link = { type: $event }"
      />
      <v-text-field
        v-if="link.type === 'URL'"
        label="Redirect URL"
        placeholder="Enter a URL to shorten..."
        :value="link.url"
        outlined
        dense
        @input="link = { ...link, url: $event }"
        :rules="urlRules"
        :hint="`SmartText Contact fields are allowed, for example: https://www.yourwebsite.com/${'{'}${'{'} contact._id ${'}'}${'}'}.  If the SmartLink is traceable, the contact's ID will be added to the URL.`"
      />
      <div
        v-else-if="resourceType"
      >
        <div
          class="d-flex align-center"
        >
          <v-chip
            v-if="resourceId && typeUi"
            class="my-2 mr-3"
          >
            <v-component
              :is="typeUi.ref"
              :record-id="resourceId"
            />
          </v-chip>
          <v-btn
            color="primary"
            outlined
            @click="selectResource"
          >{{ resourceId ? 'Change' : 'Select' }} {{ resourceName }}</v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import DocumentRef from 'ui/references/Document.vue'
import DocumentPickerDialog from 'ui/pickers/dialogs/DocumentPickerDialog.vue'
import OfferRef from 'ui/references/Offer.vue'
import OfferPickerDialog from 'ui/pickers/dialogs/OfferPickerDialog.vue'
import WebformRef from 'ui/references/Webform.vue'
import WebformPickerDialog from 'ui/pickers/dialogs/WebformPickerDialog.vue'
import validUrl from 'shared/util/validUrl'

const typeUis = {
  DOCUMENT: {
    ref: DocumentRef,
    picker: DocumentPickerDialog
  },
  OFFER: {
    ref: OfferRef,
    picker: OfferPickerDialog
  },
  WEBFORM: {
    ref: WebformRef,
    picker: WebformPickerDialog
  }
}

export default {
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    urlRules () {
      return [
        (v) => validUrl(v) ? true : 'Please enter a valid url starting with http.'
      ]
    },
    linkTypes () {
      return [
        {
          text: 'URL',
          value: 'URL'
        },
        {
          text: 'Form',
          value: 'WEBFORM'
        },
        {
          text: 'Waiver',
          value: 'DOCUMENT'
        },
        {
          text: 'SmartOffer',
          value: 'OFFER'
        }
      ]
    },
    link: {
      get () {
        return (this.record?.links || [])[0] || {}
      },
      set (link) {
        this.$emit('change', ['links', [link]])
      }
    },
    resourceId () {
      return this.link.id ||
        this.link.flow_id ||
        this.link.offer_id ||
        this.link.webform_id ||
        this.link.document_id
    },
    resourceType () {
      return this.link?.type
    },
    resourceName () {
      let resourceType
      if (this.resourceType) {
        resourceType = this.linkTypes.find(t => t.value === this.resourceType)
      }
      return resourceType?.text || null
    },
    typeUi () {
      return typeUis[this.resourceType]
    }
  },
  methods: {
    async selectResource () {
      if (!this.typeUi) {
        return
      }
      const result = await this.$openWindow({
        component: this.typeUi.picker
      })
      if (result?.record?._id) {
        this.link = {
          ...this.link,
          id: result.record._id
        }
      }
    }
  }
}
</script>
