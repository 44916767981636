import get from 'lodash/get'
import sum from 'lodash/sum'
import getLengthAndEncoding from './getLengthAndEncoding'

import gsmCharsets from './gsmCharsets'
import punycode from 'punycode' // eslint-disable-line node/no-deprecated-api

export default ({ text, gateway, allowMultiple = false, isMms = false }) => {
  let segmentCharLimit = get(gateway, 'settings.segments.char_limit') || 160
  let segmentLimit = 1
  let canAllowMultiple = (get(gateway, 'settings.segments.limit') || 1) > 1
  if (canAllowMultiple && allowMultiple) {
    segmentLimit = get(gateway, 'settings.segments.limit') || 1
  }

  if (isMms) {
    /**
     * https://support.bandwidth.com/hc/en-us/articles/360034303574-Toll-free-MMS-Limited-Availability-#what-file-types-are-supported?
     * For GSM-7, 1000 characters is the maximum recommended.
     * For UCS-2 (e.g., using emojis), 500 is the maximum number of characters recommended.
     */
    segmentCharLimit = 1000
    segmentLimit = 1
    canAllowMultiple = false
  }

  const allowUnicode = !!get(gateway, 'settings.segments.char_limit_unicode')

  const { length: originalLength, unicode, foundUnicode } = getLengthAndEncoding({ text, allowUnicode })

  if (unicode) {
    segmentCharLimit = get(gateway, 'settings.segments.char_limit_unicode') || 70
    canAllowMultiple = (get(gateway, 'settings.segments.limit_unicode') || 1) > 1
    if (canAllowMultiple && allowMultiple) {
      segmentLimit = get(gateway, 'settings.segments.limit_unicode') || 1
    }
    if (isMms) {
      /**
       * https://support.bandwidth.com/hc/en-us/articles/360034303574-Toll-free-MMS-Limited-Availability-#what-file-types-are-supported?
       * For GSM-7, 1000 characters is the maximum recommended.
       * For UCS-2 (e.g., using emojis), 500 is the maximum number of characters recommended.
       */
      segmentCharLimit = 500
      segmentLimit = 1
      canAllowMultiple = false
    }
  }

  let segments = []
  let currentSegment = ''
  // It's assumed that segment prefix will not have any non-gsm characters in it
  let segmentCharLength = 0
  const codePoints = punycode.ucs2.decode(text)
  for (let i = 0; i < codePoints.length; i++) {
    let character = punycode.ucs2.encode([codePoints[i]])
    let increment = 0
    if (unicode) {
      increment = character.length
    } else {
      if (gsmCharsets.gsm7BitBasic.indexOf(character) > -1) {
        increment = 1
      } else if (gsmCharsets.gsm7BitExtended.indexOf(character) > -1) {
        increment = 2
      } else {
        increment = 1
        character = '?' // Replace unsupported characters with a ?
      }
    }
    if (segmentCharLength + increment > segmentCharLimit) {
      // If this character is past the allowed length, start on a new segment
      segments.push({
        text: currentSegment,
        length: segmentCharLength
      })
      segmentCharLength = increment
      currentSegment = character + ''
    } else {
      // Append to the current segment if it's not too much for a segment
      currentSegment += character + ''
      segmentCharLength += increment
    }

    if (segments.length > segmentLimit) {
      break
    }
  }

  segments.push({
    text: currentSegment,
    length: segmentCharLength
  })

  segments = segments.slice(0, segmentLimit)

  return {
    text: segments.map(segment => segment.text || '').join(''),
    cost: isMms ? 3 : segments.length,
    segments: segments.length,
    segmentCharLimit,
    segmentLimit,
    segmentCharsRemaining: segmentCharLimit - segments[segments.length - 1].length,
    originalLength,
    truncatedLength: sum(segments.map(segment => segment.length || 0)),
    allowedLength: segmentLimit * segmentCharLimit,
    canAllowMultiple,
    unicode,
    foundUnicode,
    allowUnicode
  }
}
