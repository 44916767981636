import { addRecordsToStore, Contact, Conversation, EmailAlias, Event, Gateway, Template } from 'ui/models'
import renderMessage from 'shared/messages/render'
import removeUnnecessaryUnicodeCharacters from 'shared/messages/lib/removeUnnecessaryUnicodeCharacters'
import { mapGetters } from 'vuex'
import { get, flatten, fromPairs } from 'lodash'
import PasteAttachmentMixin from 'ui/blocks/actions/MessageOut/Editor/Attachments/PasteAttachmentMixin'
import SmsOut from 'shared/blocks/actions/SmsOut'
import formatUSPhoneNumber from 'shared/util/formatting/formatUSPhoneNumber'

export default {
  mixins: [
    PasteAttachmentMixin
  ],
  props: {
    contactId: {
      type: String,
      default: null
    },
    conversationId: {
      type: String,
      default: null
    },
    to: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultGatewayId: {
      type: [Number],
      default: null
    },
    defaultFrom: { // Used for emails since gateway_id may not be enough
      type: String,
      default: null
    },
    defaultSubject: { // Used for emails
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      value: {
        gatewayId: null,
        emailAliasId: null,
        text: null,
        subject: null,
        attachments: null,
        ignoreSubscription: null
      }
    }
  },
  computed: {
    ...mapGetters(['account']),
    // By making all of these computed it's possible to override them and reference data on a record
    ...fromPairs(
      ['gatewayId', 'emailAliasId', 'text', 'subject', 'attachments', 'ignoreSubscription'].map(key => {
        return [
          key,
          {
            get () {
              return this.value[key]
            },
            set (value) {
              this.value[key] = value
            }
          }
        ]
      })
    ),
    gatewayIdEmailAliasId: {
      get () {
        if (this.emailAliasId) {
          return this.gatewayId + '-' + this.emailAliasId
        }
        return this.gatewayId
      },
      set (v) {
        if (v && (v + '').includes('-')) {
          this.gatewayId = v.split('-')[0] * 1
          this.emailAliasId = (v.split('-')[1] * 1) || null
        } else {
          this.gatewayId = v
          this.emailAliasId = null
        }
      }
    },
    quickReplies () {
      return Template.query()
        .where(t => {
          return t.channel === this.channel &&
            t.class === 'MESSENGER'
        })
        .get()
        .sort((a, b) => {
          const aa = (a?.name || '').toLocaleLowerCase()
          const bb = (b?.name || '').toLocaleLowerCase()
          return aa.localeCompare(bb)
        })
    },
    gateways () {
      const gateways = Gateway.query()
        .where(g => {
          return !!g.outgoing && !g.hidden && (!!g.enabled || g.channel === 'SMS') // until sms gateways are all enabled
        })
        .get()
      let finalGateways = gateways.filter(g => g.channel !== 'EMAIL')
      const emailAddressesAsGateways = flatten(gateways
        .filter(g => g.channel === 'EMAIL' && g.incoming)
        .map(g =>
          EmailAlias.query()
            .where(a => a.gateway_id + '' === g._id + '')
            .get()
            .map(a => ({
              _id: g._id + '-' + a._id,
              channel: g.channel,
              address: a.fullAddress
            }))
        )
      )
      finalGateways = finalGateways.concat(emailAddressesAsGateways)
      return finalGateways
    },
    gateway () {
      if (!this.gatewayId) {
        return null
      }
      return Gateway.find(this.gatewayId)
    },
    emailAliases () {
      return EmailAlias.all()
    },
    emailAlias () {
      return EmailAlias.find(this.emailAliasId)
    },
    channel () {
      return this.gateway?.channel || null
    },
    conversation () {
      return Conversation.find(this.conversationId)
    },
    gatewayItems () {
      return [
        ...this.gateways
          .map(g => {
            let channel = g.channel
            if (channel === 'SMS' && g.settings?.attachments) {
              channel += '/MMS'
            }
            return {
              text: `${channel} from ${formatUSPhoneNumber(g.address)}` + (g.default ? ' (default)' : ''),
              value: g._id
            }
          }),
        ...(this.contactId && !this.useNoteDialog ? [{ text: 'Add Note', value: 'note' }] : [])
      ]
    },
    payload () {
      return {
        account: this.account,
        general: (this.account && this.account.settings && this.account.settings.general) || {},
        ...(this.contactId ? { contact: Contact.getFlattened(this.contactId) } : {}),
        _skip_account_name: this.gateway && this.gateway.account_id + '' === this.account._id + ''
      }
    },
    contact () {
      return {
        ...(this.contactId ? Contact.getFlattened(this.contactId) : {})
      }
    },
    message () {
      return {
        ...(this.to && !this.contactId ? { to: this.to } : {}),
        ...(this.contactId ? { contact_id: this.contactId } : {}),
        ...(this.conversationId ? { conversation: true } : {}),
        ...(this.conversationId && this.conversationId !== 'yes' ? { conversation_id: this.conversationId } : {}),
        gateway_id: this.gatewayId,
        allow_multiple: true,
        ignore_subscription: true,
        content: {
          channel: this.channel,
          text: this.text,
          ...(this.channel === 'EMAIL' ? { subject: this.subject } : {}),
          ...(this.channel === 'EMAIL' && this.emailAlias ? { email_alias_id: this.emailAlias._id } : {}),
          ...(this.attachments?.length && this.allowAttachments ? { attachments: this.attachments } : {})
        },
        ...(this.ignoreSubscription ? { ignore_subscription: true } : {})
      }
    },
    messageValid () {
      return !!(this.message.gateway_id &&
        !this.attachmentsInvalid.length &&
        this.message.content &&
        (
          this.message.content.text ||
          (
            this.allowAttachments &&
            this.attachments &&
            this.attachments.length
          )
        )
      )
    },
    renderedMessage () {
      if (this.channel !== 'SMS') {
        return {}
      }
      return renderMessage({
        account: this.payload && this.payload.account,
        gateway: this.gateway || { channel: 'SMS' },
        content: this.message && this.message.content,
        payload: this.payload,
        smartTextOptions: {
          preview: true
        },
        allowMultiple: true,
        analyze: true
      })
    },
    allowAttachments () {
      return !!this.gateway?.settings?.attachments
    },
    attachmentsInvalid () {
      return this.attachmentsPending
        .filter(a => a.loading || !!a.error)
    },
    maxAttachments () {
      if (this.gateway && this.gateway.channel === 'EMAIL') {
        return 5
      }
      return SmsOut.maxAttachments
    },
    bounceWarning () {
      return !this.loading &&
        !this.disabled &&
        !!(
          (this.contact?.sms_bounce && this.gateway?.channel === 'SMS') ||
          (this.contact?.email_bounce && this.gateway?.channel === 'EMAIL')
        )
    }
  },
  watch: {
    defaultGatewayId (newValue) {
      if (newValue) {
        this.gatewayId = newValue
      }
      this.determineGatewayAndEmailAlias()
    },
    defaultFrom () {
      this.determineGatewayAndEmailAlias()
    },
    defaultSubject (newValue) {
      if (newValue) {
        this.subject = newValue
      }
    },
    text (text) {
      this.text = removeUnnecessaryUnicodeCharacters(text)
    }
  },
  async created () {
    if (this.defaultGatewayId) {
      this.gatewayId = this.defaultGatewayId
    }
    if (this.defaultSubject) {
      this.subject = this.defaultSubject
    }
    if (!this.gatewayId) {
      const defaultGateway = this.gateways.find(g =>
        !!g.messenger
      )
      if (defaultGateway) {
        this.gatewayId = defaultGateway._id
      }
    }
    if (!this.emailAliases.length) {
      await EmailAlias.fetchMany()
    }
    this.determineGatewayAndEmailAlias()
  },
  methods: {
    determineGatewayAndEmailAlias () {
      const gateway = Gateway.find(this.gatewayId)
      if (gateway && gateway.channel === 'EMAIL') {
        let emailAlias
        if (this.defaultFrom) {
          emailAlias = EmailAlias.query().where(a => [a.fullAddress, a.quotedFullAddress, a.emailAddress].includes(this.defaultFrom)).first()
        }
        if (!emailAlias) {
          emailAlias = EmailAlias.query().where(a => a.gateway_id + '' === this.gatewayId + '').first()
        }
        if (emailAlias) {
          this.emailAliasId = emailAlias._id
        } else {
          this.emailAliasId = null
        }
      } else {
        this.emailAliasId = null
      }
    },
    async insertQuickReply (quickReply) {
      // if (this.text && (await this.$confirm('Do you want to replace the current message with this Quick Reply?', { title: 'Replace message?' })) !== 'Yes') {
      //   return
      // }
      const content = (quickReply && quickReply.content) || quickReply || {}
      this.text = (this.text || '') + content.text
      this.subject = content.subject
      if (content.attachments && content.attachments.length && this.allowAttachments) {
        this.attachments = content.attachments
      }
    },
    insertAttachment (newFilename) {
      if ((this.attachments || []).find(filename => filename === newFilename)) {
        this.$alert('This attachment has already been added.')
        return
      }
      this.attachments = [
        ...(this.attachments || []),
        newFilename
      ]
    },
    async send () {
      if (
        !this.messageValid ||
        this.loading ||
        this.disabled
      ) {
        return
      }
      let data
      if (this.gatewayId === 'note') {
        if (!this.contactId) {
          return
        }
        const promise = Event.save({
          contact_id: this.contactId,
          event: 'note',
          data: {
            text: this.text,
            user_id: this.$store.getters.user._uid
          }
        })
        const result = await this.$manageLoading('loading', promise)
        data = get(result, 'entities.events.0')
        if (!data.account_id) {
          data = get(result, 'response.data') // For now I need to do this until /v2/events can be updated to return { data: event } instead of just { ...event }
        }
        data = [data]
        addRecordsToStore({ events: data }, { force: true })
      } else {
        const promise = this.$http({
          method: 'POST',
          url: '/v2/messages',
          data: this.message
        })
        const response = await this.$manageLoading('loading', promise)
        data = response.data
        if (data && data.data) { // Future proofing for when v2/messages returns data correctly
          data = data.data
        }
        const relationships = data.relationships || {}
        if (data && data.conversation) {
          relationships.conversations = relationships.conversations || []
          relationships.conversations.push(data.conversation)
        }
        addRecordsToStore({ events: [data.event] }, { force: true })
        addRecordsToStore(relationships, { force: true })
      }
      this.text = null
      this.subject = null
      this.attachments = []
      this.attachmentsPending = []
      await this.$nextTick()
      this.$emit('send', data)
    }
  }
}
