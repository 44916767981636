import get from 'lodash/get'
import toNumber from 'lodash/toNumber'

const parseParams = ({ params = {}, options = {} } = {}) => {
  return {
    value: (get(params, 'value') || '') + '',
    length: toNumber(get(params, 'param')) || 0
  }
}

export default (args) => {
  const { value, length } = parseParams(args)
  return value.substr(0, length)
}

const humanizeCall = (args) => {
  const { length } = parseParams(args)
  return `Max ${length} chars`
}

const getLengths = (args) => {
  const { length } = parseParams(args)
  return {
    longest: length
  }
}

export {
  humanizeCall,
  getLengths
}
