import Trigger from '../Trigger'

class OrderDelivered extends Trigger {
  static get title () {
    return 'Order Delivered'
  }

  static get class () {
    return 'OrderDelivered'
  }

  static get restrict () {
    return { 'addons.type': 'SHOPIFY' }
  }
}

export default OrderDelivered
