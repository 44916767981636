import AvatarColorsMixin from './AvatarColorsMixin'
import { getContactFriendlyName } from 'shared/util/friendlyName'

const cdnUrl = 'https://s.citygro.com'

export default {
  mixins: [
    AvatarColorsMixin
  ],
  props: {
    contact: {
      type: Object,
      default: null
    },
    size: {
      type: [String, Number],
      default: 40
    },
    color: {
      type: [String, Number],
      default: null
    },
    icon: {
      type: String,
      default: undefined
    }
  },
  computed: {
    friendlyName () {
      return getContactFriendlyName(this.contact, { fallback: '' })
    },
    image () {
      if (this.contact?.photo) {
        let url = this.contact.photo
        // If the photo has a thumbnail, use that instead
        if (url.includes('_szfull.')) {
          url = url.replace('_szfull.', '_szthumb.')
        }
        if (!url.startsWith('http')) {
          url = `${cdnUrl}/cg/images/${url}`
        }
        return url
      }
      return undefined
    }
  }
}
