import FixedMenu from 'ui/components/FixedMenu'

export default {
  components: {
    FixedMenu
  },
  props: {
    left: {
      type: Boolean,
      default: null
    },
    icon: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    nudgeLeft: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      menu: false,
      pickerDropdownHeight: 400,
      pickerDropdownTop: false
    }
  },
  computed: {
    pickerDropdownStyle () {
      if (isNaN(this.pickerDropdownHeight * 1)) {
        return { height: this.pickerDropdownHeight }
      }
      return { height: this.pickerDropdownHeight + 'px' }
    },
    fixedMenuProps () {
      return {
        ...(this.nudgeLeft ? { nudgeLeft: this.nudgeLeft } : {})
      }
    }
  },
  watch: {
    menu () {
      this.calculatePickerDropdownDimensions()
    }
  },
  async mounted () {
    this.calculatePickerDropdownDimensions()
  },
  methods: {
    calculatePickerDropdownDimensions () {
      if (!this.$refs.activator) {
        return
      }
      const el = this.$refs.activator.$el || this.$refs.activator
      if (!el && typeof el.getBoundingClientRect === 'function') {
        return
      }
      const rect = el.getBoundingClientRect()
      // Determine if top or bottom
      if (rect.top > 0.7 * window.innerHeight) {
        this.pickerDropdownTop = true
        this.pickerDropdownHeight = rect.top - 30
      } else {
        this.pickerDropdownTop = false
        this.pickerDropdownHeight = window.innerHeight - rect.top - el.clientHeight - 10
      }

      if (this.pickerDropdownHeight < 300) {
        this.pickerDropdownHeight = 300
      }
      if (this.pickerDropdownHeight > (0.6 * window.innerHeight)) {
        this.pickerDropdownHeight = '60vh'
      }
    }
  }
}
