import Op from './Op'
import get from 'lodash/get'

class Push extends Op {
  static getValues ({ value, op }) {
    if (value === null || value === undefined || value === '') {
      value = []
    }
    const opValue = get(op, 'value')
    return {
      value,
      opValue
    }
  }

  static run (params) {
    const { value, opValue } = this.getValues(params)
    return [
      ...(Array.isArray(value) ? value : [value]),
      ...(Array.isArray(opValue) ? opValue : [opValue])
    ]
  }

  static shouldRun (params) {
    let { value, opValue } = this.getValues(params)
    opValue = Array.isArray(opValue) ? opValue[0] : opValue // For multiple type fields op value is coming in as a single value array (ideally it shouldn't but I can't trace through it right now).
    if (Array.isArray(value)) {
      return !get(params.schema, 'unique') || (
        !value.includes(opValue) && // In case it's not comparable by casting to a string (object type maybe?)
        !value.map(v => v + '').includes(opValue + '')
      )
    }
    return false
  }
}

export default Push
