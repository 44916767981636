import Model from './Model'

class Report extends Model {
  static entity = 'reports'

  static evictUnused = true

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      type: this.attr(undefined),
      config: this.attr(undefined),
      runs: this.attr(undefined),
      last_run_at: this.attr(undefined),
      error: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      created_by: this.attr(undefined),
      updated_by: this.attr(undefined),
      ...super.fields()
    }
  }
}

export default Report
