/**
 * Determine whether a filter contains rules or just cruft
 * @param  {[Filter]} filter The filter object
 * @return {Boolean}         true if a rule exists in the filter, false if the filter is empty
 */

const ruleExistsInRuleGroup = (ruleGroup) => {
  if (!ruleGroup || !ruleGroup.length || (ruleGroup.length === 1 && ['AND', 'OR'].includes(ruleGroup[0]))) {
    return false
  }
  for (var i in ruleGroup) {
    if (['AND', 'OR'].includes(ruleGroup[i])) {
      continue
    } else if (ruleGroup[i] && ruleGroup[i].length && ['AND', 'OR'].includes(ruleGroup[i][0])) {
      if (ruleExistsInRuleGroup(ruleGroup[i])) {
        return true
      }
    } else if (ruleGroup[i] &&
      (
        (Array.isArray(ruleGroup[i]) && ruleGroup[i].length > 1) ||
        (!Array.isArray(ruleGroup[i]) && typeof ruleGroup[i] === 'object' && Object.values(ruleGroup[i]).length)
      )
    ) {
      return true
    }
  }

  return false
}

const isEmpty = (filter) => {
  if (!filter) {
    return true
  }

  if (filter.limit > 0 && filter.random === true) {
    return false
  }

  if (filter.where && filter.where.length) {
    if (filter.where[0] !== 'AND' && filter.where[0] !== 'OR') {
      return false
    }

    if (ruleExistsInRuleGroup(filter.where)) {
      return false
    }
  }

  if ((filter.contacts && filter.contacts.length) || (filter.groups && filter.groups.length) || filter.channel || filter.search) {
    return false
  }

  return true
}

export default isEmpty
