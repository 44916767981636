import formatUSPhoneNumber from 'shared/util/formatting/formatUSPhoneNumber'

export default () => ({
  from: { name: 'From (phone #/email)', type: 'text', render: ({ value }) => formatUSPhoneNumber(value) },
  to: { name: 'To (phone #/email)', type: 'text', render: ({ value }) => formatUSPhoneNumber(value) },
  'content.text': { name: 'Message Text', type: 'text' },
  conversation_id: { name: 'Conversation ID', type: '_id' },
  keyword_id: { name: 'Keyword', type: 'number', resource: 'keywords' },
  auto_reply_id: { name: 'Auto Reply', type: '_id', resource: 'auto_replies' },
  gateway_id: { name: 'Gateway', type: 'number', resource: 'gateways' }
})
