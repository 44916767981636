<template>
  <v-app-bar
    app
    flat
    class="cg-top-bar"
    height="50"
    extension-height="50"
  >
    <app-bar-icon />

    <v-toolbar-title :class="{ 'pl-3': $vuetify.breakpoint.lgAndUp }">
      <slot name="title" />
    </v-toolbar-title>

    <v-spacer />

    <slot
      v-if="$slots.center"
      name="center"
    />

    <v-spacer
      v-if="$slots.center"
    />
    <div
      class="py-2 fill-height"
    >
      <v-divider
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mx-3"
        vertical
      />
    </div>

    <div
      style="display: inline-block; white-space: nowrap;"
    >
      <v-tooltip
        v-if="$restrict({ 'user.level': { $gte: 5 } }) && $route.meta && $route.meta.welcome"
        bottom
        :max-width="300"
      >
        <template v-slot:activator="{ on }">
          <span
            v-on="on"
          >
            <v-btn
              icon
              class="mr-1"
              @click="openWelcome"
              :disabled="loadingWelcome"
            >
              <v-icon
                size="20"
              >fas fa-door-open</v-icon>
            </v-btn>
          </span>
        </template>
        <div>(Visible to admins only) - Open the &quot;welcome&quot; dialog for this module.</div>
      </v-tooltip>
      <v-tooltip
        v-if="$restrict({ 'user.level': { $gte: 5 } }) && $route.meta && $route.meta.roadblock"
        bottom
        :max-width="300"
      >
        <template v-slot:activator="{ on }">
          <span
            v-on="on"
          >
            <v-btn
              icon
              class="mr-1"
              @click="$store.commit('roadblock/setRoadblockUi', true)"
              :disabled="loadingRoadblock"
            >
              <v-icon
                size="20"
              >fas fa-door-closed</v-icon>
            </v-btn>
          </span>
        </template>
        <div>(Visible to admins only) - Open the &quot;roadblock&quot; dialog for this module.</div>
      </v-tooltip>
    </div>

    <help-menu />

    <v-progress-linear
      v-if="throttledLoading || appChunkLoading"
      style="position: absolute; left: 0; top: 0;"
      indeterminate
      color="primary"
      height="4"
    ></v-progress-linear>

    <slot
      v-if="$slots.extension"
      name="extension"
      slot="extension"
    />
  </v-app-bar>
</template>

<style lang="scss">
.cg-top-bar {
  .v-toolbar__title {
    font-size: 26px;
    font-weight: 300;
    font-family: Poppins;
  }
  .v-toolbar__content {
    border-bottom: 1px rgba(128, 128, 128, 0.4) solid;
  }
  .v-toolbar__extension {
    border-bottom: 1px rgba(128, 128, 128, 0.4) solid;
    background-color: #ffffff;
  }
  &.theme--dark {
    .v-toolbar__extension {
      background-color: #000;
    }
  }
}
</style>

<script>
import { mapState } from 'vuex'
import AppBarIcon from './AppBarIcon'
import HelpMenu from '@/components/HelpMenu'

export default {
  components: {
    AppBarIcon,
    HelpMenu
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      throttledLoading: false,
      loadingRoadblock: false,
      loadingWelcome: false
    }
  },
  computed: {
    ...mapState(['appDrawerLeft', 'appChunkLoading'])
  },
  watch: {
    loading () {
      clearTimeout(this.throttleTimeout)
      if (this.loading) {
        this.throttledLoading = true
      } else {
        this.throttleTimeout = setTimeout(() => {
          this.throttledLoading = false
        }, 500)
      }
    }
  },
  methods: {
    async openWelcome () {
      this.loadingWelcome = true
      try {
        const WelcomeDialog = await this.$route.meta.welcome()
        this.$openWindow({
          component: WelcomeDialog.default
        })
      } finally {
        this.loadingWelcome = false
      }
    }
  },
  created () {
    this.throttledLoading = this.loading
  }
}
</script>
