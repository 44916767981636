import Block from './Block'
import colors from 'shared/colors'

class Action extends Block {
  static get title () {
    return 'Action ?'
  }

  static get type () {
    return 'ACTION'
  }

  static get color () {
    return colors.info
  }

  static getWarnings (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const warnings = []
    // Since this is an action it's important that something is connected to it.
    const atLeastOneIncomingPath = !!paths.find(p => p.to === block._id)
    if (!atLeastOneIncomingPath) {
      warnings.push({
        message: 'This block is not connected to a trigger.'
      })
    }
    return super.getWarnings(environment).concat(warnings)
  }
}

export default Action
