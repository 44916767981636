const entity = 'Kiosk Flow'
const path = 'flow'

const schemas = {
  _id: { type: '_id', name: 'ID' },
  name: { type: 'text', name: 'Name' },
  account_id: { type: 'number', name: 'Account ID' },
  created_at: { type: 'timestamp', name: 'Created At' },
  updated_at: { type: 'timestamp', name: 'Updated At ' },
  channel: { type: 'text', name: 'Channel' },
  active: { type: 'checkbox', name: 'Enabled' },
  settings: { type: 'object', name: 'Settings', hidden: true },
  pages: {
    type: 'object', // so the table won't show a field editor for this value
    name: 'Page Count',
    multiple: true, // because there are multiple objects in this array
    render: ({ value }) => value.length // so the table column can display something useful
  }
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path
}
