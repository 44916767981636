<template>
  <v-card
    class="contact-indicator elevation-2"
  >
    <div class="label" :style="labelStyles">
      {{ label }}
      <v-tooltip
        v-if="tooltip"
        :max-width="300"
        top
      >
        <template v-slot:activator="{ on }">
          <v-icon
            :class="(label.length < 13) ? 'ml-1 mb-2' : 'mb-2'"
            v-on="on"
            x-small
          >fa-info-circle</v-icon>
        </template>
        <div>{{ tooltip }}</div>
      </v-tooltip>
    </div>
    <div class="value" :style="valueStyles">{{ value }}</div>
    <div class="value2" :style="value2Styles" v-html="value2 || '&nbsp;'"></div>
  </v-card>
</template>

<style lang="scss" scoped>
.contact-indicator {
  height: 100%;
  width: 100%;
  padding: 3% 6%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .label {
    font-size: 85%;
  }
  .value {
    flex-grow: 10;
    font-size: 130%;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .value2 {
    font-size: 75%;
  }
}
</style>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    labelColor: {
      type: String,
      default: 'rgba(128, 128, 128, 0.6)'
    },
    tooltip: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    valueColor: {
      type: String,
      default: '#000000'
    },
    value2: {
      type: [String, Number],
      default: ''
    },
    value2Color: {
      type: [String, Number],
      default: 'rgba(128, 128, 128)'
    }
  },
  computed: {
    labelStyles () {
      return {
        color: this.$vuetify.theme.dark ? '#ccc' : this.labelColor
      }
    },
    valueStyles () {
      return {
        color: this.$vuetify.theme.dark && this.valueColor === '#000000' ? '#ffffff' : this.valueColor
      }
    },
    value2Styles () {
      return {
        color: this.$vuetify.theme.dark ? '#aaa' : this.value2Color
      }
    }
  }
}
</script>
