import max from 'lodash/max'

// Manages the stack of OMWindows

/**
 * {
 *   zIndex: 202, // Managed automatically by this plugin
 *   component, // Vue component used in the modal
 *   props, // Props passed into component
 *   focused: true, // Whether window is the focused window
 * }
 */

const zIndexInitial = 202
const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
let savedScroll = null

const windowManager = {
  stack: [],
  getUUID () { // Based off of date right now, enforces uniqueness
    let now
    let suffix = ''
    do {
      now = Date.now() + suffix
      suffix = suffix + '1'
    } while (this.stack.map(i => i.id).includes(now))
    return now
  },
  getNextZIndex () {
    return max([
      zIndexInitial,
      ...this.stack.map(i => (i.zIndex || 0))
    ]) + 1
  },
  add (windowStackItem) {
    windowStackItem = {
      ...windowStackItem,
      zIndex: this.getNextZIndex(),
      focused: false,
      id: this.getUUID()
    }
    this.stack.push(windowStackItem)
    this.focus(windowStackItem)
    // If iOS, save scrollTop and set full height
    if (isIos) {
      savedScroll = {
        x: window.pageXOffset,
        y: window.pageYOffset
      }
      document.getElementsByTagName('html')[0].classList.add('full-height')
    }
    return windowStackItem
  },
  remove (windowStackItem) {
    const index = this.stack.indexOf(windowStackItem)
    if (index > -1) {
      this.stack.splice(index, 1)
      if (this.stack.length) {
        const maxZ = max(this.stack.map(i => (i.zIndex || 0)))
        const item = this.stack.find(i => i.zIndex === maxZ) || this.stack[this.stack.length - 1]
        this.focus(item)
      }
    }
    // Remove height 100% and restore scrollTop
    // Another iOS safari hack to prevent body from capturing scroll when modals are open
    if (!this.stack.length && isIos) {
      if (savedScroll) {
        window.scrollTo(savedScroll.x, savedScroll.y)
        savedScroll = null
      }
      document.getElementsByTagName('html')[0].classList.remove('full-height')
    }
  },
  focus (windowStackItem) {
    if (!windowStackItem.focused) {
      this.stack.forEach(stackItem => {
        stackItem.focused = false
      })
      windowStackItem.focused = true
      windowStackItem.zIndex = this.getNextZIndex()
    }
  },
  getFocusedItem () {
    return this.stack.find(item => !!item.focused)
  }
}

window.$getWindowManager = () => windowManager

export default windowManager
