<template>
  <v-card
    class="cg-editor"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>{{ schema.name }} Values By Account</v-toolbar-title>

      <v-spacer />

      <v-btn
        icon
        @click="$emit('close')"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
    >
      <v-simple-table
        class="table"
      >
        <tbody>
          <tr
            v-for="{ accountId, value } in valuesByAccount"
            :key="accountId"
          >
            <td>
              <account-ref
                :record-id="accountId"
              />
            </td>
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-container>
  </v-card>
</template>

<script>
import AccountRef from 'ui/references/Account'
import { Contact } from 'ui/models'
import { fieldValue } from 'shared/field'
import { get } from 'lodash'

export default {
  props: {
    contactId: {
      type: String,
      required: true
    },
    schema: {
      type: Object,
      required: true
    }
  },
  components: {
    AccountRef
  },
  computed: {
    context () {
      return {
        contact: Contact.getFlattened(this.contactId)
      }
    },
    valuesByAccount () {
      return Object.entries(
        get(this.context, this.schema.path.replace('contact.', 'contact._')) || {}
      )
        .map(([accountId, value]) => ({
          accountId,
          value: fieldValue(value, this.schema, { timezone: this.$timezone })
        }))
    }
  }
}
</script>
