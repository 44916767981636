import Vue from 'vue'
// import '@/plugins/Sentry'
import App from './App.vue'
import './registerServiceWorker'
import store from '@/store'
import router from './router'
import { vuetify } from 'ui'
import vStylePlugin from 'ui/plugins/vStylePlugin'
import TopBar from '@/components/TopBar'
import '@/assets/styles.scss'
import OWindows from 'ui/plugins/OWindows'
import alerts from 'ui/plugins/alerts'
import actionHandler from '@/plugins/actionHandler'
import socketManager from '@/plugins/socket'
import timestampPlugin from 'ui/plugins/timestampPlugin'
import '@/plugins/visibility'

Vue.config.productionTip = false
if (process.env.NODE_ENV !== 'production' || window.location?.href?.includes('devtools=true')) {
  Vue.config.devtools = true
}

Vue.use(timestampPlugin)
Vue.use(vStylePlugin)
Vue.use(OWindows)
Vue.use(alerts)

// Make top bar component global
Vue.component('top-bar', TopBar)

// Inspired by:
// https://dev.to/konyu/using-javascript-to-determine-whether-the-client-is-ios-or-android-4i1j
// Since iOS isn't simply identifying via user agent anymore
const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return 'Android'
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (
      (
        navigator.platform === 'MacIntel' ||
        navigator.userAgentData?.platform === 'MacIntel'
      ) &&
      navigator.maxTouchPoints > 1
    )
  ) {
    return 'iOS'
  }
  return 'Other'
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  data () {
    return {
      routerInitialized: false,
      isIOS: getMobileOS() === 'iOS'
    }
  },
  async created () {
    await router.readyPromise
    await this.ready()
  },
  methods: {
    async ready () {
      this.routerInitialized = true
      // If the user data isn't provided on the window (done when loaded in production)
      // Make a request for all session data
      if (!Object.entries(window.initialState || {}).length) {
        // TODO: Initialize Store before Vue and run session request before init
        await store.dispatch('rootBootstrap', {
          hideErrors: true
        })
        // Do this here since the route is already picked at this point
        router.enforceRoadblockAndWelcome(this.$route)
      }
      await socketManager.sendState()
      actionHandler.ready()
    }
  }
}).$mount('#app')
