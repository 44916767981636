import uniq from 'lodash/uniq'

/**
 * Takes a SmartText analysis object and returns the paths of all references
 * to the payload.
 */
export default (analysis) => {
  const paths = [];
  (analysis.variables || []).forEach(variable => {
    (variable.stages || []).forEach(stage => {
      (stage.fragments || []).forEach(fragment => {
        if (fragment.method === 'get' && fragment.params && fragment.params.param) {
          paths.push(fragment.params.param)
        }
      })
    })
  })
  return uniq(paths)
}
