import Model from './Model'

class Path extends Model {
  static entity = 'paths'

  static evictUnused = true

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      sequence_id: this.attr(undefined),
      class: this.attr(undefined), // The type of Path this is (if originating Block supports multiple, defaults to Always)
      from: this.attr(undefined), // Originating Block
      to: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      created_by: this.attr(undefined),
      updated_by: this.attr(undefined)
    }
  }
}

export default Path
