import Model from './Model'
import { orderBy } from 'lodash'

class Group extends Model {
  static entity = 'groups'
  static socketInsertOnCreated = 'always'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      parent: this.attr(undefined),
      children: this.attr(undefined),
      all_members: this.attr(undefined),
      members: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      // below added by Thomas for Groups Page
      created_by: this.attr(undefined),
      permissions: this.attr(undefined)
    }
  }

  static getAsFieldOptions () {
    // TODO: Recursively go through all object type fields and bring child fields to root level with a "parent" property
    return orderBy(this.all().filter(group => !group.deleted_at).map(group => {
      return {
        label: group.name || `Group #${group._id}`,
        lclabel: (group.name || `Group #${group._id}`).toLowerCase(),
        value: group._id,
        parent: group.parent
      }
    }), 'lclabel')
  }
}

export default Group
