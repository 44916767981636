import formatUSPhoneNumber from 'shared/util/formatting/formatUSPhoneNumber'

export default (value, schema) => {
  // Format US phone numbers
  if (
    schema &&
    schema.path === 'contact.phone' &&
    typeof value === 'string'
  ) {
    value = formatUSPhoneNumber(value)
  }
  return (value || '') + ''
}
