import Alert from './Alert'
import Confirm from './Confirm'
import Prompt from './Prompt'

import { pick } from 'lodash'

const pickOptions = [
  'persistent',
  'overlay',
  'oWindow'
]

const AlertsPlugin = {
  install (Vue, options) {
    Vue.mixin({
      methods: {
        $alert (message, options = {}) {
          return this.$openWindow({
            component: Alert,
            props: {
              message,
              options
            },
            ...pick(options, pickOptions)
          })
        },
        $confirm (message, options = {}) {
          const windowOptions = {
            component: Confirm,
            props: {
              message,
              options
            },
            ...(options && options.buttons && options.buttons.length > 2
              ? {
                  oWindow: {
                    height: (Confirm.oWindow.height + ((options.buttons.length - 1) * 54))
                  }
                }
              : {}
            ),
            ...pick(options, pickOptions)
          }
          if (options.oWindow) {
            windowOptions.oWindow = {
              ...(windowOptions.oWindow),
              ...options.oWindow
            }
          }
          return this.$openWindow(windowOptions)
        },
        $prompt (message, options = {}) {
          return this.$openWindow({
            component: Prompt,
            props: {
              message,
              options
            },
            ...pick(options, pickOptions)
          })
        }
      }
    })
  }
}

export default AlertsPlugin
