import Action from '../Action'
import { sum, get } from 'lodash'
import colors from 'shared/colors'

class Pause extends Action {
  static get title () {
    return 'Delay'
  }

  static get class () {
    return 'Pause'
  }

  static get color () {
    return colors.goldYellow
  }

  static leftNumber (block) {
    if (block && block.stats && block.stats.tasks) {
      return block.stats.tasks || 0
    }
    return 0
  }

  static getDescription ({ block = {} } = {}) {
    if (block && block.props && block.props.interval) {
      const interval = block.props.interval
      return [
        [interval.years, 'Y'],
        [interval.months, 'M'],
        [interval.weeks, 'W'],
        [interval.days, 'D'],
        [interval.hours, 'h'],
        [interval.minutes, 'm']
      ].filter(([amt]) => !!amt && amt > 0)
        .map(([amt, suffix]) => `${amt}${suffix}`)
        .join(',')
    }
    return ''
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []
    const totalInterval = Object.values(get(block, 'props.interval') || {}).map(v => (v * 1) || 0)
    if (!sum(totalInterval)) {
      errors.push({
        message: 'Delay interval is empty.'
      })
    }
    return super.getErrors(environment).concat(errors)
  }

  static getWarnings (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const warnings = []
    if (get(block, 'props.anytime')) {
      warnings.push({
        message: 'This delay can resume at inopportune times.'
      })
    }
    return super.getWarnings(environment).concat(warnings)
  }
}

export default Pause
