import Action from '../Action'
import colors from 'shared/colors'
import { get } from 'lodash'

class SmsOut extends Action {
  static get title () {
    return 'Send SMS'
  }

  static get class () {
    return 'SmsOut'
  }

  static getPathTypes () {
    return [
      { name: 'Always', class: 'Always', color: null }
      // { name: 'On Response', class: 'SmsIn', color: null }
      // { name: 'On Bounce', class: 'Message.Bounced', color: null },
      // { name: 'On Click', class: 'Message.Clicked', color: null },
      // { name: 'On Opt-out', class: 'Message.Unsubscribed', color: null },
      // { name: 'On Deliver', class: 'Message.Delivered', color: null },
      // When a message is skipped but not necessarily due to a bounce (probably should be hidden)
      // { name: 'On Drop', class: 'Message.Dropped', color: null }
    ]
  }

  static get color () {
    return colors.info
  }

  static getDescription ({ block = {} } = {}) {
    return (get(block, 'props.content.text') || '').substr(0, 40)
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []
    const text = get(block, 'props.content.text')
    const attachments = get(block, 'props.content.attachments') || []
    const gatewayId = get(block, 'props.gateway_id')
    if (attachments && attachments.length > this.maxAttachments) {
      errors.push({
        message: `Too many attachments. Please select a maximum of ${this.maxAttachments}.`,
        path: 'content.attachments'
      })
    }
    if (!text && !attachments.length) {
      errors.push({
        message: 'Message content is empty.',
        path: 'content.text'
      })
    }
    if (!gatewayId && gatewayId + '' !== '0') {
      errors.push({
        message: '"Send From" number is required.',
        path: 'gateway_id'
      })
    }
    if (gatewayId && !((environment?.gateways || []).find(gw => gw._id + '' === gatewayId + ''))) {
      errors.push({
        message: 'This block is sending from an invalid gateway.',
        path: 'gateway_id'
      })
    }
    return super.getErrors(environment).concat(errors)
  }

  static get maxTotalAttachmentSize () {
    return 500 * 1024
  }

  static get maxAttachments () {
    return 5
  }

  // Return a size for each attachment that needs to be resized
  // based on maxAttachmentSize (imposed by the most restrictive carrier)
  // Attachments under a certain size (based on # attachments) should not be resized
  // Attachments are expected to look like this
  // {
  //   file: {
  //     size: 1234
  //   }
  // }
  static getAttachmentsWithResizeAmount (attachments) {
    attachments = (attachments || []).map(attachment => {
      // Ensure a size is set, even if it's 0
      return {
        ...attachment,
        file: {
          ...(attachment.file || {}),
          size: +(attachment?.file?.size || 0)
        }
      }
    })

    const cumulativeSize = attachments
      .reduce((a, { file }) => a + +file.size, 0)

    // No need to resize if all images are < max size
    if (cumulativeSize <= this.maxTotalAttachmentSize) {
      return attachments
    }

    // Max size is based on how many attachments there are
    const maxAttachmentSize = Math.floor(this.maxTotalAttachmentSize / attachments.length)
    // Attachments <= maxAttachmentSize won't be resized and this will be the space they use
    let notResizedAttachments = attachments
      .filter(({ file }) => !(+file.size > maxAttachmentSize))
    let sizeOfNotResizedAttachments = notResizedAttachments
      .reduce((a, { file }) => a + +file.size, 0)

    // How much room resized attachments can use when removing size of assets that won't be resized
    let allowedSizeOfAttachmentsToResize = this.maxTotalAttachmentSize - sizeOfNotResizedAttachments

    // These attachments will be resized
    let attachmentToResize = attachments.filter(a => !notResizedAttachments.includes(a))
    let currentSizeOfAttachmentsToResize = attachmentToResize
      .reduce((a, { file }) => a + +file.size, 0)

    // At this point the only way that currentSizeOfAttachmentsToResize < allowedSizeOfAttachmentsToResize
    // is if cumulativeSize > this.maxTotalAttachmentSize, so not necessary to check for that here

    let totalSizeToEliminateByResizing = currentSizeOfAttachmentsToResize - allowedSizeOfAttachmentsToResize

    // Add a "proposed" resize value to all images that need to be resized.
    // If a final size is lower than "maxAttachmentsSize" it will be set to maxAttachmentsSize
    // We'll then iterate one more time and ensure all the remaining images are resized accordingly
    attachments = attachments.map(attachment => {
      if (notResizedAttachments.includes(attachment)) {
        return attachment
      }

      // Calculate weighted resize amount
      const weightOfThisAttachment = attachment.file.size / currentSizeOfAttachmentsToResize
      const sizeToRemove = Math.ceil(totalSizeToEliminateByResizing * weightOfThisAttachment)
      return {
        ...attachment,
        resize: Math.max(attachment.file.size - sizeToRemove, maxAttachmentSize)
      }
    })

    // Iterate the same thing one more time but this time we won't touch if size <= maxAttachmentSize || resize <= maxAttachmentSize
    notResizedAttachments = attachments
      .filter(({ file, resize }) => !(+file.size > maxAttachmentSize) || !(resize && resize > maxAttachmentSize))

    sizeOfNotResizedAttachments = notResizedAttachments
      .reduce((a, { file, resize }) => a + +(resize || file.size), 0)

    allowedSizeOfAttachmentsToResize = this.maxTotalAttachmentSize - sizeOfNotResizedAttachments

    attachmentToResize = attachments.filter(a => !notResizedAttachments.includes(a))
    currentSizeOfAttachmentsToResize = attachmentToResize
      .reduce((a, { file }) => a + +file.size, 0)

    totalSizeToEliminateByResizing = currentSizeOfAttachmentsToResize - allowedSizeOfAttachmentsToResize

    return attachments.map(attachment => {
      if (notResizedAttachments.includes(attachment)) {
        return attachment
      }

      // Calculate weighted resize amount
      const weightOfThisAttachment = attachment.file.size / currentSizeOfAttachmentsToResize
      const sizeToRemove = Math.ceil(totalSizeToEliminateByResizing * weightOfThisAttachment)
      return {
        ...attachment,
        resize: attachment.file.size - sizeToRemove
      }
    })
  }
}

export default SmsOut
