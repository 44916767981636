import Model from './Model'

class CalendarItem extends Model {
  static entity = 'calendar_items'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      contact_id: this.attr(undefined),
      title: this.attr(undefined),
      start_at: this.attr(undefined),
      run_start_at: this.attr(undefined),
      end_at: this.attr(undefined),
      run_end_at: this.attr(undefined),
      tags: this.attr(undefined),
      data: this.attr(undefined),
      status: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

export default CalendarItem
