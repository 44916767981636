import Action from '../Action'
import colors from 'shared/colors'

class MessageReply extends Action {
  static get title () {
    return 'Message Reply'
  }

  static get class () {
    return 'MessageReply'
  }

  static get color () {
    return colors.info
  }

  // static get restrict () {
  //   return { 'user.level': { $gte: 9 } }
  // }

  static getPathTypes ({ block = {} } = {}) {
    return [
      ...(block?.props?.response_options || []).map(responseOption => {
        return {
          name: 'Replied: "' + responseOption.text + '"',
          class: responseOption.key || responseOption.text,
          color: colors.success
        }
      }),
      ...(block?.props?.response_limit_to_options
        ? []
        : [
            {
              name: 'Any Other Reply',
              class: '_CATCHALL',
              color: colors.info
            }
          ]
      ),
      {
        name: 'Expired',
        class: '_EXPIRED',
        color: colors.error
      }
    ]
  }

  static getDefaultProps () {
    return {
      gateway_id: null
    }
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []
    // const blockId = get(block, 'props.block_id')
    // if (!blockId) {
    //   errors.push({
    //     message: 'Block to trigger is required',
    //     path: 'block_id'
    //   })
    // }
    return super.getErrors(environment).concat(errors)
  }
}

export default MessageReply
