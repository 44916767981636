import Model from './Model'

class Keyword extends Model {
  static entity = 'keywords'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      gateway_id: this.attr(undefined),
      settings: this.attr(undefined),
      hit_count: this.attr(undefined),
      last_hit_at: this.attr(undefined),
      last_hit_by: this.attr(undefined),
      delegate_function: this.attr(undefined),
      is_response: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

export default Keyword
