import { get } from 'lodash'

class Block {
  constructor (props = {}) {
    Object.entries(props).forEach(([key, value]) => {
      this[key] = value
    })
  }

  static get title () {
    return 'Block ?'
  }

  static rightNumber (block) {
    if (block) {
      return block.runs || 0
    }
    return 0
  }

  static color (theme = {}) {
    return (theme && theme.secondary) || '#666666'
  }

  static getDescription ({ block = {} } = {}) {
    return ''
  }

  /**
   * This should return a mongo query that runs on an object that looks like this:
   * {
   *   "user": ...,
   *   "account": ...,
   *   "addons": ... // Array of addon configurations.  Generally filter by addons.type
   * }
   */
  static get restrict () {
    return null
  }

  static getPathTypes () {
    return [
      {
        name: 'Always',
        class: 'Always'
      }
    ]
  }

  // Returns an array of block classes that are allowed to connect to this block
  static restrictFromBlockClasses () {
    return null
  }

  // Limit the number of blocks that can be connected INTO this block
  static restrictFromBlockCount () {
    return 0 // Unlimited
  }

  static getDefaultProps () {
    return {}
  }

  // Returns array of at least { message }, someday hope to supply field paths as well
  // This returns recommendations that won't prevent the sequence from being activated
  static getWarnings (environment = {}) {
    const { block } = environment // { sequence, block, path }
    const errors = []
    const needsReview = get(block, 'needs_review')
    if (needsReview) {
      errors.push({
        message: 'This block is not approved.',
        path: 'needs_review'
      })
    }
    return errors
  }

  // Returns array of at least { message }, someday hope to supply field paths as well
  // Anything returned from these will prevent the sequence from being activated
  static getErrors (environment = {}) {
    const errors = []
    return errors
  }
}

export default Block
