<template>
  <v-card
    class="cg-editor"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>Confirm Your {{messageTypeTitle}}</v-toolbar-title>

      <v-spacer />

      <v-btn
        icon
        @click="$emit('close')"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
      class="align-center justify-center"
    >
      <div
        class="mt-0"
      >
        <h3>Confirm Message Content (Recommended)</h3>
        <p>Send a test message to verify your content is formatted properly, all links work, and there are no spelling errors.</p>
      </div>
      <div
        class="mt-6 text-left"
      >
        <v-btn
          color="primary"
          @click="sendTest()"
        >Send Test Message</v-btn>
      </div>
      <div
        class="mt-10"
      >
          <h3>{{messageTypeSubmit}}</h3>
          <h4>
            <v-checkbox
              label="Verify this message is ready to send"
              v-model="verified"
            />
          </h4>
      </div>
    </v-container>
    <v-spacer />
    <div
      class="d-flex footer pa-2 mt-3"
    >
      <v-spacer />
      <v-btn
        color="warning"
        outlined
        @click="$emit('close')"
        :disabled="loading"
      >Cancel</v-btn>
      <v-btn
        v-if="isBlast"
        color="primary"
        class="ml-2"
        @click="schedule()"
        :disabled="loading || !verified"
      >
        <v-icon
          small
          class="mr-2"
        >fas fa-paper-plane</v-icon>
        Schedule
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="ml-2"
        @click="schedule()"
        :disabled="loading || !verified"
      >
        Save & Close
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { TestDialog as SmsTestDialog } from 'ui/blocks/actions/SmsOut'
import { TestDialog as EmailTestDialog } from 'ui/blocks/actions/EmailOut'

export default {
  oWindow: {
    width: 600,
    height: 450,
    persistent: true
  },
  components: {
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    isBlast: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loading: false,
      testSent: false,
      verified: false
    }
  },
  computed: {
    ...mapGetters(['account']),
    messageTypeTitle () {
      return this.isBlast ? 'Blast' : 'Message'
    },
    messageTypeSubmit () {
      return this.isBlast
        ? (!this.testSent) ? 'Schedule Blast Without Testing (Not Recommended)' : 'Schedule Blast'
        : (!this.testSent) ? 'Schedule Message Without Testing (Not Recommended)' : 'Schedule Message'
    },
    blockProps () {
      const messageProps = this.isBlast ? this.value?.message?.props || {} : this.value || {}
      const channel = this.isBlast ? this.value?.message?.class.toUpperCase().replace('OUT', '') : 'SMS'
      return {
        gateway_id: (this.isBlast) ? (this.value?.message?.props?.gateway_id || 0) : (this.value?.gateway_id || 0),
        channel,
        ...messageProps
      }
    }
  },
  methods: {
    schedule () {
      if (this.verified) {
        this.$emit('close', { send: true })
      }
    },
    async sendTest () {
      const response = await this.$openWindow({
        component: this.value?.message?.class === 'EmailOut' ? EmailTestDialog : SmsTestDialog,
        props: {
          blockProps: {
            ...this.blockProps
          }
        }
      })

      if (response) {
        this.testSent = true
        this.verified = true
      }
    }
  }
}
</script>
