import { Model } from '@vuex-orm/core'
import jsonStableStringify from 'fast-json-stable-stringify'
import { camelCase, get, uniq, fromPairs } from 'lodash'

const existingRequests = {}
// window.existingRequests = existingRequests

const multiplexedRequests = {}

const reuseRequest = async ({ key, request, options = {} }) => {
  if (existingRequests[key] && !get(options, 'noReuse')) {
    return existingRequests[key]
  }
  existingRequests[key] = request()

  try {
    const result = await existingRequests[key]
    delete existingRequests[key]
    return result
  } catch (e) {
    delete existingRequests[key]
    throw e
  }
}

const multiplexRequest = async function ({ param, value, options = {} }) {
  let multiplexedRequestKey = `${this.entity}-fetchMany-${param}`
  let multiplexedRequest = multiplexedRequests[multiplexedRequestKey]
  // Since only 1000 per page is allowed by API, start a 2nd multiplexed request
  if (multiplexedRequest?.values?.length >= 1000) {
    multiplexedRequestKey = `${this.entity}-fetchMany-${param}-b`
    multiplexedRequest = multiplexedRequests[multiplexedRequestKey]
  }
  if (!multiplexedRequest) {
    multiplexedRequest = multiplexedRequests[multiplexedRequestKey] = {
      param,
      values: [],
      $promise: (async () => {
        // Give it multiplexTimeout ms to add more requests
        await new Promise(resolve => setTimeout(resolve, this.multiplexTimeout))
        // Delete this first so requests made while this is running will create a new request
        delete multiplexedRequests[multiplexedRequestKey]
        await this.api().request({
          method: 'GET',
          url: `/${this.entity}?${multiplexedRequest.param}=${multiplexedRequest.values.join(',')}`,
          ...((options && options.request) || {}),
          params: {
            ...(options?.request?.params || {}),
            limit: multiplexedRequest.values.length + 1
          }
        })
      })()
    }
  }

  multiplexedRequest.values = uniq([...multiplexedRequest.values, value])
  return multiplexedRequest.$promise
}

class CityGroModel extends Model {
  static fields () {
    return {}
  }

  static primaryKey = '_id'
  // Whenever a "created" notification is received from the websocket,
  // only insert into the store when it passes a filter function defined on the model
  // or a filter function defined in a model mixin.
  static socketInsertOnCreated = 'conditional'
  // Whenever an "updated" notification is received from the websocket,
  // only update existing records in the store... don't insert
  static socketInsertOnUpdated = 'existing'

  // The amount of time to wait for other requests if options.multiplex === true
  static multiplexTimeout = 250

  static pagination = {
    countKey: 'total_count'
  }

  static get apiConfig () {
    return {
      dataTransformer: (params) => {
        return this.transformIncomingData(params)
      }
    }
  }

  // Override this with a model's default values
  static get defaultValues () {
    return {}
  }

  static getValue (record, path) {
    return get(record, path) || this.defaultValues?.[path]
  }

  getValue (path) {
    return this.constructor.getValue(this, path)
  }

  // Override this to change how data for a model is received from the server and placed
  // into the local store.  I abstracted this mainly so it could also be easily used when
  // adding relationships to the store using the addRecordsToStore method defined in
  // models/index.js
  static transformIncomingData ({ data }) {
    data = data.data || data
    return data
  }

  /**
   * @param {*} id - id of record to request
   * @param {*} options.force - Fetch from server even if there is a cached record in the store
   * @param {*} options.multiplex - If there is an existing request for this record in transit,
   *  return that promise rather than make a new request.
   */
  static async fetchOne (id, options = {}) {
    const existingRecord = this.find(id)
    if (existingRecord && !get(options, 'force')) {
      return existingRecord
    }

    // The ability to have the request wait a period of time for any other multiplexed
    // Rather than requesting /:entity/:id it will instead request /:entity?id=1,2,3
    if (get(options, 'multiplex')) {
      const multiplexResult = await multiplexRequest.call(this, {
        param: 'id',
        value: id,
        options
      })
      return multiplexResult
    }

    return reuseRequest({
      key: `${this.entity}-fetchOne-${id}`,
      request: () => this.api().request({
        method: 'GET',
        url: `/${this.entity}/${id}`,
        ...(options.request || {})
      }),
      options
    })
  }

  /**
   * @param {*} requestOptions - the ability to override/specify all options going into the
   *  VueX-ORM request config (can be axios options and VueX-ORM options)
   * @param {*} options.
   */
  static async fetchMany (requestOptions = {}, options = {}) {
    // The ability to have the request wait a period of time for any other multiplexed
    // Rather than requesting /:entity/:id it will instead request /:entity?id=1,2,3
    if (get(options, 'multiplex')) {
      if (Object.entries(requestOptions.params).length !== 1) {
        throw new Error('There can only be one parameter on a multiplexed request')
      }
      if (Object.entries(requestOptions.params).length !== 1) {
        throw new Error('Remember to add a "params" object to the requestOptions on a multiplexed request')
      }
      return multiplexRequest.call(this, {
        param: Object.keys(requestOptions.params)[0],
        value: Object.values(requestOptions.params)[0]
      })
    }

    return reuseRequest({
      key: `${this.entity}-fetchMany-${jsonStableStringify(requestOptions)}`,
      request: () => this.api().request({
        method: 'GET',
        url: `/${this.entity}`,
        ...requestOptions
      }),
      options
    })
  }

  static api () {
    if (typeof window !== 'undefined' && window.proxyModelApi) {
      return window.proxyModelApi(this.entity)
    }
    return super.api()
  }

  static save (record, requestOptions = {}) {
    return this.api().request({
      method: record[this.primaryKey] ? 'PATCH' : 'POST',
      url: `/${this.entity}${record[this.primaryKey] ? '/' + record[this.primaryKey] : ''}`,
      data: record,
      ...requestOptions
    })
  }

  static async destroy (recordOrId, requestOptions = {}) {
    console.log('request options', requestOptions)
    const id = typeof recordOrId === 'object' ? recordOrId[this.primaryKey] : recordOrId
    const result = await this.api().request({
      method: 'DELETE',
      url: `/${this.entity}/${id}`,
      ...requestOptions
    })
    this.delete((r) => {
      return r[this.primaryKey] + '' === id + ''
    })
    return result
  }

  // Override this to prevent deleteing all records upon account change
  static emptyForAccount () {
    this.deleteAll()
  }

  static forAccount (accountId) {
    if (accountId) {
      return this.query().where(r => r.account_id && r.account_id + '' === accountId + '')
    }
    return this.query()
  }

  static mixin (options = {}) {
    const ComponentRegistry = require('ui/store/ComponentRegistry').default
    if (!this.entity) {
      throw new Error('No entity defined')
    }
    const model = this
    const loadingKey = camelCase(`${options?.prefix || model.entity}_loading`)
    // Only sets to true during the first load (use for a smoother UX)
    const recordsKey = camelCase(`${options?.prefix || model.entity}_records`)
    const keysKey = recordsKey + 'Id$'
    const mixin = {
      data () {
        return {
          [loadingKey + 'count']: 0, // Increment & Decrement based on completed operations
          [keysKey]: [] // Private property where the ids of the most recent fetchMany are stored
        }
      },
      computed: {
        [loadingKey] () {
          return this[loadingKey + 'count'] > 0
        },
        [recordsKey] () {
          return this.$data[keysKey].map(id => model.find(id)).filter(v => !!v)
        }
      },
      created () {
        if (options.references && options.references.length) {
          ComponentRegistry.registerReference({
            entity: model.entity,
            component: this,
            references: options.references
          })
        }
        if (typeof options.insertFilter === 'function') {
          ComponentRegistry.registerListener({
            entity: model.entity,
            component: this,
            filter: options.insertFilter,
            insertId: (id) => {
              this[camelCase(`${model.entity}_insert_id`)](id)
            }
          })
        }
      },
      beforeDestroy () {
        ComponentRegistry.destroyComponent(this)
      },
      methods: {
        ...fromPairs(
          [
            'fetchOne',
            'fetchMany',
            'save',
            'destroy'
          ].map(modelMethod => [camelCase(`${model.entity}_${modelMethod}`), async function (requestOptions, options) {
            this[loadingKey + 'count']++
            try {
              const result = await model[modelMethod].apply(model, arguments)
              if (typeof get(options, 'afterLoad') === 'function') {
                options.afterLoad(result)
              }
              this[loadingKey + 'count']--
              if (modelMethod === 'fetchMany') {
                // Store all of the keys for the result
                // I'd like to use result.entities.${model.entity} for this always but
                // sometimes these resolve in a different order than they were returned
                // from the server.  So if result.response.data.data._id exists, map that path
                let recordKeys
                if (result?.response?.data?.data?.[0]?.[model.primaryKey]) {
                  recordKeys = result.response.data.data.map(record => record[model.primaryKey])
                } else {
                  recordKeys = (get(result, `entities.${model.entity}`) || []).map(record => record[model.primaryKey])
                }
                if ((recordKeys && recordKeys.length) || !get(options, 'ignoreEmptyResult')) {
                  if (get(options, 'appendResult')) {
                    this[keysKey] = (this[keysKey] || []).concat(recordKeys)
                  } else {
                    this[keysKey] = recordKeys
                  }
                  if (options?.enforceUnique) {
                    this[keysKey] = uniq(this[keysKey])
                  }
                }
                // Register this component as a referencer to these records
                ComponentRegistry.registerReference({
                  entity: model.entity,
                  component: this,
                  references: [recordsKey]
                })
                if (!(recordKeys && recordKeys.length) && typeof get(options, 'onEmptyResult') === 'function') {
                  options.onEmptyResult()
                }
              }
              return result
            } catch (e) {
              this[loadingKey + 'count']--
              throw e
            }
          }])
        ),
        [camelCase(`${model.entity}_empty`)] () {
          this[keysKey] = []
        },
        [camelCase(`${model.entity}_insert_id`)] (id) {
          if (!this.$data[keysKey].map(i => i + '').includes(id + '')) {
            this.$data[keysKey].push(id)
          }
        }
      }
    }

    return mixin
  }
}

export default CityGroModel
