import Editor from './Editor'

const oWindow = (block) => {
  return {
    width: '1000',
    height: '800',
    persistent: true
  }
}

export {
  oWindow,
  Editor
}

export default {
  oWindow,
  Editor
}
