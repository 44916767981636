import formatUSPhoneNumber from 'shared/util/formatting/formatUSPhoneNumber'

/**
 * Return the friendly name of the contact based on what exists
 * @param {Object} contact - The contact object
 * @param {Mixed} options.fallback - The fallback value if name isn't found
 */

export default (contact, options = {}) => {
  if (!contact) {
    return ''
  }

  // To compensate for VuexOrm requiring everything schemaless to be under data
  if (contact.data) {
    contact = {
      ...contact,
      ...contact.data
    }
  }

  let name = ''
  if (contact && contact.first_name) {
    name = contact.first_name
  }
  if (contact && contact.last_name) {
    name = (name ? name + ' ' : '') + contact.last_name
  }
  if (name) {
    return name
  }
  if (options && ![null, undefined].includes(options.fallback)) {
    return options.fallback
  }

  let phone = contact.phone
  if (phone) {
    phone = formatUSPhoneNumber(phone)
  }

  if (options.preferPhone) {
    return phone || contact.email || '#' + contact._id
  }

  return contact.email || phone || '#' + contact._id
}
