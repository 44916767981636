import Trigger from '../Trigger'
import { get, omitBy } from 'lodash'
import moment from 'moment'

const rruleParse = (rruleString) => {
  const rrule = omitBy(
    (rruleString || '').split(';').reduce((object, prop) => {
      if (!prop.trim()) {
        return object
      }
      var parts = prop.split('=')
      if (!parts[1]) {
        return object
      }
      return { ...object, [parts[0].toUpperCase()]: parts[1].toUpperCase() }
    }, {}),
    (v) => [undefined, null, ''].includes(v)
  )
  return rrule
}

class Time extends Trigger {
  static get title () {
    return 'At Time'
  }

  static get class () {
    return 'Time'
  }

  static getDescription ({ block, vm = null } = {}) {
    const $timestamp = (val) => {
      if (vm && vm.$timestamp) {
        return vm.$timestamp(val, 'lll')
      }
      return val
    }
    const runAt = get(block, 'props.run_at')
    if (runAt) {
      if (!get(block, 'last_run_at') && runAt === 'NOW') {
        return 'Run Immediately'
      }
      return $timestamp(get(block, 'last_run_at') || runAt)
    } else if (get(block, 'props.rrule') !== undefined) {
      const rrule = rruleParse(block.props.rrule)
      return 'Repeating' + (rrule.FREQ ? ' ' + rrule.FREQ.toLowerCase() : '')
    }
  }

  static getWarnings (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const warnings = []
    if (get(block, 'props.run_at') === 'NOW') {
      warnings.push({
        message: 'This block is set to run immediately after this Automation is activated.'
      })
    }
    return super.getWarnings(environment).concat(warnings)
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []
    const runAt = get(block, 'props.run_at')
    const lastRunAt = get(block, 'last_run_at')
    const rruleString = get(block, 'props.rrule')
    if (runAt && runAt !== 'NOW' && !lastRunAt && moment(runAt).isBefore(moment().add(1, 'minute'))) {
      errors.push({
        message: 'Run time must be in the future.'
      })
    } else if (rruleString) {
      const rrule = rruleParse(rruleString)
      if (!rrule.DTSTART) {
        errors.push({
          message: 'Start Date is required.'
        })
      }
      if (!rrule.FREQ) {
        errors.push({
          message: 'Repeating Frequency (for example, "Weekly") is required.'
        })
      }
      if (!rrule.INTERVAL) {
        errors.push({
          message: 'Repeat interval (for example, every X weeks) is required.'
        })
      }
      if (!rrule.BYHOUR || !rrule.BYMINUTE) {
        errors.push({
          message: 'Time Of Day to repeat is required.'
        })
      }
    }
    return super.getErrors(environment).concat(errors)
  }
}

export default Time
