// Recursively sorts through all rules in a filter and triggers sanitization for each of them

const transformFilterRules = (rules, options = {}) => {
  // If rules isn't an array with at least 1 element then return it, there's nothing to transform
  if (!(rules && Array.isArray(rules) && rules.length)) {
    return rules
  }

  rules = rules.map(rule => {
    if (['AND', 'OR'].includes(rule)) {
      return rule // These are automatically valid rules requiring no transformation
    } else if (rule && Array.isArray(rule)) {
      // This is either a single rule or a group of rules
      if (rule[0] && ['AND', 'OR'].includes(rule[0])) {
        rule = transformFilterRules(rule, options)
      } else {
        if (options && typeof options.transformRule === 'function') {
          rule = options.transformRule(rule, options)
        }
      }
    }
    return rule
  })

  if (options && typeof options.transformRules === 'function') {
    rules = options.transformRules(rules, options)
  }

  return rules
}

export default transformFilterRules
