<template>
  <div
    class="preview-wrapper fill-height"
  >
    <div
      v-if="type === 'SMS'"
      style="height: 130%"
    >
      <sms-preview
        v-bind="{ value }"
        no-header
        style="height: 130%"
      />
    </div>
    <div
      v-else-if="type === 'HTML'"
      :style="htmlWrapperStyle"
    >
      <email-html-preview
        :value="contentValue"
        no-overflow
        disabled
      />
    </div>
    <div
      v-else-if="type === 'TEXT'"
    >
      <email-text-preview
        :value="contentValue"
      />
    </div>
  </div>
</template>

<script>
import EmailHtmlPreview from 'ui/blocks/actions/EmailOut/Preview/ContentHtml'
import EmailTextPreview from 'ui/blocks/actions/EmailOut/Preview/ContentText'
import SmsPreview from 'ui/blocks/actions/SmsOut/Preview'
import EmailRenderer from 'email-builder/src/App.vue'
import { getProperty } from 'shared/schemas/entities'
import Vue from 'vue'
import { Snippet } from 'ui/models'

export default {
  components: {
    SmsPreview,
    EmailHtmlPreview,
    EmailTextPreview
  },
  props: {
    channel: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      containerWidth: 0,
      containerHeight: 0
    }
  },
  computed: {
    account () {
      return this.$store.getters.account
    },
    type () {
      const channel = this.channel || this.value?.content?.channel || this.value?.channel
      if (channel === 'SMS') {
        return 'SMS'
      }
      if (this.value?.content?.html || this.value?.content?.ebon) {
        return 'HTML'
      }
      return 'TEXT'
    },
    contentValue () {
      if (this.type === 'HTML') {
        if (this.value.content.html) {
          return this.value.content.html
        }
        if (this.value.content.ebon) {
          const vm = new Vue({
            render: (h) => h(EmailRenderer, {
              props: {
                value: this.value.content.ebon,
                payload: this.$store.getters.payload,
                schemas: this.schemas,
                snippets: Snippet.all(),
                defaultProps: {
                  fontFamily: getProperty('account', this.account, 'settings.style.font_family'),
                  backgroundColor: getProperty('account', this.account, 'settings.style.background_color'),
                  contentBackgroundColor: getProperty('account', this.account, 'settings.style.content_background_color'),
                  color: getProperty('account', this.account, 'settings.style.color'),
                  buttonBackgroundColor: getProperty('account', this.account, 'settings.style.button_background_color'),
                  buttonColor: getProperty('account', this.account, 'settings.style.button_color'),
                  linkColor: getProperty('account', this.account, 'settings.style.link_color'),
                  linkNoUnderline: getProperty('account', this.account, 'settings.style.link_no_underline')
                }
              }
            })
          })
          vm.$mount()
          return vm.$el.outerHTML
        }
      }
      return this.value.content?.text
    },
    htmlWrapperStyle () {
      const aspectRatio = this.containerWidth / (this.containerHeight || 1)
      const targetWidth = 700
      const targetHeight = targetWidth / (aspectRatio || 1)
      const scale = this.containerWidth / targetWidth
      const offsetLeft = -1 * ((targetWidth / 2) - (this.containerWidth / 2))
      const offsetTop = -1 * ((targetHeight / 2) - (this.containerHeight / 2))
      return {
        position: 'absolute',
        width: targetWidth + 'px',
        height: targetHeight + 'px',
        left: offsetLeft + 'px',
        top: offsetTop + 'px',
        transform: 'scale(' + scale + ')'
      }
    }
  },
  methods: {
    resize () {
      this.containerWidth = this.$el.clientWidth
      this.containerHeight = this.$el.clientHeight
    }
  },
  async created () {
    if (!Snippet.all().length) {
      global.cardPreviewSnippetPromise = global.cardPreviewSnippetPromise || Snippet.fetchMany()
      await global.cardPreviewSnippetPromise
    }
  },
  mounted () {
    this.resize()
    const resizeObserver = new ResizeObserver(() => this.resize())
    resizeObserver.observe(this.$el)
  }
}
</script>
