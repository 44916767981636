import Trigger from '../Trigger'

class CalendarStart extends Trigger {
  static get title () {
    return 'Calendar Start'
  }

  static get class () {
    return 'CalendarStart'
  }

  static get restrict () {
    return { 'addons.type': 'CALENDAR' }
  }
}

export default CalendarStart
