import Trigger from '../Trigger'

class OrderOutForDelivery extends Trigger {
  static get title () {
    return 'Order Out 4 Delivery'
  }

  static get class () {
    return 'OrderOutForDelivery'
  }

  static get restrict () {
    return { 'addons.type': 'SHOPIFY' }
  }
}

export default OrderOutForDelivery
