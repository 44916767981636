const entity = 'Import'
const path = 'import'

const schemas = {
  _id: { type: '_id', name: 'ID' },
  created_at: { type: 'timestamp', name: 'Date' },
  records: { type: 'number', name: '# Records' },
  records_processed: { type: 'number', name: '# Records Processed' },
  status: { type: 'text', name: 'Status' },
  'settings.identifier': { type: 'text', name: 'Update Contact By' },
  'settings.policy': { type: 'text', name: 'Replace Policy' },
  accept_liability: { type: 'text', name: 'Liability Acceptance' }
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path
}
