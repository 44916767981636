import moment from 'moment-timezone'

const operators = {
  '=': '$eq',
  '!=': '$ne',
  date: 'date',
  '!date': '!date',
  '<': '$lt',
  '>': '$gt',
  '>=': '$gte',
  '<=': '$lte',
  empty: 'empty',
  '!empty': '!empty'
}

const buildQueryRule = ({ path, operator, value }, options = {}) => {
  const queryOperator = operators[operator]

  if (operator === 'date' || operator === '!date') {
    // This is a crude way to filter out invalid values
    // It really should throw an error here
    value = value || ''
    if (typeof value === 'string' || typeof value === 'number') {
      if (!(value + '').match(/^(\d{4}-\d\d-\d\d{1})$/)) {
        return null
      }
    }
    value = moment(value).format('YYYY-MM-DD') // Remove any time component if existing
    const low = moment.tz(value + ' 00:00:00', options.timezone || 'UTC').utc().toDate()
    const high = moment.tz(value + ' 00:00:00', options.timezone || 'UTC').add(1, 'days').utc().toDate()
    if (operator === 'date') {
      return { [path]: { $gte: low, $lt: high } }
    } else if (operator === '!date') {
      return {
        $or: [
          { [path]: null },
          { [path]: { $lt: low } },
          { [path]: { $gte: high } }
        ]
      }
    }
  }

  return { [path]: { [queryOperator]: moment(value).utc().toDate() } }
}

export default {
  buildQueryRule,
  operators
}

export {
  buildQueryRule,
  operators
}
