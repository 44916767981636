class MiniEventEmitter {
  constructor () {
    this._events = {}
  }

  on (event, listener) {
    if (typeof this._events[event] !== 'object') {
      this._events[event] = []
    }
    this._events[event].push(listener)
    return () => this.removeListener(event, listener)
  }

  removeListener (event, listener) {
    if (typeof this._events[event] === 'object') {
      const idx = this._events[event].indexOf(listener)
      if (idx > -1) {
        this._events[event].splice(idx, 1)
      }
    }
  }

  emit (event, ...args) {
    if (typeof this._events[event] === 'object') {
      this._events[event].forEach(listener => listener.apply(this, args))
    }
  }

  once (event, listener) {
    const remove = this.on(event, (...args) => {
      remove()
      listener.apply(this, args)
    })
  }
}

export default MiniEventEmitter
