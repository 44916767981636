// DEPRECATED, PLEASE DON'T USE
// Only used by @citygro/api

import random from './random'

const lazyRange = function * (start = 0, end = 100, step = 1) {
  for (let i = start; i < end; i += step) {
    yield i
  }
}

const randomString = (
  length = 1,
  chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
) => {
  const result = new Array(length)
  for (const i of lazyRange(0, length)) {
    result[i] = chars[Math.floor(random() * chars.length)]
  }
  return result.join('')
}

export default randomString
