import get from 'lodash/get'
import BaseField from './_BaseField'

// Id is generally going to be a mongo ObjectId type of field unless it's numeric and less than 15 characters

class Id extends BaseField {
  static sanitize ({ value }) {
    if (value) {
      // ObjectIds only happen on server after dehydration and we want them to stay.
      if (typeof value === 'object' && value.constructor && value.constructor.name.toUpperCase() === 'OBJECTID') {
        return value
      }
      if (typeof value === 'object' && get(value, '$id') && typeof value.$id === 'string') {
        value = value.$id // Support ['$id' => 'deadbeef'] format
      }
      if (typeof value.toString === 'function') {
        value = value.toString()
      }
      if (typeof value === 'string') {
        if (!isNaN(value) && value.length < 15) {
          value *= 1
        }
      }
    }
    return value || null
  }

  static unsanitize ({ value, options }) {
    if (value) {
      if (typeof value.toString === 'function') {
        value = value.toString()
      }
      if (typeof value === 'string' || typeof value === 'number') {
        if (!isNaN(value * 1) && (value + '').length < 15) {
          value *= 1
        } else {
          value = value + ''
        }
      }
    }
    return value || null
  }
}

export default Id
