import get from 'lodash/get'
import upperFirst from 'lodash/upperFirst'

import Dec from './Dec'
import Inc from './Inc'
import Pull from './Pull'
import PullAll from './PullAll'
import Push from './Push'
import Set from './Set'
import Unset from './Unset'

const opClasses = {
  Dec,
  Inc,
  Pull,
  PullAll,
  Push,
  Set,
  Unset
}

const getClassForOp = (op) => {
  if (!get(op, 'op')) {
    return
  }
  const opName = upperFirst(op.op.replace('$', ''))
  return opClasses[opName]
}

export { getClassForOp }

export default {
  ...opClasses,
  getClassForOp // For convenience
}
