<template>
  <v-card>
    <div
      class="pa-3 d-flex pb-0"
    >
      <div
        class="flex-grow-1"
      >
        <div>
          <strong>Past Discount Codes</strong>
        </div>
      </div>
      <div>
        <v-btn
          icon
          :disabled="loading"
          @click="$emit('reload')"
          small
        >
          <v-icon small>fa fa-redo</v-icon>
        </v-btn>
      </div>
    </div>
    <v-simple-table
      :style="{ opacity: loading ? 0.4 : 1 }"
    >
      <thead>
        <tr>
          <th class="px-2">Code</th>
          <th class="px-2">Value</th>
          <th class="px-2">Type</th>
          <th class="px-2">Expiration</th>
          <th class="px-2">Event Data</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="!loading && !(events && events.length)"
        >
          <td
            colspan="5"
            class="text-center"
          >No codes found.</td>
        </tr>
        <discount-code-row
          v-for="event in events"
          :key="event._id"
          v-bind="{ event }"
          expired
        />
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import DiscountCodeRow from './DiscountCodeRow.vue'

export default {
  components: {
    DiscountCodeRow
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    events: {
      type: Array,
      default: () => []
    }
  }
}
</script>
