import BaseField from './_BaseField'
import get from 'lodash/get'
import round from 'lodash/round'

class CGNumber extends BaseField {
  static sanitize ({ value, schema }) {
    // Undefined/null are always null
    if (value === undefined || value === null || value === '' || isNaN(value * 1)) {
      return null
    }

    // Boolean false should be cast to 0
    if (value === false) {
      value = 0
    }

    // Boolean true is 1
    if (value === true) {
      value = 1
    }

    // Cast any strings to numbers
    value = value * 1

    // if schema casts to an int, round the value
    if (get(schema, 'cast') === 'int') {
      value = round(value)
    }

    return value
  }

  static unsanitize ({ value, schema }) {
    // Undefined/null are always null
    if (value === undefined || value === null || value === '' || isNaN(value * 1)) {
      return null
    }

    // Boolean false should be cast to 0
    if (value === false) {
      value = 0
    }

    // Boolean true is 1
    if (value === true) {
      value = 1
    }

    // Cast any strings to numbers
    value = value * 1

    // if schema casts to an int, round the value
    if (get(schema, 'cast') === 'int') {
      value = round(value)
    }

    return value
  }
}

export default CGNumber
