<template>
  <div>
    <div
      class="text-center"
      style="font-size: 30px; line-height: 34px"
    >
      <strong>Welcome to Patch!</strong>
    </div>
    <div
      class="text-center mt-2 mb-6"
      style="font-size: 16px; line-height: 18px;"
    >Tell us a little about yourself.</div>
    <v-card
      class="pa-7"
    >
      <v-btn
        icon
        @click="$emit('back')"
        v-bind="{ disabled }"
      >
        <v-icon>fa fa-arrow-left</v-icon>
      </v-btn>
      <v-form
        @submit.prevent="$emit('next')"
      >
        <v-row>
          <v-col
            :cols="12"
            :md="6"
          >
            <v-text-field
              label="First Name"
              :value="user.first_name"
              @input="$emit('change', ['user', 'first_name', $event])"
              hide-details
              v-bind="allFieldProps"
            />
          </v-col>
          <v-col
            :cols="12"
            :md="6"
          >
            <v-text-field
              label="Last Name"
              :value="user.last_name"
              @input="$emit('change', ['user', 'last_name', $event])"
              hide-details
              v-bind="allFieldProps"
            />
          </v-col>
          <v-col
            :cols="12"
            :md="6"
          >
            <v-tooltip
              top
              :max-width="300"
            >
              <template v-slot:activator="{ on }">
                <v-select
                  label="Timezone"
                  :value="account.settings && account.settings.timezone"
                  @input="$emit('change', ['account', 'settings.timezone', $event])"
                  v-bind="allFieldProps"
                  :items="timezoneItems"
                  hide-details
                  v-on="on"
                />
              </template>
              <div>All automated activity in your account will be based on this timezone.</div>
            </v-tooltip>
          </v-col>
          <v-col
            :cols="12"
            :md="6"
          >
            <v-text-field
              label="Mobile Number"
              :value="formattedPhone"
              type="tel"
              @input="$emit('change', ['user', 'phone', $event])"
              hide-details
              v-bind="allFieldProps"
            />
          </v-col>
          <v-col
            :cols="12"
            :md="6"
          >
            <v-text-field
              label="Business Address"
              :value="address.streetlines"
              @input="$emit('change', ['account', 'settings.address.streetlines', $event])"
              hide-details
              v-bind="allFieldProps"
            />
          </v-col>
          <v-col
            :cols="12"
            :md="6"
          >
            <v-text-field
              label="City"
              :value="address.city"
              @input="$emit('change', ['account', 'settings.address.city', $event])"
              hide-details
              v-bind="allFieldProps"
            />
          </v-col>
          <v-col
            :cols="12"
            :md="6"
          >
            <v-select
              label="Country"
              :items="[{text: 'United States', value: 'US'}, {text: 'Canada', value: 'CA'}]"
              :value="address.country"
              v-bind="allFieldProps"
              :menu-props="{ offsetY: true }"
              @input="$emit('change', ['account', 'settings.address.country', $event])"
              hide-details
            />
          </v-col>
          <v-col
            :cols="12"
            :md="6"
          >
            <v-select
              label="State/Province"
              :items="statesItems"
              :value="address.state"
              @input="$emit('change', ['account', 'settings.address.state', $event])"
              hide-details
              v-bind="allFieldProps"
            />
          </v-col>
          <v-col
            :cols="12"
            :md="6"
          >
            <v-text-field
              label="Postal Code"
              :value="address.zip"
              @input="$emit('change', ['account', 'settings.address.zip', $event])"
              hide-details
              v-bind="allFieldProps"
            />
          </v-col>
        </v-row>
        <div
          class="d-flex align-center mt-8"
        >
          <div
            v-if="!valid"
            class="error--text"
          >All fields are required.</div>
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            :loading="disabled"
            :disabled="!valid"
          >Continue</v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import timezonesCommon from 'shared/data/timezonesCommon'
import states from 'shared/data/states'
import canadianProvinces from 'shared/data/canadianProvinces'
import formatUSPhoneNumber from 'shared/util/formatting/formatUSPhoneNumber'

export default {
  props: {
    account: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    allFieldProps () {
      return {
        outlined: true,
        dense: true,
        disabled: this.disabled
      }
    },
    address () {
      return this.account?.settings.address || {}
    },
    formattedPhone () {
      return formatUSPhoneNumber(this.user.phone)
    },
    timezoneItems () {
      let timezones = timezonesCommon
      if (this.account?.settings?.timezone && !timezones.find(c => c === this.account.settings.timezone + '')) {
        timezones = [
          {
            text: this.account.settings.timezone + ' (invalid)',
            value: this.account.settings.timezone
          },
          ...timezones
        ]
      }
      return timezones
    },
    statesItems () {
      return (this.account.settings?.address?.country === 'CA')
        ? canadianProvinces.map(s => ({
          text: s.name,
          value: s.id
        }))
        : states.map(s => ({
          text: s.name,
          value: s.id
        }))
    },
    valid () {
      return !!this.user?.first_name &&
        !!this.user?.last_name &&
        !!this.user?.phone &&
        !!this.account?.settings?.address?.streetlines &&
        !!this.account?.settings?.address?.city &&
        !!this.account?.settings?.address?.state &&
        // (
        //   !!this.account?.settings?.address?.zip &&
        //   /[0-9]{5}/.test(this.account?.settings?.address?.zip)
        // ) &&
        this.account?.settings?.address?.zip.length > 4 &&
        !!this.account?.settings?.timezone &&
        !!this.account?.settings?.address?.country
    }
  }
}
</script>
