import moment from 'moment-timezone'
import get from 'lodash/get'
import testObject from 'shared/filter'

/**
 * Returns undefined, or an array of all faults on an offer.
 * If an offer is unavailable due to any of the tested criteria
 * the function returns an array of faults representing that.
 *
 * Possible fault codes:
 * INACTIVE, BEFORE_START, AFTER_END, TOTAL_USE_LIMIT, FILTER, CUSTOMER_USE_LIMIT, POINTS, ACTIVATION
 *
 * @param Object offer
 * @param Object env.contact Contact to test offer against
 * @param Object[] env.schemas List of field schemas
 * @param String env.timezone Timezone of account to test with and have smart text variables converted to
 * @return String[] An array of faults string names
 */
const getOfferFaults = (offer, rawEnv = {}) => {
  let faults
  const env = {
    ...rawEnv,
    events: [...(rawEnv.events || [])].sort(idCompareSort)
  }

  Object.entries(faultMap).forEach(([faultName, test]) => {
    if (test(offer, env)) {
      faults = (faults || []).concat(faultName)
    }
  })

  return faults
}

const faultMap = {
  // First things first, ensure that the start_at and end_at dates are met
  BEFORE_START: (offer, env) => { return offer.start_at && moment(offer.start_at).isAfter(moment()) },
  AFTER_END: (offer, env) => { return offer.end_at && moment(offer.end_at).isBefore(moment()) },

  INACTIVE: (offer, env) => { return !offer.active },

  // Total use limit based on the number of times an offer has been redeemed
  TOTAL_USE_LIMIT: (offer, env) => {
    return (get(offer, 'total_use_limit', 0) * 1 > 0) && (get(offer, 'stats.redemption_count', 0) >= offer.total_use_limit)
  },

  // Ensure all filters are met
  FILTER: (offer, env) => {
    const context = {
      contact: get(env, 'contact', {}),
      account: get(env, 'account', {}),
      general: get(env, 'account.settings.general')
    }
    return (
      offer.filter &&
      !testObject(
        context,
        offer.filter,
        {
          context,
          schemas: (get(env, 'schemas') || {}),
          timezone: get(env, 'timezone', 'UTC'),
          cacheSanitized: true
        }
      )
    )
  },

  // Ensure customer use limit / does not fail
  CUSTOMER_USE_LIMIT: (offer, env) => {
    const customerUseLimit = get(offer, 'customer_use_limit', 0) * 1
    const useCount = getOfferRedemptionsFromEnv(offer, env).length
    return customerUseLimit > 0 && useCount >= customerUseLimit
  },

  // Ensure offer is valid on the provided redemption channel
  CHANNEL: (offer, env) => {
    return offer.channel && env.channel ? offer.channel !== env.channel : false
  },

  // Requre the necessary punchcard points
  POINTS: (offer, env) => {
    return offer.punchcard_key && offer.points && get(env.contact, offer.punchcard_key, 0) * 1 < get(offer, 'points', 0) * 1
  },

  // If offer must be activated to be shown, require it
  ACTIVATION: (offer, env) => {
    let result = false
    let requiresActivation = true
    if (
      offer.auto_activate === true ||
      offer.type === 'LOYALTY' ||
      offer.coupon_code
    ) {
      requiresActivation = false
    }
    if (requiresActivation) {
      const activations = getOfferActivationsFromEnv(offer, env)
      if (!activations.length) {
        result = true
      }
    }
    return result
  }
}

const idCompareSort = (a, b) => (a._id || '').toString().localeCompare((b._id || '').toString())

const getOfferActivationsFromEnv = (offer, env) => {
  const activations = (get(env.contact, 'claims') || [])
    .filter((item) => (
      get(item, 'offer_id', '') + '' === offer._id + ''
    ))
    .filter((item) => {
      const expiresAt = get(item, 'expires_at')
      const allowedCount = get(item, 'allowed', null)
      const redemptionsForThisActivation = allowedCount ? getOfferRedemptionsFromEnv(offer, env, item._id) : []
      const isNotExpired = !expiresAt || moment(expiresAt).isAfter(moment())
      const isNotUsedUp = (
        allowedCount === null ||
        allowedCount > redemptionsForThisActivation.length
      )
      return isNotExpired && isNotUsedUp
    })
    .sort(idCompareSort)
  return activations
}

const getOfferRedemptionsFromEnv = (offer, env, activationId) => {
  const redemptions = (get(env, 'events') || []).filter((item) => {
    return (
      (get(item, 'event') === 'offer.redeemed') &&
      ((get(item, 'data.offer_id', '') + '') === (offer._id + '')) &&
      (activationId ? (get(item, 'data.activation_id', '') + '' === activationId + '') : true)
    )
  }).sort(idCompareSort)
  return redemptions
}

getOfferFaults.idCompareSort = idCompareSort
getOfferFaults.getOfferActivationsFromEnv = getOfferActivationsFromEnv
getOfferFaults.getOfferRedemptionsFromEnv = getOfferRedemptionsFromEnv

export default getOfferFaults
