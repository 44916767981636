import moment from 'moment-timezone'
import Timestamp from 'ui/components/Timestamp'

export default {
  install (Vue, options) {
    Vue.component('ui-timestamp', Timestamp)

    Vue.mixin({
      computed: {
        $timezone () {
          return this.$store?.getters?.account?.settings?.timezone || 'UTC'
        }
      },
      methods: {
        $timestamp (value, format = 'LLL', options = {}) {
          if (!value) {
            return null
          }
          return this.$moment(value).tz(options?.timezone || this.$timezone).format(format)
        },
        // Returns a moment in the current timezone. This can possibly be optimized to not need the huge moment-timezone lib in the future.
        $moment (value) {
          return moment(value).tz(this.$timezone)
        }
      }
    })
  }
}
