import { isEqual, get, fromPairs } from 'lodash'
import { buildQuery } from 'shared/filter'

export default {
  props: {
    // Prevents this component from running since it's added to the
    // SelectContacts/Filter editors by default but for the BlastEditor
    // we instead want it to run for the entire blast editor so the
    // value can be used across multiple steps
    noFilterCounter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filterCounterLoading: false,
      filterCounterError: 'There was an error',
      filterStarted: null, // Filter that has been requested from server but not received response
      filterFinishedObjects: [], // Recently finished filters we can reuse counts for to prevent unnecessary requests
      filterFinishedObject: null,
      // Increase this due to many contacts matching, large account,
      // and slow response times to prevent flooding server with requests
      // If filters are taking 10+ seconds to respond, bump this up to 10
      // so less requests are made to server.  This will add 10 seconds to the
      // response time but it will overall be a better experience.
      filterCounterTimeoutInterval: 2000
    }
  },
  computed: {
    filterCounterData () { // This makes it easier to pass in just one prop to the FilterCounter component
      return {
        loading: this.filterCounterLoading,
        count: (this.filterFinishedObject && this.filterFinishedObject.count) || 0,
        error: this.filterCounterError,
        t: this.filterFinishedObject && this.filterFinishedObject.t
      }
    },
    filterCounterOn () {
      return {
        reload: () => {
          this.requestCount()
        }
      }
    },
    watchedFilter () { // Override this to watch for changes on a different filter
      return this.value && this.value.filterChanged
    },
    filterSchemas () { // Override so schemas can be used in filter validation
      return []
    },
    filterSchemasObject () { // Object version of filterSchemas
      if (Array.isArray(this.filterSchemas)) {
        return fromPairs(
          this.filterSchemas.map(schema => {
            return [
              schema.path,
              schema
            ]
          })
        )
      }
      return this.filterSchemas
    },
    filterValidationError () {
      try {
        buildQuery(this.watchedFilter, { schemas: this.filterSchemasObject })
        return false
      } catch (e) {
        return e.message
      }
    }
  },
  watch: {
    watchedFilter (to, from) {
      if (this.noFilterCounter) {
        return
      }
      if (isEqual(to, from)) {
        return
      } else if (this.filterStarted && isEqual(to, this.filterStarted)) {
        // No need to do anything else, just let that existing request resolve
        return
      }
      this.filterCounterError = null
      this.cancelExistingRequest()
      if (this.filterValidationError) {
        return
      }
      const filterFinishedObject = this.filterFinishedObjects.find(ff => {
        return isEqual(ff.filter, to) &&
          ff.t > Date.now() - 2 * 60 * 1000 // Only used cached values within the past 2 minutes
      })
      if (filterFinishedObject) {
        this.filterFinishedObject = filterFinishedObject
        return
      }
      this.filterCounterLoading = true
      this.changeTimeout = setTimeout(() => {
        this.requestCount()
      }, this.filterCounterTimeoutInterval)
    }
  },
  created () {
    if (!this.noFilterCounter) {
      this.requestCount()
    }
  },
  beforeDestroy () {
    this.cancelExistingRequest()
  },
  methods: {
    cancelExistingRequest () {
      this.filterCounterError = null
      this.filterCounterLoading = false
      clearTimeout(this.changeTimeout)
      if (this.cancelSource) {
        this.cancelSource.cancel('request cancelled')
        this.cancelSource = null
      }
    },
    async requestCount () {
      if (this.noFilterCounter) {
        return
      }
      this.cancelExistingRequest() // Just in case
      if (!this.watchedFilter) { // There needs to be a filter, otherwise it's just 0
        this.filterFinishedObject = null
        return
      }
      this.filterCounterLoading = true
      this.filterStarted = JSON.parse(JSON.stringify(this.watchedFilter))

      const CancelToken = this.$http.CancelToken
      this.cancelSource = CancelToken.source()

      const startTime = Date.now()

      this.requestPromise = this.$http({
        method: 'GET',
        url: '/v2/contacts/count',
        params: {
          filter: JSON.stringify(this.filterStarted)
        },
        cancelToken: this.cancelSource.token
      })

      try {
        const response = await this.requestPromise
        const dt = Date.now() - startTime
        if (dt > 5000) {
          // For every 5 seconds it took, add one second to the filterCounterTimeoutInterval
          this.filterCounterTimeoutInterval = 3000 + (dt / 5)
        }
        // After successful response, store this as the requested filter so if it
        // ever matches
        this.filterFinishedObject = {
          filter: this.filterStarted,
          count: get(response, 'data.total_count') || 0,
          t: Date.now()
        }
        this.filterFinishedObjects.push(this.filterFinishedObject)
        this.filterStarted = null
        this.filterCounterLoading = false
      } catch (e) {
        this.filterStarted = null
        this.filterCounterLoading = false
        this.filterFinishedObject = this.filterFinishedObjects.find(ff => {
          return isEqual(ff.filter, this.filterStarted) &&
            ff.t > Date.now() - 2 * 60 * 1000 // Only used cached values within the past 2 minutes
        })
        if (!this.filterFinishedObject) {
          if (!e.message.includes('cancelled')) {
            this.filterCounterError = e.response?.data?.error || e.message
          }
        }
        throw e
      }
    }
  }
}
