import component from './Spacer'

export default {
  id: 'spacer',
  name: 'Spacer',
  icon: 'fas fa-grip-lines',
  component,
  factory () {
    return {
      props: {
        height: 30
      }
    }
  }
}
