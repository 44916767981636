import { render, staticRenderFns } from "./Smartlink.vue?vue&type=template&id=2bdd6233&"
import script from "./Smartlink.vue?vue&type=script&lang=js&"
export * from "./Smartlink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports