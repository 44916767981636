const platforms = [
  {
    name: 'Facebook',
    id: 'facebook'
  },
  {
    name: 'Instagram',
    id: 'instagram'
  },
  {
    name: 'Twitter',
    id: 'twitter'
  },
  {
    name: 'LinkedIn',
    id: 'linkedin'
  },
  {
    name: 'Pinterest',
    id: 'pinterest'
  },
  {
    name: 'YouTube',
    id: 'youtube'
  },
  {
    name: 'TikTok',
    id: 'tiktok'
  },
  {
    name: 'Snapchat',
    id: 'snapchat'
  }
]

const iconSets = [
  {
    name: 'Colored Icon',
    id: 'color-icon'
  },
  {
    name: 'Black Icon',
    id: 'black-icon'
  },
  {
    name: 'White Icon',
    id: 'white-icon'
  },
  {
    name: 'Colored Background',
    id: 'color-bg'
  },
  {
    name: 'Black Background',
    id: 'black-bg'
  },
  {
    name: 'White Background',
    id: 'white-bg'
  }
]

export {
  platforms,
  iconSets
}
