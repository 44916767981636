import { Template } from 'ui/models'
import { intersection } from 'lodash'

export default {
  mixins: [
    Template.mixin()
  ],
  props: {
    tags: { // Recommendations will be based on one or more of these
      type: Array,
      default: () => []
    },
    callback: {
      type: Function,
      required: true
    },
    linkToSettings: {
      type: Boolean,
      default: false
    },
    filteredTemplates: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      initialLoading: false,
      loading: false
    }
  },
  computed: {
    allTemplates () {
      if (this.filteredTemplates.length) {
        return this.filteredTemplates
      }
      // Select templates that have the "Quick Reply" tag
      return this.templatesRecords.filter(t => {
        return t.tags && (
          t.tags.includes('Quick Reply')
        )
      })
    },
    recommendedTemplates () {
      if (this.filteredTemplates.length) {
        return this.filteredTemplates
          .filter(template => intersection(this.tags, template.tags || []).length)
          .map(template => ({
            template,
            tags: intersection(this.tags, template.tags || [])
          }))
      }
      return this.allTemplates
        .filter(template => intersection(this.tags, template.tags || []).length)
        .map(template => ({
          template,
          tags: intersection(this.tags, template.tags || [])
        }))
    },
    otherTemplates () {
      if (this.filteredTemplates.length) {
        return this.filteredTemplates
          .filter(template => !this.recommendedTemplates.find(r => r.template === template))
      }
      return this.allTemplates
        .filter(template => !this.recommendedTemplates.find(r => r.template === template))
    }
  },
  async created () {
    if (!this.templatesRecords.length) {
      this.initialLoading = this.loading = true
      try {
        await this.templatesFetchMany({
          params: {
            limit: 1000
          }
        })
        this.initialLoading = this.loading = false
      } catch (e) {
        this.initialLoading = this.loading = false
        throw e
      }
    }
  },
  methods: {
    requestClose () {
      if (this.loading) {
        return
      }
      this.$emit('close')
    },
    async select (template) {
      if (this.loading) {
        return
      }
      const promise = this.callback(template)
      await this.$manageLoading('loading', promise)
      this.$emit('close')
    }
  }
}
