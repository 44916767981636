export default {
  namespaced: true,
  state: {
    textChatLoading: false
  },
  mutations: {
    setTextChatLoading (state, payload) {
      state.textChatLoading = payload
    }
  },
  actions: {
    async openTextChat () {
      if (window.PATCH.textChat.isOpen) {
        window.PATCH.textChat.close()
      } else {
        window.PATCH.textChat.open()
      }
    }
  }
}
