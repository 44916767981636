import { Node, mergeAttributes } from '@tiptap/core'

/**
 * This plugin adds the capability to Tiptap which finds all span[data-type=smart-text]
 * and converts them to SmartText tokens
 */

export default Node.create({
  name: 'smartText',
  content: 'text*',
  group: 'inline',
  inline: true,
  atom: true,
  selectable: true,
  addOptions: {
    HTMLAtributes: {
      'data-type': 'smart-text',
      contenteditable: 'false'
    }
  },
  parseHTML () {
    return [
      {
        tag: 'span',
        getAttrs: node => {
          return node.getAttribute('data-type') === 'smart-text'
        }
      }
    ]
  },
  renderHTML ({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes, { 'data-type': 'smart-text', contenteditable: 'false' }), 0]
  }
})
