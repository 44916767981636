import Op from './Op'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

class Set extends Op {
  static run ({ value, op }) {
    const opValue = get(op, 'value')
    return opValue
  }

  static shouldRun ({ value, op }) {
    const opValue = get(op, 'value')
    // Treat undefined and null as equal when trying to set as null
    return !isEqual(value, opValue) && !([null, undefined].includes(value) && [null, undefined].includes(opValue))
  }
}

export default Set
