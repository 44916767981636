const types = {
  document: [
    'pdf',
    'doc',
    'docx'
  ],
  image: [
    'jpeg',
    'jpg',
    'png',
    'gif'
  ],
  video: [
    'mp4',
    'm4v',
    'ogv'
  ],
  audio: [
    'mp3'
  ],
  import: [
    'xls',
    'xlsx',
    'csv'
  ],
  vcard: [
    'vcf'
  ]
}

export default types
