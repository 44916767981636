import Model from './Model'

class EmailAlias extends Model {
  static entity = 'email_aliases'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      gateway_id: this.attr(undefined),
      alias: this.attr(undefined),
      name: this.attr(undefined),
      destination: this.attr(undefined),
      permissions: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }

  get gateway () {
    const Gateway = require('./Gateway').default
    return Gateway.find(this.gateway_id)
  }

  // Returns "Me <me@whatever.com>"
  get fullAddress () {
    const email = `${this.alias || '?'}@${this.gateway ? this.gateway.address || '?' : '?'}`
    if (!this.name) {
      return email
    }
    return `${this.name} <${email}>`
  }

  // Returns '"Me" <me@whatever.com>'
  get quotedFullAddress () {
    const email = `${this.alias || '?'}@${this.gateway ? this.gateway.address || '?' : '?'}`
    if (!this.name) {
      return email
    }
    return `"${this.name}" <${email}>`
  }

  // Returns "me@whatever.com"
  get emailAddress () {
    return `${this.alias || '?'}@${this.gateway ? this.gateway.address || '?' : '?'}`
  }
}

export default EmailAlias
