import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      username: '',
      password: '',
      remember: null,
      error: null,
      loading: false
    }
  },
  computed: {
    data () {
      return {
        remember: this.remember || undefined
      }
    },
    requestConfig () {
      return {}
    }
  },
  methods: {
    ...mapActions(['login']),
    ...mapState(['prevRoute', 'nextRoute']),
    async submit () {
      this.error = null
      this.loading = true
      try {
        await this.login({
          auth: {
            username: this.username,
            password: this.password
          },
          data: this.data,
          ...this.requestConfig
        })

        this.$emit('auth')
      } catch (e) {
        this.error = e?.response?.data?.error || e?.response?.data?.error || e.message
        throw e
      } finally {
        this.loading = false
      }
    }
  }
}
