import Action from '../Action'
import colors from 'shared/colors'

class Filter extends Action {
  static get title () {
    return 'Filter'
  }

  static get class () {
    return 'Filter'
  }

  static get color () {
    return colors.warning
  }

  static getPathTypes () {
    return [
      {
        name: 'Match',
        class: 'Yes',
        color: colors.success
      },
      {
        name: 'Not Match',
        class: 'No',
        color: colors.error
      },
      {
        name: 'Always',
        class: 'Always',
        color: null
      }
    ]
  }
}

export default Filter
