import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import set from 'lodash/set'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import runOp from './runOp'
import sanitizeOp from './sanitize/sanitizeOp'
import { getClassForSchema } from './fieldTypes'
import { getClassForOp } from './opTypes'
import schemaForPath from './schemaForPath'
import testObject from 'shared/filter'
import MiniEventEmitter from 'shared/util/MiniEventEmitter'
import cloneDeep from 'lodash/cloneDeep'

class FieldOperator extends MiniEventEmitter {
  /**
   * @param {Object} payload - the initial payload object (will not be mutated)
   * @param {Object} schemas - Object containing all schemas relative to the context (NOT only for the payload)
   * @param {Object} context - Object containing entire context for filter / smart-text
   * @param {String} contextPayloadPath - path at which the payload resides on the context
   *   If payload were the contact then this would be 'contact', otherwise it defaults to ''
   *   Setting this to contact assumes only the schemas.contact.schemas should be used
   *   It also assumes that all ops paths should start with 'contact.' and will remove that from any ops
   *   since it's implied by referencing schemas.contact.schemas
   *   Also the payload will be used in place of this path for filters and smartText so the updated values
   *   are available after ever op is run
   *   If this is falsy then it's assumed that all ops are relative to a root payload
   * @param {Object} options - Object that gets merged with the options passed into runOp(s) calls
   * @param {String} options.timezone - The timezone to use for all filter / smart-text evaluations.
   */
  constructor ({
    payload = {},
    schemas = [],
    context = {},
    contextPayloadPath,
    options = {} // for timezone, etc, will be merged
  } = {}) {
    super()

    this.originalPayload = cloneDeep(payload)
    this.schemas = schemas
    this.context = cloneDeep(context)
    this.contextPayloadPath = contextPayloadPath
    this._options = options

    // This is updated with the latest ops each time commit is called
    // Also everything has been sanitized
    this.cleanPayload = cloneDeep(this.originalPayload)

    // Ops that have been sanitized and added to cleanPayload
    this.cleanOps = []

    // This has all ops run (pre-sanitization) on it since the last commit
    this.pendingPayload = cloneDeep(this.cleanPayload)

    // Ops that have not been committed to cleanPayload
    this.pendingOps = []

    // This has all sanitized ops run.  Also, op filters are run against this payload.
    this.sanitizedPendingPayload = cloneDeep(this.pendingPayload)

    // Ops that have been sanitized but have not been committed to cleanPayload
    this.sanitizedPendingOps = []
  }

  getContext () {
    let context = cloneDeep(this.context)

    // The payload should either be set entirely under a path (such as contact)
    // or else it is actually the context
    if (this.contextPayloadPath) {
      set(context, this.contextPayloadPath, this.sanitizedPendingPayload)
    } else {
      context = {
        ...(this.sanitizedPendingPayload || {}),
        ...context
      }
    }

    return context
  }

  setOptions (options = {}) {
    this._options = options
  }

  getProps () {
    let props = pick(this, Object.getOwnPropertyNames(this))
    props = fromPairs(
      Object.entries(props)
        .filter(([key, value]) => !key.startsWith('_') && typeof value !== 'function')
    )
    return props
  }

  /**
   * Run an array of ops on the current payload
   *
   */
  runOps (ops, options = {}) {
    ops.forEach(op => {
      this.runOp(op, {
        ...options,
        skipChangeEvent: true
      })
    })

    this.emit('change', this.getProps())
    this.emit('change-dirty', this.getProps()) // TODO
    this.emit('change-pending', this.getProps())
  }

  /**
   * Run Op
   *
   * 1) Determines if op should run based on pendingPayload
   * 2) Determines if sanitized op should run based on sanitizedPendingPayload
   * 3) Determines if op should run based on filter against sanitizedPendingPayload
   * 4) Runs op on pendingPayload
   * 4b) Adds to pendingOps
   * 5) Runs sanitizedOp on sanitizedPendingPayload
   * 5b) Adds to sanitizedPendingOps
   */
  runOp (op, options = {}) {
    let path = op.key
    if (this.contextPayloadPath) {
      if (!path.startsWith(this.contextPayloadPath)) {
        path = `${this.contextPayloadPath}.${path}`
      } else {
        // Otherwise remove the contextPayloadPath from the actual op since it's root level
        op = {
          ...op,
          key: op.key.replace(this.contextPayloadPath + '.', '')
        }
      }
    }
    const schema = schemaForPath(
      this.schemas,
      path
    )

    if (!schema || schema.notfound || (schema.immutable && !get(merge({}, this._options, options), 'bypassImmutable'))) {
      return false
    }

    const fieldTypeClass = getClassForSchema(schema)
    if (!(fieldTypeClass && fieldTypeClass.shouldRun({ value: op.value }))) {
      return false
    }

    const context = this.getContext()

    const shouldRunByFilter = !op.filter || testObject(context, op.filter, {
      schemas: this.schemas,
      timezone: get(merge({}, this._options, options), 'timezone'),
      context
    })

    // Never run an op that doesn't pass a filter
    if (!shouldRunByFilter) {
      return false
    }

    // If the filter exists and passed then move it to "filter_passed" so it's never evaluated
    // again.
    if (op.filter && shouldRunByFilter) {
      op.filter_passed = op.filter
      delete op.filter
    }

    // Now determine whether the op and sanitized ops should run and run them if so
    // It's possible that an unsanitized op SHOULD run (such as for a partial date) but the sanitized op wouldn't run.
    // For example, a first name should not be set to Ben if it is already set to Ben
    const opClass = getClassForOp(op)

    // Always run the op on the pending payload regardless of whether sanitized should run
    this.pendingPayload = runOp({
      op,
      schema,
      payload: this.pendingPayload,
      options: {
        ...merge({}, this._options, options),
        context,
        setValue: null // SetValue function shouldn't run as a result of this
      }
    })
    this.pendingOps = [
      ...this.pendingOps,
      op
    ]

    // Then sanitize the op
    const sanitizedOp = sanitizeOp({
      op,
      schema,
      options: {
        ...this._options,
        context
      }
    })

    const shouldRunSanitizedPending = opClass && opClass.shouldRun({
      value: get(this.sanitizedPendingPayload, op.key),
      op: sanitizedOp,
      schema
    })

    if (shouldRunSanitizedPending) {
      // Everything looks good, run the actual op
      this.sanitizedPendingPayload = runOp({
        op: sanitizedOp,
        schema,
        payload: this.sanitizedPendingPayload,
        options: {
          ...merge({}, this._options, options),
          context
        }
      })
      this.sanitizedPendingOps = [
        ...(this.sanitizedPendingOps || []),
        sanitizedOp
      ]
      this.emit('change-clean', this.getProps())
    }

    if (!options.skipChangeEvent) {
      this.emit('change', this.getProps())
      this.emit('change-dirty', this.getProps()) // TODO
      this.emit('change-pending', this.getProps())
    }
  }

  /**
   * Commit
   *
   * 1) Get latest ops and payload (including pending)
   * 2) All pending ops are sanitized and run on sanitizedPendingPayload
   * 3) set pendingPayload to a fresh copy of cleanPayload
   * 4) set sanitizedPendingPayload to a fresh copy of cleanPayload
   */
  commit () {
    this.cleanPayload = this.sanitizedPendingPayload
    this.cleanOps = [
      ...this.cleanOps,
      ...this.sanitizedPendingOps
    ]

    this.pendingPayload = cloneDeep(this.cleanPayload)
    this.pendingOps = []
    this.sanitizedPendingPayload = cloneDeep(this.cleanPayload)
    this.sanitizedPendingOps = []

    this.emit('change', this.getProps())
    this.emit('change-dirty', this.getProps())
    this.emit('change-clean', this.getProps())
  }

  getDoneOps () {
    return this.cleanOps || []
  }

  getDoneOpsCompacted (ops) {
    // This should replace any previous fields if there is a $set operation. It should also combine all
    // $set/$unset/$inc/$dec/$push/$pull/$pullAll operations into the minimum number of operations that need to be done
    const doneOps = ops || this.getDoneOps()
    const doneOpsToHandleNow = []
    const doneOpsAlreadyHandled = []
    const doneOpsToHandleRecursivelyByKeyPrefix = {}
    const compactedSetMap = {}
    const compactedUnsetMap = {} // stays empty
    const compactedTotalMap = {}
    const compactedListAddMap = {}
    const compactedListRemoveMap = {}
    const compactedListPullAllMap = {} // stays empty
    let outputOrder = []
    const opDestinationMap = {
      $set: compactedSetMap,
      $unset: compactedUnsetMap,
      $inc: compactedTotalMap,
      $dec: compactedTotalMap,
      $push: compactedListAddMap,
      $pull: compactedListRemoveMap,
      $pullAll: compactedListPullAllMap
    }
    const swapPushPullOps = (incoming, outgoing, key, value) => {
      outgoing[key] = (outgoing[key] || [])
      const addCountBeforeFilter = outgoing[key].length
      outgoing[key] = outgoing[key].filter((item) => item !== value)
      if (addCountBeforeFilter === outgoing[key].length) {
        incoming[key] = (incoming[key] || [])
        incoming[key].push(value)
      }
    }
    const opActionMap = {
      $set: (value, key) => {
        delete compactedTotalMap[key]
        delete compactedListAddMap[key]
        delete compactedListRemoveMap[key]
        compactedSetMap[key] = value
      },
      $unset: (value, key) => {
        delete compactedSetMap[key]
        delete compactedTotalMap[key]
        delete compactedListAddMap[key]
        delete compactedListRemoveMap[key]
      },
      $inc: (value, key) => { compactedTotalMap[key] = (compactedTotalMap[key] || 0) + value },
      $dec: (value, key) => { compactedTotalMap[key] = (compactedTotalMap[key] || 0) - value },
      $push: (value, key) => {
        swapPushPullOps(compactedListAddMap, compactedListRemoveMap, key, value)
      },
      $pull: (value, key) => {
        swapPushPullOps(compactedListRemoveMap, compactedListAddMap, key, value)
      },
      $pullAll: (value, key) => {
        delete compactedSetMap[key]
        delete compactedListAddMap[key]
        delete compactedListRemoveMap[key]
      }
    }
    const opsWhichReplaceAllOthersAtKey = ['$set', '$unset', '$inc', '$dec', '$pullAll']
    const opsWhichCanStackOnSet = ['$inc', '$dec']
    const objMultiOpSplit = '].'
    doneOps.forEach((item) => {
      if (item.key.includes(objMultiOpSplit)) {
        const keySplit = item.key.split(objMultiOpSplit)
        const keyPrefix = keySplit.shift() + objMultiOpSplit
        const keySuffix = keySplit.join(objMultiOpSplit)
        const prefixlessItem = cloneDeep(item)
        prefixlessItem.key = keySuffix
        doneOpsToHandleRecursivelyByKeyPrefix[keyPrefix] = doneOpsToHandleRecursivelyByKeyPrefix[keyPrefix] || []
        doneOpsToHandleRecursivelyByKeyPrefix[keyPrefix].push(prefixlessItem)
      } else {
        if (opsWhichReplaceAllOthersAtKey.includes(item.op)) {
          Object.keys(doneOpsToHandleRecursivelyByKeyPrefix).forEach((key) => {
            if (key.indexOf(item.key) === 0) {
              delete doneOpsToHandleRecursivelyByKeyPrefix[key]
            }
          })
        }
        doneOpsToHandleNow.push(item)
      }
    })
    if (doneOpsToHandleNow.length !== doneOps.length) {
      Object.entries(doneOpsToHandleRecursivelyByKeyPrefix).forEach(([prefix, ops]) => {
        this.getDoneOpsCompacted(ops).forEach((item) => {
          item.key = prefix + item.key
          doneOpsAlreadyHandled.push(item)
        })
      })
    }
    doneOpsToHandleNow.forEach((item) => {
      const op = item.op
      const action = opActionMap[op]
      const key = item.key
      if (action) {
        action(item.value, key)
        if (opsWhichReplaceAllOthersAtKey.includes(op)) {
          outputOrder = outputOrder.filter((field) => {
            const dontReplaceSet = opsWhichCanStackOnSet.includes(op)
            let result = field.indexOf(`${key}-$`) !== 0
            if (dontReplaceSet) {
              result = result || field.indexOf(`${key}-$set`) === 0
            }
            return result
          })
        } else {
          outputOrder = outputOrder.filter((item) => item !== `${key}-${op}`)
        }
        outputOrder.push(`${key}-${op}`)
      }
    })
    let merge = []
    outputOrder.forEach((field) => {
      const [key, op] = field.split('-')
      const destination = opDestinationMap[op]
      const value = destination[key]
      let result = { key, op, value }
      if (value instanceof Array && op !== '$set') {
        const template = result
        result = value.map((value) => ({ ...template, value }))
      }
      if (op === '$inc' || op === '$dec') {
        if (value >= 0) {
          result.op = '$inc'
        } else {
          result.op = '$dec'
          result.value = Math.abs(value)
        }
      }
      merge = merge.concat(result)
    })
    merge = merge.concat(doneOpsAlreadyHandled)
    return merge
  }
}

export default FieldOperator
