import Model from './Model'

class Widget extends Model {
  static entity = 'widgets'

  static fields () {
    return {
      _id: this.attr(undefined),
      name: this.attr(undefined),
      account_id: this.attr(undefined),
      active: this.attr(undefined),
      type: this.attr(undefined),
      settings: this.attr(undefined),
      last_hit_at: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

export default Widget
