import { TestDialog as SmsTestDialog } from 'ui/blocks/actions/SmsOut'
import { TestDialog as EmailTestDialog } from 'ui/blocks/actions/EmailOut'
import AccountNameWidget from 'ui/blocks/actions/SmsOut/Editor/AccountNameWidget.vue'
import QuickReplyDialog from 'portal/src/modules/Messenger/QuickReplyDialog.vue'
import FileManager from 'ui/pickers/FilePicker/FileManager.vue'
import { get, pick } from 'lodash'
import Vue from 'vue'

export default {
  computed: {
    accountNameWidgetWidth () {
      return ((this.renderedMessage?.accountNameAdded || '').length * 10) + 2 * 10 + 18 + 5 // 18 compensates for padding.  5 Moves it a little right
    }
  },
  methods: {
    onParse ({ cm }) {
      if (this.accountNameComponent) {
        this.accountNameComponent.$destroy()
      }
      if (!this.renderedMessage?.accountNameAdded) {
        if (this.accountNameBookmark) {
          this.accountNameBookmark.clear()
          this.accountNameBookmark = false
        }
        return
      }
      this.accountNameComponent = new Vue({
        parent: this,
        render: h => h(AccountNameWidget, {
          props: {}
        })
      }).$mount()

      this.accountNameBookmark = cm.setBookmark({
        line: 0,
        ch: 0
      }, {
        widget: this.accountNameComponent.$el
      })
    },
    async openFileManager () {
      if (this.attachments && this.attachments.length >= this.maxAttachments) {
        this.$alert(`You've reached the maximum of ${this.maxAttachments} attachment${this.maxAttachments !== 1 ? 's' : ''}`, { title: 'Not Allowed' })
        return
      }
      const result = await this.$openWindow({
        component: FileManager,
        props: {
          types: ['image', 'vcard']
        }
      })
      if (result && Object.keys(result).includes('value')) {
        if (result.value?.filename) {
          this.insertAttachment(result.value.filename)
        }
      }
    },
    selectQuickReply () {
      this.$openWindow({
        component: QuickReplyDialog,
        props: {
          linkToSettings: !!(process?.env?.VUE_APP_CLIENT_ID === 'PORTAL'),
          callback: (template) => {
            this.insertQuickReply(template)
          },
          tags: get(this.conversation, 'tags') || [],
          filteredTemplates: this.quickReplies
        }
      })
    },
    sendTest () {
      this.$openWindow({
        component: this.channel === 'SMS' ? SmsTestDialog : EmailTestDialog,
        props: {
          blockProps: pick(this.message, ['gateway_id', 'content', 'allow_multiple'])
        }
      })
    }
  }
}
