import blockTypes from './index'

export default (Vue) => {
  Vue.component('v-style', {
    render: function (createElement) {
      return createElement('style', this.$slots.default)
    }
  })

  Vue.component('v-comment', {
    render (createElement) {
      const el = createElement(null)
      el.text = this.$attrs.value
      return el
    }
  })

  blockTypes.forEach(blockType => Vue.component('eb-' + blockType.id, blockType.component))
}
