import component from './Divider'

export default {
  id: 'divider',
  name: 'Divider',
  icon: 'fas fa-arrows-alt-h',
  component,
  factory () {
    return {
      props: {
        width: 90,
        padding: '10px',
        borderTop: '1px #9e9e9e solid'
      }
    }
  }
}
