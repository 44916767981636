import store from '../store'

// Use this method for any lazily loaded components so the app's
// loading indicator will show while the chunk loads
export default function (fn) {
  return function () {
    store.commit('setAppChunkLoading', true)
    const promise = fn()
    promise.then(function () {
      store.commit('setAppChunkLoading', false)
    })
    return promise
  }
}
