import Model from './Model'

class CardObject extends Model {
  static entity = 'card_objects'

  static fields () {
    return {
      _id: this.attr(undefined),
      card_id: this.attr(undefined),
      date_range: this.attr(undefined),
      account_id: this.attr(undefined),
      data: this.attr(undefined),
      refresh_queued_at: this.attr(undefined),
      refresh_requested_at: this.attr(undefined),
      at: this.attr(undefined),
      dt: this.attr(undefined),
      empty: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }

  /**
   * Remove the account id from the _id if existing since it was necessary server-side for
   * uniqueness on the _id field.
   */
  static transformIncomingData ({ data }) {
    data = data.data || data

    if (!Array.isArray(data)) {
      data = [data]
    }

    return data.map(datum => {
      return {
        ...datum,
        _id: (datum._id + '').split('-')[1] || datum._id
      }
    })
  }
}

export default CardObject
