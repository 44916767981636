import accountName from './lib/accountName'
import getMessageUrls from './lib/getMessageUrls'
import { render, analyze as analyzeSmartText } from 'shared/smart-text'
import get from 'lodash/get'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import truncate from './lib/truncate'
import emailAppendsPrepends from './lib/emailAppendsPrepends'

// Variable/URL replacements only happen on these keys
export const replaceKeyTypes = {
  SMS: ['from', 'text'],
  EMAIL: ['from', 'subject', 'preview_text', 'html', 'text', 'cc', 'bcc']
}

// Only these keys are passed through to the message log
export const keyTypes = {
  SMS: ['text', 'attachments'],
  EMAIL: ['subject', 'preview_text', 'html', 'text', 'cc', 'bcc', 'attachments']
}

// Returns test@email.com when "Test Email <test@email.com>" is passed in
const getEmailFromString = (str) => {
  const emails = (str || '').trim().match(/[^@<\s]+@[^@\s>]+/g)
  if (emails) {
    return emails[0]
  }
  return ''
}

export default ({
  account = null, // Optional, required for some things
  gateway,
  content,
  payload = {},
  smartTextOptions,
  eventLogId, // Mainly used to generate message unsubscribe/archive/track urls in emails so prereq
  allowMultiple = false,
  analyze = false,
  emailAlias = null
}) => {
  // Add message urls to payload
  payload = {
    ...payload,
    ...getMessageUrls({
      messageId: eventLogId,
      contactId: payload && payload.contact && payload.contact._id
    })
  }

  smartTextOptions = {
    ...(smartTextOptions || {}),
    timezone: get(smartTextOptions, 'timezone') || get(account, 'settings.timezone'),
    smartlinkDomain: get(smartTextOptions, 'smartlinkDomain') || get(account, 'settings.smartlink_domain') || 'c-g.co',
    loyalty: account?.settings?.loyalty // This exists to allow config to convert punchcard to loyalty cash $ to be available
  }

  // Apply gateway append/prepends to EMAIL gateways
  if (get(gateway, 'channel') === 'EMAIL') {
    if (content.html) {
      /**
       * If an unsubscribe url exists for this message and it's not specified to ignore it on the payload and
       * the message doesn't have the _url_unsubscribe smart text, add html that contains the smart text right before the closing body tag
       */
      const htmlHasUnsubscribeSmartText = /(\{{2}(.*?)_url_unsubscribe(.*?)\}{2})/g.test(content.html || '')
      if (payload._url_unsubscribe && !payload._html_has_unsubscribe && !htmlHasUnsubscribeSmartText) {
        content = {
          ...content,
          html: content.html.includes('</body>')
            // Add it to the end of the message if there is a closing body tag
            ? content.html.replace('</body>', emailAppendsPrepends.html.unsubscribe + '</body>')
            // If no closing body tag, append it to the html since it will all then be wrapped in a body tag
            : `${content.html}${emailAppendsPrepends.html.unsubscribe}`
        }
      }

      /**
       * Add the tracker image url right before the closing body tag.
       */
      if (content.html && !smartTextOptions?.preview) {
        const trackerTag = '<img width="1" height="1" src="{{ _url_tracker_image }}">'
        content = {
          ...content,
          html: content.html.includes('</body>')
            // Add it to the end of the message if there is a closing body tag
            ? content.html.replace('</body>', trackerTag + '</body>')
            // If no closing body tag, append it to the html since it will all then be wrapped in a body tag
            : `${content.html}${trackerTag}`
        }
      }

      /**
       * If there is no body tag then wrap the email in CityGro's wrapper HTML
      */
      if (!content.html.includes('<body')) {
        content = {
          ...content,
          html: emailAppendsPrepends.html.wrap(content.html)
        }
      }
    }

    if (content.text) {
      /**
       * If text does not contain unsubscribe link
       * Then append unsubscribe verbiage + smart text at the end of the text
       */
      const textHasUnsubscribeSmartText = /(\{{2}(.*?)_url_unsubscribe(.*?)\}{2})/g.test(content.text || '')
      if (payload._url_unsubscribe && !payload._html_has_unsubscribe && !textHasUnsubscribeSmartText) {
        content.text = content.text += emailAppendsPrepends.text.unsubscribe
      }
    }
  }

  // Interpolate message contents
  let analysis = {};
  (replaceKeyTypes[get(gateway, 'channel') || 'SMS'] || []).forEach(async (contentPartKey) => {
    content = {
      ...content,
      [contentPartKey]: content[contentPartKey] || ''
    }

    if (analyze) {
      analysis[contentPartKey] = analyzeSmartText(content[contentPartKey], smartTextOptions)
    }
    content[contentPartKey] = render(content[contentPartKey], payload, smartTextOptions)
  })

  if (analyze) {
    analysis = {
      ...analysis,
      variables: uniq(flatten(Object.values(analysis).map(a => (a.variables || []).map(b => b.variable || '')))).filter(v => !!v)
    }
  }

  // Ensure account name exists in message for shared SMS gateways
  // This won't be necessary in the future when all accounts have their own gateway
  let accountNameAdded
  if (!get(payload, '_skip_account_name')) {
    const accountNameResult = accountName.addIfNeeded({
      account,
      gateway,
      text: content.text
    })
    content = {
      ...content,
      text: accountNameResult.text
    }
    accountNameAdded = accountNameResult.accountNameAdded
  }

  let from = content.from
  if (get(gateway, 'channel') === 'SMS') {
    from = gateway.address // Mandatory
  } else if (get(gateway, 'channel') === 'EMAIL') {
    // If there's an account then default to account.{id}, otherwise no-reply at the domain name
    if (
      !from ||
      getEmailFromString(from).toLowerCase().split('@')[1] !== (gateway.address || '').toLowerCase().replace('@', '')
    ) {
      from = `"No Reply" <no-reply@${(gateway.address || '').replace('@', '')}`
      let alias = 'no-reply'
      if (account) {
        alias = `account.${account._id}`
      }
      if (emailAlias && emailAlias.gateway_id === gateway._id) {
        alias = emailAlias.alias
      }
      alias = alias.replace(/ /g, '')
      if (emailAlias) {
        from = `"${(emailAlias.name || emailAlias.alias || 'No Reply').replace(/"/g, '\\"')}" <${alias}@${gateway.address.replace('@', '')}>`
      } else if (account) {
        from = `"${(account.display_name || account.name || '').replace(/"/g, '\\"')}" <${alias}@${gateway.address.replace('@', '')}`
      }
      content = {
        ...content
      }
    }
  }

  // Remove empty and non-valid keys from content
  Object.keys(content).forEach((key) => {
    if ((!keyTypes[get(gateway, 'channel') || 'SMS'].includes(key) || !content[key] || (Array.isArray(content[key]) && !content[key].length))) {
      delete content[key]
    }
  })

  let truncated = false
  let cost = 1
  let truncation

  // Get cost of message (one per segment) if gateway has segment parameters
  if (get(gateway, 'settings.segments')) {
    const isMms = gateway && gateway.channel === 'SMS' && content.attachments && content.attachments.length
    truncation = truncate({
      text: content.text || '',
      gateway,
      allowMultiple,
      isMms
    })
    truncated = truncation.originalLength !== truncation.truncatedLength
    content.text = truncation.text
    cost = truncation.cost
  }

  return {
    content,
    payload,
    cost,
    from,
    accountNameAdded,
    truncated,
    truncation,
    analysis
  }
}
