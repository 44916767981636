import Trigger from '../Trigger'

class ChatIn extends Trigger {
  static get title () {
    return 'Incoming Textchat'
  }

  static get class () {
    return 'ChatIn'
  }

  static get restrict () {
    return { 'addons.type': 'MESSENGER' } // Eventually want to add a "TextChat" module for this
  }

  // These are deprecated in favor of shared/schemas/events/*
  get schemas () {
    return [
      { key: 'from', name: 'From (phone number)', type: 'text' },
      { key: 'content.text', name: 'Message text', type: 'text' },
      { key: 'meta.chat_url', name: 'Page URL', type: 'text' }
    ]
  }
}

export default ChatIn
