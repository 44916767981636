import EventBase from './EventBase'
import { Sequence, User } from 'ui/models'
import EventViewer from 'ui/viewers/EventViewer'
import EventTypes from 'shared/schemas/events'
import { startCase } from 'lodash'
import { getContactFriendlyName } from 'shared/util/friendlyName'

export default {
  components: {
    EventBase
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    contact: { // It's more efficient to just pass this in rather than query for it
      type: Object,
      default: () => ({})
    },
    verboseNames: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    friendlyEventName () {
      const eventType = EventTypes[this.event.event]
      let name = startCase(this.event.event || '')
      if (eventType) {
        name = eventType.name
      }
      if (this.verboseNames) {
        return this.friendlyContactName + ' ' + name
      }
      return name
    },
    friendlyContactName () {
      return getContactFriendlyName(this.contact)
    },
    eventData () {
      return (this.event && this.event.data) || {}
    },
    timeObject () {
      if (!(this.event && this.event.at)) {
        return ''
      }
      return this.$moment(this.event.at)
    },
    time () {
      if (this.timeObject) {
        return this.timeObject.format('h:mm a')
      }
      return ''
    },
    datetime () {
      if (this.timeObject) {
        return this.timeObject.format('ddd MMM Do, YYYY h:mm a')
      }
      return ''
    },
    sequence () {
      if (!this.event.sequence_id) {
        return null
      }
      return Sequence.find(this.event.sequence_id)
    },
    user () {
      if (this.eventData.user_id) {
        return User.find(this.eventData.user_id)
      } else if (this.eventData.system) {
        return {
          first_name: 'Patch',
          last_name: 'System',
          icon: 'fa-robot'
        }
      }
      return null
    }
  },
  methods: {
    openEventDetails (eventId) {
      this.$openWindow({
        component: EventViewer,
        props: {
          eventId: eventId || this.event._id
        }
      })
    }
  }
}
