import { File } from 'ui/models'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // List of attachments that have been uploaded (for now this just happens via paste, but
      // perhaps an the library and upload buttons will be broken up in the future)
      attachmentsPending: []
    }
  },
  computed: {
    // This will generally be a combination of whether the MMS module exists and the gateway can handle MMS
    allowAttachments () {
      console.warn('PasteAttachmentMixin: Override computed.allowAttachments')
      return false
    },
    attachments () {
      console.warn('PasteAttachmentMixin: Override computed.attachments')
    },
    maxAttachments () {
      console.warn('PasteAttachmentMixin: Override computed.maxAttachments')
      return 0
    }
  },
  methods: {
    async pasteAttachment ({ url }) {
      if (this.disabled) {
        return
      }
      if (!this.allowAttachments) {
        this.$alert('Attachments are not allowed.', { title: 'Not Allowed' })
        return
      }
      if (this.attachments && this.attachments.length >= this.maxAttachments) {
        this.$alert(`You've reached the maximum of ${this.maxAttachments} attachment${this.maxAttachments !== 1 ? 's' : ''}`, { title: 'Not Allowed' })
        return
      }
      if (
        this.attachmentsPending.find(a => a.url === url && !a.deleted)
      ) {
        this.$alert('This attachment has already been added.')
        return // Don't allow same base64 to be uploaded twice
      }
      const file = {
        filename: url
      }
      const attachment = {
        loading: false,
        error: null,
        file,
        url // Used to prevent same base64 from being pasted twice
      }
      this.attachmentsPending.push(attachment)
      this.uploadAttachment(attachment)
    },
    async uploadAttachment (attachment) {
      // Persist the file
      this.$set(attachment, 'error', null)
      this.$set(attachment, 'loading', true)
      try {
        attachment.file = (await File.save({
          ...attachment.file,
          library: false // After old portal is removed I can default this to false
        })).entities.files[0]
        this.insertAttachment(attachment.file.filename)
        this.$set(attachment, 'loading', false)
      } catch (e) {
        this.$set(attachment, 'error', e)
        this.$set(attachment, 'loading', false)
        throw e
      }
    }
  },
  insertAttachment (filename) {
    console.warn('PasteAttachmentMixin: Override methods.insertAttachment', filename)
  }
}
