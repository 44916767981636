import Action from '../Action'
import { get } from 'lodash'
import colors from 'shared/colors'

class ReportCreate extends Action {
  static get title () {
    return 'Create Report'
  }

  static get class () {
    return 'ReportCreate'
  }

  static get color () {
    return colors.success
  }

  // static get restrict () {
  //   return { 'user.level': { $gte: 9 } }
  // }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []
    const reportId = get(block, 'props.report_id')
    if (!reportId) {
      errors.push({
        message: 'Report is required.',
        path: 'report_id'
      })
    }
    return super.getErrors(environment).concat(errors)
  }
}

export default ReportCreate
