import { filterSchemas } from 'shared/schemas'
import { fromPairs } from 'lodash'
import { legacySchemasToNew } from 'shared/schemas/legacy'

export const simplifyContactPaths = (schemas) => {
  return fromPairs(
    Object.entries(schemas)
      .map(([path, schema]) => [
        path.replace('contact.', ''),
        schema
      ])
  )
}

export default {
  getters: {
    contactSchemas (state, getters) {
      let contactSchemas = filterSchemas(
        getters.schemas || {},
        (path, schema) => path.startsWith('contact.')
      )

      const objectSchemas = Object.values(contactSchemas)
        .filter(schema => schema.type === 'object' && schema.schemas && schema._id)

      objectSchemas.forEach(field => {
        // Subfields for a schema should be converted into absolute path format
        contactSchemas = {
          ...contactSchemas,
          ...legacySchemasToNew(field.schemas.filter(schema => isNaN(schema.key)), `contact.${field.key}`)
        }
      })

      return contactSchemas
    },
    filterableContactSchemas (state, getters) {
      const contactSchemas = getters.contactSchemas
      return filterSchemas(contactSchemas, (path, schema) => (
        !schema.unselectable &&
        !schema.hidden && // Remove any "hidden" fields
        !schema.path.startsWith('contact._files') && // Don't show these fields
        !schema.path.startsWith('contact.claims') && // Don't show these fields
        !schema.path.startsWith('contact.redemptions') // Don't show these fields
      ))
    },
    contactSchemasWithoutObjects (state, getters) {
      const contactSchemas = getters.filterableContactSchemas
      return filterSchemas(contactSchemas, (path, schema) => (
        (schema.type !== 'object')
      ))
    },
    mutableContactSchemas (state, getters) {
      const contactSchemas = getters.filterableContactSchemas
      return filterSchemas(contactSchemas, (path, schema) => (
        !schema.immutable
        // TODO: eventually support objects, and later, nested objects
      ))
    },
    // DEPRECATED
    // DON'T USE THIS! WE SHOULD NEVER SIMPLIFY PATHS
    // WE SHOULD UPGRADE "whatever" to "contact.whatever" whenever needed
    mutableContactSchemasWithSimplifiedPaths (state, getters) {
      const contactSchemas = getters.mutableContactSchemas
      return simplifyContactPaths(
        filterSchemas(contactSchemas, (path, schema) => (
          (schema.type !== 'object')
        ))
      )
    }
  }
}
