// Accepts 125 seconds and converts to 2 min 5 sec
// Accepts 3665 seconds and converts to 1 hour 1 min (ignoring 5 seconds)
const secondsToText = (seconds = 0, { long = false } = {}) => {
  const days = Math.floor(seconds / (24 * 60 * 60))
  seconds -= (days * 24 * 60 * 60)
  const hours = Math.floor(seconds / (60 * 60))
  seconds -= (hours * 60 * 60)
  const minutes = Math.floor(seconds / 60)
  seconds -= (minutes * 60)
  const parts = [
    {
      value: days,
      name: 'day'
    },
    {
      value: hours,
      name: 'hour'
    },
    {
      value: minutes,
      name: 'minute'
    },
    {
      value: seconds,
      name: 'second'
    }
  ]
  const returnParts = parts.filter(part => part.value > 0)
    .map(part => {
      let name = part.name
      if (part.value !== 1) {
        name += 's'
      }
      return `${Math.round(part.value)}${long ? (' ' + name) : name[0]}`
    })
    .slice(0, 2)

  if (!returnParts.length) {
    if (long) {
      return '0 seconds'
    }
    return '0s'
  }

  return returnParts.join(' ')
}

export default secondsToText
