import Action from '../Action'
import colors from 'shared/colors'

class ShopifyCreateOrder extends Action {
  static get title () {
    return 'Create Order'
  }

  static get class () {
    return 'ShopifyCreateOrder'
  }

  static get restrict () {
    return { 'addons.type': 'SHOPIFY', 'account.settings.address.country': 'US' }
  }

  static get color () {
    return colors.maroonRed
  }

  static getDefaultProps () {
    return {
      free: true,
      send_receipt: false
    }
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { block } = environment
    const errors = []
    const variantId = block?.props?.line_items?.[0]?.variant_id
    if (!variantId) {
      errors.push({
        message: 'No product/variant selected.',
        path: 'variant_id'
      })
    }
    return super.getErrors(environment).concat(errors)
  }
}

export default ShopifyCreateOrder
