import Model from './Model'
import Account from './Account'

export default class AccountUser extends Model {
  static entity = 'account_users'

  static fields () {
    return {
      _id: this.attr(undefined),
      _uid: this.attr(''),
      _aid: this.attr(''),
      first_name: this.attr(''),
      last_name: this.attr(''),
      email: this.attr(''),
      phone: this.attr(''),
      account: this.hasOne(Account, '_id', '_aid'),
      level: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      last_seen_at: this.attr(undefined),
      modules: this.attr(undefined),
      owner: this.attr(undefined),
      alerts: this.attr(undefined)
    }
  }
}
