import { fromPairs } from 'lodash'

const fonts = [
  {
    id: 'Arial', // all
    family: 'Arial, sans-serif'
  },
  {
    id: 'Bebas Neue',
    family: 'Bebas Neue, sans-serif',
    google: 'Bebas+Neue'
  },
  {
    id: 'Caveat',
    family: 'Caveat, cursive, serif',
    google: 'Caveat:wght@100;300;400;600;700'
  },
  {
    id: 'Comic Sans MS', // win/mac
    family: 'Comic Sans MS, Comic Sans, sans-serif'
  },
  {
    id: 'Courier New', // all
    family: 'Courier New, monospace, serif'
  },
  {
    id: 'Dancing Script',
    family: 'Dancing Script, cursive, serif',
    google: 'Dancing+Script:wght@100;300;400;600;700'
  },
  {
    id: 'Georgia', // win/mac
    family: 'Georgia, serif'
  },
  {
    id: 'Helvetica', // all
    family: 'Helvetica, sans-serif'
  },
  // {
  //   id: 'Impact' // win/mac
  // },
  {
    id: 'Lato',
    family: 'Lato, sans-serif',
    google: 'Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700'
  },
  {
    id: 'Montserrat',
    family: 'Montserrat, sans-serif',
    google: 'Montserrat:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700'
  },
  {
    id: 'Nunito',
    family: 'Nunito, sans-serif',
    google: 'Nunito:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700'
  },
  {
    id: 'Open Sans',
    family: 'Open Sans, sans-serif',
    google: 'Open+Sans:ital,wght@0,100;0,300;0,400;0,600;0,700;1,100;1,300;1,400;1,600;1,700'
  },
  {
    id: 'Poiret One',
    family: 'Poiret One, sans-serif',
    google: 'Poiret+One'
  },
  {
    id: 'Raleway',
    family: 'Raleway, sans-serif',
    google: 'Raleway:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700'
  },
  {
    id: 'Roboto',
    family: 'Roboto, sans-serif',
    google: 'Roboto:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700'
  },
  // {
  //   id: 'Rubik',
  //   google: 'Rubik:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700'
  // },
  {
    id: 'Times New Roman', // all
    family: 'Times New Roman, serif'
  },
  // {
  //   id: 'Trebuchet MS' // win/mac
  // },
  {
    id: 'Verdana', // win/mac
    family: 'Verdana, sans-serif'
  }
]

const fontFamilyMap = fromPairs(fonts.map(
  (font) => {
    return [font.id, font.family]
  }
))

const getFontLinkUrl = (fonts = []) => {
  const googleFonts = fonts.filter(font => !!font.google)

  if (!googleFonts.length) {
    return null
  }
  const queryParams = googleFonts.map(font => `family=${font.google}`).join('&')
  return `https://fonts.googleapis.com/css2?${queryParams}&display=swap`
}
export default fonts
export {
  getFontLinkUrl,
  fontFamilyMap
}
