<template>
  <div>
    <div
      class="text-center"
      style="font-size: 30px; line-height: 34px"
    >
      <strong>Welcome to Patch!</strong>
    </div>
    <div
      class="text-center mt-2 mb-6"
      style="font-size: 16px; line-height: 18px;"
    >Complete the information below to create your free account and start patching your leaky bucket!</div>
    <v-card
      class="pa-7"
    >
      <v-btn
        v-if="$restrict({ 'user.level': { $gte: 5 } })"
        icon
        class="mb-3"
        @click="$store.commit('setSignupOverride', true)"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
      <v-form
        @submit.prevent="$emit('next')"
      >
        <v-row>
          <v-col
            :cols="12"
          >
            <v-text-field
              label="Company Name"
              :value="account.name"
              placeholder="Company 1"
              @input="$emit('change', ['account', 'name', $event])"
              hide-details
              v-bind="allFieldProps"
            />
          </v-col>
          <v-col
            :cols="12"
          >
            <v-text-field
              label="Website"
              :value="account.settings && account.settings.website"
              placeholder="www.website.com"
              @input="$emit('change', ['account', 'settings.website', $event])"
              hide-details
              v-bind="allFieldProps"
            />
          </v-col>
        </v-row>
        <div
          class="d-flex align-center mt-4"
        >
          <div
            v-if="!valid"
            class="error--text"
          >All fields are required.</div>
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            :loading="disabled"
            :disabled="!valid"
          >Continue</v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
    account: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    allFieldProps () {
      return {
        outlined: true,
        dense: true,
        disabled: this.disabled
      }
    },
    valid () {
      return !!this.account?.name &&
        !!this.account?.settings?.website
    }
  }
}
</script>
