import BaseField from './_BaseField'
import strtotime from './lib/strtotime'
import moment from 'moment-timezone'
import get from 'lodash/get'

class Timestamp extends BaseField {
  static sanitize ({ value, options }) {
    let timezone = get(options, 'timezone') || 'UTC'
    timezone = moment.tz.zone(timezone) ? timezone : 'UTC'

    // This method should return either ISO8601 Z zone or null
    if (typeof value === 'object' && (value instanceof Date || value instanceof moment)) {
      value = moment(value).utc().format()
    }
    if (!(typeof value === 'string' && value)) {
      return null
    }

    // If it's in ISO8601 format, evaluate the timestamp in UTC
    // This takes into account a timezone offset within the timestamp
    // TODO refactor common regex into a shared module
    if ((value + '').match(/^(\d{4}-\d\d-\d\d([tT][\d:.]*){1})([zZ]|([+-])(\d\d):?(\d\d))?$/)) {
      value = moment.tz(value, 'UTC')
    } else if (value.trim().toLowerCase() === 'now') {
      value = moment()
    } else {
      // If it's not ISO8601, strtotime evaluates in local time, which really sucks
      // Most formats that strtotime would evaluate in UTC (ISO8601 style) are caught by the above regex
      value = strtotime(value)
      if (value === false) { // If the format could not be parsed
        return null
      }

      value *= 1

      // Apply env offset so the TS is for sure in UTC...server-side this will be UTC and will not affect
      value += moment.unix(value).utcOffset() * 60
      // Apply the timezone offset for the current account
      value -= moment.unix(value).tz(timezone).utcOffset() * 60
      value = moment.unix(value)
    }

    if (!value.isValid()) {
      return null
    }

    return value.utc().toDate()
  }

  // This method converts the string / object date to an ISO string in the provided timezone or UTC
  static unsanitize ({ value, options }) {
    let timezone = get(options, 'timezone') || 'UTC'
    timezone = moment.tz.zone(timezone) ? timezone : 'UTC'

    if (value && (typeof value === 'string' || typeof value === 'object')) {
      return moment(value).tz(timezone).toISOString(timezone !== 'UTC')
    }
    return value
  }
}

export default Timestamp
