import EventLogFiltered from './EventLogFiltered'
import AccountOverview from './AccountOverview'

const reportTypes = {
  EventLogFiltered: {
    name: 'Filtered Event Log',
    editor: EventLogFiltered
  },
  AccountOverview: {
    name: 'Account Overview',
    editor: AccountOverview,
    restrict: { 'user.level': { $gte: 5 } }
  }
}

export default reportTypes
