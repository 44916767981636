import { fromPairs, set } from 'lodash'

// This mixin is designed to read from the query string on vm create as well as
// watch all specified properties and update the query string in the router accordingly

// It's possible to provide a "namespace" for QS properties to prevent them from
// colliding with properties from other components

// Props should consist of:
// {
//   // The VM path to create the watcher for, should resolve to a scalar with .toString()
//   path: '',
//   // (optional) Name of Query String param, defaults to path
//   name: '',
//   // (optional), Default value where Query String param will be removed
//   // if value at path is a string equivalent.
//   default: ''
// }

export default (
  props = [], // Array of properties on vm to keep in sync with / pre-fill on load from the query string
  {
    namespace = null
  }
) => {
  return {
    watch: {
      ...fromPairs(props.map(prop => {
        let { path, name } = prop
        if (namespace) {
          name = `${namespace}.${name}`
        }
        return [
          path,
          {
            handler: function (val, oldVal) {
              if (prop.default && val && val + '' === prop.default + '') {
                val = undefined
              }
              if (!val) {
                val = undefined
              }
              if (prop.stringify && typeof prop.stringify === 'function') {
                val = prop.stringify(val)
              }
              this.$router.replace({
                query: {
                  ...this.$route.query,
                  [name]: val
                }
              }).catch(e => {
                if (e.name !== 'NavigationDuplicated') {
                  throw e
                }
              })
            }
          }
        ]
      }))
    },
    created () {
      props.forEach(prop => {
        let { path, name } = prop
        if (namespace) {
          name = `${namespace}.${name}`
        }
        let value = this.$route.query[name]
        if (value) {
          if (prop.default && value && value + '' === prop.default + '') {
            // Unset the route param since it's the default
          } else {
            if (typeof prop.transform === 'function') {
              value = prop.transform(value)
            }
            if (value) {
              set(this, path, value)
            }
          }
        }
      })
    },
    beforeDestroy () {
      // Remove all props from the query string
    }
  }
}
