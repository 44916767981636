import { get } from 'lodash'

export const applyAccountGuardToRouter = (store, router, rehydrationPromiseCreator) => {
  let readyPromiseResolve
  router.readyPromise = new Promise(resolve => (readyPromiseResolve = resolve))

  let rehydrationResolved = false
  let routerInitialized = false

  const detectAccountChange = (to, from) => {
    const fromId = '' + (get(from, 'params.accountId') || '')
    const toId = '' + (get(to, 'params.accountId') || '')
    return !!(
      (toId || fromId) &&
      toId !== fromId
    )
  }

  router.afterEach(async (to, from) => {
    if (routerInitialized) {
      try {
        const accountHasChanged = detectAccountChange(to, from)
        const shouldBootstrap = (
          accountHasChanged &&
          store.getters.user &&
          store.getters.user._id
        )
        if (shouldBootstrap) {
          await store.dispatch('rootBootstrap')
        }
        if (accountHasChanged) {
          await store.dispatch('afterSelectAccount', store.getters?.accountId || store.state?.accountId)
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      // Causes nothing to show in UI until router is initialized which yields the
      // account id from the url needed for most requests
      // This is a bit of a silly way to ensure that the router is initialized
      // before starting the app up but I don't know of a better one.  We need the accountId
      // route param for all bootstrap requests.
      routerInitialized = true
      readyPromiseResolve()
    }
  })

  router.beforeEach(async (to, from, next) => {
    // console.log('router.beforeEach: to', to)
    if (
      rehydrationPromiseCreator &&
      !rehydrationResolved
    ) {
      // don't allow any route navigationGuards to run until
      // our store.state has some gatorate before the performance
      await rehydrationPromiseCreator(to)
      rehydrationResolved = true
    }
    if (
      (get(to, 'params.accountId') && isNaN(to.params.accountId * 1))
    ) {
      return next({ path: '/' })
    }
    // If we're switching contexts
    const accountHasChanged = detectAccountChange(to, from)
    if (accountHasChanged) {
      await store.dispatch('empty')
    }

    next()
  })
}
