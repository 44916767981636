<template>
  <div class="contact-automations elevation-1">
    <v-simple-table
      dense
    >
      <thead>
        <tr>
          <th
            colspan="2"
          >Active Automations</th>
          <th>
            <v-btn
              small
              icon
              :disabled="loading"
              @click="loadData"
              class="float-right"
            >
              <v-icon small>fa-redo</v-icon>
            </v-btn>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="loading"
        >
          <td
            colspan="3"
            class="pa-2 text-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </td>
        </tr>
        <tr
          v-else-if="!sequenceObjects.length"
        >
          <td colspan="3" class="text-center pa-2">
            This contact has not been added to any automations.
          </td>
        </tr>
        <template
          v-for="{ sequence, blockObjects } in sequenceObjects"
        >
          <tr
            :key="sequence._id"
          >
            <td colspan="2">
              <div class="pa-2">
                {{ (sequence && (sequence.name || sequence._id)) || '?' }}
              </div>
            </td>
            <td
              class="text-right"
            >
              <div class="pa-2">
                &nbsp;
              </div>
            </td>
          </tr>
          <tr
            v-for="{ block, title, description, definition, blockTasks } in blockObjects"
            :key="sequence._id + '-' + block._id"
          >
            <td width="40">
              <block-icon
                :definition="definition"
                :size="40"
              />
            </td>
            <td class="pa-0">
              <div class="pa-2 pl-0">
                {{ title }}
                <div
                  style="font-size: 12px;opacity: 0.7"
                >
                  <div>{{ description }}</div>
                  <div
                    v-if="blockTasks.length"
                  >
                    <div v-if="blockTasks.length === 1 && (timeIsVeryClose(blockTasks[0].run_at) || blockTasks[0].status === 'RUNNING')">Running...</div>
                    <div
                      v-else-if="blockTasks.length === 1 && blockTasks[0].status === 'ERROR'"
                      class="error--text"
                    >{{ blockTasks[0].error || 'Unknown error'}}</div>
                    <div v-else>
                      Resumes On {{ blockTasks.filter(t => !!t.run_at).map(t => $timestamp(t.run_at)).join(', ') }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td
              class="text-right"
            >
              <div
                style="display: inline-block; white-space: nowrap;"
              >
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      :to="{ name: 'AutomationEditor', params: { sequenceId: sequence._id } }"
                      target="_blank"
                    >
                      <v-icon small>fas fa-external-link-alt</v-icon>
                    </v-btn>
                  </template>
                  <div>Open Automation</div>
                </v-tooltip>
                <v-btn
                  v-if="blockTasks.length && block.class === 'Pause'"
                  color="primary"
                  outlined
                  small
                  :disabled="loading || !block.active"
                  @click="eject({ block })"
                >Eject</v-btn>
                <v-btn
                  v-if="customBlocks.map(b => b._id).includes(block._id)"
                  class="ml-2"
                  color="primary"
                  small
                  :disabled="loading || !block.active || startingBlockIds.includes(block._id)"
                  @click="start({ block, sequence })"
                >{{ startingBlockIds.includes(block._id) ? 'Starting...' : 'Start' }}</v-btn>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { Block, BlockTask, Sequence } from 'ui/models'
import { flatten, get } from 'lodash'
import { getDefinition } from 'shared/blocks'
import { BlockIcon } from 'ui/blocks'
import moment from 'moment'

export default {
  mixins: [
    Block.mixin({
      references: ['blocks']
    }),
    Sequence.mixin({
      references: ['sequences']
    }),
    BlockTask.mixin({
      references: ['blockTasks']
    })
  ],
  components: {
    BlockIcon
  },
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      startingBlockIds: [],
      timeouts: []
    }
  },
  computed: {
    sequenceObjects () {
      const sequences = Sequence.query()
        .where(r =>
          // Sequences that have a block task
          this.blockTasks.map(t => t.sequence_id).includes(r._id) ||
          // Sequences that have a custom block that can be triggered
          this.customBlocks.map(b => b.sequence_id).includes(r._id)
        ).get()
      return sequences.map(sequence => {
        return {
          sequence,
          blockObjects: Block.query()
            .where(b =>
              b.sequence_id === sequence._id &&
              this.blockTasks
                .filter(t => t.sequence_id === sequence._id)
                .map(t => t.block_id) // Blocks that have a block task
                .concat(this.customBlocks.map(cb => cb._id)) // Custom blocks
                .includes(b._id)
            )
            .get()
            .map(block => {
              const definition = getDefinition(block.class)
              const description = block.name || (definition && definition.getDescription({
                sequence,
                blocks: Block.query().where(b => b.sequence_id === sequence._id).get(),
                block,
                vm: this
              }))

              return {
                block,
                title: (definition && definition.title) || ((block.class || '') + '?'),
                description,
                definition,
                blockTasks: this.blockTasks.filter(t => t.block_id === block._id)
              }
            })
        }
      })
    },
    customBlocks () {
      return Block.query()
        .where(r => r.class === 'Custom' && r.props && !!r.props.event && !!r.props.contact_editor)
        .get()
    },
    blocks () {
      return flatten(
        this.sequenceObjects
          .map(
            o => o.blockObjects.map(bo => bo.block)
          )
      )
    },
    sequences () {
      return this.sequenceObjects.map(o => o.sequence).filter(r => !!r)
    },
    blockTasks () {
      return BlockTask.query()
        .where(r => r.contact_id + '' === this.contactId + '')
        .get()
    }
  },
  created () {
    this.loadData()
  },
  beforeDestroy () {
    this.timeouts.map(t => clearTimeout(t))
  },
  methods: {
    timeIsVeryClose (t) {
      return t && moment(t).isBefore(moment().add(1, 'minute'))
    },
    async loadData () {
      BlockTask.delete(r => r.contact_id === this.contactId)
      await this.$manageLoading('loading', Promise.all([
        Block.fetchMany({
          params: {
            class: 'Custom',
            active: true
          }
        }),
        BlockTask.fetchMany({
          params: {
            contact_id: this.contactId
          }
        })
      ]))
    },
    async start ({ block, sequence }) {
      const requestStart = ({ force = null } = {}) => this.$http({
        method: 'POST',
        url: '/v2/contacts/trigger',
        data: {
          contact_id: this.contactId,
          block_id: block._id,
          ...(force ? { force: true } : {})
        }
      })
      const response = await this.$manageLoading('loading', requestStart())
      if (get(response, 'data.data.require_force')) {
        const response = await this.$confirm('This contact has already started this Automation.  How do you want to continue?', {
          title: 'Contact already started',
          buttons: [
            {
              name: 'Start Anyway',
              value: 'start'
            },
            {
              name: 'Eject & Start',
              value: 'eject-start'
            },
            {
              name: 'Do nothing',
              value: 'nothing'
            }
          ]
        })
        if (response === 'nothing') {
          return
        }
        if (response === 'eject-start') {
          await this.eject({ sequence, force: true })
        }
        if (response && response.includes('start')) {
          await requestStart({ force: true })
        }
      }
      this.startingBlockIds.push(block._id)
      // Give it a chance to process the event
      const timeout = setTimeout(() => {
        this.timeouts = this.timeouts.filter(t => t !== timeout)
        this.startingBlockIds = this.startingBlockIds.filter(b => b !== block._id)
        this.loadData()
      }, 10000)
      this.timeouts.push(timeout)
    },
    async eject ({ block, sequence, force = null }) {
      if (!get(block, '_id') && !get(sequence, '_id')) {
        return
      }
      const type = block ? 'block' : 'sequence'
      if (!force && (await this.$confirm(`Are you sure you want to Eject this contact from this ${type}?`)) !== 'Yes') {
        return
      }
      await this.$manageLoading('loading',
        this.$http({
          method: 'POST',
          url: '/v2/contacts/eject',
          data: {
            contact_id: this.contactId,
            ...(type === 'block' ? { block_id: block._id } : { sequence_id: sequence._id })
          }
        })
      )
      BlockTask.delete(r =>
        r.contact_id === this.contactId &&
        (
          (type === 'block' && r.block_id === block._id + '') ||
          (type === 'sequence' && r.sequence_id === sequence._id + '')
        )
      )
    }
  }
}
</script>
