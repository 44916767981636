import get from 'lodash/get'
import { schemaForPath } from 'shared/field-ops'

import fieldTypes from '../fieldTypes'

const buildQueryRule = (rule, options) => {
  let path = ((rule[0] || '') + '').trim() // Always convert this to a string
  const operator = rule[1]
  const value = rule[2]

  if (!path) {
    throw new Error(`No path specified in rule: ${JSON.stringify(rule)}`)
  }
  if (!operator) {
    throw new Error(`No operator specified in rule: ${JSON.stringify(rule)}`)
  }

  const originalPath = path

  // For server-side queries so paths don't start with "contact"
  if (get(options, 'rootPathIsContact') && path.startsWith('contact.')) {
    path = path.replace('contact.', '')
  }

  // Assume this has been "sanitized" already and contact based fields will already be prefixed if necessary
  const schema = schemaForPath(get(options, 'schemas'), originalPath)
  // If no schema is found then it won't try to cast anything and just compare by default

  if (operator === 'empty') {
    return {
      $or: [
        { [path]: { $eq: null } },
        { [path]: { $exists: false } },
        // We an only compare to a string and use the $size operator when this isn't
        // both running on the server (rootPathIsContact) and it's not an ObjectId)
        ...(!(schema?.type === '_id' && options.rootPathIsContact)
          ? [
              { [path]: { $eq: '' } },
              { [path]: { $size: 0 } }
            ]
          : [])
      ]
    }
  } else if (operator === '!empty') {
    return {
      $and: [
        { [path]: { $ne: null } },
        { [path]: { $exists: true } },
        // We an only compare to a string and use the $size operator when this isn't
        // both running on the server (rootPathIsContact) and it's not an ObjectId)
        ...(!(schema?.type === '_id' && options.rootPathIsContact)
          ? [
              { [path]: { $ne: '' } },
              { [path]: { $not: { $size: 0 } } }
            ]
          : [])
      ]
    }
  }

  try {
    if (schema && schema.type && fieldTypes[schema.type]) {
      return fieldTypes[schema.type].buildQueryRule({ rule, path, operator, value, schema }, options)
    }

    return fieldTypes.text.buildQueryRule({ rule, path, operator, value, schema }, options)
  } catch (e) {
    throw new Error(`Error in rule ${JSON.stringify(rule)}: ${e.message}`)
  }
}

export default buildQueryRule
