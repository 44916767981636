import Block from './Block'
import colors from 'shared/colors'

class Trigger extends Block {
  static get title () {
    return 'Trigger ?'
  }

  static get type () {
    return 'TRIGGER'
  }

  static get color () {
    return (colors && colors.primary) || super.color
  }

  static getWarnings (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const warnings = []
    // Since this is an action it's important that something is connected to it.
    const atLeastOneOutgoingPath = !!paths.find(p => p.from === block._id)
    if (!atLeastOneOutgoingPath) {
      warnings.push({
        message: 'This trigger is not connected to any actions and will have no effect.'
      })
    }
    return super.getWarnings(environment).concat(warnings)
  }
}

export default Trigger
