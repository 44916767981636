import Trigger from '../Trigger'

class MessageIn extends Trigger {
  static get title () {
    return 'Incoming Message'
  }

  static get class () {
    return 'MessageIn'
  }

  static get restrict () {
    return { 'addons.type': 'THISISHIDDEN' }
  }

  static getDefaultProps () {
    return {
      occur: {
        frequency: 'EVERY',
        n: 1,
        start: 1
      }
    }
  }
}

export default MessageIn
