const entity = 'Form'
const path = 'webform'

const schemas = {
  _id: { type: '_id', name: 'ID' },
  name: { type: 'text', name: 'Name' },
  account_id: { type: 'number', name: 'Account' },
  updated_at: { type: 'timestamp', name: 'Updated At' },
  smartlink_id: { type: 'text', name: 'Smartlink' },
  stats: { type: 'object', name: 'Stats', hidden: true },
  'stats.completion_count': { type: 'number', name: '# Completed' },
  'stats.last_completion_at': { type: 'timestamp', name: 'Last Completed' },
  'stats.last_completion_by': { type: 'text', name: 'Last Completed By' },
  tags: { type: 'text', name: 'Tags' },
  settings: { type: 'object', name: 'Settings', hidden: true },
  'settings.formsV2': { type: 'text', name: 'Forms V2' }
}

const defaultValues = {
  'settings.styles.width': 600,
  'settings.styles.fontSize': 16,
  'settings.content_bottom.html': '<p style="font-size: 8px;">By participating, you consent to receive text and emails messages sent by an automatic telephone dialing system. Consent to these terms is not a condition of purchase. Message and data rates may apply. Text STOP to {{sms_gateway_address}} to cancel. Terms & Conditions & Privacy Policy: <a href="https://patchretention.com/privacy-policy">www.patchretention.com/privacy-policy</a>.</p>',
  'settings.submit_label': 'Submit',
  'settings.styles.logoImage': 'form-logo-placeholder-wide.png',
  'settings.finish_title': 'Thank you for filling out our form!',
  'settings.redirect_url_label': 'Click here to finish'
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path,
  defaultValues
}
