import Model from './Model'

class Payment extends Model {
  static entity = 'payments'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      user_id: this.attr(undefined),
      invoice_id: this.attr(undefined),
      payment_source_id: this.attr(undefined),
      payment_source_type: this.attr(undefined),
      name: this.attr(undefined),
      amount: this.attr(undefined),
      amount_refunded: this.attr(undefined),
      number: this.attr(undefined),
      pay_on: this.attr(undefined),
      run_at: this.attr(undefined),
      status: this.attr(undefined),
      response: this.attr(undefined),
      data: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

export default Payment
