import { get, set, cloneDeep, isEqual, camelCase, fromPairs, flatten } from 'lodash'

/**
 * Adding the mixin ChangeTracker({ path: ['contact', 'message'] }) will create the following computed
 * props on the vm:
 *   allChanges: [] // Any changes made to any object defined in "path" (in this case contact and message)
 *   contactChanges: [] // List of changes made to contact
 *   messageChanges: [] // List of changes made to message
 *   contactChanged: {} // A duplicate of vm.contact merged with the changed values
 *   messageChanged: {} // A duplicate of vm.message merged with the changed values
 *
 * To use this lib to change the first_name of a contact one would do this:
 * vm.handleChange('contact', 'first_name', 'Ben')
 * This wouldn't touch vm.contact.  It would add an item contactChanges and then
 * contactChanged.first_name would == 'Ben'.  contact.first_name would still == the previous value.
 * Once contactChanged has been persisted you can then call clearChanges('contact') to start over.
 */

export default ({
  path = [] // List of keys of records on the vm that changes are applied to and tracked for
}) => {
  const recordPaths = (!Array.isArray(path) ? [path] : path).filter(v => !!v)

  const mixin = {
    data () {
      return {
        change$: []
      }
    },
    methods: {
      clearChanges (recordKey) {
        if (typeof recordKey === 'function') {
          this.change$ = this.change$.filter(recordKey)
        } else if (recordKey) {
          this.change$ = this.change$.filter(c => c.recordKey !== recordKey)
        } else {
          this.change$ = []
        }
        this.$emit('changes', this.allChanges)
      },
      $clearChanges (recordKey) {
        this.clearChanges(recordKey)
      },
      $handleChange (recordKey, path, value) {
        const changes = this.change$.filter(c => !(c.recordKey === recordKey && c.path === path))
        // Strict comparison and don't allow false/null/undefined to fall back to ''
        const valueABeforeCheck = get(this[recordKey], path)
        const valueA = [false, null, undefined].includes(valueABeforeCheck)
          ? valueABeforeCheck
          : (valueABeforeCheck || '')
        const valueB = ([false, null, undefined].includes(value) ? value : (value || ''))
        if (
          !isEqual(valueABeforeCheck, value) &&
          valueA !== valueB &&
          !(
            [null, undefined].includes(valueA) &&
            [null, undefined].includes(valueB)
          )
        ) {
          changes.push({ recordKey, path, value })
        } else {
          // console.log('is equal', this, path, 1, value, 2, get(this[recordKey], path), 3, valueA, 4, valueB)
        }
        this.change$ = changes
        this.$emit('changes', this.allChanges)
      },
      handleChange (recordKey, path, value) { // Temporary
        this.$handleChange(recordKey, path, value)
      },
      revertChange (recordKey, path) {
        this.change$ = this.change$.filter(c => !(c.recordKey === recordKey && c.path === path))
        this.$emit('changes', this.allChanges)
      }
    },
    computed: {
      allChanges () {
        return this.change$
      },
      ...fromPairs(flatten(recordPaths.map(recordKey => {
        return [
          [
            camelCase(`${recordKey}_changes`),
            function () {
              return this.change$.filter(change => change.recordKey === recordKey)
            }
          ],
          [
            camelCase(`${recordKey}_changed`),
            function () {
              const record = cloneDeep(this[recordKey] || (Array.isArray(this[recordKey]) ? [] : {}))
              this[camelCase(`${recordKey}_changes`)].forEach(change => {
                // So previous values aren't mutated, clone each one
                let value = change.value
                if (value) {
                  value = cloneDeep(value)
                }
                set(record, change.path, value)
              })
              return record
            }
          ]
        ]
      })))
    }
  }

  return mixin
}
