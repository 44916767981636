const entity = 'Event'
const path = 'event'

const schemas = {
  _id: { type: '_id', name: 'ID' },
  event: { type: 'text', name: 'Type' },
  account_id: { type: 'number', name: 'Account ID' },
  contact_id: { type: '_id', name: 'Contact ID' },
  sequence_id: { type: '_id', name: 'Blast/Automation ID' },
  block_id: { type: '_id', name: 'Block ID' },
  at: { type: 'timestamp', name: 'Time' },
  at_tz: { type: 'timestamp', name: 'Time (Account TZ)', hidden: true },
  'data.deleted_at': { type: 'timestamp', name: 'Delete Time' }
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path
}
