<template>
  <v-card>
    <div
      class="pa-3 d-flex"
    >
      <div
        class="flex-grow-1"
      >
        <div
          class="mb-1"
        >
          <strong>Loyalty Ledger</strong>
        </div>
        <p class="mb-0">Lifetime Loyalty Cash Earned: {{ amountEarned }}</p>
        <p class="mb-0">Lifetime Loyalty Cashed Out: {{ amountRedeemed }}</p>
      </div>
      <div>
        <v-btn
          icon
          :disabled="loading || !contactId"
          @click="loadData"
        >
          <v-icon>fa fa-redo</v-icon>
        </v-btn>
      </div>
    </div>
    <v-simple-table
      :style="{ opacity: loading ? 0.4 : 1 }"
    >
      <thead>
        <tr>
          <th>Code</th>
          <th>Amount</th>
          <th>Type</th>
          <th>Changed By</th>
          <th>Time</th>
          <th>Event Data</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="!loading && !(events && events.length)"
        >
          <td
            colspan="6"
            class="text-center"
          >No events found.</td>
        </tr>
        <loyalty-history-row
          v-for="event in events"
          :key="event._id"
          :value="event"
        />
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import LoyaltyHistoryRow from './LoyaltyHistoryRow.vue'
import { Contact, Event } from 'ui/models'

export default {
  mixins: [
    Event.mixin({
      references: ['events']
    })
  ],
  components: {
    LoyaltyHistoryRow
  },
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      events: []
    }
  },
  computed: {
    pointsRedeemValue () {
      return this.$store.getters.account?.settings?.loyalty?.points_redeem_value || 0
    },
    contact () {
      return Contact.find(this.contactId)
    },
    amountEarned () {
      const pointSum = (this.events || [])
        .filter(event => {
          const points = (event.data?.to?.punchcard || 0) - (event.data?.from?.punchcard || 0)
          return points > 0
        })
        .reduce((sum, event) => {
          const points = (event.data?.to?.punchcard || 0) - (event.data?.from?.punchcard || 0)
          return sum + points
        }, 0)
      if (!this.pointsRedeemValue) {
        return pointSum + ' points'
      }
      return window.Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(pointSum / this.pointsRedeemValue)
    },
    amountRedeemed () {
      const pointSum = (this.events || [])
        .filter(event => {
          return !!event.data?.redeem_event_id
        })
        .reduce((sum, event) => {
          const points = (event.data?.to?.punchcard || 0) - (event.data?.from?.punchcard || 0)
          return sum + points
        }, 0)
      if (!this.pointsRedeemValue) {
        return pointSum + ' points'
      }
      return window.Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(pointSum / this.pointsRedeemValue)
    }
  },
  methods: {
    async loadData () {
      if (this.loading || !this.contactId) {
        return
      }
      this.loading = true
      try {
        const result = await Event.fetchMany({
          url: `/contacts/${this.contactId}/loyalty`
        })
        this.events = result.response?.data?.data || []
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.loadData()
  }
}
</script>
