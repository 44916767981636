// THIS FILE SHOULD NEVER HAVE EXISTED WITHIN UI AND NEEDS TO BE MOVED OVER TO KIOSK
// ONCE ALL KIOSK FLOWS HAVE BEEN UPGRADED TO KIOSK4 it will be easier to eliminate this file

import { get, set } from 'lodash'
import colorConvert from 'color-convert'
import argbToRgba from 'shared/util/colors/argbToRgba'

export const mapOldPropertiesToNewShape = (
  propertyRenameMap,
  oldShape,
  converter
) => {
  const newShape = {}
  Object.entries(propertyRenameMap)
    .forEach(([newPath, oldPath]) => {
      const value = get(oldShape, oldPath)
      if (![undefined, null, ''].includes(value)) {
        set(
          newShape,
          newPath,
          converter
            ? converter(value)
            : value
        )
      }
    })
  return newShape
}

export const stackPropertyLayers = (layers) => {
  const result = {}
  layers.forEach((currentLayer) => {
    Object.keys(currentLayer).forEach((key) => {
      const newValue = currentLayer[key]
      if (![undefined, null, ''].includes(newValue)) {
        result[key] = newValue
      }
    })
  })
  return result
}

const mapOldColorsToNewColors = (
  newColors,
  propertyRenameMap,
  oldColors,
  defaultValues,
  accountColors
) => {
  const flowSettingsColors = (
    newColors ||
    mapOldPropertiesToNewShape(
      propertyRenameMap,
      oldColors,
      argbToRgba
    )
  )
  const layers = [
    defaultValues,
    accountColors,
    flowSettingsColors
  ]
  return stackPropertyLayers(layers)
}

export const colorMaps = {
  legacyWelcomePageColorMap: {
    color: 'identifier_container_text',
    content_background_color: 'identifier_container_background',
    button_color: 'regular_btn_text',
    button_background_color: 'regular_btn_fill_active',
    link_color: 'identifier_text'
  },
  legacyButtonColorMap: {
    button_back_background: 'back_btn_fill',
    button_back_text: 'back_btn_text',
    button_next_background: 'next_btn_fill',
    button_next_text: 'next_btn_text'
  },
  offerColorsMap: {
    color_button: 'color_button',
    color_button_text: 'color_button_text',
    color_font: 'color_font',
    color_background: 'color_background'
  }
}

export const offerColorsMixin = {
  computed: {
    offerColors () {
      const propertyRenameMap = colorMaps.offerColorsMap
      const defaultValues = {
        // the most fall-backest values
        color_button: '#000',
        color_button_text: '#fff',
        color_font: '#000',
        color_background: '#fff'
      }
      const mappedColors = mapOldPropertiesToNewShape(
        propertyRenameMap,
        this.offer || this.env?.offer,
        argbToRgba
      )
      return stackPropertyLayers([
        defaultValues,
        mappedColors
      ])
    }
  }
}

export const flowPageColorsMixin = {
  computed: {
    welcomePageColors () {
      const newColors = this.flowPage?.colors_rgba
      const oldColors = this.flowPage?.colors || {}
      const accountColors = this?.account?.settings?.style || {}
      const propertyRenameMap = colorMaps.legacyWelcomePageColorMap
      const defaultValues = {
        // the most fall-backest values
        color: '#fff',
        content_background_color: '#0008',
        button_color: null,
        button_background_color: null,
        link_color: null
      }

      return mapOldColorsToNewColors(
        newColors,
        propertyRenameMap,
        oldColors,
        defaultValues,
        accountColors
      )
    }
  },
  methods: {
    isColorDark (colorString) {
      const [value] = colorConvert.hex.gray(colorString)
      return value <= 34
    }
  }
}
