import Vue from 'vue'
import Editor from './Editor'
import createStore from './store'
import Accordion from './components/Accordion'
import ColumnDraggable from './components/ColumnDraggable'
import Dropzone from './components/Dropzone'
import Element from './components/Element'
import Padding from './components/Padding'
import Property from './components/Property'
import TiptapInput from 'ui/components/Tiptap/TiptapInput'
import { vuetify } from 'ui'
import { pick } from 'lodash'

const EbDropzone = Vue.extend(Dropzone)
Vue.component('eb-dropzone', EbDropzone)

const EbElement = Vue.extend(Element)
Vue.component('eb-element', EbElement)

Vue.component('eb-accordion', Accordion)
Vue.component('eb-column-draggable', ColumnDraggable)
Vue.component('eb-padding', Padding)
Vue.component('eb-property', Property)
Vue.component('eb-tiptap', TiptapInput)

Vue.config.productionTip = false
Vue.config.devtools = false

// To mount, call instance.$mount(domElement)

export default (el, options = {}) => {
  const store = createStore()

  const vm = new Vue({
    ...pick(options, ['parent']),
    store,
    vuetify,
    render: h => h(Editor, {
      ref: 'editor'
    }),
    methods: {
      setValue (value) {
        this.$store.commit('setValue', value)
      }
    },
    beforeDestroy () {
      store.commit('cleanUp')
    }
  })

  store.commit('setEditorOptions', options)
  store.commit('setRootInstance', vm)

  if (el) {
    vm.$mount(el)
  }

  return vm
}
