import Model from './Model'

class Contact extends Model {
  static entity = 'contacts'

  static evictUnused = true

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      data: this.attr(undefined)
    }
  }

  // Contact record is "schemaless" but VueX ORM doesn't support that at root level, so
  // all fields are going to be put inside the "data" object for portal access and then
  // will be transformed back to root level props when syncing to the server.  This is a
  // hack but I think it's worth it to utilize the local store capabilities of the ORM.
  static transformIncomingData ({ data }) {
    data = data.data || data

    if (!Array.isArray(data)) {
      data = [data]
    }

    return data.map(d => ({
      _id: d._id,
      account_id: d.account_id,
      data: d
    }))
  }

  static getFlattened (id) {
    const contact = this.find(id)
    if (!contact) {
      return null
    }
    return {
      ...contact.data,
      _id: contact._id,
      account_id: contact.account_id
    }
  }
}

export default Contact
