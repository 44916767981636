import Trigger from '../Trigger'

class ContactCreated extends Trigger {
  static get title () {
    return 'Contact Created'
  }

  static get class () {
    return 'ContactCreated'
  }

  // These are deprecated in favor of shared/schemas/events/*
  get schemas () {
    return [
      { key: 'client_id', name: 'Client ID (Created By)', type: 'text' }
    ]
  }
}

export default ContactCreated
