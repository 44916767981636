var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"color-picker"},[_c('v-menu',{attrs:{"value":_vm.menu && !_vm.disabled,"close-on-content-click":false,"disabled":_vm.disabled,"offset-y":""},on:{"input":function($event){_vm.menu = _vm.disabled ? false : $event}},scopedSlots:_vm._u([{key:"activator",fn:function({ }){return [_c('div',{staticClass:"color-picker-color",style:(_vm.disabled ? 'cursor: default;' : ''),on:{"click":function($event){!_vm.disabled ? _vm.menu = !_vm.menu : null}}},[_c('div',{staticClass:"v-color-picker__dot"},[_c('div',{class:{
              'color-picker-empty': !_vm.value
            },style:(_vm.disabled ? '' : { backgroundColor: _vm.value || _vm.defaultValue }),attrs:{"title":!!(!_vm.value && _vm.defaultValue)
              ? 'This field is empty, so the account default will be used'
              : undefined}})])])]}}])},[(_vm.menu)?_c('v-sheet',{staticStyle:{"padding":"20px"}},[_c('div',{staticClass:"color-picker-swatches"},_vm._l((_vm.allSwatches),function(color){return _c('div',{key:color,staticClass:"color",class:{
            active: color === _vm.value
          },on:{"click":function($event){return _vm.onInput(color)}}},[_c('div',{staticClass:"v-color-picker__dot"},[_c('div',{style:({ backgroundColor: color })})])])}),0),_c('div',{staticClass:"color-picker-swatches mb-3"},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onInput('transparent')}}},[_c('div',{staticClass:"color",class:{ active: _vm.value === 'transparent' }},[_c('div',{staticClass:"v-color-picker__dot"})]),_c('div',{staticClass:"ml-2"},[_vm._v("Transparent")])])]),(_vm.showColorPicker)?_c('v-color-picker',{attrs:{"value":_vm.valueSanitized,"dot-size":"25","mode":"hexa","hide-mode-switch":""},on:{"input":_vm.onInput}}):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-left"},[(!_vm.showColorPicker)?_c('v-btn',{attrs:{"text":"","color":"primary","small":""},on:{"click":_vm.displayColorPicker}},[_vm._v("Show Color Picker")]):_vm._e()],1),_c('div',{staticClass:"text-right flex-grow-1"},[_c('v-btn',_vm._b({attrs:{"color":"primary"},on:{"click":_vm.select}},'v-btn',{ disabled: _vm.disabled },false),[_vm._v("Select")])],1)])],1):_vm._e()],1),(!!_vm.value && !_vm.disabled && !(_vm.clearToDefault && _vm.value === _vm.defaultValue))?_c('div',{staticClass:"color-picker-clear",on:{"click":_vm.clear}},[_vm._v("X")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }