export default {
  namespaced: true,
  state: {
    roadblockUi: false // Whether to force a roadblock to be visible for the current module
  },
  getters: {
    accountExpirationRoadblock (state, getters, rootState, rootGetters) {
      const status = rootGetters.account?.status
      const startBillingOn = rootGetters.account?.billing?.start_billing_on
      const today = new Date().toJSON().substring(0, 10)
      const startBilling = startBillingOn || today
      return (
        (status === 'TRIAL') &&
        (startBilling <= today) // yes, string date comparison actually works like this
      )
    }
  },
  mutations: {
    setRoadblockUi (state, value) {
      state.roadblockUi = value
    }
  }
}
