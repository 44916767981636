export default (value) => {
  if (!value) {
    return null
  }
  value = ((value || '') + '')
  if (!value.startsWith('#')) {
    return value
  }
  value = value.replace('#', '')
  if (value.length === 6) {
    value = 'FF' + value
  } else if (value.length === 8) {
    value = value.slice(6) + value.slice(0, 6)
  }
  const ret = '#' + value
  return ret
}
