<template>
  <v-card
    class="cg-editor"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>Activate Offer</v-toolbar-title>

      <v-spacer />

      <v-btn
        icon
        @click="requestClose"
        :disabled="loading"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
    >
      <v-form
        v-model="formValid"
      >
        <activate-offer-editor
          outlined
          :value="activationPropsChanged"
          @change="$handleChange('activationProps', $event[0], $event[1])"
        />
      </v-form>
    </v-container>
    <div
      class="footer pa-2"
    >
      <v-btn
        color="primary"
        outlined
        @click="requestClose"
        :disabled="loading"
      >Cancel</v-btn>
      <v-btn
        color="primary"
        class="ml-2"
        @click="save"
        :disabled="loading || !valid"
      >Activate</v-btn>
    </div>
  </v-card>
</template>

<script>
import { Editor as ActivateOfferEditor } from 'ui/blocks/actions/ActivateOffer'
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import createPreventChangeLossMixin from 'ui/mixins/createPreventChangeLossMixin'

export default {
  oWindow: {
    width: 400,
    height: 525,
    overlay: true
  },
  mixins: [
    createPreventChangeLossMixin(),
    createChangeTrackerMixin({ path: 'activationProps' })
  ],
  components: {
    ActivateOfferEditor
  },
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      formValid: null
    }
  },
  computed: {
    activationProps () {
      return {
        redemption_limit: 1,
        duration: {
          days: 2
        },
        extend: true
      }
    },
    valid () {
      if (!this.formValid) {
        return false
      }
      if (!this.activationPropsChanged?.offer_id) {
        return false
      }
      return true
    }
  },
  methods: {
    async save () {
      this.loading = true
      try {
        const response = await this.$http({
          method: 'POST',
          url: `/v2/offers/${this.activationPropsChanged.offer_id}/activations`,
          data: {
            contact_id: this.contactId,
            props: this.activationPropsChanged,
            channel: 'PORTAL' // This should eventually be implied by client_id
          }
        })
        this.$emit('close', response.data)
      } catch (e) {
        this.loading = false
        throw e
      }
    }
  }
}
</script>
