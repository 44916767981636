const unnecessaryCharacterConversionMap = {
  // Open-quotes: http://www.fileformat.info/info/unicode/category/Pi/list.htm
  0x2018: '\'',
  0x201B: '\'',
  0x201C: '"',
  0x201F: '"',

  // Close-quotes: http://www.fileformat.info/info/unicode/category/Pf/list.htm
  0x2019: '\'',
  0x201D: '"',

  // Primes: http://www.fileformat.info/info/unicode/category/Po/list.htm
  0x2032: '\'',
  0x2033: '"',
  0x2035: '\'',
  0x2036: '"',

  0x2014: '-', // also replaces dashes with em-dash
  0x2013: '-' // and "--" with en-dash
}

const removeUnnecessaryUnicodeCharacters = (text) => {
  text = text || ''
  for (let i = 0; i < text.length; i++) {
    const char = text.codePointAt(i)
    if (unnecessaryCharacterConversionMap[char]) {
      text = text.substr(0, i) + unnecessaryCharacterConversionMap[char] + text.substr(i + 1)
    }
  }
  return text
}

export default removeUnnecessaryUnicodeCharacters
