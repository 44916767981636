const operators = {
  '=': '$eq',
  '=i': '$regex', // Case insensitive equal
  '!=': '$ne',
  '<': '$lt',
  '>': '$gt',
  has: '$regex',
  '!has': '$regex',
  empty: 'empty',
  '!empty': '!empty',
  regex: '$regex',
  'regex^': '$regex',
  in: '$in',
  '!in': '$nin'
}

const buildQueryRule = ({ path, operator, value }, options = {}) => {
  if (operator === 'has') {
    // I noticed this one snuck by and has been available for mongo queries in production for some time. I'm going to leave it
    // for now but it would be good to determine how many filters are utilizing this somehow.
    if (value) {
      return { [path]: { $regex: ((value || '') + '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), $options: 'i' } }
    }
  } else if (operator === '!has') {
    if (options?.rootPathIsContact) {
      return { [path]: { $eq: '--%regex%--%NOTALLOWED%--%%--' } } // This should make the query fail. Ideally an error would be thrown here though.
    }
    if (value) {
      return {
        $or: [
          { [path]: null },
          { [path]: { $not: { $regex: value, $options: 'i' } } }
        ]
      }
    }
  } else if (operator === 'regex') {
    // if rootPathIsContact is set then it's building a query for the server
    // Due to limited performance of the regex operator in mongo, I can only allow it to run in
    // sift queries with single records for now.
    if (options?.rootPathIsContact) {
      return { [path]: { $eq: '--%regex%--%NOTALLOWED%--%%--' } } // This should make the query fail. Ideally an error would be thrown here though.
    }
    return { [path]: { $regex: value, $options: 'i' } }
  } else if (operator === 'regex^') {
    // if rootPathIsContact is set then it's building a query for the server
    // Due to limited performance of the regex operator in mongo, I can only allow it to run in
    // sift queries with single records for now.
    if (options?.rootPathIsContact) {
      return { [path]: { $eq: '--%regex^%--%NOTALLOWED%--%%--' } } // This should make the query fail. Ideally an error would be thrown here though.
    }
    return { [path]: { $regex: '^' + value, $options: 'i' } }
  } else if (operator === '=i') {
    // if rootPathIsContact is set then it's building a query for the server
    // Due to limited performance of the regex operator in mongo, I can only allow it to run in
    // sift queries with single records for now.
    if (options?.rootPathIsContact) {
      return { [path]: { $eq: '--%=i%--%NOTALLOWED%--%%--' } } // This should make the query fail. Ideally an error would be thrown here though.
    }
    return { [path]: { $regex: `^${value}$`, $options: 'i' } }
  } else {
    if (['in', '!in'].indexOf(operator) > -1) {
      if (!Array.isArray(value)) {
        value = [value]
      }
    }
    return { [path]: { [operators[operator]]: value } }
  }
}

export default {
  buildQueryRule,
  operators
}

export {
  buildQueryRule,
  operators
}
