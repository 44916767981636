import createMessageInSchemas from './MessageIn'
import { omit } from 'lodash'

// This is a pseudo-event and should not be included in the index.js file

export default (params) => {
  const schemas = createMessageInSchemas(params)

  return omit(schemas, ['channel', 'from', 'to', 'keyword_id', 'gateway_id'])
}
