import Trigger from '../Trigger'

class OrderRefunded extends Trigger {
  static get title () {
    return 'Order Refunded'
  }

  static get class () {
    return 'OrderRefunded'
  }

  static get restrict () {
    return { 'addons.type': 'SHOPIFY', 'nobody.can': 'seethis' }
  }
}

export default OrderRefunded
