// Returns an object of all schemas added based on the shopify addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact._shopify_id': {
      type: 'number',
      immutable: true,
      name: 'Shopify Customer ID'
    },
    'contact._shopify_state': {
      name: 'Shopify Customer Account Status',
      type: 'text',
      immutable: true,
      options: [
        { value: 'disabled' },
        { value: 'invited' },
        { value: 'enabled' },
        { value: 'declined' }
      ]
    },
    'contact._shopify_tags': {
      name: 'Shopify Tags',
      type: 'text',
      multiple: true,
      immutable: true
    },
    'contact._shopify_created_at': {
      name: 'Shopify Created At',
      type: 'timestamp',
      immutable: true
    },
    'contact._shopify_total_spent': {
      name: '[Deprecated] Shopify Total Spent',
      type: 'number',
      immutable: true
    },
    'contact._shopify_order_count': {
      name: '[Deprecated] Shopify # Orders',
      type: 'number',
      immutable: true
    },
    'contact._shopify_last_order_at': {
      name: '[Deprecated] Shopify Last Order At',
      type: 'timestamp',
      immutable: true
    },
    'contact._shopify_item_names_ordered': {
      name: '[Deprecated] Shopify Item Names Ordered',
      type: 'text',
      multiple: true,
      immutable: true
    },
    'contact._shopify_skus_ordered': {
      name: '[Deprecated] Shopify SKUs Ordered',
      type: 'text',
      multiple: true,
      immutable: true
    },
    'contact._shopify_email_marketing_consent_state': {
      name: 'Shopify Email Marketing Consent State',
      type: 'text',
      immutable: true,
      options: [
        { value: 'invalid' },
        { value: 'pending' },
        { value: 'redacted' },
        { value: 'not_subscribed' },
        { value: 'subscribed' },
        { value: 'unsubscribed' }
      ]
    },
    'contact._shopify_email_marketing_consent_opt_in_level': {
      name: 'Shopify Email Marketing Consent Opt In Level',
      type: 'text',
      immutable: true,
      options: [
        { value: 'confirmed_opt_in' },
        { value: 'single_opt_in' },
        { value: 'unknown' }
      ]
    },
    'contact._shopify_sms_marketing_consent_state': {
      name: 'Shopify SMS Marketing Consent State',
      type: 'text',
      immutable: true,
      options: [
        { value: 'pending' },
        { value: 'redacted' },
        { value: 'not_subscribed' },
        { value: 'subscribed' },
        { value: 'unsubscribed' }
      ]
    },
    'contact._shopify_sms_marketing_consent_opt_in_level': {
      name: 'Shopify SMS Marketing Consent Opt In Level',
      type: 'text',
      immutable: true,
      options: [
        { value: 'confirmed_opt_in' },
        { value: 'single_opt_in' },
        { value: 'unknown' }
      ]
    }
  }
}

// This is a mapping of fields that we use to reference the shopify customer directly, such as when we want to prevent certain orders from syncing.
const customerSchemas = {
  'email_marketing_consent.state': {
    name: 'Email Marketing Consent State',
    type: 'text',
    options: [
      { value: 'invalid' },
      { value: 'pending' },
      { value: 'redacted' },
      { value: 'not_subscribed' },
      { value: 'subscribed' },
      { value: 'unsubscribed' }
    ]
  },
  'email_marketing_consent.opt_in_level': {
    name: 'Email Marketing Consent Opt In Level',
    type: 'text',
    options: [
      { value: 'confirmed_opt_in' },
      { value: 'single_opt_in' },
      { value: 'unknown' }
    ]
  },
  'sms_marketing_consent.state': {
    name: 'SMS Marketing Consent State',
    type: 'text',
    options: [
      { value: 'pending' },
      { value: 'redacted' },
      { value: 'not_subscribed' },
      { value: 'subscribed' },
      { value: 'unsubscribed' }
    ]
  },
  'sms_marketing_consent.opt_in_level': {
    name: 'SMS Marketing Consent Opt In Level',
    type: 'text',
    options: [
      { value: 'confirmed_opt_in' },
      { value: 'single_opt_in' },
      { value: 'unknown' }
    ]
  },
  first_name: {
    name: 'First Name',
    type: 'text'
  },
  created_at: {
    name: 'Created At',
    type: 'timestamp'
  },
  'default_address.address1': {
    name: 'Default Address Line 1',
    type: 'text'
  },
  'default_address.address2': {
    name: 'Default Address Line 2',
    type: 'text'
  },
  'default_address.city': {
    name: 'Default Address City',
    type: 'text'
  },
  'default_address.province': {
    name: 'Default Address Province',
    type: 'text'
  },
  'default_address.zip': {
    name: 'Default Address Postal Code',
    type: 'text'
  },
  'default_address.country_code': {
    name: 'Default Address Country Code',
    type: 'text'
  },
  email: {
    name: 'Email Address',
    type: 'text'
  },
  id: {
    name: 'Customer ID',
    type: 'number'
  },
  last_name: {
    name: 'Last Name',
    type: 'text'
  },
  orders_count: {
    name: 'Orders Count',
    type: 'number'
  },
  phone: {
    name: 'Phone Number',
    type: 'text'
  },
  tags: {
    name: 'Tags',
    type: 'text',
    multiple: true
  }
}

export {
  getSchemas,
  customerSchemas
}

export default {
  getSchemas
}
