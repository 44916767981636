export default () => ({
  client_id: { name: 'Client ID', type: 'text' },
  external_id: { name: 'External ID', type: 'text' },
  order_id: { name: 'Order ID', type: 'number' },
  order_event_id: { name: 'Order Event ID', type: '_id' },
  tracking_number: { name: 'Tracking Number', type: 'text' },
  tracking_numbers: { name: 'Tracking Numbers', type: 'text', multiple: true },
  tracking_url: { name: 'Tracking URL', type: 'text' },
  tracking_urls: { name: 'Tracking Urls', type: 'text', multiple: true }
})
