import Model from './Model'

let addonTypesPromise = null

class AddonType extends Model {
  static entity = 'addon_types'

  static fields () {
    return {
      _id: this.attr(undefined),
      name: this.attr(undefined),
      meta: this.attr(undefined),
      addons: this.attr(undefined),
      config: this.attr(undefined),
      lib: this.attr(undefined),
      hidden: this.attr(undefined),
      created_at: this.attr(undefined),
      created_by: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }

  // Since this is not account-specific, we can use this method to load these all only once
  static async fetchManyOnce () {
    addonTypesPromise = addonTypesPromise || this.fetchMany()
    return addonTypesPromise
  }
}

export default AddonType
