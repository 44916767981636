import restrict from 'shared/util/restrict'

// These are included directly by the store which is
// pretty much everywhere so please make them light

const usMoneyFormat = new Intl.NumberFormat(
  'en-US',
  { style: 'currency', currency: 'USD' }
)

export default {
  install (Vue, options) {
    Vue.mixin({
      filters: {
        formatMoney (value, options = {}) {
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', ...options }).format((value || '') + '')
        }
      },
      methods: {
        // Automatically handles loading state at loadingPath for specified promise
        // Despite resolve/reject of promise
        async $manageLoading (loadingPath, promise) {
          if (loadingPath) {
            this[loadingPath] = true
          }
          try {
            const result = await promise
            if (loadingPath) {
              this[loadingPath] = false
            }
            return result
          } catch (e) {
            if (loadingPath) {
              this[loadingPath] = false
            }
            throw e
          }
        },
        $hasModule (type = '') {
          return this.$restrict({ 'addons.type': type })
        },
        $hasRole (role) {
          return true // TODO
        },
        $hasPermission (permission) {
          return true // TODO
        },
        $hasUserLevel (level) {
          return this.$store.getters.user && this.$store.getters.user.level >= level
        },
        $restrict (query) {
          return restrict(this.$store.getters.environment, query)
        },
        $formatCurrency (number = 0) {
          return usMoneyFormat.format(number)
        }
      }
    })
  }
}
