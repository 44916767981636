import { pick } from 'lodash'

export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    schemas: { // Expected [ { path: 'contact.first_name', name: 'First Name' }]
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowCustomPath: {
      type: Boolean,
      default: false
    },
    // Since regexes can't be run as large queries, they're only going to be available in
    // situations where the query runs on a single record via Siftjs (testObject)
    optInToRegex: {
      type: Boolean,
      default: false
    },
    // Temporary until modulus is opened up for all other UI locations
    // TODO: Remove after P6 is gone
    optInToModulus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    passProps () {
      return pick(this, ['dense', 'filled', 'outlined', 'schemas', 'disabled', 'small', 'allowCustomPath', 'optInToRegex', 'optInToModulus'])
    }
  }
}
