import Action from '../Action'
import colors from 'shared/colors'

class SetFields extends Action {
  static get title () {
    return 'Set Fields'
  }

  static get class () {
    return 'SetFields'
  }

  static get color () {
    return colors.success
  }
}

export default SetFields
