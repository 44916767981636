var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fixed-menu',{staticClass:"cg-event-type-picker",attrs:{"props":{
    fixed: true,
    offsetY: true,
    minWidth: 300,
    closeOnContentClick: false,
    left: _vm.left,
    allowOverflow: false,
    top: _vm.pickerDropdownTop,
    ...(_vm.menuProps || {})
  }},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.icon)?_c('tooltip-button',_vm._g(_vm._b({ref:"activator"},'tooltip-button',{
          icon: 'sliders-h',
          tooltip: 'Filter Events',
          position: 'bottom',
          disabled: _vm.disabled,
          iconSize: '20',
          caret: true,
          badgeValue: (_vm.multiple && _vm.value && _vm.value.length > 0) || (!_vm.multiple && !!_vm.value),
          badgeContent: (_vm.multiple && _vm.value && _vm.value.length),
          buttonSize: 'large'
        },false),on)):_c('div',_vm._g({ref:"activator"},on),[_c('v-select',_vm._b({attrs:{"items":_vm.selectItems,"value":_vm.selectItems.map(i => i.value),"readonly":"","outlined":"","dense":""}},'v-select',{ label: _vm.label, multiple: _vm.multiple },false))],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',{staticClass:"event-type-picker-content",style:(_vm.pickerDropdownStyle)},[_c('v-sheet',{staticClass:"fill-height"},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.eventTypes),function(eventType){return _c('v-list-item',{key:eventType.value,staticClass:"pt-0 mt-0 px-0"},[_c('v-list-item-content',{staticClass:"ma-0"},[_c('v-checkbox',{staticClass:"mx-4",attrs:{"dense":"","hide-details":"","label":eventType.text,"input-value":(_vm.multiple && _vm.value && _vm.value.includes(eventType.value)) || (!_vm.multiple && _vm.value === eventType.value)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggle(eventType.value)}}})],1)],1)}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }