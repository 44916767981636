import Action from '../Action'
import colors from 'shared/colors'

class DoubleOptIn extends Action {
  static get title () {
    return 'Double Opt-In'
  }

  static get class () {
    return 'DoubleOptIn'
  }

  static get color () {
    return colors.info
  }

  static getPathTypes () {
    return [
      {
        name: 'Yes',
        class: 'Yes',
        color: colors.success
      },
      {
        name: 'Always',
        class: 'Always',
        color: null
      }
    ]
  }

  static getDefaultProps () {
    return {
      gateway_id: 0,
      content: {
        text: 'Please confirm your subscription to {{ account.display_name || account.name }} offers & updates. Reply Y or Yes to opt-in to SMS messaging.'
      }
    }
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []
    // const blockId = get(block, 'props.block_id')
    // if (!blockId) {
    //   errors.push({
    //     message: 'Block to trigger is required',
    //     path: 'block_id'
    //   })
    // }
    return super.getErrors(environment).concat(errors)
  }
}

export default DoubleOptIn
