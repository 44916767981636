import get from 'lodash/get'
import { schemaForPath } from 'shared/field-ops'
import { account as accountSchemas } from 'shared/schemas/entities'
import { stripSlashes } from 'slashes'
import { variableRegex } from '../transformVariables'

const parseParams = ({ params = {}, options = {}, context = {} } = {}) => {
  if (typeof params !== 'object') {
    params = { path: params }
  }
  return {
    path: params.path || params.param || '',
    context: typeof params.value !== 'undefined' ? params.value : context,
    options
  }
}

export default (args) => {
  let { path, context, options } = parseParams(args)
  if (!path) {
    return ''
  }

  // Remove escape slashes from path
  // for example, get('contact.punchcard\\{\\{\\account._id}\\}') is allowed and should be interpolated
  path = stripSlashes(path)
  if (variableRegex.test(path)) {
    const render = require('../render').default
    path = render(path, context, options)
  }

  return get(context, path)
}

const humanizeCall = (args) => {
  const { path } = parseParams(args)

  // Try to get a "friendly" name for the path
  if (path && path.startsWith('contact.') && args.options && args.options.schemas) {
    const schema = schemaForPath(args.options.schemas, path) || schemaForPath(args.options.schemas, path.replace(/^contact\./, ''))
    if (schema && schema.name && !schema.notfound) {
      return 'Contact ' + schema.name
    }
  } else if (path && path.startsWith('account.')) {
    const schema = schemaForPath(accountSchemas.default, path.replace(/^account\./, ''))
    if (schema && schema.name && !schema.notfound) {
      return 'Account ' + schema.name
    }
  } else if (path && path.startsWith('general.')) {
    return 'General Field ' + path.replace(/^general\./, '')
  }

  return `Value of ${path}`
}

const getLengths = (args) => {
  const { path, options } = parseParams(args)
  if (options && options.fieldLengths && options.fieldLengths[path]) {
    return options.fieldLengths[path]
  }
  return {}
}

export {
  humanizeCall,
  getLengths
}
