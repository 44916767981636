import Model from './Model'
import formatUSPhoneNumber from 'shared/util/formatting/formatUSPhoneNumber'

class Gateway extends Model {
  static entity = 'gateways'

  static fields () {
    return {
      _id: this.attr(undefined),
      name: this.attr(undefined),
      account_id: this.attr(undefined),
      channel: this.attr(undefined),
      provider: this.attr(undefined),
      address: this.attr(undefined),
      incoming: this.attr(undefined),
      outgoing: this.attr(undefined),
      enabled: this.attr(undefined),
      hidden: this.attr(undefined),
      settings: this.attr(undefined),
      // settings.toll_free
      provider_data: this.attr(undefined),

      // The rest of these fields come from account_gateways
      default: this.attr(undefined),
      messenger: this.attr(undefined),
      allow_overage: this.attr(undefined),
      overage_price: this.attr(undefined),
      credits: this.attr(undefined),
      forwards: this.attr(undefined),
      bill_to: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }

  static defaultForChannel (channel) {
    const defaultGateway = this.all()
      .find(record => record.channel === channel && !!record.default)

    return defaultGateway || this.all()
      .find(record => record.channel === channel)
  }

  get friendlyName () {
    if (this.channel === 'SMS' && [10, 11].includes(this.address.length)) {
      return formatUSPhoneNumber(this.address)
    }
    return this.address
  }
}

export default Gateway
