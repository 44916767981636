import ContactUpdated from './ContactUpdated'
import Default from './Default'
import MessageIn from './MessageIn'
import MessageReply from './MessageReply'
import MessageOut from './MessageOut'
import Note from './Note'

const mapping = {
  'contact.updated': ContactUpdated,
  default: Default,
  'message.in': MessageIn,
  'message.reply': MessageReply,
  'message.out': MessageOut,
  note: Note
}

export {
  ContactUpdated,
  Default,
  MessageIn,
  MessageReply,
  MessageOut,
  Note
}

export default mapping
