import { schemaForPath } from 'shared/field-ops'
import { render } from 'shared/smart-text'

import get from 'lodash/get'

// This adds some lenience and backward compatibility to rule paths which can imply targeting a
// contact field but not begin with "contact."

export default (path, options) => {
  // Run SmartText on path in case smart text is used in it
  path = render(path, get(options, 'context'), options)

  // Determine the schema for the rule
  let schema = schemaForPath(get(options, 'schemas'), path)

  // Getting this "specific" will also make it possible to filter based on events in the future
  if (!schema) {
    schema = schemaForPath(get(options, 'schemas'), `contact.${path}`)
    if (schema) {
      // Up until now, filters have only been for contact fields, so instead of
      // specifying the full-path based on the contact being part of the payload,
      // only the field key was specified.  So instead of filters having just 'first_name'
      // for the key they should begin to have 'contact.first_name' instead.  This will make
      // it possible to then target payload based fields in automation filters.

      // The sanitization will convert 'first_name' to 'contact.first_name' if there is a
      // 'first_name' schema that exists inside of the contact object.
      // It's expected that there will be another step for DB queries that will transform
      // a filter into a mongodb query and will remove 'contact.' from 'contact.first_name'
      path = `contact.${path}`
    }
  }

  // If there's still no schema than just assume it's text
  schema = schema && !schema.notfound ? schema : undefined

  return {
    schema,
    path
  }
}
