import Model from './Model'

class AutoReply extends Model {
  static entity = 'auto_replies'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      gateway_id: this.attr(undefined),
      name: this.attr(undefined),
      active: this.attr(undefined),
      order: this.attr(undefined),
      config: this.attr(undefined),
      hits: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

export default AutoReply
