<template>
  <v-card
    class="pa-3"
  >
    <div class="mb-2">
      <strong>Discount Code Generated</strong>
    </div>
    <p>The discount code has been generated and is found below.</p>
    <v-text-field
      readonly
      :value="code"
      outlined
      dense
      hide-details
    >
      <v-tooltip
        top
        slot="append"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            :color="$store.getters.color"
            v-on="on"
            @click="copyCode"
          >{{ copied ? 'fa fa-check' : 'fas fa-clipboard' }}</v-icon>
        </template>
        <div>{{ copied ? 'Copied to clipboard.' : 'Copy to clipboard.' }}</div>
      </v-tooltip>
    </v-text-field>
  </v-card>
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      copied: false
    }
  },
  watch: {
    copied (value) {
      if (value) {
        setTimeout(() => {
          this.copied = false
        }, 2000)
      }
    }
  },
  methods: {
    copyCode () {
      navigator.clipboard.writeText(this.code)
      this.copied = true
    }
  }
}
</script>
