<template>
  <div>
    <v-card
      class="mb-3"
    >
      <v-card-title>Preview Image</v-card-title>
      <v-card-text>
        <div
          class="d-flex align-center"
          style="max-width: 400px"
        >
          <div
            class="flex-grow-1"
          >
            <file-picker
              :types="['image']"
              label="SmartLink Preview Image"
              :value="record.settings && record.settings.og_image"
              @input="$emit('change', ['settings.og_image', $event])"
              :disabled="disabled"
            />
          </div>
          <div
            class="pl-3"
          >
            <v-tooltip
              top
              :max-width="300"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  class="ml-1 mb-1"
                  v-on="on"
                  small
                >fa-info-circle</v-icon>
              </template>
              <div>When sharing this SmartLink, this image will be displayed on mobile phones, social media sites and anywhere else that utilizes open-graph tags.</div>
            </v-tooltip>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      class="mb-3"
    >
      <v-card-title>Referrals</v-card-title>
      <v-card-text>
        <v-checkbox
          label="Treat this as a &quot;Referral&quot; SmartLink; Each contact that is created or updated via this link will have a &quot;Referred By&quot; field set, linking them to the contact that shared the link."
          readonly
          :disabled="disabled"
          :input-value="!!record.referral"
          @click="!disabled && ($emit('change', ['referral', !record.referral]),$emit('change', ['untraceable', false]))"
          dense
        />
      </v-card-text>
    </v-card>
    <v-card
      class="mb-3"
      v-if="!record.referral"
    >
      <v-card-title>Trackablity</v-card-title>
      <v-card-text>
        <p>By default, SmartLinks are trackable when used in messages, meaning a unique URL associated with this SmartLink is sent to every recipient and thus when the URL is clicked a specific Contact is associated with it.</p>
        <v-checkbox
          label="Force this SmartLink to remain untrackable when included in messages."
          readonly
          :disabled="disabled"
          :input-value="!!record.untraceable"
          @click="!disabled && $emit('change', ['untraceable', !record.untraceable])"
          dense
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>IP Ignore List
        <v-tooltip
          top
          :max-width="300"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              class="ml-2"
              v-on="on"
              small
            >fa-info-circle</v-icon>
          </template>
          <div>Avoid incrementing # of clicks whenever a SmartLink is clicked from one of these IP addresses.</div>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <div
          v-if="!ignoreAddresses.length"
        >No IP addresses have been added.</div>
        <div
          v-for="(address, $index) in ignoreAddresses"
          :key="$index"
          style="max-width: 300px"
          class="mb-2"
        >
          <v-text-field
            placeholder="127.0.0.1"
            :value="address"
            @input="updateIgnoreAddress($index, $event)"
            outlined
            dense
            hide-details
            append-outer-icon="fa fa-trash"
            @click:append-outer="removeIgnoreAddress($index)"
          />
        </div>
        <v-btn
          color="primary"
          outlined
          small
          @click="addIgnoreAddress()"
        >Add IP Address</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FilePicker from 'ui/pickers/FilePicker'

export default {
  components: {
    FilePicker
  },
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ignoreAddresses () {
      return this.record?.ignore_addresses || []
    }
  },
  methods: {
    addIgnoreAddress () {
      this.$emit('change', ['ignore_addresses', [
        ...this.ignoreAddresses,
        null
      ]])
    },
    updateIgnoreAddress (index, address) {
      this.$emit('change', [
        'ignore_addresses',
        [
          ...this.ignoreAddresses.slice(0, index),
          address,
          ...this.ignoreAddresses.slice(index + 1)
        ]
      ])
    },
    async removeIgnoreAddress (index) {
      if ((await this.$confirm('Are you sure you want to remove this ignored IP address?')) !== 'Yes') {
        return
      }
      this.$emit('change', [
        'ignore_addresses',
        [
          ...this.ignoreAddresses.slice(0, index),
          ...this.ignoreAddresses.slice(index + 1)
        ]
      ])
    }
  }
}
</script>
