import moment from 'moment-timezone'
import padStart from 'lodash/padStart'
import get from 'lodash/get'
import { applyOffset, humanizeOffset } from 'shared/util/time/offset'

moment.tz.setDefault('UTC')

const time = ({ params = {}, options = {}, context = {} } = {}) => {
  let timezone = get(params, 'timezone') || get(options, 'timezone') || 'UTC'

  if (!moment.tz.zone(timezone)) {
    timezone = 'UTC'
  }

  const format = params.format || ''

  let value
  if (params.value) {
    value = params.value
  }
  value = value || null

  // If value is an object (such as {y: 2017, m: 10, d: 2, v: 2017})
  if (value && typeof value === 'object') {
    if (value.v) {
      value = value.v
    } else if (value.y && value.m && value.d) {
      value = padStart(value.y + '', 4, '0') + '-' + padStart(value.m + '', 2, '0') + '-' + padStart(value.d + '', 2, '0')
    } else if (value.m && value.d) {
      value = '0000-' + padStart(value.m + '', 2, '0') + '-' + padStart(value.d + '', 2, '0')
    }
  }

  // If it's just time then prepend today's date on to it so moment recognizes it as a date
  if (value && /^[0-9]{1,2}:[0-9]{1,2}(:[0-9]{1,2})?$/.test(value)) {
    const tempMoment = moment().tz(timezone)
    const keys = ['hour', 'minute', 'second']
    value.split(':').forEach((part, i) => {
      tempMoment[keys[i]](part)
    })
    value = tempMoment.format()
  } else if (!params.parse && !isNaN(value) && 1 * value > 0) {
    params.parse = 'X'
  }

  let date
  if (params.parse === 'X') {
    date = moment.unix(value).tz(timezone || 'UTC')
  } else {
    date = moment(value || undefined, params.parse || '').tz(timezone || 'UTC')
  }

  return applyOffset(params.offset, date).format(format)
}

export default time

const humanizeFormat = (format = '') => {
  if (format) {
    return 'in [' + format + '] format'
  } else {
    return 'in ISO8601 (default) format'
  }
}

const humanizeCall = ({ params = {} }) => {
  return `Time ${humanizeFormat(get(params, 'format'))}${get(params, 'offset') ? ' ' + humanizeOffset(params.offset) : ''}`
}

export {
  humanizeFormat,
  humanizeCall
}
