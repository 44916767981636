import get from 'lodash/get'

/**
 * Returns the short name or falls back to account name
 * @param {Object} account account object
 */
export const getName = ({ account }) => {
  return account?.settings?.short_name ||
    account?.display_name ||
    account?.name
}

/**
 * The account name is required to exist in SMS messages sent by gateways without
 * an account id for whose account id doesn't match the sending account id
 */
export const isRequired = ({
  account,
  gateway
}) => {
  return get(account, '_id') &&
    get(gateway, 'channel') === 'SMS' && (
    !get(gateway, 'account_id') ||
    gateway.account_id + '' !== account._id + '' ||
    !account?.settings?.sms_bypass_account_name
  )
}

export const isIncluded = ({
  account,
  text = ''
}) => {
  const shortName = ((get(account, 'settings.short_name') || '') + '').toLowerCase()
  if (shortName && ((text || '') + '').toLowerCase().includes(shortName)) {
    return true
  }
  const displayName = ((get(account, 'display_name') || '') + '').toLowerCase()
  if (displayName && ((text || '') + '').toLowerCase().includes(displayName)) {
    return true
  }
  const name = ((get(account, 'name') || '') + '').toLowerCase()
  if (name && ((text || '') + '').toLowerCase().includes(name)) {
    return true
  }
  return false
}

export const addIfNeeded = ({ account, gateway, text }) => {
  if (!isRequired({ account, gateway, text })) {
    return {
      accountNameAdded: false,
      text
    }
  }
  if (!isIncluded({ account, gateway, text })) {
    const accountName = getName({ account, gateway, text })
    return {
      accountNameAdded: accountName,
      text: `${accountName}: ${text}`
    }
  }

  return {
    accountNameAdded: false,
    text
  }
}

export default {
  getName,
  isRequired,
  isIncluded,
  addIfNeeded
}
