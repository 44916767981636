// Converts a rule set that looks like:
// ['AND', {first_name: {$eq: Ben}}]
// into
// { $and: [{first_name: {$eq: Ben}}]}
import isEmpty from '../isEmpty'

const buildQueryRules = (rules = [], schemas, options = {}) => {
  if (isEmpty({ where: rules || [] })) {
    return {}
  }

  let conj = '$and'
  if (typeof rules[0] === 'string' && rules[0].toUpperCase() === 'OR') {
    conj = '$or'
  }

  rules = rules.filter(r => typeof r === 'object' || Array.isArray(r))

  // Default to an empty $and if no conjunction was provided
  return { [conj]: rules }
}

export default buildQueryRules
