import get from 'lodash/get'
import imageUrl from './imageUrl'
import { schemaForPath } from 'shared/field-ops'

const isImageButNoHttp = /^(?!http).*\.(jpg|gif|png)/i // make sure this never gets the g modifier, that makes the regex itself stateful
const boolTypeMap = {
  true: 'Yes',
  false: 'No'
}

/**
 * This is a method Admiral created to address the need to be able to reference contact values within waivers
 * in a clean/hydrated/friendly form.  It's preferable to have the fully qualified url for files rather than just the filename.
 * This also returns Yes/No for booleans and some other functionality for dealing with images that are cached locally the old
 * way (base64 images tokenized in an object on window so they didn't need to pass the whole image through the filters for performance).
 *
 * This method should remain undocumented for now and only used for documents.  If a "clean" or "friendly" value is desired we should just
 * create a friendly() function that does this same thing and decouples it from the "get()" functionality.
 */

export default ({ params = {}, options = {}, context = {} } = {}) => {
  if (typeof params !== 'object') {
    params = { path: params }
  }

  const path = params.path || params.param || ''
  if (!path) {
    return ''
  }

  let schemaPath = path
  let valuePath = path
  let valueAtPath = get(context, schemaPath)
  const schemaSegments = schemaPath.split('.')
  let result = valueAtPath

  if (
    schemaSegments[0] === 'contact' &&
    options.schemas
  ) {
    schemaSegments.shift()
    let parentSchema
    if (schemaSegments.length > 1) {
      parentSchema = schemaForPath(options.schemas, schemaSegments[0])
    }
    schemaPath = schemaSegments.join('.')
    const schema = schemaForPath(options.schemas, schemaPath)
    if (schema) {
      if (
        parentSchema &&
        parentSchema.multiple &&
        parentSchema.type === 'object'
      ) {
        const index = get(context, `${schemaSegments[0]}-index`) || get(context, 'index') || 0
        valuePath = path.replace(parentSchema.key, `${parentSchema.key}[${index}]`)
        valueAtPath = get(context, valuePath)
        result = valueAtPath
      }

      if (schema.type === 'image') {
        return imageUrl({ params: { path: valuePath }, options, context })
      } else if (schema.type === 'checkbox') {
        result = boolTypeMap[valueAtPath]
      }
    }
  }
  if (
    valueAtPath &&
    typeof valueAtPath === 'string' &&
    (
      isImageButNoHttp.test(valueAtPath) ||
      valueAtPath.includes('cg-local-base64:')
    )
  ) {
    result = imageUrl({ params, options, context })
  }
  /*
  console.log({
    key,
    schemaPath,
    env,
    valuePath,
    valueAtPath,
    schemaSegments,
    result
  })
  */

  return result
}

const humanizeCall = (params) => {
  return 'Call to get'
}

export {
  humanizeCall
}
