import Trigger from '../Trigger'

class OrderAbandoned extends Trigger {
  static get title () {
    return 'Abandoned Cart'
  }

  static get class () {
    return 'OrderAbandoned'
  }

  static get restrict () {
    return { 'addons.type': 'SHOPIFY' }
  }

  // These are deprecated in favor of shared/schemas/events/*
  get schemas () {
    return [
      { key: 'client_id', name: 'Client ID', type: 'text' },
      { key: 'total_amount', name: 'Total Price (in cents)', type: 'number' }
    ]
  }
}

export default OrderAbandoned
