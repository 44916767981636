import Trigger from '../Trigger'

class ConversationFinished extends Trigger {
  static get title () {
    return 'Chat Completed'
  }

  static get class () {
    return 'ConversationFinished'
  }

  static get restrict () {
    return { 'addons.type': 'MESSENGER' }
  }
}

export default ConversationFinished
