import button from './button'
import column from './column'
import divider from './divider'
import heading from './heading'
// import html from './html'
import image from './image'
import row from './row'
import snippet from './snippet'
import social from './social'
import spacer from './spacer'
import text from './text'
import unsubscribe from './unsubscribe'

const blocks = [
  heading,
  text,
  image,
  button,
  divider,
  spacer,
  // html,
  social,
  column,
  unsubscribe,
  row,
  snippet
]

export default blocks

const generate = (id) => {
  const block = blocks.find(block => block.id === id)
  if (!block) {
    throw new Error('Block not found: ' + id)
  }

  let instance = {}
  if (typeof block.factory === 'function') {
    instance = {
      type: id,
      ...block.factory()
    }
  }

  return instance
}

export {
  generate,
  button,
  column,
  divider,
  heading,
  // html,
  image,
  row,
  snippet,
  social,
  spacer,
  text
}
