import Trigger from '../Trigger'

class OrderShipped extends Trigger {
  static get title () {
    return 'Order Shipped'
  }

  static get class () {
    return 'OrderShipped'
  }

  static get restrict () {
    return { 'addons.type': 'SHOPIFY' }
  }
}

export default OrderShipped
