var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fixed-menu',{staticClass:"cg-schemas-picker",attrs:{"props":{
    fixed: true,
    offsetY: true,
    minWidth: _vm.$vuetify.breakpoint.smAndDown ? 150 : 300,
    closeOnContentClick: false,
    left: _vm.left,
    top: _vm.pickerDropdownTop
  }},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('tooltip-button',_vm._g(_vm._b({ref:"activator"},'tooltip-button',{
        badgeValue: _vm.filterType !== 'all' || _vm.tagsSelected.length > 0,
        badgeDot: true,
        icon: 'filter',
        iconSize: '20',
        buttonSize: 'large',
        tooltip: 'Type of Filter',
        caret: true
      },false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticStyle:{"max-height":"500px","overflow-y":"auto"}},[_c('v-card-text',{staticClass:"pt-0"},[_c('p',{staticClass:"mt-2"},[_vm._v("Type")]),_c('v-radio-group',{attrs:{"hide-details":""},on:{"change":_vm.handleInput},model:{value:(_vm.filterType),callback:function ($$v) {_vm.filterType=$$v},expression:"filterType"}},_vm._l((_vm.groupOptions),function(item){return _c('v-radio',{key:item.text,attrs:{"label":item.text,"value":item.value,"hide-details":""}})}),1),(_vm.tagOptions.length > 0)?[_c('v-divider',{staticClass:"mt-4"}),_c('p',{staticClass:"mt-2"},[_vm._v("Tags")]),_vm._l((_vm.tagOptions),function(item){return _c('v-checkbox',{key:item.text,attrs:{"label":item.text,"value":item.value,"hide-details":""},on:{"change":_vm.handleInput},model:{value:(_vm.tagsSelected),callback:function ($$v) {_vm.tagsSelected=$$v},expression:"tagsSelected"}})})]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }