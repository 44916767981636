import Action from '../Action'
import colors from 'shared/colors'

class Eject extends Action {
  static get title () {
    return 'Eject'
  }

  static get class () {
    return 'Eject'
  }

  static get noConfiguration () {
    return true
  }

  static get color () {
    return colors.error
  }
}

export default Eject
