import Trigger from '../Trigger'

class DocumentFinished extends Trigger {
  static get title () {
    return 'Waiver Signed'
  }

  static get class () {
    return 'DocumentFinished'
  }

  static get restrict () {
    return { 'addons.type': 'WAIVERS' }
  }

  // These are deprecated in favor of shared/schemas/events/*
  get schemas () {
    return [
      { key: 'document_id', name: 'Document ID', type: '_id', resource: 'waivers' },
      { key: 'client_id', name: 'Client ID', type: 'text' },
      { key: 'duration', name: 'Duration (in seconds)', type: 'number' },
      { key: 'flow_id', name: 'Kiosk Flow ID', type: 'text' },
      { key: 'device_id', name: 'Kiosk Device ID', type: 'number' }
    ]
  }
}

export default DocumentFinished
