import Action from '../Action'
import colors from 'shared/colors'

class DeleteContact extends Action {
  static get title () {
    return 'Delete Contact'
  }

  static get class () {
    return 'DeleteContact'
  }

  static get restrict () {
    return { 'user.level': { $gte: 5 } }
  }

  static get color () {
    return colors.error
  }

  static get noConfiguration () {
    return true
  }
}

export default DeleteContact
