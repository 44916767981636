import createPaginationMixin from 'ui/mixins/createPaginationMixin'
import PickerDialogUi from './PickerDialogUi'
import { upperFirst } from 'lodash'

export default (Model) => {
  return {
    mixins: [
      createPaginationMixin(Model, {
        requestOptionsPath: 'requestOptions',
        prefix: 'picker'
      })
    ],
    components: {
      PickerDialogUi
    },
    props: {
      requestOptionsParams: {
        type: Object,
        default: undefined
      }
    },
    data () {
      return {
        search: ''
      }
    },
    computed: {
      requestOptions () {
        return {
          params: {
            ...(this.requestOptionsParams || {}),
            ...(this.search ? { search: this.search } : {})
          }
        }
      },
      pickerProps () {
        return {
          name: upperFirst(((Model.entity || '') + '').toLowerCase()),
          loading: this.pickerLoading,
          model: Model,
          records: this.pickerRecords,
          search: this.search,
          filters: this.filters,
          pagination: this.pickerPagination,
          paginator: this.pickerPaginator
        }
      },
      pickerOn () {
        const vm = this
        return {
          'update:search' (value) {
            vm.search = value
          },
          'update:filters' (value) {
            vm.filters = value
          },
          close () {
            if (vm.loading) {
              return
            }
            vm.$emit('close')
          },
          select (record) {
            vm.$emit('close', { record })
          },
          load () {
            return vm.pickerPaginationLoad()
          },
          restrictAccount (value) {
            vm.pickerPaginationData.restrictAccount = value
            vm.pickerPaginationLoad()
          }
        }
      }
    },
    created () {
      this.pickerPaginationLoad()
    }
  }
}
