import Model from './Model'

class Blast extends Model {
  static entity = 'blasts'

  static evictUnused = true

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      active: this.attr(undefined),
      archived: this.attr(undefined),
      channels: this.attr(undefined),
      class: this.attr(undefined),
      type: this.attr(undefined), // Will always be "ONCE" for blasts
      status: this.attr(undefined),
      contact_count: this.attr(undefined),
      last_run_at: this.attr(undefined),
      next_run_at: this.attr(undefined),
      use_credit: this.attr(undefined),
      actions: this.attr(undefined),
      filter: this.attr(undefined),
      message: this.attr(undefined),
      trigger: this.attr(undefined),
      stats: this.attr(undefined),
      permissions: this.attr(undefined),
      created_at: this.attr(undefined),
      created_by: this.attr(undefined),
      updated_at: this.attr(undefined),
      updated_by: this.attr(undefined)
    }
  }
}

export default Blast
