import BaseField from './_BaseField'
import moment from 'moment-timezone'
import padStart from 'lodash/padStart'
import strtotime from './lib/strtotime'
import get from 'lodash/get'

const dateIsValid = (value) => (
  value &&
  typeof value === 'object' &&
  (
    Number.isInteger(value.y) ||
    Number.isInteger(value.m) ||
    Number.isInteger(value.d)
  )
)

class CGDate extends BaseField {
  static sanitize ({ value, options }) {
    const timezone = get(options, 'timezone') || 'UTC'
    if (typeof value === 'string') {
      const originalValue = value
      let stringSegments = []
      // If value is in ISO8601 format, evaluate in UTC
      // This does take into account the timezone offset within the timestamp
      let m = null
      let removeYear = false

      if ((value + '').match(/^(\d{4}-\d\d-\d\d([tT][\d:.]*){1})([zZ]|([+-])(\d\d):?(\d\d))?$/)) {
        m = moment.tz(value, 'UTC')
        stringSegments = originalValue.split(/[-:tT zZ.]/)
        // m.tz(options.timezone || 'UTC');
      } else if ((value + '').match(/^(\d{4}-\d\d-\d\d{1})$/)) {
        m = moment.tz(value, 'UTC')
        stringSegments = originalValue.split('-')
        // m.tz(options.timezone || 'UTC');
      } else if (value === 'now') {
        m = moment().tz(timezone)
      } else {
        if (value.length >= 5 || ((value.length === 4 || value.length === 3) && isNaN(value))) { // Dates must be at least 5 characters long or 4 characters long and not a number (ie 11/5 or 11-5).  Anything less is unacceptable.
          if (/^[0-9]{1,2}[/-][0-9]{1,2}$/.test(value)) {
            value = value.replace('-', '/') + '/' + moment().tz(timezone).format('YYYY')
          }

          const ts = strtotime(value)
          if (ts === false) { // If the format could not be parsed
            return null
          }

          m = moment.unix(ts)

          // If there is a year but that year is not found in the date, assume the date library automatically generated the year
          removeYear = true
        }
      }

      if (!m) {
        return null
      }

      const momentValues = {
        y: m.year(),
        m: m.month() + 1,
        d: m.date()
      }

      value = JSON.parse(JSON.stringify({ // purges the undefined values
        y: momentValues.y * 1 || parseInt(stringSegments[0]) || undefined,
        m: momentValues.m * 1 || parseInt(stringSegments[1]) || undefined,
        d: momentValues.d * 1 || parseInt(stringSegments[2]) || undefined
      }))

      if (removeYear && value.y && value.y > 0 && originalValue.indexOf(value.y + '') === -1) {
        delete value.y
      }
    }

    if (!dateIsValid(value)) {
      return null
    }

    value.v = [
      (value.y || '0000'),
      padStart(value.m || 0, 2, '0'),
      padStart(value.d || 0, 2, '0')
    ].join('-')
    return value
  }

  // After it's been sanitized there is no reason to convert it to any specific
  static unsanitize ({ value }) {
    if (typeof value === 'object' && value && Object.keys(value).length > 0) {
      if (value && value.v) {
        return value.v
      } else if (value.m && value.d) {
        return padStart(value.y || 0, 4, '0') + '-' + padStart(value.m || 0, 2, '0') + '-' + padStart(value.d || 0, 2, '0')
      }
      return null
    } else if (typeof value === 'string' && value) {
      return value
    }
    return null
  }
}

export default CGDate
