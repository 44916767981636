<template>
  <v-stepper
    :value="value"
    @change="$emit('input', $event)"
    class="offer-stepper d-flex flex-column fill-height"
    v-bind="{ disabled }"
  >
    <v-stepper-header>
      <div class="d-flex align-center pa-2 pr-1 pl-sm-5 pr-sm-10">
        <v-btn
          fab
          x-small
          color="primary"
          :disabled="value * 1 < 2"
          @click="$emit('input', (value * 1) - 1)"
        >
          <v-icon>fa fa-chevron-left</v-icon>
        </v-btn>
      </div>
      <v-stepper-step
        step="1"
        editable
        color="accent"
        edit-icon="$complete"
        :rules="validators[0]"
        :complete="value * 1 !== 1 && maxStepReached * 1 > 1"
        v-bind="{ disabled }"
      >Details</v-stepper-step>

      <v-divider />

      <v-stepper-step
        step="2"
        editable
        color="accent"
        edit-icon="$complete"
        :complete="value * 1 !== 2 && maxStepReached * 1 > 2"
        :rules="validators[1]"
        v-bind="{ disabled }"
      >Design</v-stepper-step>

      <v-divider />

      <v-stepper-step
        step="3"
        editable
        color="accent"
        edit-icon="$complete"
        :complete="value * 1 !== 3 && maxStepReached * 1 > 3"
        :rules="validators[2]"
        v-bind="{ disabled }"
      >Advanced</v-stepper-step>

      <v-divider />

      <v-stepper-step
        v-if="record && record._id"
        step="4"
        editable
        color="accent"
        edit-icon="$complete"
        v-bind="{ disabled }"
      >Promote</v-stepper-step>
      <div class="d-flex align-center pa-2 pl-1 pl-sm-10 pr-sm-5">
        <v-btn
          v-if="value + '' === '3' && !record._id"
          color="primary"
          :disabled="disabled"
          @click="$emit('save-enable')"
        >Save &amp; Enable</v-btn>
        <v-btn
          v-else
          fab
          x-small
          color="primary"
          :disabled="disabled || value * 1 > 3"
          @click="$emit('input', (value * 1) + 1)"
        >
          <v-icon>fa fa-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-stepper-header>
    <v-container
      fluid
      style="height: calc(100% - 72px);"
      class="pa-0"
    >
      <v-row
        class="fill-height ma-0"
      >
        <v-col
          cols="12"
          md="7"
          class="pt-0 fill-height"
          style="overflow-y: auto"
        >
          <v-stepper-items>
            <div>
              <v-stepper-content
                step="1"
                class="pa-0"
              >
                <slot name="details" />
              </v-stepper-content>
              <v-stepper-content
                step="2"
                class="pa-0"
              >
                <slot name="design" />
              </v-stepper-content>
              <v-stepper-content
                step="3"
                class="pa-0"
              >
                <slot name="advanced" />
              </v-stepper-content>
              <v-stepper-content
                step="4"
                class="pa-0"
              >
                <slot name="promote" />
              </v-stepper-content>
            </div>
          </v-stepper-items>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          class="fill-height"
          cols="12"
          md="5"
          style="
            max-height: 100%;
            overflow-y: auto;
          "
        >
          <offer-preview
            :value="record"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-stepper>
</template>

<style lang="scss">
.offer-stepper {
  .v-stepper__header {
    box-shadow: none;
    border-bottom: 1px $darkBorder solid;
    min-height: 72px;
  }
  .v-stepper__items {
    flex-grow: 1 !important;
  }
  .v-stepper__content, .v-stepper__wrapper {
    height: 100%;
  }
  .v-stepper__wrapper {
    padding-right: 2px;
  }
  .v-stepper__label {
    text-shadow: none !important;
  }
}
</style>

<script>
import OfferPreview from 'portal/src/modules/Offers/OfferEditor/OfferPreview'
export default {
  components: {
    OfferPreview
  },
  props: {
    value: {
      type: [Number, String],
      default: '1'
    },
    record: {
      type: Object,
      default: null
    },
    validators: {
      type: Array,
      default: () => []
    },
    maxStepReached: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasChannelSelected () {
      return !!(this.record &&
        this.record.message &&
        this.record.message.props &&
        this.record.message.props.content &&
        this.record.message.props.content.channel)
    }
  }
}
</script>
