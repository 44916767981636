import get from 'lodash/get'
import upperFirst from 'lodash/upperFirst'

import Checkbox from './Checkbox'
import CGDate from './Date'
import Id from './Id'
import Image from './Image'
import CGNumber from './Number'
import CGObject from './Object'
import Password from './Password'
import Punchcard from './Punchcard'
import Text from './Text'
import Time from './Time'
import Timestamp from './Timestamp'

const fieldTypeClasses = {
  Checkbox,
  Date: CGDate,
  Id,
  Image,
  Number: CGNumber,
  Object: CGObject,
  Password,
  Punchcard,
  Text,
  Time,
  Timestamp
}

const getClassForSchema = (schema) => {
  if (get(schema, 'type')) {
    // Replace the _ for the _id field type.
    // I just didn't want to name a class filename "_id" as it was inconsistent
    // with the rest of the filenames.
    const typeId = upperFirst(schema.type.replace('_', ''))
    const fieldTypeClass = fieldTypeClasses[typeId]
    return fieldTypeClass
  }
}

export { getClassForSchema }

export default {
  ...fieldTypeClasses,
  getClassForSchema // For convenience
}
