const entity = 'Filter'
const path = 'filter'

const schemas = {
  _id: { type: '_id', name: 'ID' },
  account_id: { type: 'number', name: 'Account' },
  name: { type: 'text', name: 'Name' },
  type: { type: 'text', name: 'Type' },
  created_at: { type: 'timestamp', name: 'Date Created' },
  updated_at: { type: 'timestamp', name: 'Date Updated' },
  tags: { type: 'text', name: 'Tags' }
}

const getSchemas = ({ addons } = {}) => {
  return schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path
}
