import Vue from 'vue'
import { merge } from 'lodash'
import createStore from 'ui/store/createStore'
// import LogRocket from 'logrocket'
import contactFieldsGetters from 'ui/store/contactFieldsGetters'
// import isPrivateBetaUser from './isPrivateBetaUser'
import widgets from './modules/widgets'
import shopify from './modules/shopify'
import roadblock from './modules/roadblock'
import { Gateway, Indicator } from 'ui/models'

const broadcastChannel = new BroadcastChannel('patch-service-worker')

broadcastChannel.onmessage = event => {
  console.log('broadcast channel message', event)
  if (event?.data.key === 'pushsubscriptionchange') {
    Vue.$http({
      url: '/v2/device/update_push',
      method: 'POST',
      data: {
        old_token: event.data?.oldSubscription?.endpoint,
        push: {
          subscription: JSON.parse(JSON.stringify(event?.data?.subscription))
        }
      }
    })
  }
}

const store = createStore({
  Vue,
  bootstrapModels: {
    Fieldset: {},
    Gateway: {},
    Indicator: {}
  },
  vuexConfig: merge({
    state: {
      appChunkLoading: false,
      appDrawerLeft: false,
      signupOverride: false, // Whether to override "Welcome to patch" dialog when account has SIGNUP status
      failedChargeOverride: false
    },
    getters: {
      environment (state, getters) {
        return {
          account: getters.account,
          user: getters.user,
          accountUser: getters.accountUser,
          timezone: getters.timezone,
          addons: getters.addons,
          schemas: getters.schemas,
          gateways: Gateway.all(),
          addons_original: state.addonsOriginal // Temporary until granular privileges are implemented
        }
      },
      socketState (state, getters) {
        return {
          user_id: state.userId,
          account_id: getters.account?._id
        }
      }
    },
    mutations: {
      setAppChunkLoading (state, value) {
        state.appChunkLoading = value
      },
      setSignupOverride (state, value) {
        state.signupOverride = value
      },
      setFailedChargeOverride (state, value) {
        state.failedChargeOverride = value
      },
      setAppDrawerLeft (state, value) {
        state.appDrawerLeft = value
      }
    },
    modules: {
      widgets,
      shopify,
      roadblock
    }
  }, contactFieldsGetters),
  callbacks: {
    async afterLogin () {
      store.commit('setSignupOverride', false)
      store.commit('setFailedChargeOverride', false)
      initLogRocket(store.getters) // Passes in user/account
    },
    async afterSelectAccount () {
      const socket = require('@/plugins/socket').default
      if (socket) {
        socket.sendState()
      }
    },
    async afterRootBootstrap () {
      initLogRocket(store.getters) // Passes in user/account
      if (store.getters['shopify/addon']) {
        store.dispatch('shopify/getSyncStatus')
      }
    },
    async handleRecordUpdate (context, { entity }) {
      // Update indicators on portal
      if (['conversations', 'scheduled_messages'].includes(entity)) {
        await Indicator.fetchOne('MESSENGER', { force: true })
      }
    }
  }
})

const initLogRocket = ({ user, account, addons }) => {
  // if (
  //   process.env.NODE_ENV === 'production'
  // ) {
  //   if (store.getters.user?._uid) {
  //     LogRocket.init(
  //       'ewgcty/patch-portal',
  //       {
  //         mergeIframes: true
  //       }
  //     )
  //     LogRocket.identify(
  //       store.getters.user?._uid,
  //       {
  //         name: store.getters.user.friendlyName,
  //         email: store.getters.user.email,
  //         ...(store.getters.account?._id
  //           ? {
  //               account_id: store.getters.account._id,
  //               account_name: store.getters.account.name
  //             }
  //           : {})
  //       }
  //     )
  //   }
  // }
}

// If it's desirable to add anything else to window for development purposes,
// this is a good place.
window.$getVue = () => Vue
window.$getStore = () => store
window.$countStoreRecords = () => {
  let count = 0
  Object.entries(store.state.entities).forEach(([key, entity]) => {
    if (key && key.startsWith('$')) {
      return
    }
    count += Object.values(entity.data || {}).length
  })
  return count
}

export default store
