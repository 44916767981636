import toString from 'lodash/toString'

// This method intelligently finds all smarttext variables in a string of text
// and runs them through a transformer function which accepts the matched text
// and returns the text to replace it with
export default (text, transformer) => {
  if (typeof transformer !== 'function') {
    transformer = (match) => match
  }
  text = toString(text || '')
  const _variableRegex = new RegExp(variableRegex.source, 'ig')
  text = text.replace(_variableRegex, function (path) {
    return transformer(path)
  })
  return text
}

const variableRegex = new RegExp(/(\{{2}(.*?)\}{2})/, 'ig')

export {
  variableRegex
}
