import '@fortawesome/fontawesome-free/css/all.css'
import '@fontsource/nunito'
// With custom fonts, you must explicitly require the 800 size font as well, or iOS does
// a _terrible_ job of **faking** its own "bold" version of the font, which is actually
// just 3 copies of the same glyph overlapped and offset on the X axis by a random width.
// On a sharp Serif font, this would create serrated blades that could kill.
import '@fontsource/nunito/800.css'
import '@fontsource/poppins'
import Vue from 'vue'
import Vuetify from 'vuetify'
import ripple from 'vuetify/lib/directives/ripple'
import resize from 'vuetify/lib/directives/resize'
import 'vuetify/src/styles/styles.sass'
import { light, dark } from 'shared/colors'

Vue.use(Vuetify, {
  directives: {
    // It appears we're doing a-la-carte, so need to add both of these
    ripple,
    resize
  }
})

const vuetify = new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      clear: 'fa-times',
      complete: 'fa-check'
    }
  },
  theme: {
    themes: {
      light: {
        ...light
      },
      dark: {
        ...dark
      }
    }
  }
})

window.$getVuetify = () => vuetify

export default vuetify
