<template>
  <v-card
    class="snooze-picker cg-editor with-footer"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>Add Note</v-toolbar-title>

      <v-spacer />

      <v-btn
        icon
        :disabled="loading"
        @click="requestClose"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
      class="pb-0"
    >
      <v-textarea
        outlined
        hint="This note will not be sent to this contact."
        v-model="text"
        :disabled="loading"
        persistent-hint
        class="mb-0"
      />
    </v-container>
    <div class="footer pa-2">
      <div
        v-if="loading"
        class="text-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>
      <div v-else>
        <v-btn
          color="primary"
          outlined
          :disabled="loading"
          @click="requestClose"
        >Cancel</v-btn>

        <v-btn
          color="primary"
          class="ml-2"
          :disabled="loading || !contactId"
          @click="save"
        >Save</v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import NoteDialogMixin from './shared/NoteDialogMixin'

export default {
  oWindow: {
    width: 400,
    height: 308,
    persistent: true,
    overlay: true
  },
  mixins: [
    NoteDialogMixin
  ]
}
</script>
